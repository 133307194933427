import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetZeitraumQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type GetZeitraumQuery = { zeitraum?: Types.Maybe<{ __typename?: 'Zeitraum', id: number, zeitraumVon: string, zeitraumBis: string }> };


export const GetZeitraumDocument = gql`
    query getZeitraum($id: Int!) {
  zeitraum(id: $id) {
    id
    zeitraumVon
    zeitraumBis
  }
}
    `;

/**
 * __useGetZeitraumQuery__
 *
 * To run a query within a React component, call `useGetZeitraumQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetZeitraumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetZeitraumQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetZeitraumQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetZeitraumQuery, GetZeitraumQueryVariables>) {
        return ApolloReactHooks.useQuery<GetZeitraumQuery, GetZeitraumQueryVariables>(GetZeitraumDocument, baseOptions);
      }
export function useGetZeitraumLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetZeitraumQuery, GetZeitraumQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetZeitraumQuery, GetZeitraumQueryVariables>(GetZeitraumDocument, baseOptions);
        }
export type GetZeitraumQueryHookResult = ReturnType<typeof useGetZeitraumQuery>;
export type GetZeitraumLazyQueryHookResult = ReturnType<typeof useGetZeitraumLazyQuery>;
export type GetZeitraumQueryResult = ApolloReactCommon.QueryResult<GetZeitraumQuery, GetZeitraumQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
