import React, { useState, useMemo } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { SetterOrUpdater, useRecoilState } from "recoil";

import { ResultType, GetStudiengangsQuery } from "graphqlBase/Studiengang/__generated__/getStudiengangs";
import { ModulZuordnungen, moduleDropdownIds } from "components/PagesCampusManagement/produkte/Module/recoilStates";
import TrashOutlinedIcon from "icons/components/TrashIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      maxWidth: "2em",
      fontSize: 25,
      height: 40,
      color: "rgba(0, 0, 0, 0.54)",
      "&:hover": {
        backgroundColor: "unset",
      },
    },
    deleteContainer: {
      display: "flex",
      alignItems: "center",
    },
  })
);

type Studiengang = ResultType<GetStudiengangsQuery["studiengangs"]>;

interface ModulZuordnungRowProps {
  modul: ModulZuordnungen;
  setModulZuweisungList: SetterOrUpdater<ModulZuordnungen[]>;
  studiengangs: Studiengang[];
}

const ModulZuordnungRow: React.FC<ModulZuordnungRowProps> = ({ modul, setModulZuweisungList, studiengangs }) => {
  const classes = useStyles();
  const [currentSelectedStudiengangOption, setCurrentSelectedStudiengangOption] = useState(`${modul.studiengangId}`);
  const [currentModuleDropdownIds, setCurrentModuleDropdownIds] = useRecoilState(moduleDropdownIds);
  const memoizedStudiengangs = useMemo(
    () =>
      studiengangs.filter((studiengang) =>
        [...currentModuleDropdownIds, parseInt(currentSelectedStudiengangOption)].includes(studiengang.id)
      ),
    [studiengangs, currentModuleDropdownIds]
  );

  const setCurrentModulZuweisung = (value: Partial<ModulZuordnungen> | { delete: true }) => {
    setModulZuweisungList((notificationDefinitions) => {
      const newNotificationDefinitions = notificationDefinitions.reduce<ModulZuordnungen[]>(
        (newNotificationDefinitionsInner, notificationDefinitionInner) => {
          if (modul.id === notificationDefinitionInner.id) {
            if (Object.keys(value).includes("delete")) {
              return newNotificationDefinitionsInner;
            }
            return newNotificationDefinitionsInner.concat({ ...notificationDefinitionInner, ...value });
          }
          return newNotificationDefinitionsInner.concat(notificationDefinitionInner);
        },
        []
      );
      return newNotificationDefinitions;
    });
  };

  const handleChangePre = (key: keyof ModulZuordnungen) => (event: React.ChangeEvent<{ value: unknown }>) => {
    let value: number | string = event.target.value as string;
    if (key === "studiengangId") {
      setCurrentSelectedStudiengangOption(value);
    }
    value = parseInt(value);
    setCurrentModulZuweisung({ [key]: value });
  };

  const studiengangOptions = useMemo(
    () =>
      memoizedStudiengangs.map((studiengang) => ({
        label: studiengang.studiengangName ?? "",
        value: `${studiengang.id}`,
      })),
    [memoizedStudiengangs]
  );

  const fachsemesterOptions = useMemo(
    () =>
      [
        { label: "1. Semester", value: "1" },
        { label: "2. Semester", value: "2" },
        { label: "3. Semester", value: "3" },
        { label: "4. Semester", value: "4" },
        { label: "5. Semester", value: "5" },
        { label: "6. Semester", value: "6" },
        { label: "7. Semester", value: "7" },
      ].map((planSemester) => ({
        label: planSemester.label,
        value: planSemester.value,
      })),
    []
  );

  return (
    <>
      <Grid item xs={4}>
        <Select
          id="studiengang_modulzuweisung"
          onChange={handleChangePre("studiengangId")}
          fullWidth
          variant="outlined"
          value={currentSelectedStudiengangOption || ""}
          renderValue={(selected) =>
            studiengangOptions.reduce<string>(() => {
              return studiengangOptions.find((option) => option.value === selected)?.label ?? "";
            }, "")
          }
        >
          {studiengangOptions &&
            studiengangOptions.map((option, index) => {
              return (
                <MenuItem value={option.value} key={index}>
                  {option.label}
                </MenuItem>
              );
            })}
        </Select>
      </Grid>
      <Grid item xs={4}>
        <Select
          fullWidth
          variant="outlined"
          id="plansemester_modulzuweisung"
          onChange={handleChangePre("planSemester")}
          value={modul.planSemester ? `${modul.planSemester}` : ""}
          renderValue={(selected) =>
            fachsemesterOptions.reduce<string>(() => {
              return fachsemesterOptions.find((option) => option.value === selected)?.label ?? "";
            }, "")
          }
        >
          {fachsemesterOptions &&
            fachsemesterOptions.map((option, index) => {
              return (
                <MenuItem value={option.value} key={index}>
                  {option.label}
                </MenuItem>
              );
            })}
        </Select>
      </Grid>
      <Grid item xs={2} className={classes.deleteContainer}>
        <Button
          className={classes.button}
          aria-label="delete"
          onClick={() => {
            setCurrentModulZuweisung({ delete: true });
          }}
        >
          <Typography variant="h4">
            <TrashOutlinedIcon width="1.2em" height="1.2em" />
          </Typography>
        </Button>
      </Grid>
    </>
  );
};

export default ModulZuordnungRow;
