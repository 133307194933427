import { IconButton, Modal as MuiModal, ModalProps as MuiModalProps, Paper, Typography } from "@material-ui/core";
import Button from "components/lbc-toolkit/atomics/Button/DefaultButton";
import CloseIcon from "icons/components/CloseIcon";
import getTestIDs from "lib/utils/getTestIDs";
import React from "react";
import { useStyles } from "./utils";
import translations from "translations";
export const testIDs = getTestIDs();

export interface ModalProps extends MuiModalProps {
  cancelLabel?: string;
  centerButtons?: boolean;
  confirmLabel?: string;
  height?: string | number;
  hideCancel?: boolean;
  hideConfirm?: boolean;
  marginTop?: string | number;
  maxWidth?: string | number;
  onCancel?: () => void;
  onClose: () => void;
  onConfirm: () => void;
  primaryCancel?: boolean;
  title?: string;
  width?: string | number;
}

const Modal: React.FC<ModalProps> = ({
  children,
  classes,
  cancelLabel = translations.globals.button.cancel,
  confirmLabel = translations.globals.button.confirm,
  height = "auto",
  hideCancel = false,
  hideConfirm = false,
  marginTop = "160px",
  onCancel = () => {},
  onClose = () => {},
  onConfirm = () => {},
  open,
  title = "",
  width = "640px",
  centerButtons,
  primaryCancel,
  maxWidth,
}) => {
  const modalStyles = useStyles({ centerButtons });
  const buttonWidth = centerButtons ? undefined : "auto";
  const confirmType = primaryCancel ? "secondary" : "primary";
  const cancelType = primaryCancel ? "primary" : "secondary";
  const customButtonClasses = {
    colorPrimary: modalStyles.cancelButton,
    colorSecondary: modalStyles.confirmButton,
  };

  const handleDialogClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const body = (
    <Paper
      data-testid={testIDs.ModalBody}
      style={{ width, height, top: marginTop, zIndex: 101, maxWidth }}
      className={modalStyles.root}
    >
      <div className={modalStyles.modalHeader}>
        <Typography className={modalStyles.modalHeaderLabel}>{title}</Typography>
        <IconButton data-testid={testIDs.ModalCloseButton} className={modalStyles.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={modalStyles.modalContent}>{children}</div>
      {!(hideCancel && hideConfirm) && (
        <div className={modalStyles.modalFooter}>
          <div>
            {!hideCancel && (
              <Button
                classes={customButtonClasses}
                buttonType={cancelType}
                label={cancelLabel}
                width={buttonWidth}
                onClick={onCancel}
              />
            )}
            {!hideConfirm && (
              <div style={{ marginLeft: centerButtons ? "3em" : "1.5em" }}>
                <Button
                  classes={customButtonClasses}
                  buttonType={confirmType}
                  label={confirmLabel}
                  width={buttonWidth}
                  onClick={onConfirm}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </Paper>
  );

  return (
    <MuiModal
      onClick={handleDialogClick}
      className={modalStyles.root}
      style={{ backgroundColor: "transparent" }}
      classes={classes}
      data-testid={testIDs.Modal}
      open={open}
      onClose={onClose}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(4, 4, 15, 0.8)",
        },
      }}
    >
      {body}
    </MuiModal>
  );
};

export default Modal;
