import {
  makeNewFormMemoized,
  genericDeleteFunction as genericDeleteFunctionPre,
} from "./"
import {
  Operation,
  QueryArray,
  DeleteChildProps,
  BeforeRemoteMuatation,
  BeforeRemoteMuatationProps,
  OnUpdateDataMiddleWare,
  OnUpdateDataMiddleWareProps,
  ValidateForm,
  ValidateFormProps,
} from "@ml-pa/react-apollo-form-tool/formGen.model"
import React from "react"
import * as SchemaTypes from "./internal/schemaTypes"
import { FormTypes, ModifyProps } from "./internal/typeLinks"

export type StudentCreateOnUpdateDataMiddleWare =
  OnUpdateDataMiddleWare<SchemaTypes.StudentInputCreate>
export type StudentCreateOnUpdateDataMiddleWareProps =
  OnUpdateDataMiddleWareProps<SchemaTypes.StudentInputCreate>
export type StudentCreateBeforeRemoteMuatation = BeforeRemoteMuatation<
  SchemaTypes.StudentInputCreate,
  SchemaTypes.StudentInputCreateProps["injectedValues"]
>
export type StudentCreateBeforeRemoteMuatationProps =
  BeforeRemoteMuatationProps<
    SchemaTypes.StudentInputCreate,
    SchemaTypes.StudentInputCreateProps["injectedValues"]
  >
export type StudentCreateValidateForm = ValidateForm<
  SchemaTypes.StudentInputCreate,
  SchemaTypes.StudentInputCreateProps["injectedValues"]
>
export type StudentCreateValidateFormProps = ValidateFormProps<
  SchemaTypes.StudentInputCreate,
  SchemaTypes.StudentInputCreateProps["injectedValues"]
>

export interface StudentCreateProps
  extends SchemaTypes.StudentInputCreateProps {
  ausbildungsArtId: number
  multiInstance?: boolean
  formVersion?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<SchemaTypes.StudentInputCreate>
  afterCreate?: (data: SchemaTypes.StudentInputCreate & { id: number }) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<SchemaTypes.StudentInputCreate>
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.StudentInputCreate,
    SchemaTypes.StudentInputCreateProps["injectedValues"]
  >
  validateForm?: ValidateForm<
    SchemaTypes.StudentInputCreate,
    SchemaTypes.StudentInputCreateProps["injectedValues"]
  >
  updateAfterCreateQueries?: QueryArray
}

export const StudentCreateForm: React.FC<StudentCreateProps> = (props) => {
  const {
      afterCreate,
      injectedValues,
      multiInstance = false,
      formVersion,
      onCancel,
      updateAfterCreateQueries,
      beforeRemoteMuatation,
      onUpdateDataMiddleWare,
      validateForm,
      modifyProps,
      ...params
    } = props,
    { Form: StudentCreate } = makeNewFormMemoized({
      entity: "student",

      operation: "create",
      formVersion,
      params: multiInstance ? params : undefined,
    })
  return (
    <StudentCreate
      params={params}
      onCancel={onCancel}
      injectedValues={injectedValues}
      afterCreate={afterCreate}
      updateAfterCreateQueries={updateAfterCreateQueries}
      beforeRemoteMuatation={beforeRemoteMuatation}
      onUpdateDataMiddleWare={onUpdateDataMiddleWare}
      validateForm={validateForm}
      modifyProps={modifyProps}
    />
  )
}

export type StudentUpdateOnUpdateDataMiddleWare =
  OnUpdateDataMiddleWare<SchemaTypes.StudentInputUpdate>
export type StudentUpdateOnUpdateDataMiddleWareProps =
  OnUpdateDataMiddleWareProps<SchemaTypes.StudentInputUpdate>
export type StudentUpdateBeforeRemoteMuatation = BeforeRemoteMuatation<
  SchemaTypes.StudentInputUpdate,
  SchemaTypes.StudentInputUpdateProps["injectedValues"]
>
export type StudentUpdateBeforeRemoteMuatationProps =
  BeforeRemoteMuatationProps<
    SchemaTypes.StudentInputUpdate,
    SchemaTypes.StudentInputUpdateProps["injectedValues"]
  >
export type StudentUpdateValidateForm = ValidateForm<
  SchemaTypes.StudentInputUpdate,
  SchemaTypes.StudentInputUpdateProps["injectedValues"]
>
export type StudentUpdateValidateFormProps = ValidateFormProps<
  SchemaTypes.StudentInputUpdate,
  SchemaTypes.StudentInputUpdateProps["injectedValues"]
>

export interface StudentUpdateProps
  extends SchemaTypes.StudentInputUpdateProps {
  ausbildungsArtId: number
  id: number
  multiInstance?: boolean
  formVersion?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<SchemaTypes.StudentInputUpdate>
  afterUpdate?: (data: SchemaTypes.StudentInputUpdate) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<SchemaTypes.StudentInputUpdate>
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.StudentInputUpdate,
    SchemaTypes.StudentInputUpdateProps["injectedValues"]
  >
  validateForm?: ValidateForm<
    SchemaTypes.StudentInputUpdate,
    SchemaTypes.StudentInputUpdateProps["injectedValues"]
  >
  fetchAllFieldsOnUpdate?: boolean
}

export const StudentUpdateForm: React.FC<StudentUpdateProps> = (props) => {
  const {
      afterUpdate,
      injectedValues,
      multiInstance = false,
      formVersion,
      onCancel,
      fetchAllFieldsOnUpdate,
      beforeRemoteMuatation,
      onUpdateDataMiddleWare,
      validateForm,
      modifyProps,
      ...params
    } = props,
    { Form: StudentUpdate } = makeNewFormMemoized({
      entity: "student",

      operation: "update",
      formVersion,
      params: multiInstance ? params : undefined,
    })
  return (
    <StudentUpdate
      params={params}
      onCancel={onCancel}
      injectedValues={injectedValues}
      afterUpdate={afterUpdate}
      fetchAllFieldsOnUpdate={fetchAllFieldsOnUpdate}
      beforeRemoteMuatation={beforeRemoteMuatation}
      onUpdateDataMiddleWare={onUpdateDataMiddleWare}
      validateForm={validateForm}
      modifyProps={modifyProps}
    />
  )
}
