import React from "react";
import { Avatar as MuiAvatar, AvatarProps as MuiAvatarProps } from "@material-ui/core";
import getTestIDs from "lib/utils/getTestIDs";

export const testIDs = getTestIDs();
export interface AvatarProps extends MuiAvatarProps {
  backgroundColor?: string;
  color?: string;
  initials: string;
}

const Avatar: React.FC<AvatarProps> = ({
  backgroundColor,
  color,
  initials,
  alt,
  classes,
  imgProps,
  sizes,
  src,
  srcSet,
  variant,
}) => {
  const displayInitials = initials.slice(0, 3);
  return (
    <MuiAvatar
      style={{ backgroundColor, color }}
      sizes={sizes}
      alt={alt}
      imgProps={imgProps}
      data-testid={testIDs.Avatar}
      classes={classes}
      src={src}
      srcSet={srcSet}
      variant={variant}
    >
      {displayInitials}
    </MuiAvatar>
  );
};

export default Avatar;
