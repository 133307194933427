import { Checkbox } from "@material-ui/core";
import React from "react";
import { TableToggleRowsSelectedProps } from "react-table";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      checkboxRow: {
        color: theme.palette.secondary.dark,
        "&$checked": {
          color: theme.palette.secondary.dark,
        },
        "& svg": {
          fontSize: "2em",
        },
      },
      checked: {},
    }),

  { name: `Table-Cells` }
);

export const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, TableToggleRowsSelectedProps>((props, ref) => {
  const { indeterminate, ...rest } = props;
  const defaultRef = React.useRef<HTMLInputElement>();
  const resolvedRef = ref ?? defaultRef;
  const classes = useStyles();
  React.useEffect(() => {
    // @ts-ignore
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <Checkbox
      size="medium"
      classes={{ checked: classes.checked, root: classes.checkboxRow }}
      // @ts-ignore
      ref={resolvedRef}
      {...rest}
    />
  );
});

export const IndeterminateCheckboxRow = React.forwardRef<HTMLInputElement, TableToggleRowsSelectedProps>(
  (props, ref) => {
    const { indeterminate, ...rest } = props;
    const defaultRef = React.useRef<HTMLInputElement>();
    const resolvedRef = ref ?? defaultRef;
    const classes = useStyles();

    React.useEffect(() => {
      // @ts-ignore
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <Checkbox
        size="medium"
        classes={{ checked: classes.checked, root: classes.checkboxRow }}
        // @ts-ignore
        ref={resolvedRef}
        {...rest}
      />
    );
  }
);
