import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetInfoVeranstaltungsdDatesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetInfoVeranstaltungsdDatesQuery = { veranstaltungsTyps: Array<{ __typename?: 'VeranstaltungsTyp', veranstaltungsTypName?: Types.Maybe<string>, id: number, veranstaltungs: Array<{ __typename?: 'Veranstaltung', id: number, datum: string }> }> };


export const GetInfoVeranstaltungsdDatesDocument = gql`
    query getInfoVeranstaltungsdDates {
  veranstaltungsTyps(where: {veranstaltungsTypId: {eq: 2}}) {
    veranstaltungsTypName
    id
    veranstaltungs {
      id
      datum
    }
  }
}
    `;

/**
 * __useGetInfoVeranstaltungsdDatesQuery__
 *
 * To run a query within a React component, call `useGetInfoVeranstaltungsdDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfoVeranstaltungsdDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfoVeranstaltungsdDatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInfoVeranstaltungsdDatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInfoVeranstaltungsdDatesQuery, GetInfoVeranstaltungsdDatesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInfoVeranstaltungsdDatesQuery, GetInfoVeranstaltungsdDatesQueryVariables>(GetInfoVeranstaltungsdDatesDocument, baseOptions);
      }
export function useGetInfoVeranstaltungsdDatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInfoVeranstaltungsdDatesQuery, GetInfoVeranstaltungsdDatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInfoVeranstaltungsdDatesQuery, GetInfoVeranstaltungsdDatesQueryVariables>(GetInfoVeranstaltungsdDatesDocument, baseOptions);
        }
export type GetInfoVeranstaltungsdDatesQueryHookResult = ReturnType<typeof useGetInfoVeranstaltungsdDatesQuery>;
export type GetInfoVeranstaltungsdDatesLazyQueryHookResult = ReturnType<typeof useGetInfoVeranstaltungsdDatesLazyQuery>;
export type GetInfoVeranstaltungsdDatesQueryResult = ApolloReactCommon.QueryResult<GetInfoVeranstaltungsdDatesQuery, GetInfoVeranstaltungsdDatesQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
