import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetCurrentStudentStatusQueryVariables = Types.Exact<{
  studentId: Types.Scalars['Int'];
}>;


export type GetCurrentStudentStatusQuery = { student?: Types.Maybe<(
    { __typename?: 'Student' }
    & StudentSemesterStatusesFragment
  )> };

export type StudentSemesterStatusesFragment = { __typename?: 'Student', matchStudentSemesters: Array<{ __typename?: 'MatchStudentSemester', studentSemesterStatus?: Types.Maybe<{ __typename?: 'StudentSemesterStatus', studentSemesterStatusName?: Types.Maybe<string> }>, zeitraum?: Types.Maybe<{ __typename?: 'Zeitraum', zeitraumBis: string }> }> };

export const StudentSemesterStatusesFragmentDoc = gql`
    fragment StudentSemesterStatuses on Student {
  matchStudentSemesters {
    studentSemesterStatus {
      studentSemesterStatusName
    }
    zeitraum {
      zeitraumBis
    }
  }
}
    `;
export const GetCurrentStudentStatusDocument = gql`
    query getCurrentStudentStatus($studentId: Int!) {
  student(id: $studentId) {
    ...StudentSemesterStatuses
  }
}
    ${StudentSemesterStatusesFragmentDoc}`;

/**
 * __useGetCurrentStudentStatusQuery__
 *
 * To run a query within a React component, call `useGetCurrentStudentStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentStudentStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentStudentStatusQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetCurrentStudentStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrentStudentStatusQuery, GetCurrentStudentStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrentStudentStatusQuery, GetCurrentStudentStatusQueryVariables>(GetCurrentStudentStatusDocument, baseOptions);
      }
export function useGetCurrentStudentStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrentStudentStatusQuery, GetCurrentStudentStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrentStudentStatusQuery, GetCurrentStudentStatusQueryVariables>(GetCurrentStudentStatusDocument, baseOptions);
        }
export type GetCurrentStudentStatusQueryHookResult = ReturnType<typeof useGetCurrentStudentStatusQuery>;
export type GetCurrentStudentStatusLazyQueryHookResult = ReturnType<typeof useGetCurrentStudentStatusLazyQuery>;
export type GetCurrentStudentStatusQueryResult = ApolloReactCommon.QueryResult<GetCurrentStudentStatusQuery, GetCurrentStudentStatusQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
