import {
  PruefungUpdateOnUpdateDataMiddleWare,
  PruefungCreateOnUpdateDataMiddleWare,
  PruefungUpdateOnUpdateDataMiddleWareProps,
} from "raft/PruefungForm";
import { add, isDate, Duration } from "date-fns";

export const onCreateUpdateMiddleware: PruefungCreateOnUpdateDataMiddleWare = ({ data, changedFields, origin }) => {
  const newData = fillDateFields({ data, changedFields, origin });
  return {
    ...newData,
    raum: "",
    maxPunktzahl: newData.maxPunktzahl ?? 0,
    ersteBeisitzer: newData.ersteBeisitzer || "",
  };
};
export const onUpdateUpdateMiddleware: PruefungUpdateOnUpdateDataMiddleWare = ({ data, changedFields, origin }) => {
  return fillDateFields({ data, changedFields, origin });
};

const calculateDateOffsetPre =
  (date: Date) =>
  (duration: Duration): string => {
    return add(date, duration).toISOString();
  };

function fillDateFields<T extends Partial<PruefungUpdateOnUpdateDataMiddleWareProps["data"]>>({
  data,
  changedFields,
  origin,
}: {
  data: T;
  changedFields: string[];
  origin: "user" | "store";
}): T {
  if (
    origin === "user" &&
    (changedFields.includes("datumUhrzeitVon") || changedFields.includes("pruefungsdauer")) &&
    data.datumUhrzeitVon
  ) {
    const calculateDateOffset = calculateDateOffsetPre(new Date(data.datumUhrzeitVon as string));

    const pruefungsdauer = data.pruefungsdauer ?? 90;
    return {
      ...data,
      pruefungsdauer: `${pruefungsdauer}`.length ? pruefungsdauer : 90,
      datumUhrzeitBis: calculateDateOffset({ minutes: pruefungsdauer }),
      beginnAnmeldefrist: calculateDateOffset({ days: -31 }),
      endeAnmeldefrist: calculateDateOffset({ days: -7 }),
      endeAbmeldefrist: calculateDateOffset({ days: -1 }),
      korrekturenzeitraumVon: calculateDateOffset({ days: 1 }),
      korrekturenzeitraumBis: calculateDateOffset({ days: 14 }),
      freigabePruefungsergebnis: calculateDateOffset({ days: 15 }),
      bekanntgabePruefungsergebnis: calculateDateOffset({ days: 16 }),
    };
  }
  return data;
}
