import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetCheckIfMoodleIdExistsInOtherModulesQueryVariables = Types.Exact<{
  moodleModuleInternId: Types.Scalars['Int'];
}>;


export type GetCheckIfMoodleIdExistsInOtherModulesQuery = { checkIfMoodleIdExistsInOtherModules: boolean };


export const GetCheckIfMoodleIdExistsInOtherModulesDocument = gql`
    query getCheckIfMoodleIdExistsInOtherModules($moodleModuleInternId: Int!) {
  checkIfMoodleIdExistsInOtherModules(moodleModuleInternId: $moodleModuleInternId)
}
    `;

/**
 * __useGetCheckIfMoodleIdExistsInOtherModulesQuery__
 *
 * To run a query within a React component, call `useGetCheckIfMoodleIdExistsInOtherModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckIfMoodleIdExistsInOtherModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckIfMoodleIdExistsInOtherModulesQuery({
 *   variables: {
 *      moodleModuleInternId: // value for 'moodleModuleInternId'
 *   },
 * });
 */
export function useGetCheckIfMoodleIdExistsInOtherModulesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCheckIfMoodleIdExistsInOtherModulesQuery, GetCheckIfMoodleIdExistsInOtherModulesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCheckIfMoodleIdExistsInOtherModulesQuery, GetCheckIfMoodleIdExistsInOtherModulesQueryVariables>(GetCheckIfMoodleIdExistsInOtherModulesDocument, baseOptions);
      }
export function useGetCheckIfMoodleIdExistsInOtherModulesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCheckIfMoodleIdExistsInOtherModulesQuery, GetCheckIfMoodleIdExistsInOtherModulesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCheckIfMoodleIdExistsInOtherModulesQuery, GetCheckIfMoodleIdExistsInOtherModulesQueryVariables>(GetCheckIfMoodleIdExistsInOtherModulesDocument, baseOptions);
        }
export type GetCheckIfMoodleIdExistsInOtherModulesQueryHookResult = ReturnType<typeof useGetCheckIfMoodleIdExistsInOtherModulesQuery>;
export type GetCheckIfMoodleIdExistsInOtherModulesLazyQueryHookResult = ReturnType<typeof useGetCheckIfMoodleIdExistsInOtherModulesLazyQuery>;
export type GetCheckIfMoodleIdExistsInOtherModulesQueryResult = ApolloReactCommon.QueryResult<GetCheckIfMoodleIdExistsInOtherModulesQuery, GetCheckIfMoodleIdExistsInOtherModulesQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
