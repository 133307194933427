export default {
  afterCreate: {
    body: {
      part1: "Wie es jetzt weitergeht? In den nächsten 72 Stunden prüfen wir deine Bewerbung.",
      part2: " Wir informieren dich anschließend per Mail über die Zulassung zu deinem Wunschstudiengang.",
    },
    headline: "Geschafft, auf geht`s …",
    greeting: "Bei Fragen sind wir gerne für dich da!",
    processed: "Deine Bewerbung wird verarbeitet!",
    subHeadline: "Großartig, deine Bewerbung ist bei uns eingegangen.",
  },
  form: {
    birthCountry: "Geburtsland",
    birthDate: "Geburtsdatum",
    birthPlace: "Geburtsort",
    checkPersonalData: "Bitte kontrolliere noch einmal deine persönlichen Daten.",
    confirmData:
      "Alles richtig? Dann einfach auf den Button „Studienplatz sichern“ klicken und deine Bewerbung wird abgeschickt.",
    contactDetails: "Anschrift & Kontaktdaten",
    countryOfResidence: "Wohnland",
    course: "Studiengang",
    courseText: "Was möchtest du studieren?",
    email: "Email",
    enterData: "Trage hier deine Daten ein und wir schicken dir dein persönliches Anmeldeformular per E-Mail zu.",
    gender: "Geschlecht",
    model: "Studienmodell",
    modelText: "Wie möchtest du studieren?",
    name: "Name",
    nationality: "Staatsangehörigkeit",
    payment: "Zahlungsmodell",
    paymentText: "Wie möchtest du deine Studiengebühren bezahlen?",
    personalInformation: "Persönliche Angaben",
    phoneNumber: "Telefonnummer",
    placeOfResidence: "Wohnort",
    plz: "PLZ",
    start: "Studienstart",
    street: "Straße",
  },
  validation: {
    birthCountry: "Bitte gib ein Geburtsland an!",
    birthDate: "Bitte gib ein Geburtsdatum an!",
    birthPlace: "Bitte gib einen Geburtsort an!",
    email: "Bitte gib eine gültige Email an!",
    emailMatch: "Die angegebenen Emails stimmen nicht überein!",
    firstname: "Bitte gib einen Vornamen an!",
    street: "Bitte gib eine Straße an!",
    houseNr: "Bitte gib eine Hausnummer an!",
    plz: "Bitte gib eine Postleitzahl an!",
    city: "Bitte gib einen Wohnort an!",
    country: "Bitte gib ein Wohnland an!",
    phoneNumber: "Bitte gib eine Telefonnummer an!",
    geschlechtId: "Bitte gib ein Geschlecht an!",
    lastname: "Bitte gib einen Nachnamen an!",
    privacyPolicy: "Bitte bestätige die Datenschutzerklärung!",
    underscore: "_",
    zahlungsmodellId: "Bitte gib ein Zahlungsmodel an!",
  },
  fileUpload: {
    head: "Fast geschafft!",
    enterData: "Lade hier noch deine Hochschulzugangsberechtigung hoch:",
    BaccalaureateCertificateTitle: "Du hast Abitur, Fachabitur oder eine fachgebundene Hochschulreife",
    uploadBaccalaureateCertificate: "Upload Zeugnis (pdf- oder jpg-Format)",
    educationcertificateTitle:
      "Du hast eine Ausbildung absolviert und mind. 3 Jahre Berufserfahrung in deinem Wunschstudiengang*",
    uploadEducationcertificate: "Upload Ausbildungszeugnis",
    uploadprofessionalActivity: "Upload Nachweis Berufstätigkeit",
    continuingEducationTitle: "Du hast eine Aufstiegsfortbildung absolviert",
    uploadContinuingEducationTitle: "Upload Zeugnis Fortbildung",
    note:
      "* Acuh mit einer Berufsausbildung und Berufserfahrung in einem fachfremden Bereich kannst du nach einer Feststellungsprüfung die Hochschulzugangs- berechtigung erlangen. Sprich uns einfach an",
    failedToAddFiles: "Upload von {0} fehlgeschlagen. Bitten überprüfen Sie Dateigröße (max. 20MB) und Dateityp.",
    deleteDialogTitle: "Datei löschen",
    deleteDialogBody: "Sind Sie sicher, dass Sie {0} löschen möchten?",
  },
};
