/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useImportApplicantMutation } from "graphqlBase/misc/__generated__/importApplicant";
import { BewerberUpdateForm, BewerberUpdateOnUpdateDataMiddleWareProps } from "raft/BewerberForm";
import React from "react";
import { useHistory, useParams } from "react-router-dom";

const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      BewerberUpdateFormBox: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
      afterCreate: {
        margin: "auto",
        maxWidth: "700px",
      },
      afterCreateBody: {
        marginTop: "1em",
      },
      afterCreateHeadline: { marginBottom: "10px" },
    }),

  { name: `Pages-Home` }
);

const BewerberUpdate: React.FC<{}> = () => {
  const classes = useStyles({});
  const { bewerberId } = useParams<{ bewerberId?: string }>();
  const id = +(bewerberId ?? "0");
  const [importMutation, { data, error }] = useImportApplicantMutation();
  const history = useHistory();

  const importApplicant = React.useMemo(() => {
    return (props: BewerberUpdateOnUpdateDataMiddleWareProps) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      if (props.changedFields.includes("importApplicant") && !!props.data.importApplicant && props.origin === "user")
        importMutation({ variables: { bewerberIds: [props.data.id] } })
          .then((response) => {
            const studentId = (response.data?.importApplicant?.importApplicants ?? []).map(
              (newBew) => newBew?.studentID
            )[0];
            if (studentId) history.push(`/teilnehmer/teilnehmerEdit/${studentId}`);
          })
          .catch((e) => console.log(e));
      return {};
    };
  }, [importMutation, id, history]);

  const onLeave = () => history.goBack();

  if (!id) return null;
  return (
    <div className={classes.BewerberUpdateFormBox}>
      <BewerberUpdateForm onCancel={onLeave} afterUpdate={onLeave} onUpdateDataMiddleWare={importApplicant} id={id} />
    </div>
  );
};

export default BewerberUpdate;
