import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { PruefungUpdateForm, PruefungUpdateOnUpdateDataMiddleWare } from "raft/PruefungForm";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { usePruefungsErgebnisListCallback, PruefungsergebniForRow } from "./pruefungsergebnisseState";

import {
  UpdatePruefungsErgebnisseMutationVariables,
  UpdatePruefungsErgebnisseDocument,
  UpdatePruefungsErgebnisseMutationResult,
} from "graphqlBase/Pruefungsergebnis/__generated__/updatePruefungsErgebnisse";
import {
  useGetPruefungsdatenQuery,
  ResultType,
  GetPruefungsdatenQuery,
} from "graphqlBase/Pruefung/__generated__/getPruefungsdaten";

import client from "apollo/client";
import { formatDateTime } from "translations/formatter";

type Pruefung = ResultType<GetPruefungsdatenQuery["pruefungs"]>;

const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      PruefungEditFormBox: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
      afterCreate: {
        margin: "auto",
        maxWidth: "700px",
      },
      afterCreateBody: {
        marginTop: "1em",
      },
      afterCreateHeadline: { marginBottom: "10px" },
    }),

  { name: `Pruefungen-PruefungUpdate` }
);
export const useGetMapping = () => {
  const getPruefungsergebnise = usePruefungsErgebnisListCallback();

  const persistData = async () => {
    const ergebnissePre = await getPruefungsergebnise();
    const pruefungsergebnis = ergebnissePre.pruefungsergebnis.reduce<PruefungsergebniForRow[]>((list, row) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { __typename, pruefungStatus, student, pruefung, edit, ...cleaned } = row;
      if (!edit) return list;
      return list.concat(cleaned);
    }, []);
    if (!pruefungsergebnis.length) return;
    const result = await client.mutate<
      UpdatePruefungsErgebnisseMutationResult,
      UpdatePruefungsErgebnisseMutationVariables
    >({
      mutation: UpdatePruefungsErgebnisseDocument,
      variables: { pruefungsergebnis },
    });
    ergebnissePre.resetPruefungsIds();
    return result;
  };
  return persistData;
};

const PruefungUpdate: React.FC<{}> = () => {
  const classes = useStyles({});
  const history = useHistory();
  const { pruefungId } = useParams<{ pruefungId?: string }>();
  const id = +(pruefungId ?? "0");
  const persistMatchStudiengangModul = useGetMapping();
  const { data } = useGetPruefungsdatenQuery({
    variables: { where: { pruefungId: { eq: id } } },
  });

  const [pruefung, setPruefung] = React.useState<Pruefung | null>(null);

  React.useEffect(() => {
    if (!data) return;
    setPruefung(data.pruefungs[0]);
  }, [data]);

  const onAfterUpdate = () => {
    persistMatchStudiengangModul().finally(() => {});
  };

  const onLeave = () => history.goBack();

  const onUpdateUpdateMiddleware: PruefungUpdateOnUpdateDataMiddleWare = ({ data }) => {
    let summaryOne = "";
    let summaryTwo = "";
    let summaryThree = "";
    let summaryFour = "";
    let summaryFive = "";
    if (pruefung) {
      const modulname = pruefung?.moodleModul?.modul?.modulName ?? "";
      const pruefungsTyp = pruefung?.pruefungTyp?.pruefungTypName ?? "";
      const semester = pruefung?.moodleModul?.zeitraum?.zeitraumName ?? "";
      const lehrender = `${pruefung?.moodleModul?.lehrender?.vorname ?? ""} ${
        pruefung?.moodleModul?.lehrender?.nachname ?? ""
      }`;
      const termin = pruefung.datumUhrzeitVon ? formatDateTime(new Date(pruefung.datumUhrzeitVon)) : "";
      summaryOne = modulname;
      summaryTwo = pruefungsTyp;
      summaryThree = semester;
      summaryFour = lehrender;
      summaryFive = termin;
    }

    return { ...data, summaryOne, summaryTwo, summaryThree, summaryFour, summaryFive };
  };

  if (!id) return null;
  return (
    <div className={classes.PruefungEditFormBox}>
      <PruefungUpdateForm
        onUpdateDataMiddleWare={onUpdateUpdateMiddleware}
        onCancel={onLeave}
        afterUpdate={onAfterUpdate}
        id={id}
        formVersion="PruefungsErgebnisListe"
      />
    </div>
  );
};

export default PruefungUpdate;
