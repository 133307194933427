import React, { useEffect } from "react";
import Button from "components/lbc-toolkit/atomics/Button/DefaultButton";
import translations from "translations";
import { Theme, createStyles, makeStyles } from "@material-ui/core";
import downloadBase64File from "lib/formToolHelpers/downloadBase64File";
import { useGetMyCertificateOfStudyLazyQuery } from "graphqlBase/myCertificateOfStudy/__generated__/myCertificateOfStudy";

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    addButton: {
      fontSize: 14,
      color: theme.colors.tWhite,
      backgroundColor: theme.colors.sunYellow,
      height: 40,
      borderRadius: "unset",
      "&:hover": { color: theme.colors.tWhite, backgroundColor: theme.colors.sunYellow },
    },
  })
);

interface Props {
  id: number;
}

const MyCertificateOfStudy: React.FC<Props> = ({ id }) => {
  const classes = useStyles();
  const [query, { data }] = useGetMyCertificateOfStudyLazyQuery({
    fetchPolicy: "network-only",
  });

  const handleClick = () => {
    query({ variables: {} });
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    const base64String = data.myCertificateOfStudy?.fileContentBase64;
    const fileName = data?.myCertificateOfStudy?.fileName;

    if (!base64String) {
      return;
    }
    downloadBase64File("application/pdf", base64String, `${fileName}`);
  }, [data, id]);

  return (
    <Button
      onClick={handleClick}
      label={`${"Studienbescheinigung"} ${translations.globals.button.download}`}
      buttonType="primary"
      width="325px"
      classes={{ colorPrimary: classes.addButton }}
    />
  );
};

export default MyCertificateOfStudy;
