import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetMatchStudiengangStudentsOnStudentQueryVariables = Types.Exact<{
  id?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type GetMatchStudiengangStudentsOnStudentQuery = { matchStudiengangStudents: Array<{ __typename?: 'MatchStudiengangStudent', id: number, studentId?: Types.Maybe<number>, studiengang?: Types.Maybe<{ __typename?: 'Studiengang', id: number, studiengangName?: Types.Maybe<string>, studiengangTyp: { __typename?: 'StudiengangTyp', id: number, studiengangTypName: string } }>, studiumArt?: Types.Maybe<{ __typename?: 'StudiumArt', id: number, studiumArtName?: Types.Maybe<string> }>, zeitraum?: Types.Maybe<{ __typename?: 'Zeitraum', id: number, zeitraumName: string }>, zahlungsmodellTn?: Types.Maybe<{ __typename?: 'ZahlungsmodellTn', id: number, zahlungsmodellName: string }> }>, studiumArts: Array<{ __typename?: 'StudiumArt', id: number, studiumArtName?: Types.Maybe<string> }> };


export const GetMatchStudiengangStudentsOnStudentDocument = gql`
    query getMatchStudiengangStudentsOnStudent($id: Int) {
  matchStudiengangStudents(where: {studentId: {eq: $id}}) {
    id
    studentId
    studiengang {
      id
      studiengangName
      studiengangTyp {
        id
        studiengangTypName
      }
    }
    studiumArt {
      id
      studiumArtName
    }
    zeitraum {
      id
      zeitraumName
    }
    zahlungsmodellTn {
      id
      zahlungsmodellName
    }
  }
  studiumArts {
    id
    studiumArtName
  }
}
    `;

/**
 * __useGetMatchStudiengangStudentsOnStudentQuery__
 *
 * To run a query within a React component, call `useGetMatchStudiengangStudentsOnStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMatchStudiengangStudentsOnStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMatchStudiengangStudentsOnStudentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMatchStudiengangStudentsOnStudentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMatchStudiengangStudentsOnStudentQuery, GetMatchStudiengangStudentsOnStudentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMatchStudiengangStudentsOnStudentQuery, GetMatchStudiengangStudentsOnStudentQueryVariables>(GetMatchStudiengangStudentsOnStudentDocument, baseOptions);
      }
export function useGetMatchStudiengangStudentsOnStudentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMatchStudiengangStudentsOnStudentQuery, GetMatchStudiengangStudentsOnStudentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMatchStudiengangStudentsOnStudentQuery, GetMatchStudiengangStudentsOnStudentQueryVariables>(GetMatchStudiengangStudentsOnStudentDocument, baseOptions);
        }
export type GetMatchStudiengangStudentsOnStudentQueryHookResult = ReturnType<typeof useGetMatchStudiengangStudentsOnStudentQuery>;
export type GetMatchStudiengangStudentsOnStudentLazyQueryHookResult = ReturnType<typeof useGetMatchStudiengangStudentsOnStudentLazyQuery>;
export type GetMatchStudiengangStudentsOnStudentQueryResult = ApolloReactCommon.QueryResult<GetMatchStudiengangStudentsOnStudentQuery, GetMatchStudiengangStudentsOnStudentQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
