import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { StepLabel, Typography } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import Stepper from "@material-ui/core/Stepper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Icon from "components/lbc-toolkit/atomics/Icon";
import React, { useEffect } from "react";
import StepConnector from "@material-ui/core/StepConnector";
import colors from "themes/colors";
import Props from "./index.d";
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        "& .text": {
          display: "none",
        },
        "& .MuiPaper-root": {
          backgroundColor: "inherit",
        },
      },
      button: {
        marginRight: theme.spacing(1),
      },
      instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      stepConnectorRoot: {
        top: "15px",
      },
    }),
  { name: "StepperField" }
);

export function isStepCompleted(index: number, currentBullet: number): boolean {
  return index < currentBullet;
}

export function isActive(currentBullet: number, index: number) {
  return currentBullet === index;
}

export function isCompleted(currentBullet: number, index: number) {
  return currentBullet > index;
}

export function iconColor(currentBullet: number, index: number) {
  return isActive(currentBullet, index)
    ? colors.kolpingOrange
    : isCompleted(currentBullet, index)
    ? colors.sunYellow
    : colors.koplingGrey;
}
const icon = (state: number, index: number) => {
  return (
    <div>
      <Icon color={iconColor(state, index)} fontSize={24} icon={faCircle} />
    </div>
  );
};

export function iconSize(currentBullet: number, index: number) {
  return isActive(currentBullet, index) ? 24 : 20;
}
//@todo move to lbc stepper
const StepperField: React.FC<Props> = (props) => {
  const {
    data: { state, setAndSave },
    items,
    title,
    disabled,
  } = props;

  const classes = useStyles();

  useEffect(() => {
    if (state == null || typeof state != "number") setAndSave(0);
  }, [state]);

  return (
    <div className={classes.root}>
      <Stepper
        connector={<StepConnector classes={{ root: classes.stepConnectorRoot }} />}
        activeStep={+state || 0}
        alternativeLabel
      >
        {items.map(({ label }, index) => {
          return (
            <Step key={index} active={isActive(state, index)} completed={isCompleted(state, index)}>
              <StepLabel icon={icon(state, index)}>
                <Typography variant="h4">{label}</Typography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};
export default StepperField;
