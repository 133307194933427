export default {
  name: "Gerätemodell-Attributsatz",
  plural: "Gerätemodell-Attributsätze",
  columns: {
    deviceModel: "Gerätemodell",
    deviceModelAttributeSetName: "Name des Gerätemodellattributsatzes",
    deviceModelId: "Gerätemodell ID",
    id: "ID",
    isDeleted: "ist gelöscht",
    mappingAttributeDeviceModelAttributeSet: "Zuordnungsattribut Gerätemodellattributsatz",
    tenantId: "Mieter ID",
  },
};
