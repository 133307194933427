export default {
  name: "Asset-Gerät",
  plural: "Asset-Geräte",
  columns: {
    asset: "Asset",
    assetId: "Asset ID",
    createdAt: "hergestellt in",
    device: "Gerät",
    deviceId: "Gerät ID",
    id: "ID",
    isDeleted: "ist gelöscht",
    lastChange: "Letzte Bearbeitung",
    tenantId: "Mieter ID",
  },
};
