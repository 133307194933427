import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type CreateAnmeldungMutationVariables = Types.Exact<{
  createAnmeldungs: Array<Types.AnmeldungCreateType> | Types.AnmeldungCreateType;
}>;


export type CreateAnmeldungMutation = { createAnmeldungs?: Types.Maybe<Array<Types.Maybe<{ __typename?: 'Anmeldung', id: number, veranstaltungId: number, anrede?: Types.Maybe<number>, vorname?: Types.Maybe<string>, nachname?: Types.Maybe<string>, strasse?: Types.Maybe<string>, hausnummer?: Types.Maybe<string>, plz?: Types.Maybe<string>, ort?: Types.Maybe<string>, wohnlandId?: Types.Maybe<number>, telefonnummer?: Types.Maybe<string>, email?: Types.Maybe<string>, datenschutz?: Types.Maybe<boolean> }>>> };


export const CreateAnmeldungDocument = gql`
    mutation createAnmeldung($createAnmeldungs: [AnmeldungCreateType!]!) {
  createAnmeldungs(anmeldungs: $createAnmeldungs) {
    id
    veranstaltungId
    anrede
    vorname
    nachname
    strasse
    hausnummer
    plz
    ort
    wohnlandId
    telefonnummer
    email
    datenschutz
  }
}
    `;
export type CreateAnmeldungMutationFn = ApolloReactCommon.MutationFunction<CreateAnmeldungMutation, CreateAnmeldungMutationVariables>;

/**
 * __useCreateAnmeldungMutation__
 *
 * To run a mutation, you first call `useCreateAnmeldungMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnmeldungMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnmeldungMutation, { data, loading, error }] = useCreateAnmeldungMutation({
 *   variables: {
 *      createAnmeldungs: // value for 'createAnmeldungs'
 *   },
 * });
 */
export function useCreateAnmeldungMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAnmeldungMutation, CreateAnmeldungMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAnmeldungMutation, CreateAnmeldungMutationVariables>(CreateAnmeldungDocument, baseOptions);
      }
export type CreateAnmeldungMutationHookResult = ReturnType<typeof useCreateAnmeldungMutation>;
export type CreateAnmeldungMutationResult = ApolloReactCommon.MutationResult<CreateAnmeldungMutation>;
export type CreateAnmeldungMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAnmeldungMutation, CreateAnmeldungMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
