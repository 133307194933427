import * as React from "react";
import { makeStyles, createStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";

import Props from "./index.d";

const useStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      // overflowY: "scroll",
      overflowX: "hidden",
    },
  })
);

interface GFCFieldSet_Vertical extends Props {
  children: React.ReactNode;
}

const FieldSetVertical: React.FC<GFCFieldSet_Vertical> = (props) => {
  const {} = props;
  const classes = useStyles();
  // @ts-ignore
  if (props.hidden) return null;
  return <div className={classes.root}>{props.children}</div>;
};
export default FieldSetVertical;
