import { useHistory, useLocation } from "react-router-dom";
import React from "react";
import Button from "components/lbc-toolkit/atomics/Button/DefaultButton";
import translations from "translations";
import { useStyles } from "../AnmeldungAdd";

const AnmeldungAddButton: React.FC<{}> = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  function handleClick() {
    history.push(`${location.pathname}/addAnmeldung`);
  }

  return (
    <Button
      label={`${translations.pages.anmeldung.anmeldung} ${translations.globals.button.add}`}
      buttonType="primary"
      onClick={handleClick}
      classes={{ colorPrimary: classes.addButton }}
    />
  );
};
export default AnmeldungAddButton;
