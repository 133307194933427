import { useHistory, useLocation } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useState } from "react";
import Button from "components/lbc-toolkit/atomics/Button/DefaultButton";
import translations from "translations";
import Modal from "components/lbc-toolkit/molecules/Modal";
import { PruefungsergebniCreateForm, PruefungsergebniCreateProps } from "raft/PruefungsergebniForm";

type PruefungsergebniCreatePropsInner = Pick<PruefungsergebniCreateProps, "studentId">;

interface PruefungsVerlaufAddButtonProps extends PruefungsergebniCreatePropsInner {
  refetch?: () => void;
  disabled?: boolean;
}

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    addButton: {
      fontSize: 14,
      color: theme.colors.tWhite,
      backgroundColor: theme.colors.sunYellow,
      height: 40,
      borderRadius: "unset",
      "&:hover": { color: theme.colors.tWhite, backgroundColor: theme.colors.sunYellow },
    },
  })
);

const PruefungsVerlaufAddButton: React.FC<PruefungsVerlaufAddButtonProps> = ({ studentId, refetch, disabled }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleClick() {
    // history.push(`${location.pathname}/addAnmeldung`);
    setIsModalOpen(true);
  }

  const closeModal = () => setIsModalOpen(false);

  const afterCreate = () => {
    if (refetch) refetch();
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        label={`${"Ergebnis"} ${translations.globals.button.add}`}
        buttonType="primary"
        onClick={handleClick}
        classes={{ colorPrimary: classes.addButton }}
        disabled={disabled}
      />
      <Modal open={isModalOpen} hideCancel hideConfirm onClose={closeModal} onConfirm={closeModal} title="Create">
        <PruefungsergebniCreateForm studentId={studentId} onCancel={closeModal} afterCreate={afterCreate} />
      </Modal>
    </>
  );
};
export default PruefungsVerlaufAddButton;
