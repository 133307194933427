import { atom, useRecoilCallback } from "recoil";
export interface FileWithContent extends File{
  fileContent: string 
}

interface FileExtended extends FileWithContent{
  uploadFileType: number
}

export const filesAtom = atom<FileExtended[]>({
    key:"file",
    default: [],
  });

export const useUploadfileTypeCallback = () => {
    return useRecoilCallback(({ snapshot }) => async () => {
      const uploadFiles= await snapshot.getPromise(filesAtom)
      return { uploadFiles };
    });
  };
  