import { createStyles, makeStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";
import DefaultInput from "components/atomics/DefaultInput";
import { useGetPrufungsFormOnModulLazyQuery } from "graphqlBase/Modul/__generated__/getPrufungsFormOnModul";
import React, { useEffect } from "react";
import Props from "./index.d";
const useStyles = makeStyles(
  (theme: AugmentedTheme) =>
    createStyles({
      input: {
        width: "100%",
      },
    }),
  { name: "foreignKeySelector" }
);
const ForeignKeySelectorInt: React.FC<Props> = (props) => {
  const {
    title,
    data: { state, onBlur, updateValue },
  } = props;

  const [query, { data }] = useGetPrufungsFormOnModulLazyQuery();
  useEffect(() => {
    if (state) query({ variables: { id: state } });
  }, [state]);
  const classes = useStyles(props);
  return (
    <DefaultInput
      className={classes.input}
      value={data?.modul?.pruefungsform?.pruefungsformenName ?? ""}
      disabled={true}
      defaultValue=""
      label={title}
      // {...rest}
    />
  );
};

export default ForeignKeySelectorInt;
