import { AlertTitle } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import React from "react";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { oc } from "ts-optchain";

interface SnackbarProps {
  isForExcelImport?: boolean;
}
function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useSnackbarAlert = ({ isForExcelImport = false }: SnackbarProps) => {
  const [alerts, setAlerts] = React.useState<string[]>([]);

  const addAlert = (_alert: string | string[]) => {
    setAlerts((prevState) => prevState.concat(_alert));
  };

  const handleClose = () => {
    if (!isForExcelImport) setAlerts((prevState) => prevState.slice(1, prevState.length));
    if (isForExcelImport) setAlerts([]);
  };

  const alertComponent = (
    <Snackbar open={alerts.length > 0} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
      <Alert onClose={handleClose} severity="error">
        <AlertTitle>Error</AlertTitle>
        {isForExcelImport && alerts.map((alert, index) => <p key={index}>{alert}</p>)}
        {!isForExcelImport && oc(alerts)[0]("")}
      </Alert>
    </Snackbar>
  );
  return { alertComponent, addAlert };
};

export default useSnackbarAlert;
