import { prepareServerSideTable } from "components/templates/table-factory";
import { useGetMatchMoodleModulStudentsQuery } from "graphqlBase/MatchMoodleModulStudent/__generated__/getMatchMoodleModulStudents";
import {
  GetStudentsForMatchMoodleModulStudentsQuery,
  ResultType,
  useGetStudentsForMatchMoodleModulStudentsLazyQuery,
} from "graphqlBase/Students/__generated__/getStudentsForMatchMoodleModulStudents";
import { SortEnumType, StudentFilterInput, StudentSortInput } from "graphqlBase/types";
import { default as React, useCallback, useEffect } from "react";

type Student = ResultType<GetStudentsForMatchMoodleModulStudentsQuery["students"]>;

const { useCreateServerSideColumns, useMakeServerSideTable, makeSeverSideFilter } = prepareServerSideTable<
  Student,
  StudentFilterInput,
  StudentSortInput,
  SortEnumType
>();

interface AddStudentToMoodleModulTableProps {
  moodleModulId: number;
  studentsInModule: number[];
  setStudentIdsToAdd: React.Dispatch<React.SetStateAction<number[]>>;
}

const AddStudentToMoodleModulTable: React.FC<AddStudentToMoodleModulTableProps> = ({
  studentsInModule,
  moodleModulId,
  setStudentIdsToAdd,
}) => {
  const variables = {
    where: {
      matchMoodleModulStudents: {
        none: { and: [{ moodleModulId: { eq: moodleModulId } }, { isUserDeleted: { eq: false } }] },
      },
    },
  };
  const [query, { data, refetch }] = useGetStudentsForMatchMoodleModulStudentsLazyQuery({
    fetchPolicy: "cache-and-network",
  });
  const { data: registerdStudents } = useGetMatchMoodleModulStudentsQuery({
    variables: {
      filterInput: { moodleModulId: { eq: moodleModulId } },
    },
  });

  const { columns, serversideQueryConfig } = useCreateServerSideColumns(
    [
      {
        Header: "Stud-ID",
        accessor: (row) => row.id,
        id: "stud-ID",
        remoteOrder: ({ order, sort }) => ({ studentId: sort }),
        remoteFilter: makeSeverSideFilter<number>({
          filterPath: ({ filterValue, where, merge }) => merge(where, { studentId: { eq: +filterValue } }),
        }),
      },
      {
        Header: "Vorname",
        accessor: "vorname",
        id: "vorname",
        remoteOrder: ({ order, sort }) => ({ vorname: sort }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) => merge(where, { vorname: { contains: filterValue } }),
        }),
      },
      {
        Header: "Nachname",
        accessor: "nachname",
        id: "nachname",
        remoteOrder: ({ order, sort }) => ({ nachname: sort }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) => merge(where, { nachname: { contains: filterValue } }),
        }),
      },
      {
        Header: "Studiengang",
        accessor: (row) => row.matchStudiengangStudents?.[0]?.studiengang?.studiengangName ?? "",
        id: "studiengang",
        // remoteOrder: ({ order, sort }) => ({
        //   matchStudiengangStudents: { some: { studiengangSemester: { studiengang: { studiengangName: sort } } } },
        // }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, {
              matchStudiengangStudents: {
                some: { studiengang: { studiengangName: { contains: filterValue } } },
              },
            }),
        }),
      },
    ],
    [refetch]
  );

  const notRegisteredStudents = useCallback(() => {
    return (data?.students ?? []).filter((student) => !studentsInModule.includes(student.id));
  }, [data, registerdStudents]);

  const { TableComponent: NewTableComponent, tableInstance } = useMakeServerSideTable(
    {
      data: notRegisteredStudents(),
      columns,
      initialState: { pageSize: 10 },
    },
    {
      query,
      variables,
      serversideQueryConfig,
      tableId: "AddStudentToMoodleModulTable",
      rowSelection: true,
      autoSize: true,
    }
  );
  useEffect(() => {
    setStudentIdsToAdd(tableInstance.selectedFlatRows.map<number>((row) => +row.id));
  }, [tableInstance.selectedFlatRows.length, setStudentIdsToAdd]);

  return (
    <>
      <NewTableComponent tableProps={{ rowsPerPageOptions: [10], disablePagination: true }} />
    </>
  );
};
export default AddStudentToMoodleModulTable;
