import React, { useState, useMemo } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import { SetterOrUpdater, useRecoilValue } from "recoil";
import DecimalEditor from "components/atomics/DecimalEditor";
import Select, { SelectProps } from "components/atomics/Select";
import { ResultType, GetPruefungTypsQuery } from "graphqlBase/PruefungTyp/__generated__/getPruefungTyps";
import {
  PruefungTypZuordnungen,
  pruefungTypDropdownIds,
} from "components/PagesCampusManagement/produkte/Module/recoilStates";
import TrashOutlinedIcon from "icons/components/TrashIcon";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      maxWidth: "2em",
      fontSize: 25,
      height: 40,
      color: "rgba(0, 0, 0, 0.54)",
      "&:hover": {
        backgroundColor: "unset",
      },
    },
    deleteContainer: {
      display: "flex",
      alignItems: "center",
    },
  })
);

type PruefungTyp = ResultType<GetPruefungTypsQuery["pruefungTyps"]>;

interface ModulZuordnungRowProps {
  modul: PruefungTypZuordnungen;
  setPruefungTypZuweisungList: SetterOrUpdater<PruefungTypZuordnungen[]>;
  pruefungtyps: PruefungTyp[];
}

const PruefungTypZuordnungRow: React.FC<ModulZuordnungRowProps> = ({
  modul,
  setPruefungTypZuweisungList,
  pruefungtyps,
}) => {
  const classes = useStyles();
  const [currentSelectedPruefungTypOption, setCurrentSelectedPruefungTypOption] = useState(modul.pruefungTypId);
  const currentPruefungTypDropdownIds = useRecoilValue(pruefungTypDropdownIds);
  const memoizedPruefungTyps = useMemo(
    () =>
      pruefungtyps.filter((pruefungtyp) =>
        [...currentPruefungTypDropdownIds, currentSelectedPruefungTypOption].includes(pruefungtyp.id)
      ),
    [pruefungtyps, currentPruefungTypDropdownIds]
  );
  const setCurrentModulZuweisung = (value: Partial<PruefungTypZuordnungen> | { delete: true }) => {
    setPruefungTypZuweisungList((notificationDefinitions) => {
      return notificationDefinitions.reduce<PruefungTypZuordnungen[]>(
        (newNotificationDefinitionsInner, notificationDefinitionInner) => {
          if (modul.id === notificationDefinitionInner.id) {
            if (Object.keys(value).includes("delete")) {
              if (notificationDefinitionInner.id > 0) {
                return newNotificationDefinitionsInner.concat({ ...notificationDefinitionInner, isDeleted: true });
              }
              return newNotificationDefinitionsInner;
            }
            return newNotificationDefinitionsInner.concat({ ...notificationDefinitionInner, ...value });
          }
          return newNotificationDefinitionsInner.concat(notificationDefinitionInner);
        },
        []
      );
    });
  };

  const handleSelectChange = (value: number) => {
    setCurrentSelectedPruefungTypOption(value);
    setCurrentModulZuweisung({ pruefungTypId: value });
  };

  const pruefungtypOptions = useMemo(
    () =>
      memoizedPruefungTyps.map((pruefungtyp) => ({
        label: pruefungtyp.pruefungTypName ?? "",
        value: `${pruefungtyp.id}`,
      })),
    [memoizedPruefungTyps]
  );

  return (
    <>
      <Grid item xs={4}>
        <Select
          label={"Prüfungstyp"}
          setState={(value) => {
            if (value) handleSelectChange(+value);
          }}
          state={currentSelectedPruefungTypOption || ""}
          items={pruefungtypOptions}
          error={false}
          noFormControlMarginTop={true}
        ></Select>
      </Grid>
      <Grid item xs={1} md={2}>
        <DecimalEditor
          value={modul.anteil ?? 0}
          onChange={(value) => setCurrentModulZuweisung({ anteil: value })}
          maxDecimals={0}
          maxValue={100}
          minValue={0}
          title="Anteil"
          inputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={1} md={2}>
        <DecimalEditor
          value={modul.dauer ?? 0}
          onChange={(value) => setCurrentModulZuweisung({ dauer: value })}
          maxDecimals={0}
          maxValue={1200}
          minValue={0}
          title="Dauer"
          inputProps={{ endAdornment: <InputAdornment position="end">Min.</InputAdornment> }}
        />
      </Grid>
      <Grid item xs={2} className={classes.deleteContainer}>
        <Button
          className={classes.button}
          aria-label="delete"
          onClick={() => {
            setCurrentModulZuweisung({ delete: true });
          }}
        >
          <Typography variant="h4">
            <TrashOutlinedIcon width="1.2em" height="1.2em" />
          </Typography>
        </Button>
      </Grid>
    </>
  );
};

export default PruefungTypZuordnungRow;
