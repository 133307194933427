import React from "react";
import StudentList from "../StudentList";

const where = {
  ausbildungsArtId: {
    eq: 1,
  },
  and: [
    {
      matchStudentSemesters: {
        some: {
          studentSemesterStatusId: {
            in: [2, 6],
          },
        },
      },
    },
    {
      matchStudentSemesters: {
        none: {
          studentSemesterStatusId: {
            in: [5],
          },
          zeitraum: {
            zeitraumVon: { lte: new Date().toISOString() },
          },
        },
      },
    },
  ],
};
const type = "teilnehmer";

const TeilnehmerList: React.FC<{}> = () => {
  return <StudentList formType={type} where={where} />;
};

export default TeilnehmerList;
