import { createStyles, makeStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const useStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    formHeaderbox: {
      // height: "4.7em",
      marginTop: "3em",
    },
  })
);


interface FormHeaderPorps {
  title: string;
}

const FormHeader: React.FC<FormHeaderPorps> = ({ title }) => {
  const classes = useStyles({});
  return (
    <div className={classes.formHeaderbox}>
      <Typography variant="h4">{title}</Typography>
    </div>
  );
};

export default FormHeader;
