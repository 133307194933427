import React from "react";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import colors from "themes/colors";

export interface IconProps extends FontAwesomeIconProps {
  className?: string;
  color?: string;
  fontSize?: number | "inherit";
  pulse?: boolean;
  spin?: boolean;
}

const useStyles = makeStyles({
  icon: {
    width: "100%",
    height: "auto",
    borderRadius: "0.5rem",
    left: 0,
    top: 0,
  },
  iconWrapper: {
    position: "relative",
    margin: 0,
    height: "fit-content",
  },
});

const Icon: React.FC<IconProps> = ({ icon, className, fontSize, pulse, spin, color }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.iconWrapper, className)} style={{ fontSize: fontSize }}>
      <FontAwesomeIcon pulse={pulse} spin={spin} className={classes.icon} icon={icon} color={color} />
    </div>
  );
};

export default Icon;
