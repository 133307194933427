const de = {
  name: "Fähigkeit",
  plural: "Fähigkeiten",
  columns: {
    capabilityName: "Fähigkeit Name",
    deviceCapability: "Gerätefähigkeit",
    deviceModelCapability: "Gerätemodellfähigkeit",
    id: "ID",
    isDeleted: "ist gelöscht",
    tenantId: "Mieter ID",
  },
  capabilityNames: {
    utcTimeMeasured: "Letzte Verbindungszeit",
    accX: "Beschleunigung X-Achse",
    accY: "Beschleunigung Y-Achse",
    accZ: "Beschleunigung Z-Achse",
    n_sats: "Anzahl Satelliten",
    v: "",
    Message: "Nachricht",
    Timestamp: "Zeitstempel",
    Altitude: "Höhe",
    Longitude: "GPS Länge",
    Level: "Ebene",
    Humidity: "Feuchtigkeit",
    Latitude: "GPS Breite",
    "Device Zustand": "Zustand",
    Temperature: "Temperatur",
    Direction: "Richtung",
  },
};

export default de;
