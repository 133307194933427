import React, { useEffect, useMemo } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useRecoilState } from "recoil";

import {
  modulZuordnungen,
  ModulZuordnungen,
  moduleDropdownIds,
} from "components/PagesCampusManagement/produkte/Module/recoilStates";
import { useGetMatchStudiengangModulsQuery } from "graphqlBase/matchStudiengangModuls/__generated__/getMatchStudiengangModuls";
import { useGetStudiengangsQuery } from "graphqlBase/Studiengang/__generated__/getStudiengangs";
import { useGetModulsQuery } from "graphqlBase/Modul/__generated__/getModuls";
import Props from "./index.d";
import ModulZuordnungRow from "./ModulZuordnungRow";
import LoadingIndicator from "components/atomics/LoadingIndicator";
import translation from "translations";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: "flex",
        justifyContent: "center",
      },
      container: {
        width: "50%",
        marginTop: "4em",
        minWidth: "800px",
      },
      moduleNameContainer: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "2em",
      },
      buttonAdd: {
        fontSize: 30,
        height: 40,
        lineHeight: 20,
        marginLeft: "3px",
        width: "100%",
        "& .MuiButton-label": {
          justifyContent: "normal",
        },
        "&:hover": {
          backgroundColor: "theme.colors.grey200",
        },
      },
      moduleName: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "20px",
        lineHeight: "19px",
      },
    }),
  { name: "Modulzuordnung" }
);

const Modulzuordnung: React.FC<Props> = (props) => {
  const {
    data: { state },
    disabled,
    raftVariables,
  } = props;

  const modulId = raftVariables.id ?? 0;
  const classes = useStyles();
  const [modulZuweisungList, setModulZuweisungList] = useRecoilState(modulZuordnungen);
  const [currentModuleDropdownIds, setCurrentModuleDropdownIds] = useRecoilState(moduleDropdownIds);

  const { data, loading } = useGetMatchStudiengangModulsQuery({
    variables: {
      filterInput: { modulId: { eq: modulId } },
    },
    fetchPolicy: "network-only",
    skip: typeof modulId === "string",
  });

  const { data: studiengangData, loading: studiengangLoading } = useGetStudiengangsQuery();

  const { data: modulData, loading: modulLoading } = useGetModulsQuery({
    variables: {
      where: { modulId: { eq: modulId } },
    },
    skip: typeof modulId === "string",
    fetchPolicy: "cache-first",
  });

  const moduleName = useMemo(() => {
    return modulData?.moduls[0]?.modulName ?? "";
  }, [modulData]);

  const addNewMatchStudiengangModul = () => {
    setModulZuweisungList((modulZuweisungen) => {
      return modulZuweisungen.concat({
        id: new Date().valueOf(),
        modulId,
        planSemester: null,
        studiengangId: currentModuleDropdownIds[0],
      });
    });
  };

  useEffect(() => {
    if (!data) return;
    const matchStudiengangModuls = data.matchStudiengangModuls.reduce((acc: ModulZuordnungen[], curr) => {
      const { id, modulId, planSemester, studiengangId } = curr;
      return acc.concat({ id, modulId, planSemester, studiengangId });
    }, []);
    setModulZuweisungList(matchStudiengangModuls);
  }, [data]);

  useEffect(() => {
    if (!studiengangData) return;

    const allStudiengangsIds = studiengangData.studiengangs.reduce<number[]>((ids, studiengang) => {
      if (studiengang.ausbildungsArtId === state) return ids.concat(studiengang.id);
      return ids;
    }, []);

    const assignedStudiengangsIds = modulZuweisungList.map((e) => e.studiengangId);
    const difference = allStudiengangsIds.filter((x) => !assignedStudiengangsIds.includes(x));
    setCurrentModuleDropdownIds(difference);
  }, [studiengangData, modulZuweisungList]);

  if (disabled) return null;
  if (loading || studiengangLoading || modulLoading) return <LoadingIndicator />;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {moduleName && (
          <div className={classes.moduleNameContainer}>
            <div className={classes.moduleName}>{`${translation.pages.produkte.module.label} ${moduleName}`}</div>
          </div>
        )}
        <div>
          {modulZuweisungList.map((modul) => {
            return (
              <Grid container item xs={12} spacing={3} key={modul.id}>
                <ModulZuordnungRow
                  studiengangs={studiengangData?.studiengangs ?? []}
                  setModulZuweisungList={setModulZuweisungList}
                  modul={modul}
                />
              </Grid>
            );
          })}

          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4}>
              <Button
                disabled={(studiengangData?.studiengangs ?? []).length <= modulZuweisungList.length}
                variant="contained"
                className={classes.buttonAdd}
                aria-label="buttonAdd"
                onClick={addNewMatchStudiengangModul}
              >
                <Typography variant="h4">+</Typography>
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Modulzuordnung;
