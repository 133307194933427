import { createStyles, makeStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";
import FormHeader from "components/atomics/FormHeader";
import Select from "components/atomics/Select";
import React, { useEffect } from "react";
import Props from "./index.d";
const useStyles = makeStyles(
  (theme: AugmentedTheme) =>
    createStyles({
      foreignKeySelectorIntBox: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },
    }),
  { name: "IntegerSelect" }
);
const ForeignKeySelectorInt: React.FC<Props> = (props) => {
  const {
    data: { state, setAndSave },
    disabled,
    title,
    pickDefaultFirst,
    label,
    noHeader,
    errors,
    noFormControlMarginTop = false,
    minValue,
    maxValue,
    prefix,
  } = props;

  const elems: {
    value: number;
    label: string;
  }[] = Array.from(Array(maxValue + 1 - minValue)).map<{
    value: number;
    label: string;
  }>((_, index) => {
    const value = index + minValue;
    return {
      value,
      label: prefix ? `${prefix} ${value}` : `${value}`,
    };
  });

  useEffect(() => {
    if (pickDefaultFirst && !state && !!elems && elems.length) setAndSave(elems[0].value);
  }, [elems, state, pickDefaultFirst]);
  const classes = useStyles(props);
  return (
    <div className={classes.foreignKeySelectorIntBox}>
      {!noHeader && <FormHeader title={title} />}

      <Select
        label={label}
        setState={(value) => setAndSave(+value)}
        state={state}
        items={elems}
        disabled={disabled}
        error={!!errors?.length}
        helperText={errors?.join(" ") ?? undefined}
        noFormControlMarginTop={noFormControlMarginTop}
      />
    </div>
  );
};

export default ForeignKeySelectorInt;
