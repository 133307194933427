import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { GetMoodleModulsDocument } from "graphqlBase/MoodleModul/__generated__/getMoodleModuls";
import React from "react";
import { useHistory } from "react-router-dom";
import Loader from "components/atomics/Loader";
import { MoodleModulCreateForm } from "raft/MoodleModulForm";

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    formWrapper: {
      display: "flex",
      width: "100%",
    },
    addButton: {
      fontSize: 14,
      color: theme.colors.tWhite,
      backgroundColor: theme.colors.sunYellow,
      height: 40,
      borderRadius: "unset",
      "&:hover": { color: theme.colors.tWhite, backgroundColor: theme.colors.sunYellow },
    },
  })
);

const MoodleModuleAdd: React.FC<{}> = () => {
  const classes = useStyles();
  const history = useHistory();

  const [state, setstate] = React.useState(false);

  const close = () => {
    history.goBack();
  };

  const afterCreate = ({ id }: { id: number }) => {
    history.push(`/lehre/moodleModulEdit/${id}`, { pushedFrom: "moodleModuleAdd" });
  };

  return (
    <div className={classes.formWrapper}>
      <Loader style={{ justifyContent: "center" }} loading={state}>
        <MoodleModulCreateForm
          onCancel={close}
          afterCreate={afterCreate}
          beforeRemoteMuatation={(p) => {
            setstate(true);
            return {
              ...p.data,
            };
          }}
          updateAfterCreateQueries={[
            {
              query: GetMoodleModulsDocument,
              variables: {},
            },
          ]}
        />
      </Loader>
    </div>
  );
};

export default MoodleModuleAdd;
