import React, { useState } from "react";
import Drawer, { DrawerProps } from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import Avatar from "components/lbc-toolkit/atomics/Avatar";
import routes, { Route } from "../../../routes/routes";
import { Typography } from "@material-ui/core";
import tableColors from "components/templates/table-factory/theme/tableColors";
import colors from "themes/colors";
import translations from "translations";
import { getUserName } from "authorization";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      buttonBoxClose: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
      buttonBoxOpen: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      },
      cornerBox: {
        display: "flex",
        width: "64px",
        height: "64px",
        alignItems: "flex-end",
        marginLeft: 9,
      },
      corner: {
        borderTop: "6px solid transparent",
        borderBottom: "6px solid transparent",
        borderRight: `6px solid ${colors.tBlack}`,
        transform: `rotateZ(-135deg)`,
      },
      drawerRoot: {
        // height: "100%",
        display: "flex",
        "& .MuiDrawer-paperAnchorDockedLeft": {
          border: 0,
          borderRight: `1px solid ${colors.lbcGrey}`,
        },
      },
      drawerClose: {
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: (props: { drawerWidth: { closed: string; open: string }; open: boolean }) => props.drawerWidth.closed,
      },
      drawerOpen: {
        width: (props: { drawerWidth: { closed: string; open: string }; open: boolean }) => props.drawerWidth.open,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: "hidden",
      },
      font: {
        color: colors.tBlack,
        font: "Arial",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        paddingLeft: "20px",
        paddingBottom: "4px",
        whiteSpace: "nowrap",
        width: "fit-content",
      },
      hide: {
        display: "none",
      },
      item: {
        display: "flex",
        alignContent: "center",
        height: "64px",
        width: (props: { drawerWidth: { closed: string; open: string }; open: boolean }) =>
          props.open ? props.drawerWidth.open : props.drawerWidth.closed,
      },
      itemActive: {
        borderLeft: `4px solid ${colors.brandYellow}`,
        background: colors.grey200,
        paddingRight: 0,
        paddingBottom: 0,
      },
      itemIcon: {
        minWidth: "30px",
        minHeight: "30px",
        color: colors.grey700,
      },
      itemBox: {
        display: "flex",
        alignContent: "center",
        height: "64px",
        paddingLeft: "8px",
        width: (props: { drawerWidth: { closed: string; open: string }; open: boolean }) =>
          props.open ? props.drawerWidth.open : props.drawerWidth.closed,
      },
      itemInactive: {
        borderLeft: "4px solid transparent",
        paddingBottom: 0,
      },
      link: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingBottom: "10px",
      },
      listRoot: {
        padding: 0,
        "& img": {
          height: "45px",
          width: "57px",
        },
      },
      menuButtonClose: {
        margin: 0,
        padding: 0,
        width: "40px",
        height: "40px",
        borderRadius: "20px",
        background: colors.lbcGrey,
      },
      menuButtonOpen: {
        marginRight: 0,
        padding: 0,
        paddingBottom: "5px",
        marginLeft: "auto",
        minHeight: "50px",
        width: "fit-content",
      },
      menuIcon: {
        width: "30px",
        height: "30px",
        padding: "5px",
      },
      toolbar: {
        marginTop: "auto",
      },
      toolbarClose: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: (props: { drawerWidth: { closed: string; open: string }; open: boolean }) => props.drawerWidth.closed,
        minHeight: "108px",
        paddingBottom: "19px",
      },
      toolbarOpen: {
        display: "flex",
        flexDirection: "row",
        width: (props: { drawerWidth: { closed: string; open: string }; open: boolean }) => props.drawerWidth.open,
        paddingLeft: "16px",
        paddingRight: "10px",
      },
      linkWrapper: {
        display: "flex",
        flexDirection: "row",
      },
      subNav: {
        background: colors.tWhite,
        display: "flex",
        flexDirection: "column",
        left: (props: { drawerWidth: { closed: string; open: string }; open: boolean }) =>
          props.open ? props.drawerWidth.open : props.drawerWidth.closed,
        position: "fixed",
        "& .MuiListItem-root": {
          padding: 0,
        },
      },
      subNavItem: {
        display: "flex",
        height: "50px",
        width: "100%",
        minWidth: "20em",
        "& :hover": {
          background: colors.grey200,
        },
        "& .MuiTypography-displayBlock": {
          display: "flex",
          padding: "0px 5px",
          width: "fit-content",
          marginBottom: "-2px",
        },
      },
      subNavLabel: {
        display: "flex",
        color: colors.tBlack,
        width: "100%",
        "& :hover": {
          background: colors.grey200,
        },
      },
    }),
  {
    name: "Navigator",
  }
);

export interface NavigatorProps extends DrawerProps {
  drawerWidth: { closed: string; open: string };
  open: boolean;
  setOpen: (props: boolean) => void;
  setTitle: (props: string) => void;
}

const Navigator: React.FC<NavigatorProps> = (props) => {
  const [subNavOpen, setSubNavOpen] = useState<String | undefined>();
  const location = useLocation();
  const { drawerWidth, open, setOpen, setTitle } = props;
  const classes = useStyles({ drawerWidth, open });
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const userName = getUserName();
  const initials = userName
    .split(" ")
    .map((part) => part[0] ?? "")
    .join("");

  return (
    <Drawer
      variant="permanent"
      classes={{
        root: classes.drawerRoot,
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <List className={classes.listRoot}>
        {routes
          .filter((route) => route.menuItem)
          .map((route) => (
            <div
              key={route.path}
              className={classes.linkWrapper}
              onMouseLeave={() => {
                if (subNavOpen) setSubNavOpen(undefined);
              }}
            >
              <Link key={route.path} to={route.path}>
                <ListItem
                  key={route.path}
                  button
                  onClick={() => {
                    setTitle(route.label);
                  }}
                  onMouseEnter={() => {
                    if (route.subRoutes !== undefined) {
                      setSubNavOpen(route.path);
                    } else if (subNavOpen) setSubNavOpen(undefined);
                  }}
                  className={clsx(
                    {
                      [classes.item]: route.path !== "/",
                      [classes.itemBox]: route.path === "/",
                    },
                    route.path !== "/" && route.path === location.pathname ? classes.itemActive : classes.itemInactive
                  )}
                >
                  <ListItemIcon className={classes.itemIcon}>{route.menuIcon}</ListItemIcon>
                  {open && (
                    <ListItemText classes={{ primary: classes.font }}>
                      {route.path !== "/" ? route.label : translations.pages.home.label}
                    </ListItemText>
                  )}
                  {!open && route.path === location.pathname && route.path !== "/" && (
                    <div className={classes.cornerBox}>
                      <div className={classes.corner} />
                    </div>
                  )}
                </ListItem>
              </Link>
              {subNavOpen === route.path && route.subRoutes && (
                <div className={classes.subNav}>
                  {(route.subRoutes ?? [])
                    .filter((subRoute) => subRoute.menuItem)
                    ?.map((subRoute: Route) => {
                      if (subRoute.linkOut)
                        return (
                          <a
                            href={subRoute.linkOut}
                            className={classes.subNavLabel}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <ListItem className={classes.subNavLabel} key={subRoute.path}>
                              <ListItemText>{subRoute.label}</ListItemText>
                            </ListItem>
                          </a>
                        );
                      return (
                        <Link
                          className={classes.subNavItem}
                          key={subRoute.path}
                          to={subRoute.linkOut ? subRoute.linkOut : route.path + subRoute.path}
                          style={{ padding: 0, margin: 0 }}
                          onMouseEnter={() => {
                            if (route.subRoutes) setSubNavOpen(route.path);
                          }}
                          onMouseLeave={() => {
                            if (route.subRoutes) setSubNavOpen(undefined);
                          }}
                        >
                          <ListItem className={classes.subNavLabel} key={subRoute.path}>
                            <ListItemText>{subRoute.label}</ListItemText>
                          </ListItem>
                        </Link>
                      );
                    })}
                </div>
              )}
            </div>
          ))}
      </List>
      <div className={classes.toolbar}>
        {routes
          .filter((route) => route.path === "/feedback")
          .map((route) => (
            <div key={route.path} className={clsx({ [classes.toolbarClose]: !open, [classes.toolbarOpen]: open })}>
              <div className={classes.buttonBoxClose}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  className={clsx(classes.menuButtonClose, {
                    [classes.hide]: open,
                  })}
                >
                  <MenuIcon className={classes.menuIcon} />
                </IconButton>
                {!open && (
                  <Link key={route.path} to={route.path} style={{ paddingTop: "28px" }}>
                    <Avatar backgroundColor={colors.tBlack} color={colors.tWhite} initials={initials} />
                  </Link>
                )}
              </div>
              <div className={classes.buttonBoxOpen}>
                {open && (
                  <div className={classes.link}>
                    <Link key={route.path} to={route.path}>
                      <Avatar backgroundColor={colors.tBlack} color={colors.tWhite} initials={initials} />
                    </Link>
                    <Typography className={classes.font}>{userName}</Typography>
                  </div>
                )}
                <IconButton
                  color="inherit"
                  aria-label="closed drawer"
                  className={clsx(classes.menuButtonOpen, {
                    [classes.hide]: !open,
                  })}
                  onClick={handleDrawerClose}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: tableColors.lbcgrey,
                      borderRadius: "20px",
                      color: colors.tBlack,
                      height: "40px",
                      width: "40px",
                      paddingRight: "9px",
                    }}
                  >
                    <div
                      style={{
                        width: 12,
                        height: 6,
                        borderTop: "6px solid transparent",
                        borderBottom: "6px solid transparent",
                        borderRight: "6px solid",
                      }}
                    />
                  </div>
                </IconButton>
              </div>
            </div>
          ))}
      </div>
    </Drawer>
  );
};

export default Navigator;
