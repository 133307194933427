export default {
  name: "Asset",
  plural: "Assets",
  columns: {
    assetAttributeValue: "Asset-Attribut Wert",
    assetDescription: "Asset-Beschreibung",
    assetDevice: "Asset-Gerät",
    assetImage: "Asset-Image",
    assetName: "Asset-Name",
    assetTypeAsset: "Asset-Typ",
    assetType: "Asset-Typ",
    buildDate: "Erstellungsdatum",
    id: "ID",
    inverseParentAsset: "inverses übergeordnetes Asset",
    isDeleted: "ist gelöscht",
    lMID: "lMID",
    parentAsset: "übergeordnetes Asset",
    parentAssetId: "übergeordnetes Asset ID",
    serialNo: "Seriennummer",
    tenantId: "Mieter ID",
  },
};
