import React, { useEffect, useMemo } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useRecoilState } from "recoil";

import {
  pruefungTypZuordnungen,
  PruefungTypZuordnungen,
  pruefungTypDropdownIds,
} from "components/PagesCampusManagement/produkte/Module/recoilStates";
import { useGetMatchModulPruefungTypsQuery } from "graphqlBase/MatchModulPruefungTyp/__generated__/getMatchModulPruefungTyps";
import { useGetPruefungTypsQuery } from "graphqlBase/PruefungTyp/__generated__/getPruefungTyps";
import { useGetModulsQuery } from "graphqlBase/Modul/__generated__/getModuls";
import Props from "./index.d";
import PruefungTypZuordnungRow from "./PruefungTypZuordnungRow";
import LoadingIndicator from "components/atomics/LoadingIndicator";
import translation from "translations";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: "flex",
        justifyContent: "center",
        // width: "80%",
        marginLeft: "12px",
      },
      container: {
        width: "100%",
        marginTop: "4em",
        minWidth: "800px",
      },
      moduleNameContainer: {
        display: "flex",
        justifyContent: "flex-start",
        marginBottom: "2em",
      },
      buttonAdd: {
        fontSize: 30,
        height: 40,
        lineHeight: 20,
        marginLeft: "3px",
        width: "100%",
        "& .MuiButton-label": {
          justifyContent: "normal",
        },
        "&:hover": {
          backgroundColor: "theme.colors.grey200",
        },
      },
      moduleName: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "20px",
        lineHeight: "19px",
      },
    }),
  { name: "Modulzuordnung" }
);

const ModulPruefungTyps: React.FC<Props> = (props) => {
  const {
    data: { state },
    disabled,
    title,
    raftVariables,
  } = props;

  const modulId = raftVariables.id ?? 0;
  const classes = useStyles();
  const [pruefungTypZuweisungList, setPruefungTypZuweisungList] = useRecoilState(pruefungTypZuordnungen);
  const [currentPruefungTypDropdownIds, setCurrentPruefungTypDropdownIds] = useRecoilState(pruefungTypDropdownIds);

  const { data, loading } = useGetMatchModulPruefungTypsQuery({
    variables: {
      filterInput: { modulId: { eq: modulId } },
    },
    fetchPolicy: "network-only",
    skip: typeof modulId === "string",
  });
  const { data: pruefungtypData, loading: pruefungtypLoading } = useGetPruefungTypsQuery();

  const { data: modulData, loading: modulLoading } = useGetModulsQuery({
    variables: {
      where: { modulId: { eq: modulId } },
    },
    skip: typeof modulId === "string",
    fetchPolicy: "cache-first",
  });

  const moduleName = useMemo(() => {
    return modulData?.moduls[0]?.modulName ?? "";
  }, [modulData]);

  const addNewMatchPruefungTypModul = () => {
    setPruefungTypZuweisungList((pruefungTypZuordnungenInner) => {
      return pruefungTypZuordnungenInner.concat({
        id: -new Date().valueOf(),
        modulId,
        dauer: null,
        anteil: null,
        pruefungTypId: 0,
        isDeleted: false,
      });
    });
  };

  useEffect(() => {
    if (!data) return;
    const matchPruefungTypModuls = data.matchModulPruefungTyps.reduce<PruefungTypZuordnungen[]>((acc, curr) => {
      const { id, dauer, pruefungTypId, anteil } = curr;
      return acc.concat({ id, modulId, dauer, pruefungTypId, anteil, isDeleted: false });
    }, []);
    setPruefungTypZuweisungList(matchPruefungTypModuls);
  }, [data]);

  useEffect(() => {
    if (!pruefungtypData) return;

    const allPruefungTypsIds = pruefungtypData.pruefungTyps.reduce<number[]>((ids, pruefungtyp) => {
      return ids.concat(pruefungtyp.id);
    }, []);

    const assignedPruefungTypsIds = pruefungTypZuweisungList.map((e) => e.pruefungTypId);
    const difference = allPruefungTypsIds.filter((x) => !assignedPruefungTypsIds.includes(x));
    setCurrentPruefungTypDropdownIds(difference);
  }, [pruefungtypData, pruefungTypZuweisungList]);

  if (disabled) return null;
  if (loading || pruefungtypLoading || modulLoading) return <LoadingIndicator />;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {moduleName && (
          <div className={classes.moduleNameContainer}>
            <div className={classes.moduleName}>{`${title}`}</div>
          </div>
        )}
        <div>
          {pruefungTypZuweisungList.map((modul) => {
            if (modul.isDeleted) return null;
            return (
              <Grid container item xs={12} spacing={3} key={modul.id}>
                <PruefungTypZuordnungRow
                  pruefungtyps={pruefungtypData?.pruefungTyps ?? []}
                  setPruefungTypZuweisungList={setPruefungTypZuweisungList}
                  modul={modul}
                />
              </Grid>
            );
          })}

          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4}>
              <Button
                disabled={(pruefungtypData?.pruefungTyps ?? []).length <= pruefungTypZuweisungList.length}
                variant="contained"
                className={classes.buttonAdd}
                aria-label="buttonAdd"
                onClick={addNewMatchPruefungTypModul}
              >
                <Typography variant="h4">+</Typography>
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default ModulPruefungTyps;
