import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { GetStudiengangsDocument } from "graphqlBase/Studiengang/__generated__/getStudiengangs";
import React from "react";
import Loader from "components/atomics/Loader";
import { useHistory, useLocation } from "react-router-dom";
import { StudiengangCreateForm } from "raft/StudiengangForm";

export const useStyles = makeStyles<Theme>(
  (theme: Theme) =>
    createStyles({
      formWrapper: {
        display: "flex",
        width: "100%",
      },
      addButton: {
        fontSize: 14,
        color: theme.colors.tWhite,
        backgroundColor: theme.colors.sunYellow,
        height: 40,
        borderRadius: "unset",
        "&:hover": { color: theme.colors.tWhite, backgroundColor: theme.colors.sunYellow },
      },
      StudiengangCreateFormBox: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
      afterCreate: {
        margin: "auto",
        maxWidth: "700px",
      },
      afterCreateBody: {
        marginTop: "1em",
      },
      afterCreateHeadline: { marginBottom: "10px" },
    }),

  { name: `Pages-StudiengangCreate` }
);

const StudiumAdd: React.FC<{}> = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [state, setstate] = React.useState(false);
  const onLeave = () => {
    history.goBack();
  };
  return (
    <div className={classes.StudiengangCreateFormBox}>
      <Loader style={{ justifyContent: "center" }} loading={state}>
        <StudiengangCreateForm
          // ausbildungsArtId={location.pathname.includes("zertifikatsModule") ? 2 : 1}
          afterCreate={onLeave}
          onCancel={onLeave}
          beforeRemoteMuatation={(p) => {
            setstate(true);
            return {
              ...p.data,
            };
          }}
          updateAfterCreateQueries={[
            {
              query: GetStudiengangsDocument,
              variables: {},
            },
          ]}
        />
      </Loader>
    </div>
  );
};
export default StudiumAdd;
