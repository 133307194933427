import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { MoodleModulUpdateForm, MoodleModulUpdateOnUpdateDataMiddleWare } from "raft/MoodleModulForm";
import {
  useGetMoodleModulsQuery,
  GetMoodleModulsQuery,
  ResultType,
} from "graphqlBase/MoodleModul/__generated__/getMoodleModuls";

type MoodleModule = ResultType<GetMoodleModulsQuery["moodleModuls"]>;

const MoodleModuleEdit: React.FC<{}> = () => {
  const history = useHistory();
  const { moodleModulId } = useParams<{ moodleModulId?: string }>();
  const id = +(moodleModulId ?? "0");
  const { data } = useGetMoodleModulsQuery({ variables: { where: { moodleModulId: { eq: id } } } });

  const [moodleModul, setMoodleModul] = React.useState<MoodleModule | null>(null);

  React.useEffect(() => {
    if (!data) return;
    setMoodleModul(data.moodleModuls[0]);
  }, [data]);

  const onAfterUpdate = (data: { id: number }) => {
    const state = history.location.state as { pushedFrom: string } | undefined;
    if (state?.pushedFrom === "moodleModuleAdd") {
      history.push("/lehre/alleModule");
    } else {
      history.goBack();
    }
  };

  const onLeave = () => {
    const state = history.location.state as { pushedFrom: string } | undefined;
    if (state?.pushedFrom === "moodleModuleAdd") {
      history.push("/lehre/alleModule");
    } else {
      history.goBack();
    }
  };

  const onUpdateUpdateMiddleware: MoodleModulUpdateOnUpdateDataMiddleWare = ({ data }) => {
    const summaryOne = moodleModul?.modul?.modulName ?? "";
    const summaryTwo = moodleModul?.zeitraum?.zeitraumName ?? "";
    return { ...data, summaryOne, summaryTwo };
  };

  return (
    <div style={{ width: "100%" }}>
      <MoodleModulUpdateForm
        onUpdateDataMiddleWare={onUpdateUpdateMiddleware}
        onCancel={onLeave}
        afterUpdate={onAfterUpdate}
        id={id}
      />
    </div>
  );
};

export default MoodleModuleEdit;
