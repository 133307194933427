import React from "react";

function useKeyPress(targetKey: string): boolean {
  const [keyPressed, setKeyPressed] = React.useState(false);

  function downHandler({ key }: { key: string }): void {
    if (key === targetKey) {
      setKeyPressed((val) => !val);
    }
  }

  React.useEffect(() => {
    window.addEventListener("keydown", downHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, []);
  return keyPressed;
}

const Inspect = (props: { tree: any; treeConfig: any; isField?: boolean; showData?: () => any }) => {
  if (process.env.NODE_ENV !== "development") return null;
  return <InspectInner {...props} />;
};

const InspectInner = ({
  tree,
  treeConfig,
  isField = false,
  showData,
}: {
  tree: any;
  treeConfig: any;
  isField?: boolean;
  showData?: () => any;
}) => {
  const inspect = useKeyPress("F9");
  React.useEffect(() => {
    if (inspect && showData) {
    }
  }, [inspect, showData]);
  if (!inspect) return null;
  return (
    <>
      {tree.config.uiType === "GFCForm_Base" && (
        <div>
          <b>
            <a
              style={{ fontSize: "1.5em" }}
              href={`${location.protocol}//${location.host}/configurator?form=${treeConfig.form}`}
              target="_blank"
            >
              {treeConfig.form}
            </a>
            &nbsp;&nbsp;
          </b>
          {showData && <Logger data={showData()} form={treeConfig.form} />}
        </div>
      )}
      {isField && (
        <div>
          <a
            href={`${location.protocol}//${location.host}/configurator?form=${treeConfig.form}#${tree.id}`}
            target="_blank"
          >
            {tree.config.uiType}
          </a>
          &nbsp;&nbsp;
        </div>
      )}
    </>
  );
};

const Logger = ({ data, form }: { data: any; form: string }) => {
  React.useEffect(() => {
    console.table({ __form__: form, ...data });
  }, []);
  return null;
};

export default Inspect;
