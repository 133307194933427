export default {
  name: "Regelverweis",
  plural: "Regelverweisen",
  columns: {
    id: "ID",
    isDeleted: "ist gelöscht",
    referenceNumber: "Referenznummer",
    referenceUnitId: "Referenz Einheit ID",
    rule: "Regel",
    ruleReferenceDescription: "Regel Referenz Beschreibung",
    ruleReferenceName: "Regel Referenzname",
    ruleReferenceUnit: "Regel Referenzeinheit",
  },
};
