import {
  makeNewFormMemoized,
  genericDeleteFunction as genericDeleteFunctionPre,
} from "./"
import {
  Operation,
  QueryArray,
  DeleteChildProps,
  BeforeRemoteMuatation,
  BeforeRemoteMuatationProps,
  OnUpdateDataMiddleWare,
  OnUpdateDataMiddleWareProps,
  ValidateForm,
  ValidateFormProps,
} from "@ml-pa/react-apollo-form-tool/formGen.model"
import React from "react"
import * as SchemaTypes from "./internal/schemaTypes"
import { FormTypes, ModifyProps } from "./internal/typeLinks"

export type PruefungsergebniCreateOnUpdateDataMiddleWare =
  OnUpdateDataMiddleWare<SchemaTypes.PruefungsergebniInputCreate>
export type PruefungsergebniCreateOnUpdateDataMiddleWareProps =
  OnUpdateDataMiddleWareProps<SchemaTypes.PruefungsergebniInputCreate>
export type PruefungsergebniCreateBeforeRemoteMuatation = BeforeRemoteMuatation<
  SchemaTypes.PruefungsergebniInputCreate,
  SchemaTypes.PruefungsergebniInputCreateProps["injectedValues"]
>
export type PruefungsergebniCreateBeforeRemoteMuatationProps =
  BeforeRemoteMuatationProps<
    SchemaTypes.PruefungsergebniInputCreate,
    SchemaTypes.PruefungsergebniInputCreateProps["injectedValues"]
  >
export type PruefungsergebniCreateValidateForm = ValidateForm<
  SchemaTypes.PruefungsergebniInputCreate,
  SchemaTypes.PruefungsergebniInputCreateProps["injectedValues"]
>
export type PruefungsergebniCreateValidateFormProps = ValidateFormProps<
  SchemaTypes.PruefungsergebniInputCreate,
  SchemaTypes.PruefungsergebniInputCreateProps["injectedValues"]
>

export interface PruefungsergebniCreateProps
  extends SchemaTypes.PruefungsergebniInputCreateProps {
  studentId: number
  multiInstance?: boolean
  formVersion?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<SchemaTypes.PruefungsergebniInputCreate>
  afterCreate?: (
    data: SchemaTypes.PruefungsergebniInputCreate & { id: number }
  ) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<SchemaTypes.PruefungsergebniInputCreate>
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.PruefungsergebniInputCreate,
    SchemaTypes.PruefungsergebniInputCreateProps["injectedValues"]
  >
  validateForm?: ValidateForm<
    SchemaTypes.PruefungsergebniInputCreate,
    SchemaTypes.PruefungsergebniInputCreateProps["injectedValues"]
  >
  updateAfterCreateQueries?: QueryArray
}

export const PruefungsergebniCreateForm: React.FC<PruefungsergebniCreateProps> =
  (props) => {
    const {
        afterCreate,
        injectedValues,
        multiInstance = false,
        formVersion,
        onCancel,
        updateAfterCreateQueries,
        beforeRemoteMuatation,
        onUpdateDataMiddleWare,
        validateForm,
        modifyProps,
        ...params
      } = props,
      { Form: PruefungsergebniCreate } = makeNewFormMemoized({
        entity: "pruefungsergebni",

        operation: "create",
        formVersion,
        params: multiInstance ? params : undefined,
      })
    return (
      <PruefungsergebniCreate
        params={params}
        onCancel={onCancel}
        injectedValues={injectedValues}
        afterCreate={afterCreate}
        updateAfterCreateQueries={updateAfterCreateQueries}
        beforeRemoteMuatation={beforeRemoteMuatation}
        onUpdateDataMiddleWare={onUpdateDataMiddleWare}
        validateForm={validateForm}
        modifyProps={modifyProps}
      />
    )
  }

export type PruefungsergebniUpdateOnUpdateDataMiddleWare =
  OnUpdateDataMiddleWare<SchemaTypes.PruefungsergebniInputUpdate>
export type PruefungsergebniUpdateOnUpdateDataMiddleWareProps =
  OnUpdateDataMiddleWareProps<SchemaTypes.PruefungsergebniInputUpdate>
export type PruefungsergebniUpdateBeforeRemoteMuatation = BeforeRemoteMuatation<
  SchemaTypes.PruefungsergebniInputUpdate,
  SchemaTypes.PruefungsergebniInputUpdateProps["injectedValues"]
>
export type PruefungsergebniUpdateBeforeRemoteMuatationProps =
  BeforeRemoteMuatationProps<
    SchemaTypes.PruefungsergebniInputUpdate,
    SchemaTypes.PruefungsergebniInputUpdateProps["injectedValues"]
  >
export type PruefungsergebniUpdateValidateForm = ValidateForm<
  SchemaTypes.PruefungsergebniInputUpdate,
  SchemaTypes.PruefungsergebniInputUpdateProps["injectedValues"]
>
export type PruefungsergebniUpdateValidateFormProps = ValidateFormProps<
  SchemaTypes.PruefungsergebniInputUpdate,
  SchemaTypes.PruefungsergebniInputUpdateProps["injectedValues"]
>

export interface PruefungsergebniUpdateProps
  extends SchemaTypes.PruefungsergebniInputUpdateProps {
  id: number
  multiInstance?: boolean
  formVersion?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<SchemaTypes.PruefungsergebniInputUpdate>
  afterUpdate?: (data: SchemaTypes.PruefungsergebniInputUpdate) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<SchemaTypes.PruefungsergebniInputUpdate>
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.PruefungsergebniInputUpdate,
    SchemaTypes.PruefungsergebniInputUpdateProps["injectedValues"]
  >
  validateForm?: ValidateForm<
    SchemaTypes.PruefungsergebniInputUpdate,
    SchemaTypes.PruefungsergebniInputUpdateProps["injectedValues"]
  >
  fetchAllFieldsOnUpdate?: boolean
}

export const PruefungsergebniUpdateForm: React.FC<PruefungsergebniUpdateProps> =
  (props) => {
    const {
        afterUpdate,
        injectedValues,
        multiInstance = false,
        formVersion,
        onCancel,
        fetchAllFieldsOnUpdate,
        beforeRemoteMuatation,
        onUpdateDataMiddleWare,
        validateForm,
        modifyProps,
        ...params
      } = props,
      { Form: PruefungsergebniUpdate } = makeNewFormMemoized({
        entity: "pruefungsergebni",

        operation: "update",
        formVersion,
        params: multiInstance ? params : undefined,
      })
    return (
      <PruefungsergebniUpdate
        params={params}
        onCancel={onCancel}
        injectedValues={injectedValues}
        afterUpdate={afterUpdate}
        fetchAllFieldsOnUpdate={fetchAllFieldsOnUpdate}
        beforeRemoteMuatation={beforeRemoteMuatation}
        onUpdateDataMiddleWare={onUpdateDataMiddleWare}
        validateForm={validateForm}
        modifyProps={modifyProps}
      />
    )
  }
