export default {
  name: "Asset-Art",
  plural: "Asset-Arten",
  columns: {
    articleNo: "Artikel Nummer",
    asset: "Asset",
    assetTypeAttributeSet: "Asset-Art-Attributsatz",
    assetTypeName: "Asset-Type Name",
    heightInMeter: "Höhenmesser",
    id: "ID",
    isDeleted: "ist gelöscht",
    lengthInMeter: "Länge in Meter",
    tenantId: "Mieter ID",
    weightInKilogram: "Gewicht in Kilogramm",
    widthInMeter: "Breite in Meter",
  },
};
