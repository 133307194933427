import React, { useEffect, useState } from "react";
import { getTokenAsync } from "authorization";
import { useGetCiandoTokenLazyQuery } from "graphqlBase/misc/__generated__/getCiandoToken";
import { useQuery } from "raft/internal/apolloBarrel";

const getTokenOut = async () => {
  const token = await getTokenAsync();
  // console.log(token);
};

export default () => {
  const [tokenPre, setTokenPre] = useState<string | undefined>("");
  const [query, { data }] = useGetCiandoTokenLazyQuery();

  useEffect(() => {
    getTokenAsync()
      .then((token) => setTokenPre(token ?? undefined))
      .catch(console.log);
  });

  useEffect(() => {
    if (tokenPre) query({ variables: { token: tokenPre } });
  }, [query, tokenPre]);

  useEffect(() => {
    if (!data) return;
    window.location.href = `https://kolping-hochschule.ciando.com/index.cfm?referer=kolping&token=${
      data?.generateCiandoJWTToken?.token ?? ""
    }`;
  }, [data]);
  return null;
};
