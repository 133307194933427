/* eslint-disable react/display-name */
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import TrashOutlinedIcon from "icons/components/TrashIcon";
import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { genericDeleteFunction, GenericDeleteParams } from "raft/index";
import Modal from "../../../../lbc-toolkit/molecules/Modal";
import translations from "translations";
import { Typography } from "@material-ui/core";
import { isChangedState } from "components/formElements/fields/PruefungenPruefungsErgebnisse/PruefungsErgebnisse/InputCells";
import { useRecoilValue } from "recoil";

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    color: theme.colors.grey700,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    margin: "-12px",
  },
  penButton: {
    "&:hover": {
      backgroundColor: theme.colors.tTransparent,
    },
    marginTop: "0.2em",
    "& svg": {
      color: theme.colors.grey500,
      width: "2em",
      height: "2em",
    },
  },
  deleteButton: {
    "&:hover": {
      backgroundColor: theme.colors.tTransparent,
    },
    "& svg": {
      color: theme.colors.grey500,
      width: "1.5em",
      height: "1.5em",
    },
  },
}));

interface OnDeleteProps extends GenericDeleteParams {
  message?: React.ReactNode;
}

interface ActionsCellProps {
  onDelete?: OnDeleteProps;
  onEdit?: (value: number) => void;
}

const actionsCellFactory = ({ onDelete, onEdit }: ActionsCellProps) => ({
  cell: { value },
}: {
  cell: { value: string };
}) => {
  const classes = useStyles();
  const [idToDelete, setIdToDelete] = useState<number | null>();
  const [currTitle, setCurrTitle] = useState(translations.globals.button.change);
  const isChanged = useRecoilValue(isChangedState);

  const onDeleteInner = useCallback(() => {
    if (!onDelete) return;
    const { message, ...genericParams } = onDelete;

    genericDeleteFunction(genericParams)(idToDelete ?? 0).finally(() => setIdToDelete(null));
  }, [idToDelete, setIdToDelete]);

  const onEditInner = (e: React.MouseEvent<unknown>) => {
    setCurrTitle(translations.globals.button.change);
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
    if (onEdit) onEdit((value as unknown) as number);
  };

  const setIdToDeleteInner = (e: React.MouseEvent<unknown>) => {
    setCurrTitle(translations.globals.button.delete);
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
    if (setIdToDelete) setIdToDelete((value as unknown) as number);
  };

  return (
    <>
      <Modal
        open={!!idToDelete}
        onClose={() => setIdToDelete(null)}
        onCancel={() => setIdToDelete(null)}
        onConfirm={() => onDeleteInner()}
        title={currTitle}
        confirmLabel={isChanged ? translations.globals.button.saveAndContinue : translations.globals.button.delete}
        primaryCancel
      >
        <>
          {onDelete?.message ?? <Typography variant="h4">{translations.globals.popupMessages.dataloss_2}</Typography>}
          {!isChanged ? (
            <></>
          ) : (
            <Typography variant="h4">{translations.globals.popupMessages.examnModalMessage}</Typography>
          )}
        </>
      </Modal>
      <div className={classes.buttonRow}>
        {!!onEdit && (
          <IconButton className={classes.penButton} onClick={onEditInner}>
            <EditIcon />
          </IconButton>
        )}

        {!!onDelete && (
          <IconButton className={classes.deleteButton} onClick={setIdToDeleteInner}>
            <TrashOutlinedIcon />
          </IconButton>
        )}
      </div>
    </>
  );
};

export default actionsCellFactory;
