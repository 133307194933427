import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetTranscriptOfRecordsQueryVariables = Types.Exact<{
  studentId: Types.Scalars['Int'];
}>;


export type GetTranscriptOfRecordsQuery = { transcriptOfRecords?: Types.Maybe<{ __typename?: 'DownloadFile', fileName?: Types.Maybe<string>, fileContent?: Types.Maybe<Array<any>>, fileContentBase64?: Types.Maybe<string>, fileType?: Types.Maybe<string> }> };


export const GetTranscriptOfRecordsDocument = gql`
    query getTranscriptOfRecords($studentId: Int!) {
  transcriptOfRecords(studentId: $studentId) {
    fileName
    fileContent
    fileContentBase64
    fileType
  }
}
    `;

/**
 * __useGetTranscriptOfRecordsQuery__
 *
 * To run a query within a React component, call `useGetTranscriptOfRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTranscriptOfRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTranscriptOfRecordsQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetTranscriptOfRecordsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTranscriptOfRecordsQuery, GetTranscriptOfRecordsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTranscriptOfRecordsQuery, GetTranscriptOfRecordsQueryVariables>(GetTranscriptOfRecordsDocument, baseOptions);
      }
export function useGetTranscriptOfRecordsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTranscriptOfRecordsQuery, GetTranscriptOfRecordsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTranscriptOfRecordsQuery, GetTranscriptOfRecordsQueryVariables>(GetTranscriptOfRecordsDocument, baseOptions);
        }
export type GetTranscriptOfRecordsQueryHookResult = ReturnType<typeof useGetTranscriptOfRecordsQuery>;
export type GetTranscriptOfRecordsLazyQueryHookResult = ReturnType<typeof useGetTranscriptOfRecordsLazyQuery>;
export type GetTranscriptOfRecordsQueryResult = ApolloReactCommon.QueryResult<GetTranscriptOfRecordsQuery, GetTranscriptOfRecordsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
