import * as React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import LoadingIndicator from "components/atomics/LoadingIndicator";
import translations from "translations";
import Props from "./index.d";
const useStyles = makeStyles<Theme, Props>(
  (theme) =>
    createStyles({
      CancelButton: {
        cursor: "pointer",
        height: "40px",
        alignItems: "center",
        appearance: "none",
        backgroundColor: (props) => (!props.disabled ? theme.colors.lbcGrey : theme.colors.lbcGrey),
        // backgroundColor: (props) => (!props.disabled ? theme.colors.tWhite : theme.colors.lbcGrey),
        borderStyle: "none",
        color: (props) => (!props.disabled ? theme.colors.tBlack : theme.colors.grey400),
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "217px",
      },
      title: {
        boxSizing: "content-box",
        textTransform: "uppercase",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 15,
        lineHeight: "26px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        letterSpacing: "0.46px",
      },
    }),
  { name: "Button" }
);

const CancelButton: React.FC<Props> = (props) => {
  const {
    title,
    variant,
    disabled,
    data: { setAndSave },
  } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <button className={classes.CancelButton} disabled={disabled} onClick={() => setAndSave(true)}>
        <span className={classes.title}>{translations.globals.terms.cancel}</span>

        {disabled && <LoadingIndicator color="inherit" padding="0 0 0 1rem" size="2.5rem" />}
      </button>
    </div>
  );
};

export default CancelButton;
