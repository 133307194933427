import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { GetBewerberOverviewDocument } from "graphqlBase/Beweber/__generated__/getBewerberOverview";
import React from "react";
import { useHistory } from "react-router-dom";
import Loader from "components/atomics/Loader";
import { BewerberCreateForm, BewerberCreateOnUpdateDataMiddleWare } from "raft/BewerberForm";
import validate from "validate.js";

const constraints = {
  vorname: { presence: { message: "ausfüllen" } },
  nachname: { presence: { message: "ausfüllen" } },
  geschlechtId: { presence: { message: "ausfüllen" } },
  email: { presence: { message: "ausfüllen" } },
  geburtsdatum: { presence: { message: "ausfüllen" } },
  geburtsort: { presence: { message: "ausfüllen" } },
  privacyPolicyAccepted: { presence: { message: "ausfüllen" } },
  zahlungsdatum: { presence: { message: "ausfüllen" } },
};
interface ValidationResult<T extends {}> {
  [key: string]: string[];
}

function validateForm<T extends object>(data: T): ValidationResult<T> {
  return validate(data, constraints, { format: "custom" });
}

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    formWrapper: {
      display: "flex",
      width: "100%",
    },
    addButton: {
      fontSize: 14,
      color: theme.colors.tWhite,
      backgroundColor: theme.colors.sunYellow,
      height: 40,
      borderRadius: "unset",
      "&:hover": { color: theme.colors.tWhite, backgroundColor: theme.colors.sunYellow },
    },
  })
);

const AnmeldungAdd: React.FC<{}> = () => {
  const classes = useStyles();
  const history = useHistory();

  const [state, setstate] = React.useState(false);

  const close = () => {
    history.goBack();
  };

  const validateFormCreate: BewerberCreateOnUpdateDataMiddleWare = ({ data, changedFields, dataBefore, ...rest }) => {
    const formErrors = validateForm(data);
    const hasErrors = !!formErrors && Object.values(formErrors).some((v) => !!v?.length);
    return {
      submit: hasErrors ? false : data.submit,
      // formErrors: formErrors ?? { nope: [] },
      formHasErrors: hasErrors ? 1 : 0,
      // zahlungsdatum: "2021-01-01T00:00:00.001",
      strasse: data.strasse || "",
      hausnummer: data.hausnummer || "",
      plz: data.plz || "",
      wohnort: data.wohnort || "",
      telefonnummer: data.telefonnummer || "",
      newsletterAccepted: data.newsletterAccepted || false,
    };
  };

  const onUpdateDataMiddleWare: BewerberCreateOnUpdateDataMiddleWare = (props) => {
    const validated = validateFormCreate({ ...props });

    return {
      ...props.data,
      ...validated,
    };
  };

  return (
    <div className={classes.formWrapper}>
      <Loader style={{ justifyContent: "center" }} loading={state}>
        <BewerberCreateForm
          formVersion="cmsinternal"
          onCancel={close}
          afterCreate={close}
          onUpdateDataMiddleWare={onUpdateDataMiddleWare}
          beforeRemoteMuatation={(p) => {
            setstate(true);
            return {
              ...p.data,
            };
          }}
          updateAfterCreateQueries={[
            {
              query: GetBewerberOverviewDocument,
              variables: {},
            },
          ]}
        />
      </Loader>
    </div>
  );
};

export default AnmeldungAdd;
