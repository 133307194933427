import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { prepareServerSideTable } from "components/templates/table-factory";
import actionsCellFactory from "components/templates/table-factory/Table/Cells/actionsCellFactory";
import {
  GetBewerberOverviewQuery,
  ResultType,
  useGetBewerberOverviewLazyQuery,
} from "graphqlBase/Beweber/__generated__/getBewerberOverview";
import { BewerberFilterInput, BewerberSortInput, SortEnumType } from "graphqlBase/types";
import React from "react";
import { useHistory } from "react-router-dom";
import { formatDateTime } from "translations/formatter";
import AnmeldungAddButton from "./AnmeldungAdd/AnmeldungAddButton";
import TableTopContainer from "components/atomics/TableTopContainer";

const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      root: { width: "100%", marginTop: "2em", paddingLeft: "28px", paddingRight: "33px" },
    }),
  { name: `bewerber-BewerberList` }
);

type Bewerber = ResultType<GetBewerberOverviewQuery["bewerbers"]>;

const variables = {
  order: [{ bewerberId: "DESC" as SortEnumType.Desc }],
  where: { isImported: { neq: true } },
};

const { useCreateServerSideColumns, useMakeServerSideTable, makeSeverSideFilter } = prepareServerSideTable<
  Bewerber,
  BewerberFilterInput,
  BewerberSortInput,
  SortEnumType
>();

const BewerberList: React.FC<{}> = () => {
  const classes = useStyles();
  const [query, { data, refetch }] = useGetBewerberOverviewLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const history = useHistory();
  const handleEdit = (value: number) => {
    history.push(`/anmeldung/bewerberEdit/${value}`);
  };
  const ActionCell = actionsCellFactory({
    onEdit: handleEdit,
    onDelete: {
      entity: "bewerber",
      afterDelete: () => {
        if (refetch)
          refetch().catch(() => {
            //
          });
      },
    },
  });

  const { columns, serversideQueryConfig } = useCreateServerSideColumns(
    [
      {
        Header: "Erstellt am",
        accessor: (row) => (row.createdAt ? formatDateTime(new Date(row.createdAt)) : ""),
        id: "createdAt",
        remoteOrder: ({ order, sort }) => ({ createdAt: sort }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) => merge(where, { vorname: { contains: filterValue } }),
        }),
      },
      {
        Header: "Vorname",
        accessor: "vorname",
        id: "vorname",
        remoteOrder: ({ order, sort }) => ({ vorname: sort }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) => merge(where, { vorname: { contains: filterValue } }),
        }),
      },
      {
        Header: "Nachname",
        accessor: "nachname",
        id: "nachname",
        remoteOrder: ({ order, sort }) => ({ nachname: sort }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) => merge(where, { nachname: { contains: filterValue } }),
        }),
      },
      {
        Header: "Email",
        accessor: "email",
        id: "email",
        remoteOrder: ({ order, sort }) => ({ email: sort }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) => merge(where, { email: { contains: filterValue } }),
        }),
      },
      {
        Header: "Studienmodell",
        accessor: (row) => row.studiengangSemester?.studiengangOnBew?.studienmodell.studienmodellName ?? "",
        id: "studienmodell",
        remoteOrder: ({ order, sort }) => ({
          studiengangSemester: { studiengangOnBew: { studienmodell: { studienmodellName: sort } } },
        }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, {
              studiengangSemester: {
                studiengangOnBew: { studienmodell: { studienmodellName: { contains: filterValue } } },
              },
            }),
        }),
      },
      {
        Header: "Studiengang",
        accessor: (row) => row.studiengangSemester?.studiengangOnBew?.studiengangName ?? "",
        id: "studiengang",
        remoteOrder: ({ order, sort }) => ({
          studiengangSemester: { studiengangOnBew: { studiengangName: sort } },
        }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, {
              studiengangSemester: { studiengangOnBew: { studiengangName: { contains: filterValue } } },
            }),
        }),
      },
      {
        Header: "Semester",
        accessor: (row) => row.studiengangSemester?.semester?.semesterName ?? "",
        id: "semester",
        remoteOrder: ({ order, sort }) => ({
          studiengangSemester: { semester: { semesterName: sort } },
        }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, {
              studiengangSemester: { semester: { semesterName: { contains: filterValue } } },
            }),
        }),
      },
      {
        Header: "",
        accessor: "id",
        id: "id",
        // eslint-disable-next-line react/display-name
        Cell: ActionCell,
        width: 60,
      },
    ],
    [refetch]
  );

  const { TableComponent: NewTableComponent } = useMakeServerSideTable(
    {
      data: data?.bewerbers.filter((bewerber) => !bewerber.isImported) ?? [],
      columns,
      initialState: { pageSize: 25 },
    },
    {
      query,
      variables,
      serversideQueryConfig,
      tableId: "Bewerberlist",
    }
  );

  return (
    <div className={classes.root}>
      <TableTopContainer>
        <AnmeldungAddButton key="anmeldung-add-button-1" />
      </TableTopContainer>
      <NewTableComponent tableProps={{ rowsPerPageOptions: [10, 25, 50, 100] }} />
    </div>
  );
};

export default BewerberList;
