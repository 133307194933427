import {
  makeNewFormMemoized,
  genericDeleteFunction as genericDeleteFunctionPre,
} from "./"
import {
  Operation,
  QueryArray,
  DeleteChildProps,
  BeforeRemoteMuatation,
  BeforeRemoteMuatationProps,
  OnUpdateDataMiddleWare,
  OnUpdateDataMiddleWareProps,
  ValidateForm,
  ValidateFormProps,
} from "@ml-pa/react-apollo-form-tool/formGen.model"
import React from "react"
import * as SchemaTypes from "./internal/schemaTypes"
import { FormTypes, ModifyProps } from "./internal/typeLinks"

export type MoodleModulCreateOnUpdateDataMiddleWare =
  OnUpdateDataMiddleWare<SchemaTypes.MoodleModulInputCreate>
export type MoodleModulCreateOnUpdateDataMiddleWareProps =
  OnUpdateDataMiddleWareProps<SchemaTypes.MoodleModulInputCreate>
export type MoodleModulCreateBeforeRemoteMuatation = BeforeRemoteMuatation<
  SchemaTypes.MoodleModulInputCreate,
  SchemaTypes.MoodleModulInputCreateProps["injectedValues"]
>
export type MoodleModulCreateBeforeRemoteMuatationProps =
  BeforeRemoteMuatationProps<
    SchemaTypes.MoodleModulInputCreate,
    SchemaTypes.MoodleModulInputCreateProps["injectedValues"]
  >
export type MoodleModulCreateValidateForm = ValidateForm<
  SchemaTypes.MoodleModulInputCreate,
  SchemaTypes.MoodleModulInputCreateProps["injectedValues"]
>
export type MoodleModulCreateValidateFormProps = ValidateFormProps<
  SchemaTypes.MoodleModulInputCreate,
  SchemaTypes.MoodleModulInputCreateProps["injectedValues"]
>

export interface MoodleModulCreateProps
  extends SchemaTypes.MoodleModulInputCreateProps {
  multiInstance?: boolean
  formVersion?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<SchemaTypes.MoodleModulInputCreate>
  afterCreate?: (
    data: SchemaTypes.MoodleModulInputCreate & { id: number }
  ) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<SchemaTypes.MoodleModulInputCreate>
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.MoodleModulInputCreate,
    SchemaTypes.MoodleModulInputCreateProps["injectedValues"]
  >
  validateForm?: ValidateForm<
    SchemaTypes.MoodleModulInputCreate,
    SchemaTypes.MoodleModulInputCreateProps["injectedValues"]
  >
  updateAfterCreateQueries?: QueryArray
}

export const MoodleModulCreateForm: React.FC<MoodleModulCreateProps> = (
  props
) => {
  const {
      afterCreate,
      injectedValues,
      multiInstance = false,
      formVersion,
      onCancel,
      updateAfterCreateQueries,
      beforeRemoteMuatation,
      onUpdateDataMiddleWare,
      validateForm,
      modifyProps,
      ...params
    } = props,
    { Form: MoodleModulCreate } = makeNewFormMemoized({
      entity: "moodleModul",

      operation: "create",
      formVersion,
      params: multiInstance ? params : undefined,
    })
  return (
    <MoodleModulCreate
      params={params}
      onCancel={onCancel}
      injectedValues={injectedValues}
      afterCreate={afterCreate}
      updateAfterCreateQueries={updateAfterCreateQueries}
      beforeRemoteMuatation={beforeRemoteMuatation}
      onUpdateDataMiddleWare={onUpdateDataMiddleWare}
      validateForm={validateForm}
      modifyProps={modifyProps}
    />
  )
}

export type MoodleModulUpdateOnUpdateDataMiddleWare =
  OnUpdateDataMiddleWare<SchemaTypes.MoodleModulInputUpdate>
export type MoodleModulUpdateOnUpdateDataMiddleWareProps =
  OnUpdateDataMiddleWareProps<SchemaTypes.MoodleModulInputUpdate>
export type MoodleModulUpdateBeforeRemoteMuatation = BeforeRemoteMuatation<
  SchemaTypes.MoodleModulInputUpdate,
  SchemaTypes.MoodleModulInputUpdateProps["injectedValues"]
>
export type MoodleModulUpdateBeforeRemoteMuatationProps =
  BeforeRemoteMuatationProps<
    SchemaTypes.MoodleModulInputUpdate,
    SchemaTypes.MoodleModulInputUpdateProps["injectedValues"]
  >
export type MoodleModulUpdateValidateForm = ValidateForm<
  SchemaTypes.MoodleModulInputUpdate,
  SchemaTypes.MoodleModulInputUpdateProps["injectedValues"]
>
export type MoodleModulUpdateValidateFormProps = ValidateFormProps<
  SchemaTypes.MoodleModulInputUpdate,
  SchemaTypes.MoodleModulInputUpdateProps["injectedValues"]
>

export interface MoodleModulUpdateProps
  extends SchemaTypes.MoodleModulInputUpdateProps {
  id: number
  multiInstance?: boolean
  formVersion?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<SchemaTypes.MoodleModulInputUpdate>
  afterUpdate?: (data: SchemaTypes.MoodleModulInputUpdate) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<SchemaTypes.MoodleModulInputUpdate>
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.MoodleModulInputUpdate,
    SchemaTypes.MoodleModulInputUpdateProps["injectedValues"]
  >
  validateForm?: ValidateForm<
    SchemaTypes.MoodleModulInputUpdate,
    SchemaTypes.MoodleModulInputUpdateProps["injectedValues"]
  >
  fetchAllFieldsOnUpdate?: boolean
}

export const MoodleModulUpdateForm: React.FC<MoodleModulUpdateProps> = (
  props
) => {
  const {
      afterUpdate,
      injectedValues,
      multiInstance = false,
      formVersion,
      onCancel,
      fetchAllFieldsOnUpdate,
      beforeRemoteMuatation,
      onUpdateDataMiddleWare,
      validateForm,
      modifyProps,
      ...params
    } = props,
    { Form: MoodleModulUpdate } = makeNewFormMemoized({
      entity: "moodleModul",

      operation: "update",
      formVersion,
      params: multiInstance ? params : undefined,
    })
  return (
    <MoodleModulUpdate
      params={params}
      onCancel={onCancel}
      injectedValues={injectedValues}
      afterUpdate={afterUpdate}
      fetchAllFieldsOnUpdate={fetchAllFieldsOnUpdate}
      beforeRemoteMuatation={beforeRemoteMuatation}
      onUpdateDataMiddleWare={onUpdateDataMiddleWare}
      validateForm={validateForm}
      modifyProps={modifyProps}
    />
  )
}
