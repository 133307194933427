import React, { useEffect } from "react";
import { makeStyles, createStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";
import Props from "./index.d";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "components/lbc-toolkit/molecules/Select";
import useGetForeignKeyList from "./useGetForeignKeyList";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles(
  (theme: AugmentedTheme) =>
    createStyles({
      root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
      },
      titleBox: {
        margin: "0.2em 0.5em 0.5em 0.4em",
        color: theme.colors.grey500,
        fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: 1.2,
      },
      select: {
        display: "inherit",
        marginTop: 10,
      },
    }),
  { name: "TextArea" }
);
const DropDown: React.FC<Props> = (props) => {
  const {
    data: { state, setAndSave },
    disabled,
    title,
    pickDefaultFirst,
    foreignKeyParams,
  } = props;
  const elems = useGetForeignKeyList({ foreignKeyParams });
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    setAndSave(value as string);
  };

  useEffect(() => {
    if (pickDefaultFirst && !state && !!elems && elems.length) setAndSave(elems[0].value);
  }, [elems, state, pickDefaultFirst]);

  const classes = useStyles(props);
  return (
    <Select
      disabled={disabled}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      selected={state}
      onChange={handleChange}
      className={classes.select}
    >
      {elems.map((elem) => (
        <MenuItem key={elem.value} value={elem.value}>
          {elem.label}
        </MenuItem>
      ))}
    </Select>
  );
  // return (
  //   <div className={classes.titleBox}>
  //     <div className={classes.titleBox}>
  //       <Typography align="left" variant="inherit">
  //         {title}:
  //       </Typography>
  //     </div>
  //     <Select
  //       disabled={disabled}
  //       labelId="demo-simple-select-label"
  //       id="demo-simple-select"
  //       value={state}
  //       onChange={handleChange}
  //       className={classes.select}
  //     >
  //       {elems.map((elem) => (
  //         <MenuItem key={elem.value} value={elem.value}>
  //           {elem.label}
  //         </MenuItem>
  //       ))}
  //     </Select>
  //   </div>
  // );
};

export default DropDown;
