import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { TableSubcomponentProps } from "../tableTypes";

const useStyles = makeStyles<Theme, any>(
  (theme) =>
    createStyles({
      subcomponent: {
        backgroundColor: "inherit !important",
      },
    }),

  { name: `Table-${"Standard"}-TableSubcomponent` }
);

const TableSubcomponent: React.FC<TableSubcomponentProps<object>> = ({
  row,
  tableProps,
  tableInstance,
  renderRowSubComponent,
}) => {
  const classes = useStyles({});

  return (
    <TableRow className={classes.subcomponent} {...row.getRowProps()} {...tableProps?.bodySubComponentRow}>
      <TableCell colSpan={tableInstance.visibleColumns.length} {...tableProps?.bodySubComponentCell}>
        {renderRowSubComponent({ row })}
      </TableCell>
    </TableRow>
  );
};

export default TableSubcomponent;
