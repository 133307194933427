import { useQuery, gql } from "@apollo/client";
import { useMemo } from "react";
import props from "./index.d";

interface UseGetForeignKeyListStudienberaterParams {
  foreignKeyParams: props["foreignKeyParams"];
  mitarbeiterTypeId: number;
}
function useGetForeignKeyListStudienberater({
  foreignKeyParams,
  mitarbeiterTypeId
}: UseGetForeignKeyListStudienberaterParams): { value: number; label: string }[] {
  const query = useMemo(() => {
    return gql`query get${foreignKeyParams.entityName}($mitarbeiterTypeId: Int!) {
          elems:${
            foreignKeyParams.entityName
          }(where: { matchMitarbeiterTypMitarbeiters: {some: {mitarbeiterTypId: {eq: $mitarbeiterTypeId}}} }){
            value:id
            ${foreignKeyParams.column.map((column, index) => `label${index}:${column}`)}
          }
        }`;
  }, [foreignKeyParams]);
  const { data } = useQuery(query, {variables: { mitarbeiterTypeId }});
  //@ts-ignore
  return (data?.elems ?? []).map(({ value, __typename, ...rest }) => ({ value, label: Object.values(rest).join(" ") }));
}

export default useGetForeignKeyListStudienberater;
