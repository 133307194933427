import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetPruefungsdatenQueryVariables = Types.Exact<{
  skip?: Types.Maybe<Types.Scalars['Int']>;
  take?: Types.Maybe<Types.Scalars['Int']>;
  where?: Types.Maybe<Types.PruefungFilterInput>;
  order?: Types.Maybe<Array<Types.PruefungSortInput> | Types.PruefungSortInput>;
}>;


export type GetPruefungsdatenQuery = { pruefungs: Array<{ __typename?: 'Pruefung', id: number, pruefungsversuch?: Types.Maybe<number>, datumUhrzeitVon?: Types.Maybe<string>, datumUhrzeitBis?: Types.Maybe<string>, pruefungsdauer?: Types.Maybe<number>, pruefungTyp?: Types.Maybe<{ __typename?: 'PruefungTyp', id: number, pruefungTypName?: Types.Maybe<string> }>, moodleModul?: Types.Maybe<{ __typename?: 'MoodleModul', id: number, modul?: Types.Maybe<{ __typename?: 'Modul', id: number, modulName: string, studiengangTyp?: Types.Maybe<{ __typename?: 'StudiengangTyp', id: number, studiengangTypKuerzel?: Types.Maybe<string> }> }>, zeitraum?: Types.Maybe<{ __typename?: 'Zeitraum', id: number, zeitraumName: string }>, lehrender?: Types.Maybe<{ __typename?: 'StammdatenMa', id: number, vorname: string, nachname: string }> }> }> };


export const GetPruefungsdatenDocument = gql`
    query getPruefungsdaten($skip: Int, $take: Int, $where: PruefungFilterInput, $order: [PruefungSortInput!]) {
  pruefungs(where: $where, order: $order, skip: $skip, take: $take) {
    id
    pruefungsversuch
    datumUhrzeitVon
    datumUhrzeitBis
    pruefungsdauer
    pruefungTyp {
      id
      pruefungTypName
    }
    moodleModul {
      id
      modul {
        id
        modulName
        studiengangTyp {
          id
          studiengangTypKuerzel
        }
      }
      zeitraum {
        id
        zeitraumName
      }
      lehrender {
        id
        vorname
        nachname
      }
    }
  }
}
    `;

/**
 * __useGetPruefungsdatenQuery__
 *
 * To run a query within a React component, call `useGetPruefungsdatenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPruefungsdatenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPruefungsdatenQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetPruefungsdatenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPruefungsdatenQuery, GetPruefungsdatenQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPruefungsdatenQuery, GetPruefungsdatenQueryVariables>(GetPruefungsdatenDocument, baseOptions);
      }
export function useGetPruefungsdatenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPruefungsdatenQuery, GetPruefungsdatenQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPruefungsdatenQuery, GetPruefungsdatenQueryVariables>(GetPruefungsdatenDocument, baseOptions);
        }
export type GetPruefungsdatenQueryHookResult = ReturnType<typeof useGetPruefungsdatenQuery>;
export type GetPruefungsdatenLazyQueryHookResult = ReturnType<typeof useGetPruefungsdatenLazyQuery>;
export type GetPruefungsdatenQueryResult = ApolloReactCommon.QueryResult<GetPruefungsdatenQuery, GetPruefungsdatenQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
