/* eslint-disable filenames/match-exported */
import mergePre from "lodash/merge";
import React, { useEffect, useMemo, useState } from "react";
import {
  ActionType,
  Column,
  Filters,
  TableInstance,
  TableOptions,
  UseColumnOrderState,
  UseExpandedState,
  UseFiltersState,
  UseGlobalFiltersState,
  UseGroupByState,
  UsePaginationState,
  UseSortByState,
} from "react-table";
import useMakeTable from "./useMakeTable";
import { MakeTableConfig } from "./tableTypes";
import { NoFilter } from "./Filters";

interface TableState<D extends object = {}>
  extends UseColumnOrderState<D>,
    UseExpandedState<D>,
    UseFiltersState<D>,
    UseGlobalFiltersState<D>,
    UseGroupByState<D>,
    UsePaginationState<D>,
    UseSortByState<D> {}
type StateReducer<T extends Object> = (
  newState: TableState<T>,
  action: ActionType,
  previousState: TableState<T>,
  instance?: TableInstance<T>
) => TableState<T>;

interface ServerSideState<T extends object> {
  disablePagination: boolean;
  pageCount: number;
  stateReducer: StateReducer<T>;
}

interface QueryVariables<FilterType, Ordertype> {
  order?: Ordertype;
  skip?: number;
  take?: number;
  where?: StriktFilterType<FilterType> | null;
}

type StriktFilterType<T> = { [P in keyof T]?: T[P] };
type ArrayElement<A> = A extends ReadonlyArray<infer T> ? T : never;

export type Filterbuilder<T extends Object, Filter> = (
  where: StriktFilterType<Filter>,
  value: ArrayElement<Filters<T>>,
  index: number,
  allValues: Filters<T>
) => StriktFilterType<Filter>;

export type ServerSideTableConfig<D extends Object, Filter, OrderType, SortEnumType> = Omit<
  ServerSideMakeTableConfig<D, Filter, OrderType, SortEnumType>,
  "columnConfig"
>;

export interface ServerSideMakeTableConfig<D extends Object, Filter, OrderType, SortEnumType>
  extends MakeTableConfig<D>,
    ServerSideParams<D, Filter, OrderType, SortEnumType> {}

interface ServerSideTableOptions<D extends Object> extends TableOptions<D> {}

interface ServerSideParams<T extends Object, Filter, Ordertype, SortEnumType> {
  clientSidePagination?: boolean;
  query: (params?: { variables: Required<QueryVariables<Filter, Ordertype[]>> }) => void;
  serversideQueryConfig: Array<ServerSideColumnConfig<T, Filter, Ordertype, SortEnumType>>;
  variables: QueryVariables<Filter, Ordertype[]>;
}

interface ServerSideParamsOuter<T extends Object, Filter, OrderType, SortEnumType>
  extends ServerSideParams<T, Filter, OrderType, SortEnumType> {
  data: T[] | null | undefined;
}

export interface TypedColumnFilter<D extends object, T> {
  column: {
    filterValue: T | undefined | null;
    setFilter: (val: T | undefined | null) => void;
  } & Column<D>;
}

const isNullish = (obj: unknown) =>
  obj === undefined || obj === null || (typeof obj === "object" && !Object.keys(obj ?? {}).length);

const emptyArray = (obj: unknown[]) => {
  const emptyArr = obj.map((e) => removeNullish(e)).filter((o) => !isNullish(o));
  if (emptyArr.length) return emptyArr;
  return undefined;
};

function removeNullish<D>(obj: D): D {
  if (Array.isArray(obj)) return emptyArray(obj) as unknown as D;
  if (typeof obj === "object") {
    const newObj = Object.entries(obj)
      .filter(([k, v]) => ![undefined, null].includes(v))
      .reduce((r, [key, value]) => {
        const cleaned = removeNullish<D>(value);
        if (isNullish(cleaned)) return r;
        return { ...r, [key]: cleaned };
      }, {}) as D;
    if (isNullish(newObj)) return undefined as unknown as D;
    return newObj;
  } else {
    return obj;
  }
}

function merge<D>(baseObj: D, newObj: D): D {
  return mergePre(baseObj, newObj);
}

export interface FilterParams<D extends object, FilterType, T = any> {
  Filter?: (f: TypedColumnFilter<D, T>) => JSX.Element;
  filterPath: ({
    merge,
    where,
    filterValue,
  }: {
    filterValue: T;
    merge: (obj: FilterType, val: FilterType) => FilterType;
    where: FilterType;
  }) => FilterType | undefined | null;
}

interface ServerSideColumnConfig<D extends object, Filtertype, OrderType, SortEnumType> {
  id: string;
  remoteFilter?: FilterParams<D, Filtertype>;
  hide?: boolean;
  remoteOrder?: ({
    order,
    sort,
    merge,
  }: {
    merge: (obj: OrderType, val: OrderType) => OrderType;
    order: OrderType[];
    sort: SortEnumType;
  }) => OrderType;
}

type ServerSideColumn<D extends object, Filtertype, OrderType, SortEnumType> = ServerSideColumnConfig<
  D,
  Filtertype,
  OrderType,
  SortEnumType
> &
  Column<D>;

export function makeSeverSideFilterPre<D extends object, FilterType>() {
  return function <T>({
    Filter,
    filterPath,
  }: {
    Filter?: (f: TypedColumnFilter<D, T>) => JSX.Element;
    filterPath: ({
      merge,
      where,
      filterValue,
    }: {
      filterValue: T;
      merge: (obj: FilterType, val: FilterType) => FilterType;
      where: FilterType;
    }) => FilterType | undefined | null;
  }) {
    return { Filter, filterPath };
  };
}

function getFilter<D extends object, F>(remoteFilter: FilterParams<D, F, any> | undefined): object {
  if (remoteFilter?.Filter)
    return {
      Filter: remoteFilter.Filter,
    };

  if (remoteFilter?.filterPath) return {};

  return { Filter: NoFilter };
}

export function useCreateServerSideColumns<D extends object, F, O, SortEnumType>(
  params: Array<ServerSideColumn<D, F, O, SortEnumType>>,
  updaters?: any[]
): { columns: Array<Column<D>>; serversideQueryConfig: Array<ServerSideColumnConfig<D, F, O, SortEnumType>> } {
  return React.useMemo(() => {
    const { columns, serversideQueryConfig } = params.reduce<{
      columns: Array<Column<D>>;
      serversideQueryConfig: Array<ServerSideColumnConfig<D, F, O, SortEnumType>>;
    }>(
      (result, item) => {
        if (item.hide) return result;
        const { remoteFilter, remoteOrder, ...column } = item;
        const newResult = {
          columns: result.columns.concat({
            ...column,
            disableSortBy: !remoteOrder || column.disableSortBy,
            disableFilters: column.disableFilters,
            ...getFilter<D, F>(remoteFilter),
          }),

          serversideQueryConfig: result.serversideQueryConfig.concat({
            id: column.id,
            remoteFilter,
            remoteOrder,
          }),
        };
        return newResult;
      },
      { columns: [], serversideQueryConfig: [] }
    );

    return { columns, serversideQueryConfig };
  }, updaters ?? []);
}

function serverSideStatePre<T extends object, FilterType, OrderType, SortEnumType>({
  query,
  serversideQueryConfig,
  variables,
}: ServerSideParams<T, FilterType, OrderType, SortEnumType>): ServerSideState<T>["stateReducer"] {
  return (newState, action, previousState) => {
    if (action.type === "toggleRowSelected" || action.type === "toggleAllRowsSelected") return newState;
    const initialWhere = (variables.where ?? {}) as FilterType;
    const changedAttributes = Object.keys(action).filter((key) => key !== "type");
    const orderPre = (newState.sortBy ?? []).reduce<OrderType[]>(
      (order, sorter) => {
        const { remoteOrder } = serversideQueryConfig.find((ssQc) => ssQc.id === sorter.id) ?? {};
        const sort = sorter.desc ? ("DESC" as unknown as SortEnumType) : ("ASC" as unknown as SortEnumType);
        if (!remoteOrder) return order;
        return order.concat(remoteOrder({ sort, order, merge }));
      },
      newState.sortBy.length ? [] : variables.order ?? []
    );
    const filterIds = (newState.filters ?? []).map((nFilter) => nFilter.id);
    const deletedFilters = (previousState.filters ?? [])
      .map((pFilter) => pFilter.id)
      .filter((id) => !filterIds.includes(id))
      .map((dFilter) => ({ id: dFilter, value: undefined }));

    const wherePre = newState.filters.concat(deletedFilters).reduce<FilterType>((wherePre, filter) => {
      const { remoteFilter } = serversideQueryConfig.find((ssQc) => ssQc.id === filter.id) ?? {};
      if (!remoteFilter) return wherePre;
      return {
        ...wherePre,
        ...remoteFilter.filterPath({ filterValue: filter.value ?? null, where: wherePre, merge }),
      };
    }, initialWhere);

    const pageIndex: number = changedAttributes.includes("pageSize") ? 0 : action.pageIndex ?? newState.pageIndex;

    const nextState = { ...newState, pageIndex };

    const where = (Object.keys(wherePre).length === 0 ? undefined : removeNullish(wherePre)) as FilterType;
    const order = orderPre.length === 0 ? [] : orderPre;
    //
    const take = newState.pageSize;
    const skip = nextState.pageIndex * newState.pageSize;
    query({ variables: { ...variables, skip, take, order, where } });

    return nextState;
  };
}

function useServerState<T extends Object, FilterType, OrderType, SortEnumType>({
  query,
  serversideQueryConfig,
  variables,
  data,
}: ServerSideParamsOuter<T, FilterType, OrderType, SortEnumType>): ServerSideState<T> {
  const [disablePagination, setDisablePagination] = useState(false);
  const [pageCount] = useState(0);
  useEffect(() => {
    if (data && !data.length && !disablePagination) {
      setDisablePagination(true);
    }
  }, [data, disablePagination]);
  const stateReducer = useMemo(
    () => serverSideStatePre<T, FilterType, OrderType, SortEnumType>({ query, serversideQueryConfig, variables }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variables]
  );

  return { disablePagination, stateReducer, pageCount };
}

export function useMakeServerSideTable<D extends { id: number | string }, Filter, OrderType, SortEnumType>(
  tableOptions: ServerSideTableOptions<D>,
  config: ServerSideTableConfig<D, Filter, OrderType, SortEnumType>
) {
  const _config: ServerSideMakeTableConfig<D, Filter, OrderType, SortEnumType> = {
    filtering: true,
    sorting: true,
    stickyHeader: true,
    ...config,
  };

  const serverSideState = useServerState<D, Filter, OrderType, SortEnumType>({ ..._config, data: tableOptions.data });

  if (tableOptions.stateReducer) {
    // @ts-ignore
    tableOptions.stateReducer = (newStateIn, action, oldState) => {
      const newState = tableOptions.stateReducer?.(newStateIn, action, oldState) ?? newStateIn;
      // @ts-ignore
      return serverSideState.stateReducer(newState, action, oldState);
    };
  } else {
    // @ts-ignore
    tableOptions.stateReducer = serverSideState.stateReducer;
  }

  const nextOptions = {
    ...tableOptions,
    manualFilters: true,
    manualPagination: true, //!config.clientSidePagination,
    manualSortBy: true,
  };

  return useMakeTable<D>(nextOptions, _config);
}

function prepareServerSideTable<D extends { id: number | string }, F, O, SortEnumType>() {
  return {
    useCreateServerSideColumns: (v: Array<ServerSideColumn<D, F, O, SortEnumType>>, updaters?: any[]) =>
      useCreateServerSideColumns<D, F, O, SortEnumType>(v, updaters),
    useMakeServerSideTable: (
      tableOptions: ServerSideTableOptions<D>,
      config: ServerSideTableConfig<D, F, O, SortEnumType>
    ) => useMakeServerSideTable<D, F, O, SortEnumType>(tableOptions, config),
    makeSeverSideFilter: makeSeverSideFilterPre<D, F>(),
  };
}

// export default {};
export default prepareServerSideTable;
