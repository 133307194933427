import React, { useEffect, useState } from "react";
import { useCreateAnmeldungMutation } from "../../../graphqlBase/Anmeldung/__generated__/createAnmeldung";
import DefaultInput from "../../atomics/DefaultInput";
import Checkbox from "../../atomics/CheckboxBoolean";
import Button from "@material-ui/core/Button";
import { AnmeldungCreateType } from "../../../graphqlBase/types";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { VeranstaltungFragment } from "../../../graphqlBase/Anmeldung/__generated__/getVeranstaltungById";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import LoadingIndicator from "components/atomics/LoadingIndicator";
import translations from "translations";

interface FormState extends AnmeldungCreateType {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: { maxWidth: "100%", padding: "0 1em" },
      submit: {
        backgroundAttachment: "scroll",
        backgroundClip: "border-box",
        backgroundColor: "rgba(0, 0, 0, 0)",
        backgroundImage: "linear-gradient(rgb(255, 184, 48) 0%, rgb(245, 154, 18) 100%)",
        backgroundOrigin: "padding-box",
        backgroundPositionX: "0%",
        backgroundPositionY: "0%",
        backgroundSize: "auto",
        borderBottomColor: "rgb(233, 142, 6)",
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        borderBottomStyle: "none",
        borderBottomWidth: "0px",
        borderImageOutset: "0",
        borderImageRepeat: "stretch",
        borderImageSlice: "100%",
        borderImageSource: "none",
        borderImageWidth: "1",
        borderLeftColor: "rgb(233, 142, 6)",
        borderLeftStyle: "none",
        borderLeftWidth: "0px",
        borderRightColor: "rgb(233, 142, 6)",
        borderRightStyle: "none",
        borderRightWidth: "0px",
        borderTopColor: "rgb(233, 142, 6)",
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        borderTopStyle: "none",
        borderTopWidth: "0px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px 0px",
        boxSizing: "border-box",
        color: "rgb(255, 255, 255)",
        cursor: "pointer",
        display: "inline-block",
        fontFamily: "Roboto latin, Roboto, Arial",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "21.6px",
        overflowWrap: "break-word",
        paddingBottom: "25px",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "25px",
        textAlign: "center",
        textDecorationColor: "rgb(255, 255, 255)",
        textDecorationLine: "none",
        textDecorationStyle: "solid",
        textDecorationThickness: "auto",
        textShadow: "none",
        textSizeAdjust: "100%",
        textTransform: "uppercase",
        "&:hover": {
          backgroundImage: "linear-gradient(rgb(255, 154, 30) 0%, rgb(239, 124, 0) 100%)",
        },
      },
      disabledButton: {
        // backgroundColor: "lightGrey",
        // backgroundImage: "none",
      },
      header: { margin: "2em 0 1em 0" },
      section: {
        marginBottom: "16px",
      },
      flexContainer: { display: "flex" },
      bold: { fontWeight: "bold", marginBottom: "16px", marginTop: "3em" },
      gapRight: { marginRight: "0em" },
      gapLeft: { marginLeft: "2em" },
      flexGrow: {
        // flexGrow: 1,
        flexBasis: "50%",
      },
      selectContainer: {
        // flexGrow: 2,
        flexBasis: "50%",
        "& :first-child": {
          margin: 0,
        },
      },
      loadingIndicatorContainer: {
        display: "flex",
        maxHeight: "2em",
        alignItems: "center",
        marginLeft: "10px",
      },
      buttonContainer: {
        display: "flex",
        height: "100%",
        alignItems: "center",
      },
      afterCreate: {
        margin: "auto",
        maxWidth: "700px",
      },
      successMessage: {
        minHeight: "5em",
        display: "flex",
        justifyContent: "center",
      },
    }),
  { name: "Veranstaltungen" }
);

interface VeranstaltungFormShortProps {
  veranstaltung: VeranstaltungFragment;
}

const VeranstaltungFormShort: React.FC<VeranstaltungFormShortProps> = ({ veranstaltung }) => {
  const { short: translation } = translations.pages.veranstaltungen.anmeldungCreateForm;
  const classes = useStyles();
  const defaultAgreeDatenschutz = false;
  const [state, setstate] = useState<FormState>({
    // veranstaltungId: veranstaltung.id,
    veranstaltungId: veranstaltung.id,
    datenschutz: defaultAgreeDatenschutz,
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const [createAnmeldungMutation] = useCreateAnmeldungMutation({
    onCompleted: () => {
      setSuccessMessage(translation.successMessage);
      setDisableSubmit(true);
    },
    onError: () => {
      setSuccessMessage(translation.errorMessage);
      setDisableSubmit(true);
    },
  });

  const [agreeDatenschutz, setAgreeDatenschutz] = useState(defaultAgreeDatenschutz);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setstate({ ...state, [name]: value });
  };

  const onChangeCheckbox = (value: boolean) => {
    setAgreeDatenschutz(value);
    setstate({ ...state, datenschutz: value });
  };

  const handleOnSubmit = async () => {
    setIsLoading(true);
    try {
      await createAnmeldungMutation({ variables: { createAnmeldungs: [{ ...state }] } });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (successMessage) {
      setDisableSubmit(true);
    } else if (state.vorname && state.nachname && state.email && state.telefonnummer && state.datenschutz) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [state, successMessage]);

  return (
    <div className={classes.root}>
      <>
        <div className={classes.section}>
          <Typography className={classes.bold}>{`${translation.firstname} *`}</Typography>
          <DefaultInput fullWidth name="vorname" value={state.vorname} onChange={handleOnChange} />
        </div>
        <div className={classes.section}>
          <Typography className={classes.bold}>{`${translation.lastname} *`}</Typography>
          <DefaultInput fullWidth name="nachname" value={state.nachname} onChange={handleOnChange} />
        </div>
        <div className={classes.section}>
          <Typography className={classes.bold}>{`${translation.email} *`}</Typography>
          <DefaultInput
            placeholder={veranstaltung?.veranstaltungEmailFeldText ?? ""}
            fullWidth
            name="email"
            value={state.email}
            onChange={handleOnChange}
          />
        </div>
        <div className={classes.section}>
          <Typography className={classes.bold}>{`${translation.phone} *`}</Typography>
          <DefaultInput
            placeholder={veranstaltung?.veranstaltungHandynummerFeldText ?? ""}
            fullWidth
            name="telefonnummer"
            value={state.telefonnummer}
            onChange={handleOnChange}
          />
        </div>
        <div>
          <div className={classes.section}>
            <Typography className={classes.bold}>{`${translation.privacyPolicy} *`}</Typography>
            <Checkbox
              disabled={false}
              setAndSave={onChangeCheckbox}
              checked={agreeDatenschutz}
              label={veranstaltung?.veranstaltungCheckboxFeldText ?? translation.privacyPolicyDefaultText}
            />
          </div>

          <div className={clsx(classes.section, classes.buttonContainer)}>
            <Button
              onClick={handleOnSubmit}
              className={classes.submit}
              classes={{ disabled: classes.disabledButton }}
              disabled={disableSubmit || isLoading}
            >
              {veranstaltung?.veranstaltungBestaetigungFeldText ?? translation.signUp}
            </Button>
          </div>
          <div className={classes.successMessage}>
            <div className={classes.loadingIndicatorContainer}>{isLoading && <LoadingIndicator />}</div>
            {successMessage && <Typography variant="h4">{successMessage}</Typography>}
          </div>
        </div>
      </>
    </div>
  );
};

export default VeranstaltungFormShort;
