import React from "react";
import Props from "./index.d";
import PruefungsErgebnisse from "./PruefungsErgebnisse";
import { createStyles, makeStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";
const useStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    box: {
      width: "100%",
    },
    foreignKeySelectorIntBox: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  })
);

const PruefungenPruefungsErgebnisse: React.FC<Props> = (props) => {
  const {
    data: { state: pruefungId },
  } = props;
  const classes = useStyles();

  return <div className={classes.box}>{!!pruefungId && <PruefungsErgebnisse pruefungId={pruefungId} />}</div>;
};

export default PruefungenPruefungsErgebnisse;
