import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import Props from "./index.d";
import { useGetVerlaufTnsQuery } from "graphqlBase/VerlaufTn/__generated__/getVerlaufTns";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";
import { formatDate } from "translations/formatter";
import { ListSubheader } from "@material-ui/core";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
      },
      listRoot: {
        height: "80vh",
      },
      listRootInner: {
        width: "100%",
        maxHeight: "80%",
        overflowY: "auto",
      },
      listItem: {
        paddingLeft: 0,
        paddingRight: 0,
        display: "flex",
        background: theme.colors.grey50,
        marginTop: 0,
      },
      listItemText: {
        flex: "1 1 0",
      },
      header: {
        fontWeight: "bold",
      },
      headerEnd: {
        // textAlign: "end",
      },
    }),
  { name: "StudentVerlauf" }
);

const StudentVerlauf: React.FC<Props> = (props) => {
  const {
    data: { state },
  } = props;
  const classes = useStyles();

  const { data, loading, error } = useGetVerlaufTnsQuery({
    variables: {
      filterInput: { studentId: { eq: state } },
    },
    skip: typeof state === "string",
  });

  return (
    <div className={classes.listRoot}>
      <div className={classes.listRootInner}>
        <List aria-label="Modul-Studiengang-Tabelle" disablePadding>
          <ListSubheader className={classes.listItem}>
            <ListItemText
              className={clsx(classes.header, classes.headerEnd, classes.listItemText)}
              disableTypography
              primary={"Erstellt am"}
            />
            <ListItemText
              className={clsx(classes.header, classes.listItemText)}
              disableTypography
              primary={"Bemerkung"}
            />
          </ListSubheader>
          <Divider />
          {(data?.verlaufTns ?? []).map((entry) => {
            return (
              <div key={entry.id}>
                <ListItem className={classes.listItem}>
                  <ListItemText
                    className={clsx(classes.headerEnd, classes.listItemText)}
                    disableTypography
                    primary={entry?.verlaufDatum ? formatDate(new Date(entry.verlaufDatum)) : ""}
                  />
                  <ListItemText
                    className={classes.listItemText}
                    disableTypography
                    primary={entry?.verlaufTypTn.verlaufTypName ?? ""}
                  />
                </ListItem>
                <Divider />
              </div>
            );
          })}
        </List>
      </div>
    </div>
  );
};

export default StudentVerlauf;
