// eslint-disable-next-line filenames/match-exported
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { prepareServerSideTable } from "components/templates/table-factory";
import actionsCellFactory from "components/templates/table-factory/Table/Cells/actionsCellFactory";
import {
  useGetModulTermineLazyQuery,
  ResultType,
  GetModulTermineQuery,
} from "graphqlBase/ModulTermine/__generated__/getModulTermine";
import { ModulterminFilterInput, ModulterminSortInput, MoodleModulFilterInput, SortEnumType } from "graphqlBase/types";
import React, { useState } from "react";
import { formatDateTime } from "translations/formatter";
import TableTopContainer from "components/atomics/TableTopContainer";
import ModulTerminAddButton from "./ModulTerminAddButton";
import ModulTerminImportButton from "./ModulTerminImportButton";
import Modal from "components/lbc-toolkit/molecules/Modal";
import { ModulterminUpdateForm, ModulterminUpdateOnUpdateDataMiddleWare } from "raft/ModulterminForm";
import translations from "translations";
import OutlookLink from "./OutlookLink";
import MoodleSync from "../helpers/MoodleSync";
import Props from "./index.d";
import { add, parseISO } from "date-fns/esm";
import { useParams } from "react-router-dom";
const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      root: { width: "100%", marginTop: "2em" },
      listRootHorizontal: {
        // height: "80vh",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },
    }),
  { name: `StudentModul-StudienVerlauf` }
);

export type ModulTerminRow = ResultType<GetModulTermineQuery["modultermins"]>;

interface MoodleModulTermineListProps extends Props {
  where?: MoodleModulFilterInput;
}
const { useCreateServerSideColumns, useMakeServerSideTable, makeSeverSideFilter } = prepareServerSideTable<
  ModulTerminRow,
  ModulterminFilterInput,
  ModulterminSortInput,
  SortEnumType
>();
const ModulTermin: React.FC<MoodleModulTermineListProps> = (props) => {
  const { moodleModulId } = useParams<{ moodleModulId?: string }>();
  const id = +(moodleModulId ?? "0");
  const variables = {
    order: [{ datumVon: "DESC" as SortEnumType.Desc }],
    where: { moodleModulId: { eq: id } },
  };
  const classes = useStyles();
  const [query, { data, refetch }] = useGetModulTermineLazyQuery({
    fetchPolicy: "cache-and-network",
    variables,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [terminId, setTerminId] = useState<number | null>(null);

  React.useEffect(() => {
    query();
  }, [query, id]);

  const handleEdit = (value: number) => {
    setTerminId(value);
    setIsModalOpen(true);
  };

  const ActionCell = actionsCellFactory({
    onEdit: handleEdit,
    onDelete: {
      entity: "modultermin",
      afterDelete: () => (refetch ? refetch() : {}),
    },
  });
  const closeModal = () => setIsModalOpen(false);
  const { columns, serversideQueryConfig } = useCreateServerSideColumns(
    [
      {
        Header: "Modulname",
        accessor: (row) => row.moodleModul?.modul?.modulName ?? "",
        id: "modulName",
        remoteOrder: ({ order, sort }) => ({ moodleModul: { modul: { modulName: sort } } }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { moodleModul: { modul: { modulName: { eq: filterValue } } } }),
        }),
      },
      {
        Header: "Zeitraum",
        accessor: (row) => row.moodleModul?.zeitraum?.zeitraumName ?? "",
        id: "zeitraum",
        remoteOrder: ({ order, sort }) => ({ moodleModul: { zeitraum: { zeitraumName: sort } } }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { moodleModul: { zeitraum: { zeitraumName: { eq: filterValue } } } }),
        }),
      },

      {
        Header: "Datum",
        accessor: (row) => {
          return row.datumVon ? formatDateTime(new Date(parseISO(row.datumVon))) : "";
        },
        id: "createdAt",
        remoteOrder: ({ order, sort }) => ({ datumVon: sort }),
      },
      {
        Header: "Dauer",
        accessor: "dauer",
        id: "dauer",
        remoteOrder: ({ order, sort }) => ({ dauer: sort }),
        remoteFilter: makeSeverSideFilter<number>({
          filterPath: ({ filterValue, where, merge }) => merge(where, { dauer: { eq: filterValue } }),
        }),
      },
      {
        Header: "Termintyp",
        accessor: (row) => row.modulterminTyp?.modulterminTypName ?? "",
        id: "Termintyp",
        remoteOrder: ({ order, sort }) => ({ modulterminTyp: { modulterminTypName: sort } }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { modulterminTyp: { modulterminTypName: { eq: filterValue } } }),
        }),
      },
      {
        Header: "",
        accessor: (row) => <OutlookLink {...row} />,
        id: "outlook",
        width: "30px",
        maxWidth: 0,
      },
      {
        Header: "",
        accessor: "id",
        id: "id",
        // eslint-disable-next-line react/display-name
        Cell: ActionCell,
        width: 60,
      },
    ],
    [refetch]
  );

  const { TableComponent: NewTableComponent } = useMakeServerSideTable(
    {
      data: data?.modultermins ?? [],
      columns,
      initialState: { pageSize: 25 },
    },
    {
      query,
      variables,
      serversideQueryConfig,
      tableId: "MoodleModulTermineList",
    }
  );

  const refetchFull = () => {
    if (refetch) refetch();
  };
  const onUpdateUpdateMiddleware: ModulterminUpdateOnUpdateDataMiddleWare = ({ data, origin, changedFields }) => {
    if (origin === "user" && (changedFields.includes("datumVon") || changedFields.includes("dauer")) && data.datumVon) {
      return {
        ...data,
        dauer: data.dauer ?? undefined,
        datumBis: add(new Date(data.datumVon as string), { minutes: data.dauer ?? 90 }).toISOString(),
      };
    } else {
      return { ...data };
    }
  };
  return (
    <div className={classes.root}>
      <Modal
        open={isModalOpen}
        hideCancel
        hideConfirm
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => setIsModalOpen(false)}
        title={translations.pages.moodleModul.moduleDateUpdate}
      >
        <>
          {terminId && (
            <ModulterminUpdateForm
              id={terminId}
              afterUpdate={closeModal}
              onCancel={closeModal}
              onUpdateDataMiddleWare={onUpdateUpdateMiddleware}
            />
          )}
        </>
      </Modal>
      <TableTopContainer>
        <div className={classes.listRootHorizontal}>
          <MoodleSync {...props} buttonLabel="Termine" syncOperation="syncCalendarEvents" />
          <ModulTerminImportButton where={variables.where} />
          <ModulTerminAddButton moodleModulId={id} key="ModulTermin-add-button-1" refetch={refetchFull} />
        </div>
      </TableTopContainer>
      <NewTableComponent tableProps={{ rowsPerPageOptions: [10, 25, 50, 100] }} />
    </div>
  );
};

export default ModulTermin;
