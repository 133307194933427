export default {
  name: "Einheit",
  plural: "Einheiten",
  columns: {
    deviceCapability: "Gerätefähigkeit",
    deviceModelCapability: "Gerätemodellfähigkeit",
    id: "ID",
    isDeleted: "ist gelöscht",
    tenantId: "Mieter-ID",
    unitName: "Einheitenname",
    unitSymbol: "Einheit Symbol",
    uri: "uri",
  },
};
