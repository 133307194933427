export default {
  label: "Teilnehmer",
  student: "Student",
  zertifikatsTeilnehmer: "Zertifikatsteilnehmer",
  myStudent: "Mein Studium",
  editTeilnehmer: {
    label: "Studierende Bearbeiten",
  },
  editZertifikatsTeilnehmer: {
    label: "Zertifikatsteilnehmer Bearbeiten",
  },
  bewerberList: {
    label: "Bewerber",
  },
  teilnehmerList: {
    label: "Studierende",
  },
  zertifikatsTeilnehmerList: {
    label: "Zertifikat Teilnehmer",
  },
  zertifikatsTeilnehmerAbbrecherList: {
    label: "Zertifikat Abbrecher",
  },
  bewerberEdit: {
    label: "Bewerber Bearbeiten",
  },
  abbrecherList: {
    label: "Abbrecher",
  },
};
