import * as React from "react";
import { makeStyles, createStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Typography from "@material-ui/core/Typography";

import Props from "./index.d";
import tableColors from "components/templates/table-factory/theme/tableColors";

const useStyles = makeStyles(
  (theme: AugmentedTheme) =>
    createStyles({
      root: {
        color: theme.colors.grey500,
        display: "flex",
        flexDirection: "column",
        fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: 1.2,
        letterSpacing: 0,
        margin: 5,
        width: "100%",
      },
      textBox: {
        background: (props: { disabled: boolean }) => (!props.disabled ? tableColors.lbcgrey : theme.colors.tWhite),
        border: (props: { disabled: boolean }) => (!props.disabled ? "none" : `1px solid ${theme.colors.grey400}`),
        borderRadius: "4px",
        cursor: (props: { disabled: boolean }) => (!props.disabled ? "text" : "not-allowed"),
        height: "180px",
        padding: "8px 0 0 8px",
        // width: "180px",
        "&:hover": {
          backgroundColor: theme.colors.tWhite,
        },
        "&:focus": {
          background: theme.colors.tWhite,
          border: "1px solid rgba(150, 150, 150, 1)",
          borderRadius: "4px",
          height: "40px",
        },
        "&:before": {
          borderBottomStyle: "none",
          borderBottom: 0,
          content: "none",
          transition: "none",
        },
        "&:after": {
          border: "none",
        },
      },
      titleBox: {
        margin: "0.2em 0.5em 0.5em 0.4em",
      },
      child: {
        display: "inherit",
        marginTop: 10,
      },
    }),
  { name: "TextArea" }
);

const Textarea: React.FC<Props> = (props) => {
  const {
    title,
    data: { state, setstate, onBlur, updateValue },
  } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <div className={classes.titleBox}>
        <Typography variant="inherit">{title}:</Typography>
      </div>
      <TextareaAutosize
        className={classes.textBox}
        style={{
          appearance: "none",
          fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
          fontSize: "16px",
          outlineStyle: "none",
          width: "100%",
        }}
        rowsMax={10}
        rows={10}
        aria-label={title}
        placeholder=""
        // defaultValue=""
        value={state}
        onChange={updateValue}
        onBlur={onBlur}
        disabled={props.disabled}
      />
    </div>
  );
};

export default Textarea;
