import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as React from "react";

import { Theme } from "@material-ui/core";
import Props from "./index.d";

interface GFCFieldSet_ConfigNode extends Props {
  children: React.ReactNode;
}

const FieldSetHorizontal: React.FC<GFCFieldSet_ConfigNode> = (props) => <>{props.children}</>;

export default FieldSetHorizontal;
