import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetMatchMoodleModulStudentsQueryVariables = Types.Exact<{
  filterInput?: Types.Maybe<Types.MatchMoodleModulStudentFilterInput>;
}>;


export type GetMatchMoodleModulStudentsQuery = { matchMoodleModulStudents: Array<{ __typename?: 'MatchMoodleModulStudent', id: number, studentId?: Types.Maybe<number>, isUserDeleted?: Types.Maybe<boolean>, moodleModulId?: Types.Maybe<number>, student?: Types.Maybe<{ __typename?: 'Student', id: number, vorname?: Types.Maybe<string>, nachname?: Types.Maybe<string>, matchStudiengangStudents: Array<{ __typename?: 'MatchStudiengangStudent', id: number, studentId?: Types.Maybe<number>, studiengangId?: Types.Maybe<number>, studiengang?: Types.Maybe<{ __typename?: 'Studiengang', id: number, studiengangName?: Types.Maybe<string>, studiengangTyp: { __typename?: 'StudiengangTyp', id: number, studiengangTypName: string, studiengangTypKuerzel?: Types.Maybe<string> } }> }> }> }> };


export const GetMatchMoodleModulStudentsDocument = gql`
    query getMatchMoodleModulStudents($filterInput: MatchMoodleModulStudentFilterInput) {
  matchMoodleModulStudents(where: $filterInput) {
    id
    studentId
    isUserDeleted
    moodleModulId
    student {
      id
      vorname
      nachname
      matchStudiengangStudents {
        id
        studentId
        studiengangId
        studiengang {
          id
          studiengangName
          studiengangTyp {
            id
            studiengangTypName
            studiengangTypKuerzel
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetMatchMoodleModulStudentsQuery__
 *
 * To run a query within a React component, call `useGetMatchMoodleModulStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMatchMoodleModulStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMatchMoodleModulStudentsQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *   },
 * });
 */
export function useGetMatchMoodleModulStudentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMatchMoodleModulStudentsQuery, GetMatchMoodleModulStudentsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMatchMoodleModulStudentsQuery, GetMatchMoodleModulStudentsQueryVariables>(GetMatchMoodleModulStudentsDocument, baseOptions);
      }
export function useGetMatchMoodleModulStudentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMatchMoodleModulStudentsQuery, GetMatchMoodleModulStudentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMatchMoodleModulStudentsQuery, GetMatchMoodleModulStudentsQueryVariables>(GetMatchMoodleModulStudentsDocument, baseOptions);
        }
export type GetMatchMoodleModulStudentsQueryHookResult = ReturnType<typeof useGetMatchMoodleModulStudentsQuery>;
export type GetMatchMoodleModulStudentsLazyQueryHookResult = ReturnType<typeof useGetMatchMoodleModulStudentsLazyQuery>;
export type GetMatchMoodleModulStudentsQueryResult = ApolloReactCommon.QueryResult<GetMatchMoodleModulStudentsQuery, GetMatchMoodleModulStudentsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
