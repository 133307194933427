import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type UpdateMatchStudentSemestersMutationVariables = Types.Exact<{
  matchStudentSemesters?: Types.Maybe<Array<Types.Maybe<Types.MatchStudentSemesterUpdateType>> | Types.Maybe<Types.MatchStudentSemesterUpdateType>>;
}>;


export type UpdateMatchStudentSemestersMutation = { updateMatchStudentSemesters?: Types.Maybe<Array<Types.Maybe<{ __typename?: 'MatchStudentSemester', id: number, studentSemesterStatusId?: Types.Maybe<number> }>>> };


export const UpdateMatchStudentSemestersDocument = gql`
    mutation updateMatchStudentSemesters($matchStudentSemesters: [MatchStudentSemesterUpdateType]) {
  updateMatchStudentSemesters(matchStudentSemesters: $matchStudentSemesters) {
    id
    studentSemesterStatusId
  }
}
    `;
export type UpdateMatchStudentSemestersMutationFn = ApolloReactCommon.MutationFunction<UpdateMatchStudentSemestersMutation, UpdateMatchStudentSemestersMutationVariables>;

/**
 * __useUpdateMatchStudentSemestersMutation__
 *
 * To run a mutation, you first call `useUpdateMatchStudentSemestersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMatchStudentSemestersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMatchStudentSemestersMutation, { data, loading, error }] = useUpdateMatchStudentSemestersMutation({
 *   variables: {
 *      matchStudentSemesters: // value for 'matchStudentSemesters'
 *   },
 * });
 */
export function useUpdateMatchStudentSemestersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMatchStudentSemestersMutation, UpdateMatchStudentSemestersMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMatchStudentSemestersMutation, UpdateMatchStudentSemestersMutationVariables>(UpdateMatchStudentSemestersDocument, baseOptions);
      }
export type UpdateMatchStudentSemestersMutationHookResult = ReturnType<typeof useUpdateMatchStudentSemestersMutation>;
export type UpdateMatchStudentSemestersMutationResult = ApolloReactCommon.MutationResult<UpdateMatchStudentSemestersMutation>;
export type UpdateMatchStudentSemestersMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMatchStudentSemestersMutation, UpdateMatchStudentSemestersMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
