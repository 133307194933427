import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { ModulUpdateForm, ModulUpdateOnUpdateDataMiddleWare } from "raft/ModulForm";
import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { useGetMapping } from "./useGetMapping";

const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      ModuleEditFormBox: {
        margin: "0 auto",
        // padding: "0 1em",
        width: "calc(100vw - 64px)",
        // maxWidth: "2400px",
        display: "flex",
        flexDirection: "column",
        // height: "100%",
      },
      afterCreate: {
        margin: "auto",
        maxWidth: "700px",
      },
      afterCreateBody: {
        marginTop: "1em",
      },
      afterCreateHeadline: { marginBottom: "10px" },
    }),

  { name: `Pages-ModuleEdit` }
);
const ModuleEdit: React.FC<{}> = () => {
  const classes = useStyles({});
  const history = useHistory();
  const location = useLocation();
  const { moduleId } = useParams<{ moduleId?: string }>();
  const id = +(moduleId ?? "0");
  const persistMatchStudiengangModul = useGetMapping();

  const onAfterUpdate = (data: { id: number }) => {
    persistMatchStudiengangModul(data.id).finally(() => {
      //
    });
    history.goBack();
  };

  const onLeave = () => history.goBack();

  const onUpdateUpdateMiddleware: ModulUpdateOnUpdateDataMiddleWare = ({ data }) => {
    const summaryOne = data.modulName ?? "";
    return { ...data, summaryOne };
  };

  if (!id) return null;
  return (
    <div className={classes.ModuleEditFormBox}>
      <ModulUpdateForm
        ausbildungsArtId={location.pathname.includes("zertifikatsModuleEdit") ? 2 : 1}
        onUpdateDataMiddleWare={onUpdateUpdateMiddleware}
        onCancel={onLeave}
        afterUpdate={onAfterUpdate}
        id={id}
      />
    </div>
  );
};

export default ModuleEdit;
