export default {
  name: "Gateway-Geräteverbindung",
  plural: "Gateway-Geräteverbindungen",
  columns: {
    device: "Gerät",
    deviceId: "Gerät-ID",
    gatewayDeviceName: "Gateway Gerätename",
    id: "ID",
    isDeleted: "ist gelöscht",
    primaryAuthenticationKey: "primärer Authentifizierungsschlüssel",
    primaryConnectionString: "primäre Verbindungszeichenfolge",
    secondaryAuthenticationKey: "sekundärer Authentifizierungsschlüssel",
    secondaryConnectionString: "sekundäre Verbindungszeichenfolge",
    tenantId: "Mieter-ID",
  },
};
