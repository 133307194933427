import {
  makeNewFormMemoized,
  genericDeleteFunction as genericDeleteFunctionPre,
} from "./"
import {
  Operation,
  QueryArray,
  DeleteChildProps,
  BeforeRemoteMuatation,
  BeforeRemoteMuatationProps,
  OnUpdateDataMiddleWare,
  OnUpdateDataMiddleWareProps,
  ValidateForm,
  ValidateFormProps,
} from "@ml-pa/react-apollo-form-tool/formGen.model"
import React from "react"
import * as SchemaTypes from "./internal/schemaTypes"
import { FormTypes, ModifyProps } from "./internal/typeLinks"

export type BewerberCreateOnUpdateDataMiddleWare =
  OnUpdateDataMiddleWare<SchemaTypes.BewerberInputCreate>
export type BewerberCreateOnUpdateDataMiddleWareProps =
  OnUpdateDataMiddleWareProps<SchemaTypes.BewerberInputCreate>
export type BewerberCreateBeforeRemoteMuatation = BeforeRemoteMuatation<
  SchemaTypes.BewerberInputCreate,
  SchemaTypes.BewerberInputCreateProps["injectedValues"]
>
export type BewerberCreateBeforeRemoteMuatationProps =
  BeforeRemoteMuatationProps<
    SchemaTypes.BewerberInputCreate,
    SchemaTypes.BewerberInputCreateProps["injectedValues"]
  >
export type BewerberCreateValidateForm = ValidateForm<
  SchemaTypes.BewerberInputCreate,
  SchemaTypes.BewerberInputCreateProps["injectedValues"]
>
export type BewerberCreateValidateFormProps = ValidateFormProps<
  SchemaTypes.BewerberInputCreate,
  SchemaTypes.BewerberInputCreateProps["injectedValues"]
>

export interface BewerberCreateProps
  extends SchemaTypes.BewerberInputCreateProps {
  multiInstance?: boolean
  formVersion?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<SchemaTypes.BewerberInputCreate>
  afterCreate?: (data: SchemaTypes.BewerberInputCreate & { id: number }) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<SchemaTypes.BewerberInputCreate>
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.BewerberInputCreate,
    SchemaTypes.BewerberInputCreateProps["injectedValues"]
  >
  validateForm?: ValidateForm<
    SchemaTypes.BewerberInputCreate,
    SchemaTypes.BewerberInputCreateProps["injectedValues"]
  >
  updateAfterCreateQueries?: QueryArray
}

export const BewerberCreateForm: React.FC<BewerberCreateProps> = (props) => {
  const {
      afterCreate,
      injectedValues,
      multiInstance = false,
      formVersion,
      onCancel,
      updateAfterCreateQueries,
      beforeRemoteMuatation,
      onUpdateDataMiddleWare,
      validateForm,
      modifyProps,
      ...params
    } = props,
    { Form: BewerberCreate } = makeNewFormMemoized({
      entity: "bewerber",

      operation: "create",
      formVersion,
      params: multiInstance ? params : undefined,
    })
  return (
    <BewerberCreate
      params={params}
      onCancel={onCancel}
      injectedValues={injectedValues}
      afterCreate={afterCreate}
      updateAfterCreateQueries={updateAfterCreateQueries}
      beforeRemoteMuatation={beforeRemoteMuatation}
      onUpdateDataMiddleWare={onUpdateDataMiddleWare}
      validateForm={validateForm}
      modifyProps={modifyProps}
    />
  )
}

export type BewerberUpdateOnUpdateDataMiddleWare =
  OnUpdateDataMiddleWare<SchemaTypes.BewerberInputUpdate>
export type BewerberUpdateOnUpdateDataMiddleWareProps =
  OnUpdateDataMiddleWareProps<SchemaTypes.BewerberInputUpdate>
export type BewerberUpdateBeforeRemoteMuatation = BeforeRemoteMuatation<
  SchemaTypes.BewerberInputUpdate,
  SchemaTypes.BewerberInputUpdateProps["injectedValues"]
>
export type BewerberUpdateBeforeRemoteMuatationProps =
  BeforeRemoteMuatationProps<
    SchemaTypes.BewerberInputUpdate,
    SchemaTypes.BewerberInputUpdateProps["injectedValues"]
  >
export type BewerberUpdateValidateForm = ValidateForm<
  SchemaTypes.BewerberInputUpdate,
  SchemaTypes.BewerberInputUpdateProps["injectedValues"]
>
export type BewerberUpdateValidateFormProps = ValidateFormProps<
  SchemaTypes.BewerberInputUpdate,
  SchemaTypes.BewerberInputUpdateProps["injectedValues"]
>

export interface BewerberUpdateProps
  extends SchemaTypes.BewerberInputUpdateProps {
  id: number
  multiInstance?: boolean
  formVersion?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<SchemaTypes.BewerberInputUpdate>
  afterUpdate?: (data: SchemaTypes.BewerberInputUpdate) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<SchemaTypes.BewerberInputUpdate>
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.BewerberInputUpdate,
    SchemaTypes.BewerberInputUpdateProps["injectedValues"]
  >
  validateForm?: ValidateForm<
    SchemaTypes.BewerberInputUpdate,
    SchemaTypes.BewerberInputUpdateProps["injectedValues"]
  >
  fetchAllFieldsOnUpdate?: boolean
}

export const BewerberUpdateForm: React.FC<BewerberUpdateProps> = (props) => {
  const {
      afterUpdate,
      injectedValues,
      multiInstance = false,
      formVersion,
      onCancel,
      fetchAllFieldsOnUpdate,
      beforeRemoteMuatation,
      onUpdateDataMiddleWare,
      validateForm,
      modifyProps,
      ...params
    } = props,
    { Form: BewerberUpdate } = makeNewFormMemoized({
      entity: "bewerber",

      operation: "update",
      formVersion,
      params: multiInstance ? params : undefined,
    })
  return (
    <BewerberUpdate
      params={params}
      onCancel={onCancel}
      injectedValues={injectedValues}
      afterUpdate={afterUpdate}
      fetchAllFieldsOnUpdate={fetchAllFieldsOnUpdate}
      beforeRemoteMuatation={beforeRemoteMuatation}
      onUpdateDataMiddleWare={onUpdateDataMiddleWare}
      validateForm={validateForm}
      modifyProps={modifyProps}
    />
  )
}
