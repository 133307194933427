import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { Theme } from "@material-ui/core";
import Props from "./index.d";

const useStyles = makeStyles<Theme, Props>(
  (theme: Theme) =>
    createStyles({
      root: {
        display: "flex",
        justifyContent: ({ justify }) => justify ?? "unset",
        flexDirection: ({ direction }) => direction ?? "row",
        width: ({ width }) => width ?? "100%",
        height: ({ height }) => height ?? "unset",
        background: ({ background }) => (background ? theme.colors[background] : undefined),
        alignContent: ({ alignContent }) => alignContent,
        alignItems: ({ alignItems }) => alignItems,
        padding: ({ padding }) => padding,
        margin: ({ margin }) => margin,
        // backgroundColor: theme.palette.background.paper
      },
      readonly: {
        display: "flex",
        justifyContent: ({ justify }) => justify ?? "unset",
        flexDirection: ({ direction }) => direction ?? "row",
        width: ({ width }) => width ?? "100%",
        height: ({ height }) => height ?? "unset",
        background: ({ background }) => (background ? theme.colors[background] : undefined),
        alignContent: ({ alignContent }) => alignContent,
        alignItems: ({ alignItems }) => alignItems,
        padding: ({ padding }) => padding,
        margin: ({ margin }) => margin,
        pointerEvents: "none",
        cursor: " not-allowed! important",
      },
      child: {
        display: "inherit",
        marginLeft: 30,
      },
    }),
  { name: `FieldSetHorizontalTight` }
);
interface GFCFieldSet_FieldSetHorizontalTight extends Props {
  children: React.ReactNode;
}

const FieldSetHorizontalTight: React.FC<GFCFieldSet_FieldSetHorizontalTight> = (props) => {
  const classes = useStyles(props);
  //  @ts-ignore
  if (props.hidden) return null;
  if (props.readonly) {
    return <div className={classes.readonly}>{props.children}</div>;
  }
  return <div className={classes.root}>{props.children}</div>;
};
export default FieldSetHorizontalTight;
