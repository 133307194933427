import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type UpdateModulMatchingsMutationVariables = Types.Exact<{
  mappingStudiengangModul?: Types.Maybe<Array<Types.Maybe<Types.MatchStudiengangModulCreateType>> | Types.Maybe<Types.MatchStudiengangModulCreateType>>;
  updateMatchModulPruefungTyps?: Types.Maybe<Array<Types.Maybe<Types.MatchModulPruefungTypUpdateType>> | Types.Maybe<Types.MatchModulPruefungTypUpdateType>>;
  createMappingPruefungTypModul?: Types.Maybe<Array<Types.Maybe<Types.MatchModulPruefungTypCreateType>> | Types.Maybe<Types.MatchModulPruefungTypCreateType>>;
  deleteMatchModulPruefungTyps?: Types.Maybe<Array<Types.Scalars['Int']> | Types.Scalars['Int']>;
  deleteMappingStudiengangModul?: Types.Maybe<Array<Types.Scalars['Int']> | Types.Scalars['Int']>;
}>;


export type UpdateModulMatchingsMutation = { createMatchStudiengangModuls?: Types.Maybe<Array<Types.Maybe<{ __typename?: 'MatchStudiengangModul', id: number }>>>, deleteMatchStudiengangModuls?: Types.Maybe<Array<Types.Maybe<{ __typename?: 'MatchStudiengangModul', id: number }>>>, createMatchModulPruefungTyps?: Types.Maybe<Array<Types.Maybe<{ __typename?: 'MatchModulPruefungTyp', id: number }>>>, updateMatchModulPruefungTyps?: Types.Maybe<Array<Types.Maybe<{ __typename?: 'MatchModulPruefungTyp', id: number }>>>, deleteMatchModulPruefungTyps?: Types.Maybe<Array<Types.Maybe<{ __typename?: 'MatchModulPruefungTyp', id: number }>>> };


export const UpdateModulMatchingsDocument = gql`
    mutation updateModulMatchings($mappingStudiengangModul: [MatchStudiengangModulCreateType], $updateMatchModulPruefungTyps: [MatchModulPruefungTypUpdateType], $createMappingPruefungTypModul: [MatchModulPruefungTypCreateType], $deleteMatchModulPruefungTyps: [Int!], $deleteMappingStudiengangModul: [Int!]) {
  createMatchStudiengangModuls(matchStudiengangModuls: $mappingStudiengangModul) {
    id
  }
  deleteMatchStudiengangModuls(ids: $deleteMappingStudiengangModul) {
    id
  }
  createMatchModulPruefungTyps(matchModulPruefungTyps: $createMappingPruefungTypModul) {
    id
  }
  updateMatchModulPruefungTyps(matchModulPruefungTyps: $updateMatchModulPruefungTyps) {
    id
  }
  deleteMatchModulPruefungTyps(ids: $deleteMatchModulPruefungTyps) {
    id
  }
}
    `;
export type UpdateModulMatchingsMutationFn = ApolloReactCommon.MutationFunction<UpdateModulMatchingsMutation, UpdateModulMatchingsMutationVariables>;

/**
 * __useUpdateModulMatchingsMutation__
 *
 * To run a mutation, you first call `useUpdateModulMatchingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModulMatchingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModulMatchingsMutation, { data, loading, error }] = useUpdateModulMatchingsMutation({
 *   variables: {
 *      mappingStudiengangModul: // value for 'mappingStudiengangModul'
 *      updateMatchModulPruefungTyps: // value for 'updateMatchModulPruefungTyps'
 *      createMappingPruefungTypModul: // value for 'createMappingPruefungTypModul'
 *      deleteMatchModulPruefungTyps: // value for 'deleteMatchModulPruefungTyps'
 *      deleteMappingStudiengangModul: // value for 'deleteMappingStudiengangModul'
 *   },
 * });
 */
export function useUpdateModulMatchingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateModulMatchingsMutation, UpdateModulMatchingsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateModulMatchingsMutation, UpdateModulMatchingsMutationVariables>(UpdateModulMatchingsDocument, baseOptions);
      }
export type UpdateModulMatchingsMutationHookResult = ReturnType<typeof useUpdateModulMatchingsMutation>;
export type UpdateModulMatchingsMutationResult = ApolloReactCommon.MutationResult<UpdateModulMatchingsMutation>;
export type UpdateModulMatchingsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateModulMatchingsMutation, UpdateModulMatchingsMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
