import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Props from "./index.d";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Icon } from "@lbc-toolkit/icon";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@material-ui/core";
import tableColors from "components/templates/table-factory/theme/tableColors";

const useStyles = makeStyles<Theme, Props>(
  (theme) =>
    createStyles({
      disabled: {
        alignItems: "center",
        background: theme.colors.tWhite,
        border: "1px solid rgba(154, 151, 143, 0.5)",
        borderRadius: "4px",
        color: theme.colors.grey500,
        cursor: "not-allowed",
        display: "flex",
        justifyContent: "space-between",
        height: "40px",
        paddingLeft: "10px",
        paddingRight: "10px",
        width: "180px",
      },
      formControl: {
        appearance: "none",
        backgroundColor: tableColors.lbcgrey,
        borderRadius: "4px",
        display: "flex",
        height: "40px",
        paddingLeft: "10px",
        paddingRight: "5px",
        paddingTop: "5px",
        width: "180px",
        "&:hover": {
          backgroundColor: theme.colors.grey200,
        },
      },
      label: {
        fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
        fontSize: "16px",
      },
      root: {
        "& .MuiFormControl-root": {
          display: "flex",
          border: "1px solid rgba(154, 151, 143, 0.5)",
        },
        "& .MuiSelect-root": {
          backgroundColor: "transparent",
        },
        "& .MuiSelect-icon": {
          color: "black",
        },
      },
      title: {
        WebkitTextFillColor: theme.colors.grey500,
        color: theme.colors.grey500,
        display: "block",
        fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
        fontSize: "16px",
        fontWeight: 400,
        letterSpacing: 0,
        lineHeight: 1.2,
        margin: "0.2em 0.5em 0.5em 0.4em",
        maxWidth: "fit-content",
      },
    }),
  { name: "SelectBox" }
);

const GFCFieldSelect: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(false);
  const {
    data: { setAndSave, state = "" },
    disabled,
    options,
    title,
  } = props;

  const classes = useStyles(props);
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    setAndSave(value as string);
  };

  return (
    <div className={classes.root}>
      <Typography align="right" className={classes.title} variant="body2">
        {title}:
      </Typography>
      {disabled ? (
        <div className={classes.disabled}>
          <span className={classes.label}>{state}</span>
          <Icon className={classes.dropdownIcon} src={faChevronDown} color="#ededsed" />
        </div>
      ) : (
        <>
          <FormControl className={classes.formControl} disabled={disabled}>
            <Select
              value={state || ""}
              onChange={handleChange}
              onClick={() => setOpen(!open)}
              displayEmpty
              disableUnderline
              className={classes.selectEmpty}
              disabled={disabled}
              inputProps={{ "aria-label": "Without label" }}
              IconComponent={ExpandMoreIcon}
              open={open}
            >
              {!open && (
                <MenuItem disabled={disabled} value="">
                  <em className={classes.label} />
                </MenuItem>
              )}
              {props.options.map((item, index) => {
                return (
                  <MenuItem key={index} disabled={disabled} value={item.value}>
                    <span className={classes.label}>{item.label}</span>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </>
      )}
    </div>
  );
};

export default GFCFieldSelect;
