import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import LoadingIndicator from "components/atomics/LoadingIndicator";
import translations from "translations";
import { MoodleSyncStudentsDocument } from "graphqlBase/moodleSync/__generated__/moodleSyncStudents";
import { MoodleSyncExamEventsDocument } from "graphqlBase/moodleSync/__generated__/moodleSyncExamEvents";
import { MoodleSyncCalendarEventsDocument } from "graphqlBase/moodleSync/__generated__/moodleSyncCalendarEvents";

const synOP = {
  syncStudents: MoodleSyncStudentsDocument,
  syncExamEvents: MoodleSyncExamEventsDocument,
  syncCalendarEvents: MoodleSyncCalendarEventsDocument,
};

import { BaseField, DataString, RaftValueDateTime, DataInteger, RaftValueSubmit } from "../../../formTypes";
export interface Props extends BaseField {
  data: DataInteger;
  title: string;
  buttonLabel: string;
  syncOperation: keyof typeof synOP;
}
import Modal from "components/lbc-toolkit/molecules/Modal";
import { Typography } from "@material-ui/core";

import { gql, useApolloClient, useQuery } from "@apollo/client";
import { truncateSync } from "fs";

const useStyles = makeStyles<Theme, Props>(
  (theme) =>
    createStyles({
      SubmitButton: {
        cursor: "pointer",
        height: "40px",
        alignItems: "center",
        appearance: "none",
        backgroundColor: (props) => (!props.disabled ? theme.colors.christineRed : theme.colors.lbcGrey),
        borderStyle: "none",
        // borderRadius: "4px",
        color: (props) => (!props.disabled ? theme.colors.tWhite : theme.colors.grey400),
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        // width: "217px",
      },
      title: {
        textTransform: "uppercase",
        boxSizing: "content-box",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 15,
        lineHeight: "26px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        letterSpacing: "0.46px",
      },
    }),
  { name: "Button" }
);

const SyncMoodle: React.FC<Props> = (props) => {
  const {
    title,
    buttonLabel,
    syncOperation,
    data: { state },
    raftQuery,
    raftVariables,
  } = props;

  const client = useApolloClient();

  const query = gql(raftQuery);

  const [submitError, setSubmitError] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [moodleModulInternId, setMoodleModulInternId] = useState<number | undefined>();
  const classes = useStyles({ ...props, disabled: !moodleModulInternId || submiting });

  client.query({ query, variables: raftVariables }).then((res) => {
    setMoodleModulInternId(res?.data?.result?.moodleModulInternId ?? undefined);
  });

  const sync = () => {
    setSubmiting(true);
    client
      .mutate({ mutation: synOP[syncOperation], variables: { moodleCourseId: moodleModulInternId } })
      .then((res) => {
        const status = res?.data.moodleSync?.status ?? false;
        if (!status) {
          setSubmitError(true);
        }
      })
      .finally(() => setSubmiting(false));
  };

  return (
    <>
      <Modal
        open={!!submitError}
        onClose={() => setSubmitError(false)}
        onCancel={() => setSubmitError(false)}
        onConfirm={() => setSubmitError(false)}
        primaryCancel
        title={title}
        confirmLabel={translations.globals.button.OK}
        hideCancel
      >
        <Typography variant="h4">{translations.globals.popupMessages.sycFailed}</Typography>
      </Modal>
      <div className={classes.root}>
        <button className={classes.SubmitButton} disabled={!moodleModulInternId || submiting} onClick={sync}>
          <span className={classes.title}>
            {buttonLabel} {translations.globals.terms.sync}
          </span>
        </button>
      </div>
    </>
  );
};

export default SyncMoodle;
