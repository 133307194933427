import { ApolloProvider } from "@apollo/client";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import deLocale from "date-fns/locale/de";
import { msalInstance } from "authorization";
import React from "react";
import { RecoilRoot } from "recoil";
import { main } from "themes/index";
import client from "./apollo/client";
import MetaDecorator from "./components/templates/MetaDecorator";
import Router from "./routes/Router";
import LoadHubspot from "components/atomics/LoadHubspot";

function AppInner() {
  return (
    <RecoilRoot>
      <ApolloProvider client={client}>
        <ThemeProvider theme={main}>
          <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
            <CssBaseline />
            <MetaDecorator />
            <Router />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </ApolloProvider>
    </RecoilRoot>
  );
}

function App() {
  if (process.env.REACT_APP_USE_API === "CampusManagement") {
    return (
      <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <AppInner />
        </MsalAuthenticationTemplate>
        <UnauthenticatedTemplate />
      </MsalProvider>
    );
  } else {
    return (
      <>
        <LoadHubspot />
        <AppInner />
      </>
    );
  }
}

export default App;
