import { useNotificationCallback } from "../recoilStates";
import {
  GetMatchStudiengangModulsDocument,
  GetMatchStudiengangModulsQuery,
  GetMatchStudiengangModulsQueryVariables,
} from "graphqlBase/matchStudiengangModuls/__generated__/getMatchStudiengangModuls";
import {
  GetMatchModulPruefungTypsDocument,
  GetMatchModulPruefungTypsQuery,
  GetMatchModulPruefungTypsQueryVariables,
} from "graphqlBase/MatchModulPruefungTyp/__generated__/getMatchModulPruefungTyps";
import {
  UpdateModulMatchingsMutationVariables,
  UpdateModulMatchingsMutation,
  UpdateModulMatchingsDocument,
} from "graphqlBase/Modul/__generated__/updateModulMatchings";
import client from "apollo/client";

export const useGetMapping = () => {
  const getMatchStudiengangModulData = useNotificationCallback();
  const getData = async (modulId: number): Promise<UpdateModulMatchingsMutationVariables> => {
    const { modulZuordnungenList, pruefungTypZuordnungenList } = await getMatchStudiengangModulData();
    const modulZuordnungPre = await client.query<
      GetMatchStudiengangModulsQuery,
      GetMatchStudiengangModulsQueryVariables
    >({
      query: GetMatchStudiengangModulsDocument,
      variables: { filterInput: { modulId: { eq: modulId } } },
    });
    const pruefungTypZuordnungengPre = await client.query<
      GetMatchModulPruefungTypsQuery,
      GetMatchModulPruefungTypsQueryVariables
    >({
      query: GetMatchModulPruefungTypsDocument,
      variables: { filterInput: { modulId: { eq: modulId } } },
    });

    const deleteMappingStudiengangModul = (modulZuordnungPre.data.matchStudiengangModuls ?? []).map(({ id }) => id);
    const deleteMatchModulPruefungTyps = pruefungTypZuordnungenList.reduce<number[]>(
      (idsToDelete, { id, isDeleted }) => {
        if (isDeleted) return idsToDelete.concat(id);
        return idsToDelete;
      },
      []
    );

    const mappingStudiengangModul = modulZuordnungenList.reduce<
      UpdateModulMatchingsMutationVariables["mappingStudiengangModul"]
    >((studiengangModulMapping, { planSemester, studiengangId }) => {
      if (Array.isArray(studiengangModulMapping))
        return studiengangModulMapping.concat({ modulId, planSemester, studiengangId });
      return studiengangModulMapping;
    }, []);

    const createMappingPruefungTypModul = pruefungTypZuordnungenList.reduce<
      UpdateModulMatchingsMutationVariables["createMappingPruefungTypModul"]
    >((pruefungTypModulMapping, { pruefungTypId, dauer, anteil, id }) => {
      if (Array.isArray(pruefungTypModulMapping) && pruefungTypId && id < 0)
        return pruefungTypModulMapping.concat({ modulId, pruefungTypId, dauer, anteil });

      return pruefungTypModulMapping;
    }, []);

    const updateMatchModulPruefungTyps = pruefungTypZuordnungenList.reduce<
      UpdateModulMatchingsMutationVariables["updateMatchModulPruefungTyps"]
    >((pruefungTypModulMapping, { isDeleted, ...pruefungTypModul }) => {
      if (Array.isArray(pruefungTypModulMapping) && pruefungTypModul.pruefungTypId && pruefungTypModul.id > 0)
        return pruefungTypModulMapping.concat(pruefungTypModul);

      return pruefungTypModulMapping;
    }, []);

    return {
      deleteMappingStudiengangModul,
      mappingStudiengangModul,
      deleteMatchModulPruefungTyps,
      createMappingPruefungTypModul,
      updateMatchModulPruefungTyps,
    };
  };

  const persistData = async (modulId: number) => {
    const variables = await getData(modulId);

    return await client.mutate<UpdateModulMatchingsMutation, UpdateModulMatchingsMutationVariables>({
      mutation: UpdateModulMatchingsDocument,
      variables,
    });
  };
  return persistData;
};
