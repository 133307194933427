import * as React from "react";

import Checkbox from "@material-ui/core/Checkbox";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Props from "./index.d";
import tableColors from "components/templates/table-factory/theme/tableColors";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Link } from "react-router-dom";

interface StyleProps {
  errors: string[] | undefined;
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme) =>
    createStyles({
      input: {
        appearance: "none",
        WebkitAppearance: "none",
        borderRadius: "4px",
        border: "0.1em solid black",
        fontSize: "16px",
        height: "18px",
        margin: "0.3em 0.3em 0.3em 0.3em",
        width: "18px",
        userSelect: "none",
        "&:focus": {
          outline: "none",
        },
        "&:checked": {
          appearance: "none",
          backgroundColor: theme.colors.kolpingOrange,
          backgroundImage: `url('data:image/svg+xml;utf8,<svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>')`,
          border: "0.1em solid rgba(255,0,0,0);",
          height: "18px",
          WebkitAppearance: "none",
          width: "18px",
        },
        "&:disabled": {
          border: tableColors.lbcgrey,
          backgroundColor: tableColors.lbcgrey,
          opacity: 0.5,
        },
      },
      helperText: {
        color: theme.colors.red,
        position: "absolute",
        top: "20px",
      },
      formControlLabel: {
        width: "40em",
        marginBottom: ({ errors }) => (errors && errors.length > 0 ? "1em" : "unset"),
      },
    }),
  { name: "CheckboxBoolean" }
);

const CheckBoxBoolean: React.FC<Props> = (props) => {
  const {
    data: { state, setAndSave },
    disabled,
    fieldId,
    errors,
  } = props;
  const classes = useStyles({ errors });
  return (
    <>
      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Checkbox
            // required
            icon={<></>}
            checkedIcon={<></>}
            disabled={disabled}
            checked={state || false}
            onChange={(i, val) => setAndSave(val)}
            value="primary"
            inputProps={{ "aria-label": "primary checkbox", className: classes.input }}
          />
        }
        label={
          //@ts-ignore
          fieldId === "privacyPolicyAccepted" && props.operation === "create" && props.title !== " CMSInternal" ? (
            <div style={{ position: "relative" }}>
              <span>
                Ich habe die Informationen zum{" "}
                <a target="_blank" href="https://www.kolping-hochschule.de/index.php/datenschutzerklaerung-bewerbung/">
                  Datenschutz
                </a>{" "}
                zur Kenntnis genommen. *
              </span>
              {errors && errors.length > 0 && (
                <FormHelperText className={classes.helperText}>{errors?.concat(" ") ?? undefined}</FormHelperText>
              )}
            </div>
          ) : (
            props.item.label
          )
        }
      />
    </>
  );
};
export default CheckBoxBoolean;
