import React from "react";
import ICalendarLink from "react-icalendar-link";
import { ModulTerminRow } from "./MoodleModulTermineList";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import snakeCase from "lodash/snakeCase";
import { formatDateTime } from "translations/formatter";
import { IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  invitationButton: {
    padding: "7px 2em 0 0",
    width: 1,
    "&:hover": {
      backgroundColor: theme.colors.tTransparent,
    },
    "& svg": {
      color: theme.colors.grey500,
      width: "2em",
      height: "2em",
    },
  },
}));

const OutlookLink: React.FC<ModulTerminRow> = (row) => {
  const classes = useStyles();
  const title = `${row.moodleModul?.modul?.modulName}  - ${row.moodleModul?.zeitraum?.zeitraumName} - ${row?.moodleModul?.modulVerantwortlicher?.vorname} ${row?.moodleModul?.modulVerantwortlicher?.nachname}`;
  const { vorname, nachname, emailKh } = row.moodleModul?.modulVerantwortlicher ?? {};
  const event = {
    title,
    startTime: `${row.datumVon}`,
    endTime: `${row.datumBis}`,
    attendees: [`${vorname} ${nachname} <${emailKh}>`],
  };

  return (
    <ICalendarLink
      filename={snakeCase(`${title}-${row.datumVon ? formatDateTime(new Date(row.datumVon)) : ""}`)}
      event={event}
    >
      <IconButton className={classes.invitationButton}>
        <InsertInvitationIcon />
      </IconButton>
    </ICalendarLink>
  );
};

export default OutlookLink;
