import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type CreateMatchStudiengangStudentsMutationVariables = Types.Exact<{
  matchStudiengangStudents?: Types.Maybe<Array<Types.Maybe<Types.MatchStudiengangStudentCreateType>> | Types.Maybe<Types.MatchStudiengangStudentCreateType>>;
}>;


export type CreateMatchStudiengangStudentsMutation = { createMatchStudiengangStudents?: Types.Maybe<Array<Types.Maybe<{ __typename?: 'MatchStudiengangStudent', id: number, studentId?: Types.Maybe<number>, studiumStatusId?: Types.Maybe<number> }>>> };


export const CreateMatchStudiengangStudentsDocument = gql`
    mutation createMatchStudiengangStudents($matchStudiengangStudents: [MatchStudiengangStudentCreateType]) {
  createMatchStudiengangStudents(matchStudiengangStudents: $matchStudiengangStudents) {
    id
    studentId
    studiumStatusId
  }
}
    `;
export type CreateMatchStudiengangStudentsMutationFn = ApolloReactCommon.MutationFunction<CreateMatchStudiengangStudentsMutation, CreateMatchStudiengangStudentsMutationVariables>;

/**
 * __useCreateMatchStudiengangStudentsMutation__
 *
 * To run a mutation, you first call `useCreateMatchStudiengangStudentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMatchStudiengangStudentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMatchStudiengangStudentsMutation, { data, loading, error }] = useCreateMatchStudiengangStudentsMutation({
 *   variables: {
 *      matchStudiengangStudents: // value for 'matchStudiengangStudents'
 *   },
 * });
 */
export function useCreateMatchStudiengangStudentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMatchStudiengangStudentsMutation, CreateMatchStudiengangStudentsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMatchStudiengangStudentsMutation, CreateMatchStudiengangStudentsMutationVariables>(CreateMatchStudiengangStudentsDocument, baseOptions);
      }
export type CreateMatchStudiengangStudentsMutationHookResult = ReturnType<typeof useCreateMatchStudiengangStudentsMutation>;
export type CreateMatchStudiengangStudentsMutationResult = ApolloReactCommon.MutationResult<CreateMatchStudiengangStudentsMutation>;
export type CreateMatchStudiengangStudentsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMatchStudiengangStudentsMutation, CreateMatchStudiengangStudentsMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
