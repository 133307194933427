import React from "react";
import { useParams } from "react-router-dom";
import { useGetVeranstaltungbyIdQuery } from "graphqlBase/Anmeldung/__generated__/getVeranstaltungById";
import { useGetInfoVeranstaltungsdDatesQuery } from "graphqlBase/VeranstaltungsType/__generated__/getInfoVeranstaltungsdDates";
import { Typography } from "@material-ui/core";
import VeranstaltungenForm from "./VeranstaltungFormShort";
import { formatDateTime } from "translations/formatter";

const InfoVeranstaltung: React.FC<{}> = () => {
  const { veranstaltungId = "2" } = useParams<{ veranstaltungId?: string }>();

  const veranstaltung = useGetVeranstaltungbyIdQuery({
    variables: { id: parseInt(veranstaltungId) },
  });

  const veranstaltungsDates = useGetInfoVeranstaltungsdDatesQuery();
  if (veranstaltung.loading || veranstaltungsDates.loading) return <Typography>Loading ...</Typography>;
  if (veranstaltung.error ?? veranstaltungsDates.error)
    return <Typography>ERROR! {veranstaltung.error?.message ?? veranstaltungsDates.error?.message}</Typography>;
  if (veranstaltung.data?.veranstaltung == null || veranstaltungsDates.data?.veranstaltungsTyps === null)
    return <Typography>Veranstaltung existiert nicht.</Typography>;

  const veranstaltungsDatesList = (veranstaltungsDates.data?.veranstaltungsTyps ?? []).flatMap((verantaltungsType) =>
    verantaltungsType.veranstaltungs.map((veranstaltung) => ({
      value: `${veranstaltung.id}`,
      label: `${formatDateTime(new Date(veranstaltung.datum))} Uhr`,
    }))
  );

  return (
    <VeranstaltungenForm
      veranstaltung={veranstaltung.data.veranstaltung}
      veranstaltungsDatesList={veranstaltungsDatesList}
    />
  );
};

export default InfoVeranstaltung;
