import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import LoadingIndicator from "components/atomics/LoadingIndicator";
import translations from "translations";
import Props from "./index.d";
import Modal from "components/lbc-toolkit/molecules/Modal";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles<Theme, Props>(
  (theme) =>
    createStyles({
      SubmitButton: {
        cursor: "pointer",
        height: "40px",
        alignItems: "center",
        appearance: "none",
        backgroundColor: (props) => (!props.disabled ? theme.colors.christineRed : theme.colors.lbcGrey),
        borderStyle: "none",
        // borderRadius: "4px",
        color: (props) => (!props.disabled ? theme.colors.tWhite : theme.colors.grey400),
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "217px",
      },
      title: {
        textTransform: "uppercase",
        boxSizing: "content-box",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 15,
        lineHeight: "26px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        letterSpacing: "0.46px",
      },
    }),
  { name: "Button" }
);

const SubmitButton: React.FC<Props> = (props) => {
  const {
    title,
    variant,
    confirm,
    disabled,
    buttonLabel,
    data: { setAndSave },
  } = props;
  const classes = useStyles(props);
  const [submit, setSubmit] = useState(false);

  const setAndSaveInner = () => {
    if (!confirm) {
      setAndSave(true);
    } else {
      setSubmit(true);
    }
  };

  const submitAfterConfirm = () => {
    setSubmit(false);
    setAndSave(true);
  };

  return (
    <>
      <Modal
        open={!!submit}
        onClose={() => setSubmit(false)}
        onCancel={() => setSubmit(false)}
        onConfirm={submitAfterConfirm}
        primaryCancel
        title={title}
      >
        <Typography variant="h4">{translations.globals.popupMessages.saveData}</Typography>
      </Modal>
      <div className={classes.root}>
        <button className={classes.SubmitButton} disabled={disabled} onClick={setAndSaveInner}>
          <span className={classes.title}>{buttonLabel ?? translations.globals.terms.submit}</span>
        </button>
      </div>
    </>
  );
};

export default SubmitButton;
