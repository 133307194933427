import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

function openInNewTab(url: string) {
  window?.open(url, "_blank")?.focus();
}
const Portal: React.FC<{}> = () => {
  const history = useHistory();

  useEffect(() => {
    history.go(-1);
    setTimeout(() => {
      openInNewTab(
        process.env.REACT_APP_GRAPHQL_URI === "https://app-kolping-dev-gateway.azurewebsites.net/graphql"
          ? "https://portal-dev.kolping-hochschule.de/my/"
          : "https://portal.kolping-hochschule.de/"
      );
    }, 10);
  }, [history]);
  return null;
};

export default Portal;
