import { useQuery, gql } from "@apollo/client";
import { useMemo } from "react";
import props from "./index.d";

interface UseGetForeignKeyListParams {
  foreignKeyParams: props["foreignKeyParams"];
  sortOnColumns?: props["sortOnColumns"];
}
function useGetForeignKeyList({
  foreignKeyParams,
  sortOnColumns,
}: UseGetForeignKeyListParams): { value: number; label: string }[] {
  const query = useMemo(() => {
    const sortString = `(order:[${foreignKeyParams.column.map((column, index) => `{${column}:ASC}`)}])`;
    return gql`query get${foreignKeyParams.entityName} {
          elems:${foreignKeyParams.entityName}${sortOnColumns ? sortString : ""}{
            value:id
            ${foreignKeyParams.column.map((column, index) => `label${index}:${column}`)}
            ${Object.keys(foreignKeyParams.filters ?? {}).map((column, index) => `filter${column}:${column}`)}
            
          }
        }`;
  }, [foreignKeyParams]);
  const { data } = useQuery(query);

  //@ts-ignore
  return (data?.elems ?? []).reduce((entries, { value, __typename, ...rest }) => {
    if (
      Object.entries(foreignKeyParams.filters ?? {}).reduce((res, [column, filter]) => {
        return res && rest[`filter${column}`] === filter;
      }, true)
    )
      return entries.concat({ value, label: Object.values(rest).join(" ") });
    return entries;
  }, []);
}

export default useGetForeignKeyList;
