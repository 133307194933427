import type * as Types from '../../types';

import type { StudentSemesterStatusesFragment } from '../../MatchStudentSemesters/__generated__/getCurrentStudentStatus';
import gql from 'graphql-tag';
import { StudentSemesterStatusesFragmentDoc } from '../../MatchStudentSemesters/__generated__/getCurrentStudentStatus';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetStudentOverviewQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.StudentFilterInput>;
  take?: Types.Maybe<Types.Scalars['Int']>;
  skip?: Types.Maybe<Types.Scalars['Int']>;
  order?: Types.Maybe<Array<Types.StudentSortInput> | Types.StudentSortInput>;
}>;


export type GetStudentOverviewQuery = { students: Array<(
    { __typename: 'Student', id: number, emailKh?: Types.Maybe<string>, studienstartStudiengangKuerzel?: Types.Maybe<string>, vorname?: Types.Maybe<string>, nachname?: Types.Maybe<string>, geburtsdatum?: Types.Maybe<string>, geburtsort?: Types.Maybe<string>, plz?: Types.Maybe<string>, wohnort?: Types.Maybe<string>, strasse?: Types.Maybe<string>, hausnummer?: Types.Maybe<string>, wohnlandTnid?: Types.Maybe<number>, telefonnummer?: Types.Maybe<string>, emailPrivat?: Types.Maybe<string>, createdAt?: Types.Maybe<string>, newsletterAccepted?: Types.Maybe<boolean>, privacyPolicyAccepted?: Types.Maybe<boolean>, geschlechtTn?: Types.Maybe<{ __typename?: 'GeschlechtTn', id: number, geschlechtName: string }>, matchStudiengangStudents: Array<{ __typename?: 'MatchStudiengangStudent', studiengang?: Types.Maybe<{ __typename?: 'Studiengang', id: number, studiengangName?: Types.Maybe<string>, studiengangKuerzel?: Types.Maybe<string> }>, studiumArt?: Types.Maybe<{ __typename?: 'StudiumArt', id: number, studiumArtName?: Types.Maybe<string> }>, zeitraum?: Types.Maybe<{ __typename?: 'Zeitraum', id: number, zeitraumName: string, zeitraumNameKuerzel?: Types.Maybe<string> }>, zahlungsmodellTn?: Types.Maybe<{ __typename?: 'ZahlungsmodellTn', id: number, zahlungsmodellName: string }> }>, geburtslandTn?: Types.Maybe<{ __typename?: 'GeburtslandTn', id: number, geburtslandName: string }>, staatsangehoerigkeitTn?: Types.Maybe<{ __typename?: 'StaatsangehoerigkeitTn', staatsangehoerigkeitName: string }>, wohnlandTn?: Types.Maybe<{ __typename?: 'WohnlandTn', id: number, wohnlandName: string }> }
    & StudentSemesterStatusesFragment
  )> };


export const GetStudentOverviewDocument = gql`
    query getStudentOverview($where: StudentFilterInput, $take: Int, $skip: Int, $order: [StudentSortInput!]) {
  students(where: $where, take: $take, skip: $skip, order: $order) {
    id
    emailKh
    geschlechtTn {
      id
      geschlechtName
    }
    studienstartStudiengangKuerzel
    matchStudiengangStudents {
      studiengang {
        id
        studiengangName
        studiengangKuerzel
      }
      studiumArt {
        id
        studiumArtName
      }
      zeitraum {
        id
        zeitraumName
        zeitraumNameKuerzel
      }
      zahlungsmodellTn {
        id
        zahlungsmodellName
      }
    }
    vorname
    nachname
    geburtsdatum
    geburtslandTn {
      id
      geburtslandName
    }
    staatsangehoerigkeitTn {
      staatsangehoerigkeitName
    }
    geburtsort
    plz
    wohnort
    strasse
    hausnummer
    wohnlandTnid
    telefonnummer
    emailPrivat
    wohnlandTn {
      id
      wohnlandName
    }
    ...StudentSemesterStatuses
    createdAt
    newsletterAccepted
    privacyPolicyAccepted
    telefonnummer
    wohnort
    plz
    hausnummer
    strasse
    geburtsort
    geburtsdatum
    nachname
    vorname
    __typename
  }
}
    ${StudentSemesterStatusesFragmentDoc}`;

/**
 * __useGetStudentOverviewQuery__
 *
 * To run a query within a React component, call `useGetStudentOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentOverviewQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetStudentOverviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStudentOverviewQuery, GetStudentOverviewQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStudentOverviewQuery, GetStudentOverviewQueryVariables>(GetStudentOverviewDocument, baseOptions);
      }
export function useGetStudentOverviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStudentOverviewQuery, GetStudentOverviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStudentOverviewQuery, GetStudentOverviewQueryVariables>(GetStudentOverviewDocument, baseOptions);
        }
export type GetStudentOverviewQueryHookResult = ReturnType<typeof useGetStudentOverviewQuery>;
export type GetStudentOverviewLazyQueryHookResult = ReturnType<typeof useGetStudentOverviewLazyQuery>;
export type GetStudentOverviewQueryResult = ApolloReactCommon.QueryResult<GetStudentOverviewQuery, GetStudentOverviewQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
