import React from "react";
import { IconButton, IconButtonClassKey, IconButtonProps } from "@material-ui/core";
import Icon from "components/lbc-toolkit/atomics/Icon";
import clsx from "clsx";
import { getTheme, useOtherStyles } from "./utils";
import getTestIDs from "lib/utils/getTestIDs";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export const testIDs = getTestIDs();
export interface DefaultButtonProps extends IconButtonProps {
  animation?: "" | "pulse" | "spin";
  buttonType: "primary" | "secondary" | "danger" | "radial";
  classes?: Partial<Record<IconButtonClassKey, string>>;
  disabled?: boolean;
  icon?: IconProp;
  label?: string;
  onClick: () => void;
  theme?: "light" | "dark";
  width?: string;
}

const DefaultButton: React.FC<DefaultButtonProps> = (props) => {
  const {
    label = "",
    buttonType = "",
    icon,
    onClick,
    disabled = false,
    animation = "",
    theme = "light",
    classes,
    width = "standard",
    style,
  } = props;

  const otherClasses = useOtherStyles();
  const color = buttonType === "primary" || buttonType === "secondary" ? buttonType : "default";
  const layoutWrapper = icon && label ? otherClasses.combinedWrapper : otherClasses.wrapper;
  const computedWidth = width === "standard" && !label ? "40px" : width === "standard" && !icon ? "280px" : width;
  const dangerStyles = buttonType === "danger" ? otherClasses.danger : "";
  const radialStyles = buttonType === "radial" ? otherClasses.radial : "";
  const pulse = animation === "pulse" && true;
  const spin = animation === "spin" && true;
  const darkTheme = getTheme(theme, buttonType, otherClasses);

  return (
    <IconButton
      data-testid={testIDs.DefaultButton}
      className={clsx(radialStyles, dangerStyles, darkTheme)}
      style={{ width: computedWidth, ...style }}
      onClick={onClick}
      disabled={disabled}
      classes={classes}
      color={color}
    >
      <div className={layoutWrapper}>
        {icon && <Icon pulse={pulse} spin={spin} icon={icon} color="inherit" />}
        {label && <span className={otherClasses.label}>{label}</span>}
      </div>
    </IconButton>
  );
};

export default DefaultButton;
