import asset from "./asset/de";
import assetAttribute from "./assetAttribute/de";
import assetAttributeValue from "./assetAttributeValue/de";
import assetDevice from "./assetDevice/de";
import assetImage from "./assetImage/de";
import assetType from "./assetType/de";
import assetTypeAttributeSet from "./assetTypeAttributeSet/de";
import capability from "./capability/de";
import device from "./device/de";
import deviceAttribute from "./deviceAttribute/de";
import deviceAttributeValue from "./deviceAttributeValue/de";
import deviceCapability from "./deviceCapability/de";
import deviceModel from "./deviceModel/de";
import deviceModelAttributeSet from "./deviceModelAttributeSet/de";
import deviceModelCapability from "./deviceModelCapability/de";
import deviceModelVersion from "./deviceModelVersion/de";
import gatewayDeviceConnection from "./gatewayDeviceConnection/de";
import manufacturer from "./manufacturer/de";
import mappingAttributeAssetTypeAttributeSet from "./mappingAttributeAssetTypeAttributeSet/de";
import mappingAttributeDeviceModelAttributeSet from "./mappingAttributeDeviceModelAttributeSet/de";
import queryStatisticsOutputType from "./queryStatisticsOutputType/de";
import rule from "./rule/de";
import ruleAction from "./ruleAction/de";
import ruleReference from "./ruleReference/de";
import ruleReferenceUnit from "./ruleReferenceUnit/de";
import ruleResultType from "./ruleResultType/de";
import unit from "./unit/de";

export default {
  asset,
  assetAttribute,
  assetAttributeValue,
  assetDevice,
  assetImage,
  assetType,
  assetTypeAttributeSet,
  device,
  capability,
  deviceAttribute,
  deviceAttributeValue,
  deviceCapability,
  deviceModel,
  deviceModelAttributeSet,
  deviceModelCapability,
  deviceModelVersion,
  gatewayDeviceConnection,
  manufacturer,
  mappingAttributeAssetTypeAttributeSet,
  mappingAttributeDeviceModelAttributeSet,
  queryStatisticsOutputType,
  rule,
  ruleAction,
  ruleReference,
  ruleReferenceUnit,
  ruleResultType,
  unit,
};
