import React, { useEffect } from "react";
import { InputProps, TextFieldProps } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { numberToLimitedString, stringToLimitedNumber, stringToNumber } from "./utils";

export interface DecimalEditorProps {
  inputProps?: InputProps;
  maxDecimals?: number;
  maxValue?: number;
  minValue?: number;
  onBlur?: (value: number | null) => void;
  onChange?: (value: number | null) => void;
  textFieldProps?: TextFieldProps;
  title?: string;
  value: number | null;
}

const DecimalEditor: React.FC<DecimalEditorProps> = (props) => {
  const { title = "", minValue, maxValue, maxDecimals = 5 } = props;

  const [currentStringValue, setCurrentStringValue] = React.useState<string>("");
  const [currentNumberValue, setCurrentNumberValue] = React.useState<number | null>(null);

  useEffect(() => {
    const limitedVal = numberToLimitedString(props.value, maxDecimals);
    if (currentStringValue !== limitedVal) setCurrentStringValue(limitedVal);
    setCurrentNumberValue(stringToLimitedNumber(limitedVal, maxDecimals));
  }, [props.value]);

  const onChange: DecimalEditorProps["onChange"] = (value) => {
    if (props.onChange != null) props.onChange(value);
  };

  const onBlur = () => {
    if (props.onBlur != null) props.onBlur(currentNumberValue);
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.target.value === "") {
      setCurrentNumberValue(null);
      setCurrentStringValue("");
      onChange(null);
    } else {
      const stringValuePre = event.target.value
        .replace(",", ".")
        .split(".")
        .map((s, i) => {
          if (s === "" || s === "undefined") return "";
          const num = s !== "" ? s.slice(0, i === 1 ? maxDecimals : 15) : "0";
          if (isNaN(+num)) return "0";
          return num;
        })
        .slice(0, 2)
        .join(".");
      const stringValue = maxDecimals === 0 ? stringValuePre.replace(".", "") : stringValuePre;
      setCurrentStringValue(stringValue);
      const numValue = stringToNumber(stringValue); // is already limited
      onChange(numValue);
      setCurrentNumberValue(numValue);
    }
  };

  const isValueValid =
    (minValue == null ? true : currentNumberValue != null && currentNumberValue >= minValue) &&
    (maxValue == null ? true : currentNumberValue != null && currentNumberValue <= maxValue);

  const helperText = !isValueValid && `Der Wert muss zwischen ${minValue} und ${maxValue} liegen!`;

  return (
    <TextField
      onBlur={onBlur}
      error={!isValueValid}
      helperText={helperText}
      value={currentStringValue}
      onChange={handleChange}
      label={title}
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      disabled={props.inputProps?.disabled === true}
      InputProps={props.inputProps}
      {...props.textFieldProps}
    />
  );
};

export default DecimalEditor;
