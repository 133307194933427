export default {
  name: "Gerätemodellversion",
  plural: "Gerätemodellversionen",
  columns: {
    device: "Gerät",
    deviceModel: "Gerätemodell",
    deviceModelCapability: "Gerätemodellfähigkeit",
    deviceModelId: "Gerät Modell-ID",
    id: "ID",
    isDeleted: "ist gelöscht",
    tenantId: "Mieter-ID",
  },
};
