export default {
  name: "Asset-Art-Attributsatz",
  plural: "Asset-Art-Attributsätze",
  columns: {
    assetType: "Asset-Art",
    assetTypeAttributeSetName: "Asset-Art Attribut Set Name",
    assetTypeId: "Asset-Art ID",
    id: "ID",
    isDeleted: "ist gelöscht",
    mappingAttributeAssetTypeAttributeSet: "Zuordnungsattribut Asset-Art Attributsatz",
    tenantId: "Mieter ID",
  },
};
