import React from "react";
import translations from "translations";

const { terms: termTexts } = translations.globals;

export function makeId(length: number) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function useGetRandomId(length: number = 4) {
  return React.useMemo(() => makeId(length), []);
}

export function debug(name: string, ...input: any) {
  if (process.env.NODE_ENV === "development") console.log(`[${name}]`, ...input);
}

export function parseIntsFromString(string: string, separator: string = ","): number[] {
  return string.split(separator).reduce<number[]>((res, current) => {
    const id = parseInt(current);
    if (isNaN(id)) return res;
    res.push(id);
    return res;
  }, []);
}

export const getSelectBooleanOptions = () => {
  return [
    { value: "Clear", label: termTexts.clear },
    { value: "true", label: termTexts.true },
    { value: "false", label: termTexts.false },
  ];
};
