import React from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { MatchStudiengangStudentUpdateForm } from "raft/MatchStudiengangStudentForm";

const MatchStudiengangStudentUpdate: React.FC<{}> = () => {
  const { matchStudiengangId, studId } = useParams<{ matchStudiengangId?: string; studId?: string }>();
  const id = +(matchStudiengangId ?? "0");
  const studentId = +(studId ?? "0");
  const history = useHistory();
  const location = useLocation();
  const handleAfterUpdate = () => {
    history.push(
      `/teilnehmer/${
        location.pathname.includes("zertifikatsStudiengang") ? "zertifikatsTeilnehmerEdit" : "teilnehmerEdit"
      }/${studentId}`
    );
  };

  return (
    <MatchStudiengangStudentUpdateForm
      id={id}
      formVersion={location.pathname.includes("zertifikatsStudiengang") ? "studiengangZertifikate" : "studiengang"}
      afterUpdate={handleAfterUpdate}
    />
  );
};

export default MatchStudiengangStudentUpdate;
