import React from "react"
import memoize from "lodash/memoize"
import {
  Operation,
  QueryArray,
} from "@ml-pa/react-apollo-form-tool/formGen.model"
import * as SchemaTypes from "./internal/schemaTypes"
import BuildForm from "@ml-pa/react-apollo-form-tool/buildForm"
import deleteItems, {
  DeleteItemsFucType,
  IdType,
} from "@ml-pa/react-apollo-form-tool/buildForm/deleteItems"
import elements from "./internal/elements"
import config from "./internal/raft.config"
import clientFields from "./internal/clientFields.json"
import externalFields from "./internal/externalFields.json"
import schemaDescription from "./internal/schemaDescription.json"
import client from "apollo/client"
import localization from "translations"
import afterChange from "lib/formToolHelpers/testAfter"

import treeConfig from "./config.json"
const clientLocal = (path: string) => {
  return {
    data: { configurator: { data: JSON.stringify((treeConfig as any)[path]) } },
  }
}

export type DeleteItemsFuncType = DeleteItemsFucType

const makeNewForm = ({
  params,
  entity,
  operation,
  formVersion,
}: {
  params?: any
  operation: Operation
  entity: keyof typeof schemaDescription
  formVersion?: string
}) => {
  return new BuildForm({
    client,
    clientFields,
    externalFields,
    config,
    entity,
    operation,
    elements,
    clientLocal,
    afterChange,
    allFields: Object.keys(schemaDescription[entity].fields),

    formVersion,
    localization,
  })
}
export const makeNewFormMemoized = memoize(makeNewForm)

export interface GenericDeleteParams {
  afterDelete?: (ids: IdType) => void
  updateAfterDeleteQueries?: QueryArray
  entity: keyof typeof schemaDescription
}
export const genericDeleteFunction = (props: GenericDeleteParams) => {
  const baseProps = {
    client,
  }
  const genericDelete = deleteItems({ ...props, ...baseProps })
  return genericDelete
}
