import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { StepLabel, Typography } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import Stepper from "@material-ui/core/Stepper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Icon from "components/lbc-toolkit/atomics/Icon";
import React, { useEffect } from "react";
import colors from "themes/colors";
import Props from "./index.d";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        "& .text": {
          display: "none",
        },
        "& .MuiPaper-root": {
          backgroundColor: "inherit",
        },
      },
    }),
  { name: "TabsBar" }
);

const TabsBar: React.FC<Props> = (props) => {
  const {
    data: { state, setAndSave },
    items,
    title,
    disabled,
    centered,
    indicatorColor,
    orientation,
    variant,
  } = props;

  const classes = useStyles();

  useEffect(() => {
    if (state == null || typeof state != "number") setAndSave(0);
  }, [state]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setAndSave(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={+state || 0}
        onChange={handleChange}
        centered={centered}
        disabled={disabled}
        indicatorColor={indicatorColor}
        variant={variant}
        orientation={orientation}
        aria-label="simple tabs example"
      >
        {items.map(({ label }, index) => {
          return <Tab label={label} />;
        })}
      </Tabs>
    </div>
  );
};
export default TabsBar;
