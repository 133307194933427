import React, { useEffect } from "react";
import Button from "components/lbc-toolkit/atomics/Button/DefaultButton";
import translations from "translations";
import { Theme, createStyles, makeStyles } from "@material-ui/core";
import downloadBase64File from "lib/formToolHelpers/downloadBase64File";
import { useGetCertificateOfStudyLazyQuery } from "graphqlBase/CertificateOfStudy/__generated__/getCertificateOfStudy";

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    addButton: {
      fontSize: 14,
      color: theme.colors.tWhite,
      backgroundColor: theme.colors.sunYellow,
      height: 40,
      borderRadius: "unset",
      "&:hover": { color: theme.colors.tWhite, backgroundColor: theme.colors.sunYellow },
    },
  })
);

interface Props {
  id: number;
}

const CertificateOfStudy: React.FC<Props> = ({ id }) => {
  const classes = useStyles();
  const [query, { data }] = useGetCertificateOfStudyLazyQuery({
    fetchPolicy: "network-only",
  });

  const handleClick = () => {
    query({ variables: { studentId: id } });
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    const base64String = data.certificateOfStudy?.fileContentBase64;
    const fileName = data?.certificateOfStudy?.fileName;

    if (!base64String) {
      return;
    }
    downloadBase64File("application/pdf", base64String, `${fileName}`);
  }, [data, id]);

  return (
    <Button
      onClick={handleClick}
      label={`${"Studienbescheinigung"} ${translations.globals.button.download}`}
      buttonType="primary"
      classes={{ colorPrimary: classes.addButton }}
    />
  );
};

export default CertificateOfStudy;
