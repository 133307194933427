import anmeldung from "./anmeldung/de";
import bewerbung from "./bewerbung/de";
import buchhaltung from "./buchhaltung/de";
import configurator from "./configurator/de";
import development from "./development/de";
import feedback from "./feedback/de";
import home from "./home/de";
import lehre from "./lehre/de";
import mitarbeiter from "./mitarbeiter/de";
import moodleModul from "./moodleModul/de";
import produkte from "./produkte/de";
import reporting from "./reporting/de";
import settings from "./settings/de";
import teilnehmer from "./teilnehmer/de";
import veranstaltungen from "./veranstaltungen/de";

export default {
  development,
  feedback,
  settings,
  anmeldung,
  buchhaltung,
  home,
  lehre,
  mitarbeiter,
  moodleModul,
  produkte,
  reporting,
  teilnehmer,
  configurator,
  bewerbung,
  veranstaltungen,
};
