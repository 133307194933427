import { Overrides } from "@material-ui/core/styles/overrides";
import theme from "./tableTheme";

const overrides: Overrides = {
  MuiTypography: {
    body1: {
      fontWeight: "inherit",
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
      },
    },
    body2: {
      fontWeight: "inherit",
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
      },
    },
  },
  MuiInput: {
    underline: {
      //   "&:before": {
      //     borderBottom: `1px solid ${theme.tableColors.whiteSmoke}`,
      //   },
    },
  },
  MuiIconButton: {
    root: {
      color: "inherit",
      // "&.Mui-disabled": {
      //   color: "rgb(245,245,245, 0.5)",
      // },
    },
  },
  MuiCheckbox: {
    root: { color: "inherit" },
    colorSecondary: {
      "&$checked": {
        color: "inherit",
      },
    },
  },
  MuiTableSortLabel: {
    // root: {
    //   "&:hover": {
    //     // color: theme.tableColors.whiteSmoke,
    //   },
    //   icon: {
    //     // color: theme.tableColors.whiteSmoke,
    //   },
    // },
  },
  MuiList: {
    root: { color: theme.tableColors.black },
  },
  MuiSelect: {
    root: {
      fontWeight: "inherit",
      // fontSize: "13px",
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
      },
    },
    icon: { top: "inherit" },
  },
  MuiSvgIcon: {
    root: { color: "inherit" },
  },
  MuiButtonBase: {
    //
  },
  MuiButton: {
    root: { color: theme.palette.secondary.dark },
    // containedPrimary: { backgroundColor: theme.palette.secondary.dark },
  },
  MuiTablePagination: {
    toolbar: {
      borderBottom: `1px solid ${theme.tableColors.grey300}`,
      marginBottom: "2px",
      borderTop: `1px solid ${theme.tableColors.grey300}`,
      marginTop: "3px",
      fontSize: "14px",
      [theme.breakpoints.down("lg")]: {
        fontSize: "12px",
      },
    },
    root: {
      // backgroundColor: theme.palette.primary.light,
      backgroundColor: theme.tableColors.white,
      // boxShadow: "0px 22px teal",
      // borderBottom: "1px solid black",
      // marginBottom: "-6px",
    },
    menuItem: {
      color: "black",
    },
  },
  MuiTable: {
    stickyHeader: {
      borderCollapse: "collapse",
    },
  },
  MuiTableCell: {
    head: {
      fontWeight: "bold",
    },
    stickyHeader: {
      backgroundColor: theme.palette.primary.light,
    },
    root: {
      [theme.breakpoints.up("lg")]: {
        fontSize: "14px",
      },
      padding: "12.5px",
      height: "auto",

      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
        padding: ".5rem",
      },
    },

    body: {
      // color: `${theme.tableColors.black} !important`,
      // border: `2px solid ${theme.tableColors.whiteSmoke}`,
      border: `1px solid ${theme.tableColors.lbcgrey}`,
      borderLeft: 0,
      borderRight: 0,
    },
  },
  MuiTableRow: {
    root: {
      "&:hover": {
        // backgroundColor: "#979FBE"
        backgroundColor: theme.tableColors.lbcyellow,
      },
    },
  },
};

export default overrides;
