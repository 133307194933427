/* eslint-disable react/display-name */
import GearWheelIcon from "icons/components/GearWheelIcon";
import LiTrackIcon from "icons/components/LiTrackIcon";
import React from "react";
import translations from "translations";
import Home from "../components/pages/Home";
import CiandoRedirect from "../components/PagesCampusManagement/CiandoRedirect";
import BewerberList from "../components/PagesCampusManagement/bewerber/BewerberList";
import AnmeldungAdd from "../components/PagesCampusManagement/bewerber/BewerberList/AnmeldungAdd";
import Development from "../components/pages/Development";
import Configurator from "../raft/configurator";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import LaptopWindowsIcon from "@material-ui/icons/LaptopWindows";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PersonIcon from "@material-ui/icons/Person";
import BarChartIcon from "@material-ui/icons/BarChart";
import Veranstaltungen from "../components/pages/Veranstaltungen";
import InfoVeranstaltung from "../components/pages/InfoVeranstaltung";
import Veranstaltung from "../components/pages/Veranstaltung";
import TeilnehmerEdit from "components/PagesCampusManagement/teilnehmer/StudentUpdate";
import BewerberEdit from "components/PagesCampusManagement/teilnehmer/BewerberUpdate";

import TeilnehmerList from "components/PagesCampusManagement/teilnehmer/TeilnehmerList";
import ZertifikatsTeilnehmerListe from "components/PagesCampusManagement/teilnehmer/TeilnehmerList/ZertifikatsTeilnehmerListe";
import ZertifikatsTeilnehmerAbgebrochenListe from "components/PagesCampusManagement/teilnehmer/TeilnehmerList/ZertifikatsTeilnehmerAbgebrochenListe";
import AbbrecherList from "components/PagesCampusManagement/teilnehmer/TeilnehmerList/AbbrecherList";
import ModuleList from "components/PagesCampusManagement/produkte/Module/ModuleList";
import ZertifikatsModule from "components/PagesCampusManagement/produkte/Module/ModuleList/ZertifikatsModule";
import ModuleEdit from "components/PagesCampusManagement/produkte/Module/ModuleEdit";
import ModuleAdd from "components/PagesCampusManagement/produkte/Module/ModuleAdd";
import Studium from "components/PagesCampusManagement/produkte/Studium";
import StudiumAdd from "components/PagesCampusManagement/produkte/Studium/StudiumAdd";
import ZertifikatsList from "components/PagesCampusManagement/produkte/Studium/StudiengangList/ZertifikatsList";
import StudiengangEdit from "components/PagesCampusManagement/produkte/Studium/StudiengangEdit";
import Portal from "components/PagesCampusManagement/Portal";
import AllModules from "components/PagesCampusManagement/lehre/MoodleModules/AllModules";
import PastModules from "components/PagesCampusManagement/lehre/MoodleModules/PastModules";
import CurrentModules from "components/PagesCampusManagement/lehre/MoodleModules/CurrentModules";
import FutureModules from "components/PagesCampusManagement/lehre/MoodleModules/FutureModules";
import MoodleModuleAdd from "components/PagesCampusManagement/lehre/MoodleModules/MoodleModuleAdd";
import MoodleModuleEdit from "components/PagesCampusManagement/lehre/MoodleModules/MoodleModuleEdit";
import AddStudent from "components/PagesCampusManagement/teilnehmer/TeilnehmerList/StudentAdd";
import Pruefungsliste from "components/PagesCampusManagement/lehre/Pruefungen/Pruefungsliste";
import PruefungsergebnisseOnPruefung from "components/PagesCampusManagement/lehre/Pruefungen/PruefungUpdate";
import BewerberUpdate from "../components/PagesCampusManagement/bewerber/BewerberUpdate";
import MatchStudiengangStudentUpdate from "../components/PagesCampusManagement/teilnehmer/MatchStudiengangStudentUpdate";
import Avatar from "components/lbc-toolkit/atomics/Avatar";
import colors from "themes/colors";
import MyStudent from "../components/PagesCampusManagement/teilnehmer/MyStudent";
// Startseite
// Bewerber
//     Online Bewerbungen
// Teilnehmer
// Produkte
//     Studium
// Mitarbeiter
//     Stammdaten
// Lehre
// Buchhaltung
// Reporting

export interface Route {
  addBreadcrumb?: boolean;
  component: React.ComponentType<unknown> | null;
  exact: boolean;
  initials?: string;
  label: string;
  linkOut?: string;
  mainLayout?: boolean;
  menuIcon: string | JSX.Element;
  menuItem?: boolean;
  path: string;
  subRoutes?: Route[];
}
const routesOpenApi: Route[] = [
  {
    path: "/",
    component: () => null,
    exact: true,
    label: translations.pages.home.label,
    menuItem: false,
    mainLayout: false,
    menuIcon: <img src={process.env.PUBLIC_URL + "/LogoSmall.png"} />,
  },
  {
    path: "/bewerbung",
    component: Home,
    exact: true,
    label: translations.pages.home.label,
    menuItem: true,
    mainLayout: false,
    menuIcon: <img src={process.env.PUBLIC_URL + "/LogoSmall.png"} />,
  },
  {
    path: "/veranstaltungen/:veranstaltungId",
    component: Veranstaltungen,
    exact: true,
    label: translations.pages.home.label,
    menuItem: true,
    mainLayout: false,
    menuIcon: <img src={process.env.PUBLIC_URL + "/LogoSmall.png"} />,
  },
  {
    path: "/infoveranstaltung/",
    component: InfoVeranstaltung,
    exact: true,
    label: translations.pages.home.label,
    menuItem: true,
    mainLayout: false,
    menuIcon: <img src={process.env.PUBLIC_URL + "/LogoSmall.png"} />,
  },
  {
    path: "/infoveranstaltung/:veranstaltungId",
    component: InfoVeranstaltung,
    exact: true,
    label: translations.pages.home.label,
    menuItem: true,
    mainLayout: false,
    menuIcon: <img src={process.env.PUBLIC_URL + "/LogoSmall.png"} />,
  },
  {
    path: "/veranstaltung/:veranstaltungId",
    component: Veranstaltung,
    exact: true,
    label: translations.pages.home.label,
    menuItem: true,
    mainLayout: false,
    menuIcon: <img src={process.env.PUBLIC_URL + "/LogoSmall.png"} />,
  },
];

const routes: Route[] = [
  {
    path: "/",
    component: () => <Development label={translations.pages.home.label} />,
    exact: true,
    label: translations.pages.home.label,
    menuItem: true,
    mainLayout: true,

    menuIcon: (
      <Avatar backgroundColor={colors.tBlack} color={colors.tWhite} initials={translations.pages.home.initials} />
    ),
  },
  {
    path: "/ciandoAnmeldung",
    component: CiandoRedirect,
    exact: true,
    label: translations.pages.home.label,
    menuItem: false,
    mainLayout: false,
    menuIcon: (
      <Avatar backgroundColor={colors.tBlack} color={colors.tWhite} initials={translations.pages.home.initials} />
    ),
  },

  {
    path: "/anmeldung",
    component: () => <Development label={translations.pages.anmeldung.label} />,
    exact: true,
    label: translations.pages.anmeldung.label,
    menuItem: true,
    mainLayout: true,
    menuIcon: <PeopleOutlineIcon />,
    subRoutes: [
      {
        path: "/onlineAnmeldungen",
        component: BewerberList,
        exact: true,
        label: translations.pages.anmeldung.onlineAnmeldungen.label,
        menuItem: true,
        mainLayout: true,
        menuIcon: <GroupAddIcon />,
        subRoutes: [
          {
            path: "/addAnmeldung",
            component: AnmeldungAdd,
            exact: true,
            label: `${translations.globals.button.add} ${translations.pages.anmeldung.anmeldung}`,
            menuIcon: "",
            mainLayout: true,
          },
        ],
      },
      {
        path: "/bewerberEdit/:bewerberId",
        component: BewerberUpdate,
        exact: true,
        label: translations.pages.anmeldung.editBewerber.label,
        menuItem: false,
        mainLayout: true,
        menuIcon: <GroupAddIcon />,
      },
    ],
  },
  {
    path: "/teilnehmer",
    component: () => <Development label={translations.pages.produkte.label} />,
    exact: true,
    label: translations.pages.teilnehmer.label,
    menuItem: true,
    mainLayout: true,
    menuIcon: <SupervisedUserCircleIcon />,
    subRoutes: [
      {
        path: "/teilnehmerList",
        component: TeilnehmerList,
        exact: true,
        label: translations.pages.teilnehmer.teilnehmerList.label,
        menuItem: true,
        mainLayout: true,
        menuIcon: <SupervisedUserCircleIcon />,
      },
      {
        path: "/abbrecherlist",
        component: AbbrecherList,
        exact: true,
        label: translations.pages.teilnehmer.abbrecherList.label,
        menuItem: true,
        mainLayout: true,
        menuIcon: <SupervisedUserCircleIcon />,
      },
      {
        path: "/zertifikatsTeilnehmer",
        component: ZertifikatsTeilnehmerListe,
        exact: true,
        label: translations.pages.teilnehmer.zertifikatsTeilnehmerList.label,
        menuItem: true,
        mainLayout: true,
        menuIcon: <SupervisedUserCircleIcon />,
      },
      {
        path: "/zertifikatsTeilnehmerAbbrecherList",
        component: ZertifikatsTeilnehmerAbgebrochenListe,
        exact: true,
        label: translations.pages.teilnehmer.zertifikatsTeilnehmerAbbrecherList.label,
        menuItem: true,
        mainLayout: true,
        menuIcon: <SupervisedUserCircleIcon />,
      },
      {
        path: "/addStudent",
        component: AddStudent,
        exact: true,
        label: `${translations.globals.button.add} ${translations.pages.teilnehmer.student}`,
        menuIcon: "",
        mainLayout: true,
      },
      {
        path: "/addTeilnehmer",
        component: AddStudent,
        exact: true,
        label: `${translations.globals.button.add} ${translations.pages.teilnehmer.zertifikatsTeilnehmer}`,
        menuIcon: "",
        mainLayout: true,
      },
      {
        path: "/teilnehmerEdit/:teilnehmerId",
        component: TeilnehmerEdit,
        exact: true,
        label: translations.pages.teilnehmer.editTeilnehmer.label,
        menuItem: false,
        mainLayout: true,
        menuIcon: <SupervisedUserCircleIcon />,
      },
      {
        path: "/bewerberEdit/:bewerberId",
        component: BewerberEdit,
        exact: true,
        label: translations.pages.teilnehmer.bewerberEdit.label,
        menuItem: false,
        mainLayout: true,
        menuIcon: <SupervisedUserCircleIcon />,
      },
      {
        path: "/zertifikatsTeilnehmerEdit/:teilnehmerId",
        component: TeilnehmerEdit,
        exact: true,
        label: translations.pages.teilnehmer.editZertifikatsTeilnehmer.label,
        menuItem: false,
        mainLayout: true,
        menuIcon: <SupervisedUserCircleIcon />,
      },
      {
        path: "/studiengang/:matchStudiengangId/:studId",
        component: MatchStudiengangStudentUpdate,
        exact: true,
        label: "Studiengang",
        menuItem: false,
        mainLayout: true,
        menuIcon: <SupervisedUserCircleIcon />,
      },
      {
        path: "/zertifikatsStudiengang/:matchStudiengangId/:studId",
        component: MatchStudiengangStudentUpdate,
        exact: true,
        label: "Studiengang",
        menuItem: false,
        mainLayout: true,
        menuIcon: <SupervisedUserCircleIcon />,
      },
    ],
  },
  {
    path: "/produkte",
    component: () => <Development label={translations.pages.produkte.label} />,
    exact: true,
    label: translations.pages.produkte.label,
    menuItem: true,
    mainLayout: true,
    menuIcon: <ShoppingCartIcon />,
    subRoutes: [
      {
        path: "/studium",
        component: Studium,
        exact: true,
        label: translations.pages.produkte.studium.labelPlural,
        menuItem: true,
        mainLayout: true,
        menuIcon: <GroupAddIcon />,
        subRoutes: [
          {
            path: "/addstudium",
            component: StudiumAdd,
            exact: true,
            label: `${translations.globals.button.add} ${translations.pages.produkte.studium.label}`,
            menuIcon: "",
            mainLayout: true,
          },
        ],
      },

      {
        path: "/studiengangEdit/:studiengangId",
        component: StudiengangEdit,
        exact: true,
        label: "Studiengang bearbeiten",
        menuItem: false,
        mainLayout: true,
        menuIcon: <SupervisedUserCircleIcon />,
      },
      {
        path: "/zertifikatEdit/:studiengangId",
        component: StudiengangEdit,
        exact: true,
        label: "Zertifikat bearbeiten",
        menuItem: false,
        mainLayout: true,
        menuIcon: <SupervisedUserCircleIcon />,
      },
      {
        path: "/module",
        component: ModuleList,
        exact: true,
        label: translations.pages.produkte.module.labelPlural,
        menuItem: true,
        mainLayout: true,
        menuIcon: <GroupAddIcon />,
        subRoutes: [
          {
            path: "/addModule",
            component: ModuleAdd,
            exact: true,
            label: `${translations.globals.button.add} ${translations.pages.produkte.module.label}`,
            menuIcon: "",
            mainLayout: true,
          },
        ],
      },
      {
        path: "/moduleEdit/:moduleId",
        component: ModuleEdit,
        exact: true,
        label: `${translations.pages.produkte.module.labelPlural} bearbeiten`,
        menuItem: false,
        mainLayout: true,
        menuIcon: <SupervisedUserCircleIcon />,
      },
      {
        path: "/zertifikatsList",
        component: ZertifikatsList,
        exact: true,
        label: translations.pages.produkte.zertifikat.labelPlural,
        menuItem: true,
        mainLayout: true,
        menuIcon: <GroupAddIcon />,
        subRoutes: [
          {
            path: "/addstudium",
            component: StudiumAdd,
            exact: true,
            label: `${translations.globals.button.add} ${translations.pages.produkte.studium.label}`,
            menuIcon: "",
            mainLayout: true,
          },
        ],
      },
      {
        path: "/zertifikatsModule",
        component: ZertifikatsModule,
        exact: true,
        label: translations.pages.produkte.zertifikatsModule.labelPlural,
        menuItem: true,
        mainLayout: true,
        menuIcon: <GroupAddIcon />,
        subRoutes: [
          {
            path: "/addModule",
            component: ModuleAdd,
            exact: true,
            label: `${translations.globals.button.add} ${translations.pages.produkte.zertifikatsModule.label}`,
            menuIcon: "",
            mainLayout: true,
          },
        ],
      },
      {
        path: "/zertifikatsModuleEdit/:moduleId",
        component: ModuleEdit,
        exact: true,
        label: `${translations.pages.produkte.zertifikatsModule.labelPlural} bearbeiten`,
        menuItem: false,
        mainLayout: true,
        menuIcon: <SupervisedUserCircleIcon />,
      },
    ],
  },
  {
    path: "/mitarbeiter",
    component: () => <Development label={translations.pages.mitarbeiter.label} />,
    exact: true,
    label: translations.pages.mitarbeiter.label,
    menuItem: true,
    mainLayout: true,
    menuIcon: <HowToRegIcon />,
    subRoutes: [
      {
        path: "/stammdaten",
        component: () => <Development label={translations.pages.mitarbeiter.stammdaten.label} />,
        exact: true,
        label: translations.pages.mitarbeiter.stammdaten.label,
        menuItem: true,
        mainLayout: true,
        menuIcon: <GroupAddIcon />,
      },
    ],
  },
  {
    path: "/lehre",
    component: () => <Development label={translations.pages.lehre.label} />,
    exact: true,
    label: translations.pages.lehre.label,
    menuItem: true,
    mainLayout: true,
    menuIcon: <LaptopWindowsIcon />,
    subRoutes: [
      {
        path: "/laufendeModule",
        component: CurrentModules,
        exact: true,
        label: "Laufende Module",
        menuItem: true,
        mainLayout: true,
        menuIcon: <GroupAddIcon />,
      },
      {
        path: "/geplanteModule",
        component: FutureModules,
        exact: true,
        label: "Geplante Module",
        menuItem: true,
        mainLayout: true,
        menuIcon: <GroupAddIcon />,
      },
      {
        path: "/abgeschlosseneModule",
        component: PastModules,
        exact: true,
        label: "Abgeschlossene Module",
        menuItem: true,
        mainLayout: true,
        menuIcon: <GroupAddIcon />,
      },
      {
        path: "/alleModule",
        component: AllModules,
        exact: true,
        label: "Alle Module",
        menuItem: true,
        mainLayout: true,
        menuIcon: <GroupAddIcon />,
      },
      {
        path: "/moodleModulEdit/:moodleModulId",
        component: MoodleModuleEdit,
        exact: true,
        label: "Moodle Modul bearbeiten",
        menuItem: false,
        mainLayout: true,
        menuIcon: <GroupAddIcon />,
      },
      {
        path: "/addMoodleModule",
        component: MoodleModuleAdd,
        exact: true,
        label: "Moodle Modul planen",
        menuItem: false,
        mainLayout: true,
        menuIcon: <GroupAddIcon />,
      },
      {
        path: "/pruefungen",
        component: Pruefungsliste,
        exact: true,
        label: "Prüfungsübersicht",
        menuItem: true,
        mainLayout: true,
        menuIcon: <GroupAddIcon />,
      },
      {
        path: "/pruefungen/pruefungsergebnisse/:pruefungId",
        component: PruefungsergebnisseOnPruefung,
        exact: false,
        label: "Prüfungsergebnisse",
        menuItem: false,
        mainLayout: true,
        menuIcon: <GroupAddIcon />,
      },
      {
        path: "/portal",
        component: Portal,
        exact: true,
        label: translations.pages.lehre.portal.label,
        menuItem: true,
        mainLayout: true,
        menuIcon: <GroupAddIcon />,
        // linkOut: "https://portal.kolping-hochschule.de/",
      },
    ],
  },

  {
    path: "/buchhaltung",
    component: () => <Development label={translations.pages.buchhaltung.label} />,
    exact: true,
    label: translations.pages.buchhaltung.label,
    menuItem: true,
    mainLayout: true,
    menuIcon: <AccountBalanceIcon />,
  },
  {
    path: "/reporting",
    component: () => <Development label={translations.pages.reporting.label} />,
    exact: true,
    label: translations.pages.reporting.label,
    menuItem: true,
    mainLayout: true,
    menuIcon: <BarChartIcon />,
  },
  {
    path: "/feedback",
    component: null,
    exact: true,
    label: translations.pages.feedback.label,
    initials: translations.pages.feedback.placeholder.initials,
    menuItem: false,
    mainLayout: true,
    menuIcon: "",
  },
];
export const routesStudent: Route[] = [
  {
    path: "/",
    component: () => <Development label={translations.pages.home.label} />,
    exact: true,
    label: translations.pages.home.label,
    menuItem: true,
    mainLayout: true,

    menuIcon: (
      <Avatar backgroundColor={colors.tBlack} color={colors.tWhite} initials={translations.pages.home.initials} />
    ),
  },
  {
    path: "/ciandoAnmeldung",
    component: CiandoRedirect,
    exact: true,
    label: translations.pages.home.label,
    menuItem: false,
    mainLayout: false,
    menuIcon: "",
  },
  {
    path: "/myStudent",
    component: MyStudent,
    exact: true,
    label: translations.pages.teilnehmer.myStudent,
    menuItem: true,
    mainLayout: true,
    menuIcon: <PersonIcon />,
  },
];

if (process.env.NODE_ENV === "development") {
  const configurator = {
    path: "/configurator",
    component: Configurator,
    exact: true,
    label: translations.pages.configurator.label,
    menuItem: true,
    mainLayout: false,
    menuIcon: GearWheelIcon({}),
  };
  routesOpenApi.push(configurator);
  routes.push(configurator);
  routesStudent.push(configurator);
}

// const getScopedRoutes = (isVerwaltung: boolean) => {
//   const defaulRoutes = process.env.REACT_APP_USE_API === "CampusManagement" ? routes : routesOpenApi;
//   return isVerwaltung ? defaulRoutes : routesStudent;
// };

// export default getScopedRoutes;

export default process.env.REACT_APP_USE_API === "CampusManagement" ? routes : routesOpenApi;
// export default process.env.REACT_APP_USE_OPEN_API ? routesOpenApi : routes;
