export default {
  name: "Zuordnungsattribut Assetart-Attributsatz",
  plural: "Zuordnungsattribut Assetart-Attributsätze",
  columns: {
    assetAttribute: "Asset-Attribut",
    assetAttributeId: "Asset-Attribut ID",
    assetTypeAttributeSet: "Asset-Typ-Attributsatz",
    assetTypeAttributeSetId: "Asset-Typ-Attributsatz ID",
    id: "ID",
    isDeleted: "ist gelöscht",
    tenantId: "Mieter-ID",
  },
};
