import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import Props from "./index.d";
import useGetStudentStatusFromSemesterStatus from "lib/hooks/useGetStudentStatusFromSemesterStatus";

import DefaultInput from "../../../atomics/DefaultInput";

interface TextFieldComponentProps {
  studentId: number;
  title: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: "100%",
    },
  })
);

const TextFieldComponent: React.FC<TextFieldComponentProps> = ({ studentId, title }) => {
  const classes = useStyles();
  const status = useGetStudentStatusFromSemesterStatus(studentId);
  return <DefaultInput className={classes.input} value={status} disabled={true} label={title} defaultValue="" />;
};

const StudiumStatus: React.FC<Props> = (props) => {
  const {
    data: { state, setAndSave },
    title,
    disabled,
    submit,
    readonly,
    //@ts-ignore
    update,
  } = props;

  const classes = useStyles(props);
  useEffect(() => {
    if (submit?.some(({ state }) => state)) {
    }
  }, [submit]);
  if (!state) return null;
  return <TextFieldComponent title={title} studentId={state} />;
};
export default StudiumStatus;
