import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: { iconSize?: string | number; width?: string | number }) => ({
      width: props.width ? props.width : "100%",
      "& .Mui-disabled": {
        opacity: 0.5,
        cursor: "not-allowed",
        backgroundColor: theme.colors.tWhite,
        borderColor: theme.colors.lbcGrey,
        color: theme.colors.grey400,
        "& .MuiChip-deletable": {
          cursor: "not-allowed",
          backgroundColor: theme.colors.tWhite,
          color: theme.colors.grey400,
          border: `1px solid ${theme.colors.lbcGrey}`,
          "& svg": {
            color: theme.colors.grey400,
          },
        },
        "& svg": {
          color: theme.colors.grey400,
          cursor: "not-allowed",
        },
      },
      "& .MuiFormLabel-root": {
        transform: "none",
        position: "relative",
        color: theme.colors.grey500,
        display: "block",
        margin: "0.2em 0.5em 0.2em 0.2em",
      },
      "& .MuiInputBase-root": {
        "& .MuiSelect-icon": {
          top: "unset",
          width: props?.iconSize ?? "2em",
          height: props?.iconSize ?? "2em",
          color: theme.colors.tBlack,
          right: "0.25em",
          "&.Mui-disabled": {
            color: theme.colors.grey400,
          },
        },
      },
      "& .MuiChip-deleteIcon": {
        display: "flex",
        width: "fit-content",
        height: "fit-content",
        margin: 0,
        "& svg": {
          top: "unset",
          width: "1.2em",
          height: "1.2em",
          color: theme.colors.tWhite,
          marginRight: "0.2em",
        },
      },
    }),
  })
);
