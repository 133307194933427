import * as React from "react";
import { makeStyles, createStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Props from "./index.d";
import DefaultInput from "components/atomics/DefaultInput";
import tableColors from "components/templates/table-factory/theme/tableColors";

export type EmailWithConfirmationComponentProps = Props;
const useStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    input: {
      width: "100%",
    },
  })
);

const EmailWithConfirmationComponent: React.FC<Props> = (props) => {
  const {
    title,
    data: { state, setstate, onBlur, updateValue },
    placeholder = "placeholder",
    errors,
    ...rest
  } = props;
  const classes = useStyles(props);

  return (
    <DefaultInput
      className={classes.input}
      onBlur={onBlur}
      value={state || ""}
      onChange={updateValue}
      disabled={props.disabled}
      defaultValue=""
      error={!!errors?.length}
      label={title}
      helperText={errors?.concat(" ") ?? undefined}
      // {...rest}
    />
  );
};

export default EmailWithConfirmationComponent;
