import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "components/lbc-toolkit/atomics/Button/DefaultButton";
import React, { useEffect } from "react";
import translations from "translations";
import { useGetModulsPrufungsTermineLazyQuery } from "graphqlBase/ModulsPrufungsTermine/__generated__/getModulsPrufungsTermine";
import { MoodleModulFilterInput } from "graphqlBase/types";
interface MoodleModulImportButtonProps {
  where?: MoodleModulFilterInput;
}
const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    addButton: {
      fontSize: 14,
      color: theme.colors.tWhite,
      backgroundColor: theme.colors.sunYellow,
      height: 40,
      borderRadius: "unset",
      marginRight: 60,
      "&:hover": { color: theme.colors.tWhite, backgroundColor: theme.colors.sunYellow },
    },
  })
);

const EVENTBEGIN = `BEGIN:VEVENT`;
const EVENTEND = `END:VEVENT`;

const makeDateString = (dateIn: string) => `${new Date(`${dateIn}`).toISOString()}`;
const makeEventStringInner = ({
  terminEvents,
  von,
  bis,
  summary,
}: {
  von: string;
  bis: string;
  terminEvents: string;
  summary: string;
}) =>
  `${terminEvents}\n${EVENTBEGIN}\nDTSTART;VALUE=DATE:${makeDateString(von)}\nDTEND;VALUE=DATE:${makeDateString(
    bis
  )}\nSUMMARY:${summary}\n${EVENTEND}`;

const MoodleModulImportButton: React.FC<MoodleModulImportButtonProps> = ({ where }) => {
  const classes = useStyles();
  const [query, { data, refetch }] = useGetModulsPrufungsTermineLazyQuery({
    fetchPolicy: "cache-and-network",
    variables: { where },
  });
  function newFunction(calendar: string) {
    const blob = new Blob([calendar], { type: "text/calendar;charset=utf-8" });
    const calendarName= (data?.moodleModuls ?? []).map((item)=> `${item.modul?.modulName}${item.zeitraum?.zeitraumName}`)
    const fileName: string = `${calendarName}.ics`
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();        
  }
  const saveCallInvite = () => {
    const DataModul = (data?.moodleModuls ?? []).reduce<string>((eventsString, item) => {
      const summary = `${item.modul?.modulName}  - ${item.zeitraum?.zeitraumName}`;
      const terminEventsString = item?.modultermins.reduce((terminEvents, termin) => {
        if (!termin.datumBis || !termin.datumVon) return terminEvents;
        return makeEventStringInner({ terminEvents, summary, von: termin.datumVon, bis: termin.datumBis });
      }, eventsString);
      return `${terminEventsString}`;
    }, "");

    const DataPruefung = (data?.moodleModuls ?? []).reduce<string>((eventsString, item) => {
      const summary = `Prüfungstermin: ${item.modul?.modulName}  - ${item.zeitraum?.zeitraumName} - ${item?.modulVerantwortlicher?.vorname} ${item?.modulVerantwortlicher?.nachname}`;
      const PruefungEventsString = item?.pruefungs.reduce((PruefungEvents, termin) => {
        if (!termin.datumUhrzeitVon || !termin.datumUhrzeitBis) return PruefungEvents;
        return makeEventStringInner({
          terminEvents: PruefungEvents,
          summary,
          von: termin.datumUhrzeitVon,
          bis: termin.datumUhrzeitBis,
        });
      }, eventsString);
      return `${PruefungEventsString}`;
    }, "");

    const calendar = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:icalendar-ruby
CALSCALE:GREGORIAN
METHOD:PUBLISH
${DataModul}
${DataPruefung}
END:VCALENDAR`;

    newFunction(calendar);
  };

  function handleClick() {
    query();
  }
  useEffect(() => {
    if (!!data) saveCallInvite();
  }, [data]);
  return (
    <>
      <Button
        label={`${"Termine"} ${translations.globals.button.download}`}
        buttonType="primary"
        onClick={handleClick}
        classes={{ colorPrimary: classes.addButton }}
      />
    </>
  );
};
export default MoodleModulImportButton;