export default {
  name: "Gerätemodell",
  plural: "Gerätemodelle",
  columns: {
    device: "Gerät",
    deviceModelAttributeSet: "Gerätemodellattributsatz",
    deviceModelName: "Gerät Modellname",
    deviceModelVersion: "Gerätemodellversion",
    fieldDeviceIdFieldSelector: "Feld Geräte-ID Feldauswahl",
    id: "ID",
    isDeleted: "ist gelöscht",
    tenantId: "Mieter ID",
  },
};
