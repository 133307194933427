/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/naming-convention */
import { gql, useQuery } from "@apollo/client";
import { createStyles, makeStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";
import fieldState from "@ml-pa/raft/buildForm/fieldState";
import DefaultInput from "components/atomics/DefaultInput";
import Select, { SelectProps } from "components/atomics/Select";
import { useGetPruefungsergebniseQuery } from "graphqlBase/Pruefungsergebnis/__generated__/getPruefungsergebnise";
import React, { useEffect, useMemo } from "react";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import {
  pruefungsErgebnisse,
  PruefungsergebniForRow,
} from "components/PagesCampusManagement/lehre/Pruefungen/pruefungsergebnisseState";
import calcNoteFromPunkte from "./calcNoteFromPunkte";
const useStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    input: {
      width: "100%",
    },
    foreignKeySelectorIntBox: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  })
);

export interface FKUuidField {
  foreignKeyParams: { entityName: string; column: string[] };
}
export const isChangedState = atom({
  key: "isChangedState",
  default: false,
});
export interface Data<T> {
  onBlur: () => void;
  setAndSave: (data: T) => void;
  setstate: (data: T) => void;
  setNull?: () => void;
  state: T;
  updateValue: (event: React.ChangeEvent<any>) => void;
}

export const useMakeInputCell = (columName: keyof PruefungsergebniForRow) => ({
  row: { original },
}: {
  row: { original: PruefungsergebniForRow };
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [value, set] = useRecoilState(pruefungsErgebnisse(original));
  useEffect(() => {
    set(original);
  }, [original]);
  const setInner = (v: PruefungsergebniForRow) => {
    set((currentRow) => ({ ...currentRow, ...v }));
  };
  const data = fieldState(original[columName], columName, setInner) as Data<string>;

  return TextField({ data });
};
export const useMakeDecimalFieldInputCell = (columName: keyof PruefungsergebniForRow, disabled?: boolean) => ({
  row: { original },
}: {
  row: { original: PruefungsergebniForRow };
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [value, set] = useRecoilState(pruefungsErgebnisse(original));
  useEffect(() => {
    set(original);
  }, [original]);
  const setInner = (v: PruefungsergebniForRow) => {
    set((currentRow) => ({ ...currentRow, [columName]: +(v[columName] ?? "0") }));
  };
  const data = fieldState(original[columName], columName, setInner) as Data<number>;

  return DecimalField({ data, disabled });
};
export const useMakePunkteCell = (columName: keyof PruefungsergebniForRow) => ({
  row: { original },
}: {
  row: { original: PruefungsergebniForRow };
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [value, set] = useRecoilState(pruefungsErgebnisse(original));
  useEffect(() => {
    set(original);
  }, [original]);
  const { data: dataPre } = useGetPruefungsergebniseQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      where: {
        pruefungId: { eq: value.pruefung?.id ?? 0 },
      },
    },
  });
  const setInner = (v: PruefungsergebniForRow) => {
    if (v[columName] ?? "empty" !== "empty") {
      const max = dataPre?.pruefungsergebnis.map((ergebniss) => ergebniss.pruefung?.maxPunktzahl) ?? [0];
      const maxPunkte = max[0] ?? 0;
      const punkte = +(v[columName] ?? "0");
      const note = calcNoteFromPunkte({ punkte, maxPunkte });
      set((currentRow) => ({ ...currentRow, punkte, note }));
    } else {
      set(({ note, ...currentRow }) => ({ ...currentRow, note: 0 }));
      set(({ punkte, ...currentRow }) => ({ ...currentRow, punkte: 0 }));
    }
  };

  const data = fieldState(original[columName], columName, setInner) as Data<number>;
  return DecimalField({ data });
};
export const useMakeNoteCell = () => ({ row: { original } }: { row: { original: PruefungsergebniForRow } }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [value, set] = useRecoilState(pruefungsErgebnisse(original));
  const classes = useStyles({});

  return (
    <DefaultInput
      className={classes.input}
      inputProps={{ style: { fontSize: "16px" } }}
      value={value.note ? value.note.toFixed(1) : ""}
      disabled
    />
  );
};

export const useMakeForeignKeySelctor = (columName: keyof PruefungsergebniForRow, disabled?: boolean) => ({
  row: { original },
}: {
  row: { original: PruefungsergebniForRow };
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [value, set] = useRecoilState(pruefungsErgebnisse(original));
  useEffect(() => {
    set(original);
  }, [original]);

  const setInner = (v: PruefungsergebniForRow) => {
    set((currentRow) => ({ ...currentRow, ...v }));
  };
  const data = fieldState(original[columName], columName, setInner) as Data<number>;

  const foreignKeyParams = { entityName: "pruefungStatuses", column: ["pruefungStatusName"] };
  return ForeignKeySelectorInt({ data, pickDefaultFirst: false, foreignKeyParams, disabled });
};

const TextField: React.FC<{
  data: Data<string>;
}> = (props) => {
  const {
    data: { state, setstate, onBlur, updateValue },
  } = props;
  const classes = useStyles({});
  const setIsChanged = useSetRecoilState(isChangedState);

  useEffect(() => {
    if (state !== undefined && state !== "") {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [setIsChanged, state]);
  return (
    <DefaultInput
      className={classes.input}
      inputProps={{ style: { fontSize: "16px" } }}
      onBlur={onBlur}
      value={state || ""}
      onChange={updateValue}
      defaultValue=""
    />
  );
};
const DecimalField: React.FC<{
  data: Data<number>;
  disabled?: boolean;
}> = (props) => {
  const {
    data: { state, setstate, onBlur, setNull, setAndSave },
    disabled,
  } = props;
  const classes = useStyles({});
  const setIsChanged = useSetRecoilState(isChangedState);
  useEffect(() => {
    if (state !== null && state !== 0) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [setIsChanged, state]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = event.target.value
      .replace(",", ".")
      .split(".")
      .map((v, i) => {
        if (v === "" || v === "undefined") return "";
        const num = v !== "" ? v.slice(0, i ? 5 : 15) : "0";
        if (isNaN(+num)) return "0";
        return num;
      })
      .slice(0, 2)
      .join(".");

    if (value.length) {
      setstate((value as unknown) as number);
    } else {
      if (setNull) {
        setNull();
      } else {
        setstate((value as unknown) as number);
      }
    }
  };
  const onBlurInner = () => {
    if (!state) {
      if (setNull) {
        setNull();
      } else {
        setAndSave(0);
      }
    } else {
      onBlur();
    }
  };
  return (
    <DefaultInput
      className={classes.input}
      inputProps={{ style: { fontSize: "16px" } }}
      onBlur={onBlurInner}
      value={state || null}
      onChange={handleChange}
      disabled={disabled}
      defaultValue=""
    />
  );
};

interface ForeignKeySelectorIntProps {
  foreignKeyParams: FKUuidField["foreignKeyParams"];
  data: Data<number>;
  pickDefaultFirst: boolean;
  disabled?: boolean;
}

const ForeignKeySelectorInt: React.FC<ForeignKeySelectorIntProps> = (props) => {
  const {
    data: { state, setAndSave },
    pickDefaultFirst,
    disabled,
  } = props;
  const elems = useGetForeignKeyList(props);
  useEffect(() => {
    if (pickDefaultFirst && !state && !!elems && elems.length) setAndSave(elems[0].value);
  }, [elems, state, pickDefaultFirst]);

  const setIsChanged = useSetRecoilState(isChangedState);
  useEffect(() => {
    if (state !== 1) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [setIsChanged, state]);

  const classes = useStyles(props);
  return (
    <div className={classes.foreignKeySelectorIntBox}>
      <Select
        setState={(value) => setAndSave(+value)}
        state={state}
        items={elems}
        noFormControlMarginTop
        disabled={disabled}
        error={false}
        fontSize="16px"
      />
    </div>
  );
};
function useGetForeignKeyList({ foreignKeyParams }: ForeignKeySelectorIntProps): { value: number; label: string }[] {
  const query = useMemo(() => {
    return gql`query get${foreignKeyParams.entityName} {
          elems:${foreignKeyParams.entityName}{
            value:id
            ${foreignKeyParams.column.map((column, index) => `label${index}:${column}`)}
            
          }
        }`;
  }, [foreignKeyParams]);
  const { data } = useQuery(query);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  return (data?.elems ?? []).map(({ value, __typename, ...rest }: { value: number; __typename: string }) => ({
    value,
    label: Object.values(rest).join(" "),
  }));
}
