import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetMatchModulPruefungTypsQueryVariables = Types.Exact<{
  filterInput?: Types.Maybe<Types.MatchModulPruefungTypFilterInput>;
}>;


export type GetMatchModulPruefungTypsQuery = { matchModulPruefungTyps: Array<{ __typename?: 'MatchModulPruefungTyp', id: number, pruefungTypId?: Types.Maybe<number>, dauer?: Types.Maybe<number>, anteil?: Types.Maybe<number>, modulId?: Types.Maybe<number> }> };


export const GetMatchModulPruefungTypsDocument = gql`
    query getMatchModulPruefungTyps($filterInput: MatchModulPruefungTypFilterInput) {
  matchModulPruefungTyps(where: $filterInput) {
    id
    pruefungTypId
    dauer
    anteil
    modulId
  }
}
    `;

/**
 * __useGetMatchModulPruefungTypsQuery__
 *
 * To run a query within a React component, call `useGetMatchModulPruefungTypsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMatchModulPruefungTypsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMatchModulPruefungTypsQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *   },
 * });
 */
export function useGetMatchModulPruefungTypsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMatchModulPruefungTypsQuery, GetMatchModulPruefungTypsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMatchModulPruefungTypsQuery, GetMatchModulPruefungTypsQueryVariables>(GetMatchModulPruefungTypsDocument, baseOptions);
      }
export function useGetMatchModulPruefungTypsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMatchModulPruefungTypsQuery, GetMatchModulPruefungTypsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMatchModulPruefungTypsQuery, GetMatchModulPruefungTypsQueryVariables>(GetMatchModulPruefungTypsDocument, baseOptions);
        }
export type GetMatchModulPruefungTypsQueryHookResult = ReturnType<typeof useGetMatchModulPruefungTypsQuery>;
export type GetMatchModulPruefungTypsLazyQueryHookResult = ReturnType<typeof useGetMatchModulPruefungTypsLazyQuery>;
export type GetMatchModulPruefungTypsQueryResult = ApolloReactCommon.QueryResult<GetMatchModulPruefungTypsQuery, GetMatchModulPruefungTypsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
