export default {
  name: "Asset-Bild",
  plural: "Asset-Bilder",
  columns: {
    asset: "Asset",
    assetId: "Asset ID",
    id: "ID",
    isDeleted: "ist gelöscht",
    pictureBlobStorageUrl: "Bild Blob Storage Url",
    tenantId: "Mieter ID",
    thumbnailBlobStorageUrl: "thumbnail Blob Storage Url",
  },
};
