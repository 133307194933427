import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import Props from "./index.d";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    readonly: {
      height: "100%",
      display: "flex",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
      pointerEvents: "none",
      cursor: " not-allowed! important",
    },
    child: {
      flexGrow: 1,
      width: "0%",
      "&:not(:first-child)": {
        margin: "0 0 0 3em",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
        "&:not(:first-child)": {
          margin: "3em 0 0 0",
        },
      },
    },
  })
);
interface GFCFieldSet_ResponsiveColumns extends Props {
  children: React.ReactNode;
}

const FieldSetFixedFooter: React.FC<GFCFieldSet_ResponsiveColumns> = (props) => {
  const { children } = props;
  const classes = useStyles();
  //  @ts-ignore
  if (props.hidden) return null;
  const childrenArray = React.Children.map(children, (child) => child);
  if (props.readonly) {
    return (
      <div className={classes.readonly}>
        {childrenArray?.map((child, i) => (
          <div className={classes.child} key={i}>
            {child}
          </div>
        ))}
      </div>
    );
  }
  return (
    <div className={classes.root}>
      {childrenArray?.map((child, i) => (
        <div className={classes.child} key={i}>
          {child}
        </div>
      ))}
    </div>
  );
};
export default FieldSetFixedFooter;
