export default {
  name: "Asset Attribut",
  plural: "Asset Attribute",
  columns: {
    assetAttributeValue: "Asset-Attribut Wert",
    attributeDataType: "Attribut Datentyp",
    attributeName: "Attribut Name",
    id: "ID",
    isDeleted: "ist gelöscht",
    mappingAttributeAssetTypeAttributeSet: "Zuordnungsattribut Asset-Typ Attributsatz",
    required: "erforderlich",
    tenantId: "Mieter ID",
  },
};
