import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { Theme } from "@material-ui/core";
import Props from "./index.d";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: "white",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        position: "fixed",
        bottom: "0",
        borderTop: "1px solid #e6e5e3",
        width: "100vw",
        zIndex: 1,
        minHeight: "4em",
        alignItems: "center",
        "& :last-child": {
          marginRight: 0,
        },
        "& :first-child:": {
          marginLeft: 0,
        },
      },
      child: {
        marginRight: "5px",
        marginLeft: "5px",
      },
    }),
  { name: "FixedFooterView" }
);
interface GFCFieldSet_FixedFooterView extends Props {
  children: React.ReactNode;
}

const FieldSetFixedFooter: React.FC<GFCFieldSet_FixedFooterView> = (props) => {
  const { children } = props;
  const classes = useStyles();
  //  @ts-ignore
  if (props.hidden) return null;
  const childrenArray = React.Children.map(children, (child) => child);
  return (
    <div className={classes.root}>
      {childrenArray &&
        childrenArray.map((child, index) => (
          <div key={index} className={classes.child}>
            {child}
          </div>
        ))}
    </div>
  );
};
export default FieldSetFixedFooter;
