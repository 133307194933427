import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetModulsQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.ModulFilterInput>;
  take?: Types.Maybe<Types.Scalars['Int']>;
  skip?: Types.Maybe<Types.Scalars['Int']>;
  order?: Types.Maybe<Array<Types.ModulSortInput> | Types.ModulSortInput>;
}>;


export type GetModulsQuery = { moduls: Array<{ __typename?: 'Modul', id: number, modulName: string, ectspunkte?: Types.Maybe<number>, workload?: Types.Maybe<number>, digitalePraesenzstunden?: Types.Maybe<number>, lokalePraesenzstunden?: Types.Maybe<number>, caseDays?: Types.Maybe<number>, digitalGestuetzeLehre?: Types.Maybe<number>, selbststudienzeit?: Types.Maybe<number>, qualifikationsziele?: Types.Maybe<string>, moodleMasterModul?: Types.Maybe<number>, studiengangTyp?: Types.Maybe<{ __typename?: 'StudiengangTyp', id: number, studiengangTypKuerzel?: Types.Maybe<string> }> }> };


export const GetModulsDocument = gql`
    query getModuls($where: ModulFilterInput, $take: Int, $skip: Int, $order: [ModulSortInput!]) {
  moduls(where: $where, take: $take, skip: $skip, order: $order) {
    id
    modulName
    ectspunkte
    workload
    digitalePraesenzstunden
    lokalePraesenzstunden
    caseDays
    digitalGestuetzeLehre
    selbststudienzeit
    qualifikationsziele
    moodleMasterModul
    studiengangTyp {
      id
      studiengangTypKuerzel
    }
  }
}
    `;

/**
 * __useGetModulsQuery__
 *
 * To run a query within a React component, call `useGetModulsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModulsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModulsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetModulsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetModulsQuery, GetModulsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetModulsQuery, GetModulsQueryVariables>(GetModulsDocument, baseOptions);
      }
export function useGetModulsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetModulsQuery, GetModulsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetModulsQuery, GetModulsQueryVariables>(GetModulsDocument, baseOptions);
        }
export type GetModulsQueryHookResult = ReturnType<typeof useGetModulsQuery>;
export type GetModulsLazyQueryHookResult = ReturnType<typeof useGetModulsLazyQuery>;
export type GetModulsQueryResult = ApolloReactCommon.QueryResult<GetModulsQuery, GetModulsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
