/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */

import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, Modal, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import client from "apollo/client";
import { SendApplicationDocument } from "graphqlBase/misc/__generated__/sendApplication";
import CongratulationSVG from "icons/components/Congratulations";
import {
  BewerberCreateForm,
  BewerberCreateOnUpdateDataMiddleWare,
  BewerberCreateOnUpdateDataMiddleWareProps,
} from "raft/BewerberForm";
import { useUploadfileTypeCallback } from "components/formElements/fields/fileUpload/fileState";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import translations from "translations";
import validateForm2 from "./validateForm";
import { useUploadDocumentsMutation } from "graphqlBase/Beweber/__generated__/uploadDocuments";
import { AttachementInput } from "graphqlBase/types";
const localStorageKey = "applicationKo";
const hubSpotApiUri =
  "https://api.hsforms.com/submissions/v3/integration/submit/20233320/48c9af60-8e00-4a4a-887a-9ca03234e05c";

const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      bewerberCreateFormBox: {
        margin: "0 auto",
        padding: "0 1em",
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        // height: "100%",
      },
      modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      },
      progress: {
        top: "6%",
        left: "50%",
        width: "3%",
        color: theme.colors.brandYellow,
        position: "absolute",
      },
      afterCreate: {
        margin: "auto",
        maxWidth: "800px",
        marginTop: "2.5em",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      afterCreateBody: {
        marginTop: "1em",
        color: theme.colors.mineShaftBlack,
        fontSize: "18px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      afterCreateHeadline: { marginBottom: "10px", fontWeight: 700, fontSize: "35px", color: theme.colors.smaltBlue },
      afterCreateSubHeadline: { fontWeight: 400, color: theme.colors.gumboTeal, fontSize: "25px" },
      linkContainer: { display: "flex", marginTop: "1em", color: theme.colors.sunYellow },
      linkTypography: { marginLeft: "15px", color: theme.colors.mineShaftBlack, fontSize: "18px" },
      link: {
        color: theme.colors.mineShaftBlack,
        "&:hover": {
          textDecoration: "underline",
        },
      },
      svgContainer: { width: "12em", height: "12em", marginBottom: "2em" },
      noMargin: { margin: 0 },
    }),

  { name: `Pages-Home` }
);

type Data = BewerberCreateOnUpdateDataMiddleWareProps["data"];

const getPersisted = () => {
  try {
    return JSON.parse(window.window.localStorage.getItem(localStorageKey) ?? '{"step": 0}');
  } catch (e) {
    return { step: 0, loaded: true };
  }
};

const onUpdateDataMiddleWare: BewerberCreateOnUpdateDataMiddleWare = (props) => {
  const data = (!props.data.loaded ? getPersisted() : { loaded: true }) as Data;

  const validated = validateForm({ ...props, data: { ...data, ...props.data } });
  const dataStore = { ...data, ...props.data, ...validated };

  window.localStorage.setItem(localStorageKey, JSON.stringify(dataStore));
  return {
    ...data,
    ...validated,
    loaded: true,
    // stringyfiedData: JSON.stringify({ ...props.data, ...data, ...validated }),
  };
};

const validateForm: BewerberCreateOnUpdateDataMiddleWare = ({ data, changedFields, dataBefore, ...rest }) => {
  const hasStepIncreased = (data.step ?? 0) > (dataBefore.step ?? 0);
  const hasStepDecreased = (data.step ?? 0) < (dataBefore.step ?? 0);

  if (hasStepIncreased || data.submit || data.formHasErrors) {
    const formErrors = validateForm2(data, (hasStepIncreased ? dataBefore.step : data.step) as 0 | 1 | 2 | 3 | 4);
    const hasErrors = !!formErrors && Object.values(formErrors).some((v) => !!v?.length);

    return {
      formErrors: formErrors ?? { nope: [] },
      formHasErrors: hasErrors ? 1 : 0,
      submit: hasErrors ? false : data.submit,
      step: hasStepDecreased
        ? data.step
        : hasErrors
        ? dataBefore.step
        : (dataBefore.step ?? 0) + (hasStepIncreased ? 1 : 0),
      zahlungsdatum: "2021-01-01T00:00:00.001",

      strasse: data.strasse || "",
      hausnummer: data.hausnummer || "",
      plz: data.plz || "",
      wohnort: data.wohnort || "",
      telefonnummer: data.telefonnummer || "",
      newsletterAccepted: data.newsletterAccepted || false,
    };
  }

  return {};
};

const callHubSpotApi = async (
  data: Data & {
    id: number;
  }
) => {
  const body = {
    submittedAt: Date.now(),
    fields: [
      {
        name: "email",
        value: data.email,
      },
    ],
    context: {
      hutk: getHutk(),
      pageUri: "www.kolping-hochschule.de/bewerbung",
      pageName: "bewerbung",
    },
  };

  await fetch(hubSpotApiUri, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
};

const getHutk = () => {
  const name = "hubspotutk=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (const iterator of ca) {
    let c = iterator;
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

const Home: React.FC<{}> = () => {
  const { afterCreate: translation } = translations.pages.bewerbung;
  const classes = useStyles({});
  const [state, setState] = useState<number | undefined>();
  const [docUpload] = useUploadDocumentsMutation();
  const [open, setOpen] = useState(false);
  const getUploadFile = useUploadfileTypeCallback();
  const persistData = async (id: number) => {
    const uploadFilePre = await getUploadFile();
    const documents = uploadFilePre?.uploadFiles.map<AttachementInput>((elem) => ({
      fileContent: elem.fileContent,
      bewerberId: id,
      fileTypeId: elem.uploadFileType,
      fileName: elem.name,
    }));
    setOpen(true);
    docUpload({
      variables: {
        documents: documents,
      },
    })
      .then(() => setOpen(false))
      .catch((e) => setState(id));
  };
  const afterCreate = ({
    id,
    ...rest
  }: Data & {
    id: number;
  }) => {
    setOpen(true);
    setState(id);
    client
      .mutate({ mutation: SendApplicationDocument, variables: { id } })
      .then(async () => {
        return await persistData(id);
      })
      .then(async () => {
        return await callHubSpotApi({ id, ...rest });
      })
      .then((res) => {
        window.localStorage.removeItem(localStorageKey);
      })
      .catch(() => {
        setState(id);
      });
  };

  return (
    <div className={classes.bewerberCreateFormBox}>
      {!state && <BewerberCreateForm onUpdateDataMiddleWare={onUpdateDataMiddleWare} afterCreate={afterCreate} />}
      {!!state && (
        <div className={classes.afterCreate}>
          <Modal className={classes.modal} open={open}>
            <div className={classes.paper}>
              <Typography variant="h2">{translations.pages.bewerbung.afterCreate.processed}</Typography>
              <div className={classes.loader}>
                <CircularProgress className={classes.progress} size="70" />
              </div>
            </div>
          </Modal>
          <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-304155666" />
          </Helmet>
          <Helmet
            script={[
              {
                type: "text/javascript",
                innerHTML: `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);};
     gtag('js', new Date());
      gtag('config', 'AW-304155666');
      gtag('event', 'conversion', {'send_to': 'AW-304155666/0CUDCL-XwZUDEJKYhJEB'});  `,
              },
            ]}
          />
          <div className={classes.svgContainer}>
            <CongratulationSVG />
          </div>
          <Typography className={classes.afterCreateHeadline}>{translation.headline}</Typography>
          <Typography className={classes.afterCreateSubHeadline}>{translation.subHeadline}</Typography>
          <Typography className={classes.afterCreateBody}>
            <p className={classes.noMargin}>{translation.body.part1}</p>
            <p className={classes.noMargin}>{translation.body.part2}</p>
          </Typography>
          <Typography className={classes.afterCreateBody}>{translation.greeting}</Typography>
          <div className={classes.linkContainer}>
            <FontAwesomeIcon size="2x" icon={faEnvelope} />
            <Typography className={classes.linkTypography}>
              <a className={classes.link} href="mailto:studienberatung@kolping-hochschule.de">
                studienberatung@kolping-hochschule.de
              </a>
            </Typography>
          </div>
          <div className={classes.linkContainer}>
            <FontAwesomeIcon size="2x" icon={faPhone} />
            <Typography className={classes.linkTypography}>
              <a className={classes.link} href="tel:+4922171790990">
                +49 221 71790990
              </a>
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
