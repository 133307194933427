import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type MoodleSyncExamEventsMutationVariables = Types.Exact<{
  moodleCourseId: Types.Scalars['Int'];
}>;


export type MoodleSyncExamEventsMutation = { moodleSync?: Types.Maybe<{ __typename?: 'MoodleSyncResult', status: boolean, statusCode?: Types.Maybe<string>, statusMessage?: Types.Maybe<string> }> };


export const MoodleSyncExamEventsDocument = gql`
    mutation moodleSyncExamEvents($moodleCourseId: Int!) {
  moodleSync: moodleSyncExamEvents(moodleCourseId: $moodleCourseId) {
    status
    statusCode
    statusMessage
  }
}
    `;
export type MoodleSyncExamEventsMutationFn = ApolloReactCommon.MutationFunction<MoodleSyncExamEventsMutation, MoodleSyncExamEventsMutationVariables>;

/**
 * __useMoodleSyncExamEventsMutation__
 *
 * To run a mutation, you first call `useMoodleSyncExamEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoodleSyncExamEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moodleSyncExamEventsMutation, { data, loading, error }] = useMoodleSyncExamEventsMutation({
 *   variables: {
 *      moodleCourseId: // value for 'moodleCourseId'
 *   },
 * });
 */
export function useMoodleSyncExamEventsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MoodleSyncExamEventsMutation, MoodleSyncExamEventsMutationVariables>) {
        return ApolloReactHooks.useMutation<MoodleSyncExamEventsMutation, MoodleSyncExamEventsMutationVariables>(MoodleSyncExamEventsDocument, baseOptions);
      }
export type MoodleSyncExamEventsMutationHookResult = ReturnType<typeof useMoodleSyncExamEventsMutation>;
export type MoodleSyncExamEventsMutationResult = ApolloReactCommon.MutationResult<MoodleSyncExamEventsMutation>;
export type MoodleSyncExamEventsMutationOptions = ApolloReactCommon.BaseMutationOptions<MoodleSyncExamEventsMutation, MoodleSyncExamEventsMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
