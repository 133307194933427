export default {
  name: "Geräteattribut",
  plural: "Geräteattribute",
  columns: {
    attributeDataType: "Attribut Datentyp",
    attributeName: "Attributname",
    deviceAttributeValue: "Geräteattributwert",
    id: "ID",
    isDeleted: "ist gelöscht",
    mappingAttributeDeviceModelAttributeSet: "Zuordnungsattribut Gerätemodellattributsatz",
    tenantId: "Mieter ID",
  },
};
