import * as React from "react";
import { makeStyles, createStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";

import Props from "./index.d";

const useStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    NoSideBarWithScroll: {
      width: "100%",
      /* height: 100%; */
      display: "flex",
      /* overflow: hidden; */
      /* position: absolute; */
      flexGrow: 1,
      flexDirection: "column",
      backgroundColor: "#fff",
    },
  })
);

interface GFCFieldSet_NoSideBar extends Props {
  children: React.ReactNode;
}

const FieldNoSideBarWithScroll: React.FC<GFCFieldSet_NoSideBar> = (props) => {
  const classes = useStyles({});
  return <div className={classes.NoSideBarWithScroll}>{props.children}</div>;
};
export default FieldNoSideBarWithScroll;
