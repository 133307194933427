/* eslint-disable no-void */
import React, { useEffect } from "react";
import Button from "components/lbc-toolkit/atomics/Button/DefaultButton";
import translations from "translations";
import { Theme, createStyles, makeStyles } from "@material-ui/core";
import { useGetAllTranscriptsOfRecordLazyQuery } from "graphqlBase/AllTranscriptsOfRecord/__generated__/getAllTranscriptsOfRecord";
import LoadingIndicator from "components/atomics/LoadingIndicator";

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    addButton: {
      fontSize: 14,
      color: theme.colors.tWhite,
      backgroundColor: theme.colors.sunYellow,
      height: 40,
      borderRadius: "unset",
      marginRight: 60,
      "&:hover": { color: theme.colors.tWhite, backgroundColor: theme.colors.sunYellow },
    },
  })
);

const AllTranscriptOfRecords: React.FC<{}> = () => {
  const classes = useStyles();
  const [query, { data, loading }] = useGetAllTranscriptsOfRecordLazyQuery({
    fetchPolicy: "network-only",
  });
  const handleClick = () => {
    query({});
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    const fileName = data?.allTranscriptsOfRecord?.fileName ?? "";
    const downloadUri = data?.allTranscriptsOfRecord?.downloadUri ?? "";
    if (!downloadUri) {
      return;
    }
    void fetch(fileName).then((response) => {
      void response.blob().then(() => {
        const fileURL = downloadUri;
        const alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = fileName;
        alink.click();
      });
    });
  }, [data]);

  return (
    <div style={{ display: "contents" }}>
      <div>{loading && <LoadingIndicator />}</div>
      <Button
        onClick={handleClick}
        label={`${"Notenübersicht"} ${translations.globals.button.download}`}
        buttonType="primary"
        classes={{ colorPrimary: classes.addButton }}
      />
    </div>
  );
};

export default AllTranscriptOfRecords;
