import { TextField, TextFieldProps } from "@material-ui/core";
import React from "react";

export type DefaultInputProps = TextFieldProps;

const DefaultInput: React.FC<TextFieldProps> = (props) => {
  const { ...inputProps } = props;
  return <TextField variant="outlined" {...inputProps} />;
};

export default DefaultInput;
