import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetMatchStudentSemestersQueryVariables = Types.Exact<{
  filterInput?: Types.Maybe<Types.MatchStudentSemesterFilterInput>;
}>;


export type GetMatchStudentSemestersQuery = { matchStudentSemesters: Array<{ __typename?: 'MatchStudentSemester', id: number, studentId?: Types.Maybe<number>, hochschulsemester?: Types.Maybe<number>, fachsemester?: Types.Maybe<number>, studentSemesterStatusId?: Types.Maybe<number>, zeitraumId?: Types.Maybe<number>, zeitraum?: Types.Maybe<{ __typename?: 'Zeitraum', id: number, zeitraumNameKuerzel?: Types.Maybe<string>, zeitraumVon: string }> }> };


export const GetMatchStudentSemestersDocument = gql`
    query getMatchStudentSemesters($filterInput: MatchStudentSemesterFilterInput) {
  matchStudentSemesters(where: $filterInput) {
    id
    studentId
    hochschulsemester
    fachsemester
    studentSemesterStatusId
    zeitraumId
    zeitraum {
      id
      zeitraumNameKuerzel
      zeitraumVon
    }
  }
}
    `;

/**
 * __useGetMatchStudentSemestersQuery__
 *
 * To run a query within a React component, call `useGetMatchStudentSemestersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMatchStudentSemestersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMatchStudentSemestersQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *   },
 * });
 */
export function useGetMatchStudentSemestersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMatchStudentSemestersQuery, GetMatchStudentSemestersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMatchStudentSemestersQuery, GetMatchStudentSemestersQueryVariables>(GetMatchStudentSemestersDocument, baseOptions);
      }
export function useGetMatchStudentSemestersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMatchStudentSemestersQuery, GetMatchStudentSemestersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMatchStudentSemestersQuery, GetMatchStudentSemestersQueryVariables>(GetMatchStudentSemestersDocument, baseOptions);
        }
export type GetMatchStudentSemestersQueryHookResult = ReturnType<typeof useGetMatchStudentSemestersQuery>;
export type GetMatchStudentSemestersLazyQueryHookResult = ReturnType<typeof useGetMatchStudentSemestersLazyQuery>;
export type GetMatchStudentSemestersQueryResult = ApolloReactCommon.QueryResult<GetMatchStudentSemestersQuery, GetMatchStudentSemestersQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
