import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useGetMatchStudentSemestersQuery } from "graphqlBase/MatchStudentSemesters/__generated__/getMatchStudentSemesters";
import { useGetStudentSemesterStatusesQuery } from "graphqlBase/StudentSemesterStatus/__generated__/getStudentSemesterStatuses";
import Props from "./index.d";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { studentSemesterStatusIds, MatchStudentSemesterStatusIdStudentSpecific } from "./recoilState";
import { ListSubheader } from "@material-ui/core";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        paddingRight: "20px",
      },
      listRoot: {
        height: "80vh",
      },
      listRootInner: {
        width: "100%",
        maxHeight: "80%",
        overflowY: "auto",
      },
      listItem: {
        paddingLeft: 0,
        paddingRight: 0,
        display: "flex",
        background: theme.colors.grey50,
        marginTop: 0,
      },
      listItemText: {
        flex: "1 1 0",
      },
      header: {
        fontWeight: "bold",
      },
      headerEnd: {
        // textAlign: "end",
      },
      select: {
        paddingRight: "5px",
      },
    }),
  { name: "StudienVerlauf" }
);

const StudienVerlauf: React.FC<Props> = (props) => {
  const {
    data: { state },
  } = props;
  const classes = useStyles();
  const setCurrentStudentSemesterStatusIds = useSetRecoilState(studentSemesterStatusIds);
  const statusValues = useRecoilValue(studentSemesterStatusIds);

  const { data } = useGetMatchStudentSemestersQuery({
    variables: {
      filterInput: { studentId: { eq: state } },
    },
    skip: typeof state === "string",
    fetchPolicy: "cache-and-network",
  });

  const { data: statusData } = useGetStudentSemesterStatusesQuery();

  useEffect(() => {
    if (!data) return;
    if (statusValues[state] != null && Object.keys(statusValues[state]).length === data.matchStudentSemesters.length)
      return;
    const initialStatus = data.matchStudentSemesters.reduce<MatchStudentSemesterStatusIdStudentSpecific>(
      (acc, curr) => {
        if (curr.studentSemesterStatusId) {
          return { ...acc, [curr.id]: curr.studentSemesterStatusId };
        } else {
          return acc;
        }
      },
      {}
    );

    setCurrentStudentSemesterStatusIds({ ...statusValues, [state]: initialStatus });
  }, [data, state]);

  const memoizedOrderedData = React.useMemo(
    () =>
      (data?.matchStudentSemesters ? [...data?.matchStudentSemesters] : []).sort((a, b) => {
        const timeperiodOne = a?.zeitraum?.zeitraumVon ? new Date(a.zeitraum.zeitraumVon).getTime() : 1;
        const timeperiodTwo = b?.zeitraum?.zeitraumVon ? new Date(b.zeitraum.zeitraumVon).getTime() : 1;
        return timeperiodOne - timeperiodTwo;
      }),
    [data, Object.keys(statusValues[state] ?? {}).length]
  );

  const handleChangePre = (key: number) => (event: React.ChangeEvent<{ value: unknown }>) => {
    let value: number | string = event.target.value as string;
    value = parseInt(value);

    const temp = Object.entries(statusValues[state]).reduce((temp, [index, oldValue]) => {
      if (value === 2 && oldValue === 6) {
        if (+index >= key) return { ...temp, [index]: value };
        return temp;
      } else {
        if (+index === key) {
          return { ...temp, [index]: value };
        }
        return temp;
      }
    }, statusValues[state]);
    setCurrentStudentSemesterStatusIds({ ...statusValues, [state]: temp });
    // setCurrentStudentSemesterStatusIds({ ...statusValues, [state]: { ...statusValues[state], [key]: value } });
  };

  let hochschulSemesterCounter = 0;
  let fachSemesterCounter = 0;
  return (
    <div className={classes.root}>
      <div className={classes.listRoot}>
        <div className={classes.listRootInner}>
          <List aria-label="Semester-Status-Tabelle" disablePadding>
            <ListSubheader className={classes.listItem}>
              <ListItemText
                className={clsx(classes.header, classes.headerEnd, classes.listItemText)}
                disableTypography
                primary={"Semester"}
              />
              <ListItemText
                className={clsx(classes.header, classes.headerEnd, classes.listItemText)}
                disableTypography
                primary={"Status"}
              />
              <ListItemText
                className={clsx(classes.header, classes.listItemText)}
                disableTypography
                primary={"Hochschulsemester"}
              />
              <ListItemText
                className={clsx(classes.header, classes.listItemText)}
                disableTypography
                primary={"Fachsemester"}
              />
            </ListSubheader>
            <Divider />
            {(memoizedOrderedData ?? []).map((entry, index) => {
              return (
                <div key={entry.id}>
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      className={clsx(classes.headerEnd, classes.listItemText)}
                      disableTypography
                      primary={entry?.zeitraum?.zeitraumNameKuerzel ?? ""}
                    />

                    <ListItemText
                      className={classes.listItemText}
                      disableTypography
                      primary={
                        <div className={classes.select}>
                          {/* {Object.keys(statusValues).length !== 0 && statusValues.constructor === Object && ( */}
                          {statusValues[state] != null && (
                            <Select
                              fullWidth
                              variant="outlined"
                              id="studienverlauf"
                              onChange={handleChangePre(entry.id)}
                              value={statusValues[state][entry.id] ?? entry.studentSemesterStatusId}
                            >
                              {statusData &&
                                statusData.studentSemesterStatuses.map((option, index) => {
                                  return (
                                    <MenuItem value={option.id} key={option.id}>
                                      {option.studentSemesterStatusName}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          )}
                        </div>
                      }
                    />
                    <ListItemText
                      className={classes.listItemText}
                      disableTypography
                      primary={entry.hochschulsemester ?? ""}
                    />
                    <ListItemText
                      className={classes.listItemText}
                      disableTypography
                      primary={entry.fachsemester ?? ""}
                    />
                  </ListItem>
                  <Divider />
                </div>
              );
            })}
          </List>
        </div>
      </div>
    </div>
  );
};

export default StudienVerlauf;
