import React, { useEffect } from "react";
import { makeStyles, createStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";
import Props from "./index.d";
import { useGetZeitraumQuery } from "graphqlBase/Zeitraum/__generated__/getZeitraum";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import translations from "translations";

const useStyles = makeStyles(
  (theme: AugmentedTheme) =>
    createStyles({
      dateDisplayContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      },
      displayLeft: { marginRight: "20px", width: "100%" },
    }),
  { name: "MoodleModulDisplayZeitraum" }
);
const MoodleModulDisplayZeitraum: React.FC<Props> = (props) => {
  const {
    data: { state, setAndSave },
    disabled,
  } = props;

  const classes = useStyles(props);
  const { data, loading, error } = useGetZeitraumQuery({
    variables: {
      id: state,
    },
    skip: typeof state === "string",
  });

  const handleChange = (date: MaterialUiPickersDate) => {
    //
  };

  return (
    <div className={classes.dateDisplayContainer}>
      <div className={classes.displayLeft}>
        <KeyboardDatePicker
          label={translations.pages.lehre.moodleModulUpdateForm.from}
          disabled
          inputVariant="outlined"
          value={data?.zeitraum?.zeitraumVon}
          onChange={handleChange}
          format="dd.MM.yyyy"
          fullWidth
        />
      </div>
      <KeyboardDatePicker
        label={translations.pages.lehre.moodleModulUpdateForm.to}
        disabled
        inputVariant="outlined"
        value={data?.zeitraum?.zeitraumBis}
        onChange={handleChange}
        format="dd.MM.yyyy"
        fullWidth
      />
    </div>
  );
};

export default MoodleModulDisplayZeitraum;
