import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { StudiengangUpdateForm, StudiengangUpdateOnUpdateDataMiddleWare } from "raft/StudiengangForm";
import React from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  GetStudiengangsQuery,
  ResultType,
  useGetStudiengangsQuery,
} from "graphqlBase/Studiengang/__generated__/getStudiengangs";

const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      StudiengangEditFormBox: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
      afterCreate: {
        margin: "auto",
        maxWidth: "700px",
      },
      afterCreateBody: {
        marginTop: "1em",
      },
      afterCreateHeadline: { marginBottom: "10px" },
    }),

  { name: `Pages-StudiengangEdit` }
);

type Studiengang = ResultType<GetStudiengangsQuery["studiengangs"]>;

const StudiengangEdit: React.FC<{}> = () => {
  const classes = useStyles({});
  const history = useHistory();
  const location = useLocation();
  const { studiengangId } = useParams<{ studiengangId?: string }>();
  const id = +(studiengangId ?? "0");
  const { data } = useGetStudiengangsQuery({
    variables: { where: { studiengangId: { eq: id } } },
  });
  const [studiengang, setStudiengang] = React.useState<Studiengang | null>(null);

  React.useEffect(() => {
    if (!data) return;
    setStudiengang(data.studiengangs[0]);
  }, [data]);

  const onAfterUpdate = (data: { id: number }) => {
    history.goBack();
  };

  const onLeave = () => {
    history.goBack();
  };

  const onUpdateUpdateMiddleware: StudiengangUpdateOnUpdateDataMiddleWare = ({ data }) => {
    const summaryOne = studiengang?.studiengangName ?? "";
    const summaryTwo = studiengang?.studiengangTyp?.studiengangTypName ?? "";
    return { ...data, summaryOne, summaryTwo };
  };

  if (!id) return null;
  return (
    <div className={classes.StudiengangEditFormBox}>
      <StudiengangUpdateForm
        ausbildungsArtId={location.pathname.includes("zertifikatEdit") ? 2 : 1}
        onUpdateDataMiddleWare={onUpdateUpdateMiddleware}
        onCancel={onLeave}
        afterUpdate={onAfterUpdate}
        id={id}
      />
    </div>
  );
};

export default StudiengangEdit;
