import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { prepareServerSideTable } from "components/templates/table-factory";
import actionsCellFactory from "components/templates/table-factory/Table/Cells/actionsCellFactory";
import {
  useGetPruefungsdatenLazyQuery,
  ResultType,
  GetPruefungsdatenQuery,
} from "graphqlBase/Pruefung/__generated__/getPruefungsdaten";
import { PruefungFilterInput, PruefungSortInput, SortEnumType } from "graphqlBase/types";
import React, { useState } from "react";

import { formatDateTime } from "translations/formatter";
import TableTopContainer from "components/atomics/TableTopContainer";
import ModulPruefungAddButton from "./ModulPruefungAddButton";
import Modal from "components/lbc-toolkit/molecules/Modal";
import { PruefungUpdateForm } from "raft/PruefungForm";
import { onUpdateUpdateMiddleware } from "./onUpdateDataMiddleWare";
import Props from "./index.d";
import MoodleSync from "../helpers/MoodleSync";
const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      root: { width: "100%", marginTop: "2em" },
      listRootHorizontal: {
        // height: "80vh",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },
    }),
  { name: `StudentModul-StudienVerlauf` }
);

type ModulPruefung = ResultType<GetPruefungsdatenQuery["pruefungs"]>;

interface MoodleModulPruefungenListProps extends Props {
  moodleModulId: number;
}

const { useCreateServerSideColumns, useMakeServerSideTable, makeSeverSideFilter } = prepareServerSideTable<
  ModulPruefung,
  PruefungFilterInput,
  PruefungSortInput,
  SortEnumType
>();

const ModulPruefung: React.FC<MoodleModulPruefungenListProps> = (props) => {
  const {
    data: { state: moodleModulId },
  } = props;
  const variables = {
    order: [{ datumUhrzeitVon: "ASC" as SortEnumType.Desc }],
    where: { moodleModulId: { eq: moodleModulId } }
  };
  const classes = useStyles();
  const [query, { data, refetch }] = useGetPruefungsdatenLazyQuery({
    fetchPolicy: "cache-and-network",
    variables,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [terminId, setTerminId] = useState<number | null>(null);

  const handleEdit = (value: number) => {
    setTerminId(value);
    setIsModalOpen(true);
  };

  const ActionCell = actionsCellFactory({
    onEdit: handleEdit,
    onDelete: {
      entity: "pruefung",
      afterDelete: () => (refetch ? refetch() : {}),
    },
  });
  const closeModal = () => setIsModalOpen(false);
  const { columns, serversideQueryConfig } = useCreateServerSideColumns(
    [
      {
        Header: "Name",
        accessor: (row) =>
          `${row.moodleModul?.modul?.modulName} ${row.moodleModul?.zeitraum?.zeitraumName} ${row.pruefungTyp?.pruefungTypName ?? ""
          }`,
        id: "pruefungName",
      },
      {
        Header: "Datum",
        accessor: (row) => (row.datumUhrzeitVon ? formatDateTime(new Date(row.datumUhrzeitVon)) : ""),
        id: "Datum",
        remoteOrder: ({ order, sort }) => ({ datumUhrzeitVon: sort }),
      },
      {
        Header: "Prüfungsnummer",
        accessor: (row) => (row.pruefungsversuch ? ` Prüfungsnummer ${row.pruefungsversuch}` : ""),
        id: "wiederholerpruefung",
        remoteOrder: ({ order, sort }) => ({ pruefungsversuch: sort }),
      },
      {
        Header: "",
        accessor: "id",
        id: "id",
        // eslint-disable-next-line react/display-name
        Cell: ActionCell,
        width: 60,
      },
    ],
    [refetch]
  );

  const { TableComponent: NewTableComponent } = useMakeServerSideTable(
    {
      data: data?.pruefungs ?? [],
      columns,
      initialState: { pageSize: 25 },
    },
    {
      query,
      variables,
      serversideQueryConfig,
      tableId: "MoodleModulPruefungenList",
    }
  );

  const refetchFull = () => {
    if (refetch) refetch();
  };
  return (
    <div className={classes.root}>
      <Modal
        open={isModalOpen}
        hideCancel
        hideConfirm
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => setIsModalOpen(false)}
        title="Update"
      >
        <>
          {terminId && (
            <PruefungUpdateForm
              id={terminId}
              afterUpdate={closeModal}
              onCancel={closeModal}
              onUpdateDataMiddleWare={onUpdateUpdateMiddleware}
            />
          )}
        </>
      </Modal>
      <TableTopContainer>
        <div className={classes.listRootHorizontal}>
          <MoodleSync {...props} buttonLabel="Prüfung" syncOperation="syncExamEvents" />
          <ModulPruefungAddButton
            moodleModulId={moodleModulId}
            key="ModulPruefung-add-button-1"
            refetch={refetchFull}
          />
        </div>
      </TableTopContainer>
      <NewTableComponent tableProps={{ rowsPerPageOptions: [10, 25, 50, 100] }} />
    </div>
  );
};

export default ModulPruefung;
