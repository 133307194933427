import React, { CSSProperties } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { CircularProgressProps } from "@material-ui/core/CircularProgress/CircularProgress";

interface LoadingIndicatorProps extends CircularProgressProps {
  isLoading?: boolean;
  padding?: CSSProperties["padding"];
}

const useStyles = makeStyles<Theme, LoadingIndicatorProps>(
  (theme) =>
    createStyles({
      root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: (props) => props.padding ?? "2rem",
      },
    }),
  { name: "LoadingIndicator" }
);

const LoadingIndicator: React.FC<LoadingIndicatorProps> = (props) => {
  const classes = useStyles(props);
  const { isLoading = true, padding, ...cpProps } = props;

  if (!isLoading) return null;

  return (
    <div className={classes.root}>
      <CircularProgress {...cpProps} />
    </div>
  );
};

export default LoadingIndicator;
