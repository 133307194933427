import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type RemoveStudentFromMoodleModulStutentMappingsMutationVariables = Types.Exact<{
  studentsToRemove?: Types.Maybe<Array<Types.Maybe<Types.MatchMoodleModulStudentUpdateType>> | Types.Maybe<Types.MatchMoodleModulStudentUpdateType>>;
}>;


export type RemoveStudentFromMoodleModulStutentMappingsMutation = { updateMatchMoodleModulStudents?: Types.Maybe<Array<Types.Maybe<{ __typename?: 'MatchMoodleModulStudent', id: number, isUserDeleted?: Types.Maybe<boolean> }>>> };


export const RemoveStudentFromMoodleModulStutentMappingsDocument = gql`
    mutation removeStudentFromMoodleModulStutentMappings($studentsToRemove: [MatchMoodleModulStudentUpdateType]) {
  updateMatchMoodleModulStudents(matchMoodleModulStudents: $studentsToRemove) {
    id
    isUserDeleted
  }
}
    `;
export type RemoveStudentFromMoodleModulStutentMappingsMutationFn = ApolloReactCommon.MutationFunction<RemoveStudentFromMoodleModulStutentMappingsMutation, RemoveStudentFromMoodleModulStutentMappingsMutationVariables>;

/**
 * __useRemoveStudentFromMoodleModulStutentMappingsMutation__
 *
 * To run a mutation, you first call `useRemoveStudentFromMoodleModulStutentMappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStudentFromMoodleModulStutentMappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStudentFromMoodleModulStutentMappingsMutation, { data, loading, error }] = useRemoveStudentFromMoodleModulStutentMappingsMutation({
 *   variables: {
 *      studentsToRemove: // value for 'studentsToRemove'
 *   },
 * });
 */
export function useRemoveStudentFromMoodleModulStutentMappingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveStudentFromMoodleModulStutentMappingsMutation, RemoveStudentFromMoodleModulStutentMappingsMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveStudentFromMoodleModulStutentMappingsMutation, RemoveStudentFromMoodleModulStutentMappingsMutationVariables>(RemoveStudentFromMoodleModulStutentMappingsDocument, baseOptions);
      }
export type RemoveStudentFromMoodleModulStutentMappingsMutationHookResult = ReturnType<typeof useRemoveStudentFromMoodleModulStutentMappingsMutation>;
export type RemoveStudentFromMoodleModulStutentMappingsMutationResult = ApolloReactCommon.MutationResult<RemoveStudentFromMoodleModulStutentMappingsMutation>;
export type RemoveStudentFromMoodleModulStutentMappingsMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveStudentFromMoodleModulStutentMappingsMutation, RemoveStudentFromMoodleModulStutentMappingsMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
