import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { GetModulsDocument } from "graphqlBase/Modul/__generated__/getModuls";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "components/atomics/Loader";
import { ModulCreateForm } from "raft/ModulForm";

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    formWrapper: {
      display: "flex",
      width: "100%",
    },
    addButton: {
      fontSize: 14,
      color: theme.colors.tWhite,
      backgroundColor: theme.colors.sunYellow,
      height: 40,
      borderRadius: "unset",
      "&:hover": { color: theme.colors.tWhite, backgroundColor: theme.colors.sunYellow },
    },
  })
);

const ModuleAdd: React.FC<{}> = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [state, setstate] = React.useState(false);

  const close = () => {
    history.goBack();
  };

  return (
    <div className={classes.formWrapper}>
      <Loader style={{ justifyContent: "center" }} loading={state}>
        <ModulCreateForm
          ausbildungsArtId={location.pathname.includes("zertifikatsModule") ? 2 : 1}
          onCancel={close}
          afterCreate={close}
          beforeRemoteMuatation={(p) => {
            setstate(true);
            return {
              ...p.data,
            };
          }}
          updateAfterCreateQueries={[
            {
              query: GetModulsDocument,
              variables: {},
            },
          ]}
        />
      </Loader>
    </div>
  );
};

export default ModuleAdd;
