import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Navigator from "../organisms/Navigator";
import PageTitle from "../atomics/PageTitle";
import AppBar from "@material-ui/core/AppBar";

const drawerWidth = { open: "300px", closed: "64px" };
const appBarHeight = "64px";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      app: {
        color: "black",
        height: appBarHeight,
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
          marginLeft: drawerWidth.closed,
        },
        [theme.breakpoints.up("md")]: {
          marginLeft: drawerWidth.closed,
        },
      },
      appBar: {
        // paddingLeft: "22px",
        zIndex: theme.zIndex.drawer - 1,
        boxShadow: "none",
      },
      appBarShift: {
        [theme.breakpoints.down("sm")]: {
          marginLeft: drawerWidth.closed,
        },
        [theme.breakpoints.up("md")]: {
          marginLeft: drawerWidth.open,
        },
        marginLeft: "32px",
      },
      logo: {
        color: "white",
        height: "100%",
        display: "flex",
        marginLeft: "auto",
        "& img": {
          backgroundColor: theme.colors.tWhite,
          objectFit: "contain",
        },
      },
      devTitle: {
        color: "white",
        display: "flex",
        marginLeft: "auto",
        alignItems: "center",
      },
      main: {
        [theme.breakpoints.down("sm")]: {
          marginLeft: drawerWidth.closed,
        },
        [theme.breakpoints.up("md")]: {
          marginLeft: drawerWidth.closed,
        },
        marginTop: (props: MainLayoutProps) => (props.pageTitle ? appBarHeight : 0),
        // padding: "10px 10px",
        display: "flex",
        flexGrow: 1,
      },
      mainShift: {
        [theme.breakpoints.down("sm")]: {
          marginLeft: drawerWidth.closed,
        },
        [theme.breakpoints.up("md")]: {
          marginLeft: drawerWidth.open,
        },
        marginLeft: "53px",
        marginTop: (props: MainLayoutProps) => (props.pageTitle ? appBarHeight : 0),
        padding: "10px 10px",
        display: "flex",
        flexGrow: 1,
      },
      root: {
        minHeight: "100vh",
        backgroundColor: "#F1F1F1",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
      title: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
        paddingLeft: "24px",
        width: "100%",
        "& .MuiTypography-body1": {
          fontSize: "30px",
          fontWeight: 500,
          color: theme.colors.tWhite,
        },
      },
    }),
  { name: "MainLayout" }
);

interface MainLayoutProps {
  noElevation?: boolean;
  pageTitle: string;
}

const MainLayout: React.FC<MainLayoutProps> = (props) => {
  const { pageTitle, noElevation } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(pageTitle);
  useEffect(() => {
    setTitle(pageTitle);
  }, [pageTitle]);

  const classes = useStyles({ pageTitle: title, noElevation });
  return (
    <div className={classes.root}>
      {props.pageTitle !== "LiTRACK" && (
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <div
            className={clsx(classes.app, {
              [classes.appBarShift]: open,
            })}
          >
            <div className={classes.title}>
              <PageTitle title={title} />
              {process.env.REACT_APP_GRAPHQL_URI === "https://app-kolping-dev-gateway.azurewebsites.net/graphql" && (
                <div className={classes.devTitle}>
                  <PageTitle title={"Entwicklungumgebung"} />
                </div>
              )}
              <div className={classes.logo}>
                <img src={process.env.PUBLIC_URL + "/Logo-kh-web.png"} />
              </div>
            </div>
          </div>
        </AppBar>
      )}
      <Navigator drawerWidth={drawerWidth} open={open} setOpen={setOpen} setTitle={setTitle} />
      <main
        className={clsx({
          [classes.main]: !open,
          [classes.mainShift]: open,
        })}
      >
        {props.children}
      </main>
    </div>
  );
};

export default MainLayout;
