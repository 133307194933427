import * as React from "react";
import { makeStyles, createStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Props from "./index.d";
import DefaultInput from "components/atomics/DefaultInput";
import tableColors from "components/templates/table-factory/theme/tableColors";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
export type TextFieldComponentProps = Props;
const useStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    wrapper: { display: "flex", alignItems: "center" },
    input: {
      width: "100%",
    },
  })
);

const TextFieldComponent: React.FC<Props> = (props) => {
  const {
    title,
    data: { state, setstate, onBlur, updateValue },
    placeholder = "placeholder",
    errors,
    ...rest
  } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.wrapper}>
      <DefaultInput
        className={classes.input}
        onBlur={onBlur}
        value={state || ""}
        onChange={updateValue}
        disabled={props.disabled}
        defaultValue=""
        error={!!errors?.length}
        label={<a href={state}>{title}</a>}
        variant="outlined"
        helperText={errors?.concat(" ") ?? undefined}
        // {...rest}
      />
      <a href={state} target="blank">
        <ArrowForwardIcon />
      </a>
    </div>
  );
};

export default TextFieldComponent;
