import translations from ".";
export default () => {};

export const getCurreny = (value: number, currency: string = "EUR") => {
  const currentLanguage = translations.getLanguage();
  return new Intl.NumberFormat(currentLanguage, { style: "currency", currency }).format(value);
};

export const formatDate = (date: Date) => {
  const currentLanguage = translations.getLanguage();
  const options: Intl.DateTimeFormatOptions | undefined = {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  return Intl.DateTimeFormat(currentLanguage, options).format(date);
};

export const formatTime = (date: Date) => {
  const currentLanguage = translations.getLanguage();
  const options: Intl.DateTimeFormatOptions | undefined = {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    hour: "numeric",
    minute: "numeric",
  };
  return new Intl.DateTimeFormat(currentLanguage, options).format(date);
};

export const formatDateTime = (date: Date) => {
  const currentLanguage = translations.getLanguage();
  const options: Intl.DateTimeFormatOptions | undefined = {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return Intl.DateTimeFormat(currentLanguage, options).format(date);
};

export const formatDateForRange = (startDate: Date, endDate: Date) => {
  const currentLanguage = translations.getLanguage();
  const options: Intl.DateTimeFormatOptions | undefined = {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    day: "numeric",
    month: "numeric",
    year: "numeric",
  };

  return [
    Intl.DateTimeFormat(currentLanguage, options).format(startDate),
    Intl.DateTimeFormat(currentLanguage, options).format(endDate),
  ];
};

export const formatDateTimeForRange = (startDate: Date, endDate: Date) => {
  const currentLanguage = translations.getLanguage();
  const options: Intl.DateTimeFormatOptions | undefined = {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  return [
    Intl.DateTimeFormat(currentLanguage, options).format(startDate),
    Intl.DateTimeFormat(currentLanguage, options).format(endDate),
  ];
};

export const getNumber = (number: number, minimumFractionDigits: number = 2) => {
  const currentLanguage = translations.getLanguage();
  return new Intl.NumberFormat(currentLanguage, { minimumFractionDigits }).format(number);
};

export const getDistance = (distance: number) => {
  const numericString = getNumber(distance);
  return `${numericString} km`;
};
