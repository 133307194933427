/* eslint-disable filenames/match-exported */
import { ListSubheader } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import Props from "./index.d";
import { getNumber } from "translations/formatter";
import MyTranscriptOfRecords from "./MyTranscriptOfRecords";
import MyStudentIdCard from "./MyStudentIdCard";
import MyCertificateOfStudy from "./MyCertificateOfStudy";
import { useGetMyStudentGradeOverviewLazyQuery } from "graphqlBase/myStudentGradeOverview/__generated__/getMyStudentGradeOverview";
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        display: "flex",
      },
      listRoot: {
        width: "50%",

        height: "80vh",
        // overflowY: "auto",
      },
      listRootInner: {
        width: "190%",
        maxHeight: "60%",
        overflowY: "auto",
      },
      listItem: {
        paddingLeft: 0,
        paddingRight: 0,
        display: "flex",
        background: theme.colors.grey50,
        marginTop: 0,
      },
      listItemText: {
        flex: "1 1 0",
        textAlign: "center",
      },
      listBodyElem: {
        cursor: "pointer",
      },
      header: {
        fontWeight: "bold",
      },
      headerEnd: {
        // textAlign: "end",
      },
      largeCol: {
        flexGrow: 3,
      },
      sideBar: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
      },
      sideBarFormWrapper: {
        display: "flex",
        flexDirection: "column",
        maxHeight: "30%",
        marginBottom: "3em",
      },
      sideBarTableWrapper: {
        display: "flex",
        flexDirection: "column",
        maxHeight: "60%",
      },
      modulbezeichnung: {
        margin: "1.7em",
        marginBottom: "-2.5em",
      },
      notenDurchsSchnitt: {
        display: "flex",
        fontSize: "1.2em",
        fontWeight: "bold",
        width: "80%",
        height: 60,
        padding: "30px 0",
        "& div": {
          width: "30%",
          // padding: "0 50px 0 0",
        },
      },
      noModuleSelected: {
        textAlign: "center",
        marginTop: "50%",
      },
      listRootHorizontal: {
        width: "187%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: "1.5em",
        marginBottom: "1.5em",
        marginRight: "5em",
      },
    }),
  { name: "StudentModul" }
);

const StudentModul: React.FC<Props> = (props) => {
  const {
    data: { state: studentId },
  } = props;

  const classes = useStyles();

  const [query, { data }] = useGetMyStudentGradeOverviewLazyQuery({
    variables: {},
    // skip: typeof studentId === "string",
  });
  const [element, setElement] = useState<{
    modulId: number;
    matchModulStudentId: number;
    modulbezeichnung: string;
  } | null>(null);

  useEffect(() => {
    query();
  }, [query, studentId]);

  const count = (data?.myStudentGradeOverview?.modules ?? []).reduce<number>((count, elem) => {
    const ects = elem?.eCTS ?? 0;
    return count + ects;
  }, 0);
  const notenDurchsSchnitt = data?.myStudentGradeOverview?.grade;
  console.log("data", data);
  return (
    <div className={classes.root}>
      <div className={classes.listRoot}>
        <div className={classes.listRootHorizontal}>
          <MyCertificateOfStudy id={data?.myStudentGradeOverview?.student?.id ?? 0} />
          <MyTranscriptOfRecords id={studentId} />
          <MyStudentIdCard id={studentId} />
        </div>
        <div className={classes.listRootInner}>
          <List aria-label="Modul-Studiengang-Tabelle" disablePadding>
            <ListSubheader className={classes.listItem}>
              <ListItemText
                className={clsx(classes.header, classes.largeCol, classes.listItemText)}
                disableTypography
                primary={"Modulbezeichnung"}
              />
              <ListItemText
                className={clsx(classes.header, classes.listItemText)}
                disableTypography
                primary={"Semester"}
              />
              <ListItemText
                className={clsx(classes.header, classes.listItemText)}
                disableTypography
                primary={"Status"}
              />
              <ListItemText className={clsx(classes.header, classes.listItemText)} disableTypography primary={"Note"} />
              <ListItemText className={clsx(classes.header, classes.listItemText)} disableTypography primary={"ECTS"} />
            </ListSubheader>
            <Divider />
            {(data?.myStudentGradeOverview?.modules ?? [])
              .slice()
              .sort((a, b) => (a?.semester ?? 0) - (b?.semester ?? 0))
              .reduce<JSX.Element[]>((elements, entry) => {
                if (!entry) return elements;
                return elements.concat(
                  <div
                    className={classes.listBodyElem}
                    key={entry.modulId}
                    onClick={() =>
                      setElement({
                        modulId: entry.modulId ?? 0,
                        matchModulStudentId: entry.modulId,
                        modulbezeichnung: entry.modulbezeichnung ?? "",
                      })
                    }
                  >
                    <ListItem className={classes.listItem}>
                      <ListItemText
                        className={clsx(classes.largeCol, classes.listItemText)}
                        disableTypography
                        primary={entry.modulbezeichnung ?? ""}
                      />
                      <ListItemText
                        className={classes.listItemText}
                        disableTypography
                        primary={entry?.semester ?? ""}
                      />
                      <ListItemText
                        className={classes.listItemText}
                        disableTypography
                        primary={
                          <span style={{ background: entry?.color ?? "inherit", padding: "2px 5px" }}>
                            {entry?.examStatus ?? ""}
                          </span>
                        }
                      />
                      <ListItemText
                        className={classes.listItemText}
                        disableTypography
                        primary={entry.note ? getNumber(entry.note, 1) : ""}
                      />
                      <ListItemText
                        className={classes.listItemText}
                        disableTypography
                        primary={entry.eCTSString ?? ""}
                      />
                    </ListItem>
                    <Divider component="li" />
                  </div>
                );
              }, [])}
          </List>
        </div>
        <div className={classes.notenDurchsSchnitt} />
        <hr style={{ width: "190%" }} />
        <div className={classes.notenDurchsSchnitt}>
          <div>Notendurchschnitt:</div>
          <div>{`${notenDurchsSchnitt ? getNumber(notenDurchsSchnitt) : ""}`}</div>
          <div>ECTS:</div>
          <div>{`${getNumber(data?.myStudentGradeOverview?.eCTS ?? 0)} / ${getNumber(count)}`}</div>
        </div>
      </div>
    </div>
  );
};

export default StudentModul;
