import * as React from "react";
import { makeStyles, createStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";
import Props from "./index.d";
import DefaultInput from "components/atomics/DefaultInput";
import { useEffect, useState } from "react";
import { useGetMoodleModulsForPruefungCreateQuery } from "graphqlBase/MoodleModul/__generated__/getMoodleModulsForPruefungCreate";
import { update } from "lodash";

export type TextFieldComponentProps = Props;
const useStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    input: {
      width: "100%",
    },
  })
);

const MoodleModulValueOnPruefung: React.FC<Props> = (props) => {
  const {
    title,
    data: { state },

    variant = "outlined",
    valueToShow,
    raftVariables,
    //@ts-ignore
    update,
  } = props;
  const classes = useStyles(props);

  const { data } = useGetMoodleModulsForPruefungCreateQuery({
    variables: { id: raftVariables.moodleModulId },
    skip: !raftVariables.moodleModulId,
  });
  useEffect(() => {
    if (data?.moodleModul) {
      if (valueToShow === "ects") setValue(`${data?.moodleModul?.modul?.ects ?? ""}`);
      if (valueToShow === "pruefungsform") {
        const pruefungsform = data?.moodleModul?.modul?.pruefungsform;
        setValue(`${pruefungsform?.pruefungsformenName ?? ""}`);
        if (pruefungsform) update({ pruefungsformId: pruefungsform.id });
      }
      if (valueToShow === "lehrender") {
        const lehrender = data?.moodleModul?.lehrender;

        setValue(`${lehrender?.anrede ?? ""} ${lehrender?.vorname ?? ""} ${lehrender?.nachname ?? ""}`);
      }
    }
  }, [data, state]);

  const [value, setValue] = useState("");

  return (
    <DefaultInput
      className={classes.input}
      value={value}
      disabled={true}
      defaultValue=""
      // error={!!errors?.length}
      label={title}
      // multiline={multiline}
      variant={variant}
    // helperText={errors?.concat(" ") ?? undefined}
    // {...rest}
    />
  );
};

export default MoodleModulValueOnPruefung;
