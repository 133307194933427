import * as React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import LoadingIndicator from "components/atomics/LoadingIndicator";
import translations from "translations";
import Props from "./index.d";
const useStyles = makeStyles<Theme, Props>(
  (theme) =>
    createStyles({
      SubmitButton: {
        cursor: "pointer",
        height: "40px",
        alignItems: "center",
        appearance: "none",
        backgroundColor: (props) => (!props.disabled ? theme.colors.christineRed : theme.colors.lbcGrey),
        borderStyle: "none",
        // borderRadius: "4px",
        color: (props) => (!props.disabled ? theme.colors.tWhite : theme.colors.grey400),
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "217px",
      },
      title: {
        textTransform: "uppercase",
        boxSizing: "content-box",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 15,
        lineHeight: "26px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        letterSpacing: "0.46px",
      },
    }),
  { name: "Button" }
);

const BooleanToggleButton: React.FC<Props> = (props) => {
  const {
    title,
    variant,
    disabled,
    data: { state, setAndSave },
  } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <button className={classes.SubmitButton} disabled={disabled} onClick={() => setAndSave(!state)}>
        <span className={classes.title}>{props.title}</span>
      </button>
    </div>
  );
};

export default BooleanToggleButton;
