import round from "lodash/round";

export const limitDecimals = (n: number, maxDecimals: number) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  round(n, maxDecimals);

export const numberToLimitedString = (n: number | null, maxDecimals: number): string => {
  if (n == null) return "";
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  return limitDecimals(n, maxDecimals).toString();
};

export const stringToNumber = (s: string): number | null => {
  if (s === "") return null;
  const s2 = s.replace(",", ".");
  const res = parseFloat(s2);
  if (isNaN(res)) return null;
  return res;
};

export const stringToLimitedNumber = (s: string, maxDecimals: number): number | null => {
  const n = stringToNumber(s);
  if (n === null) return null;
  return limitDecimals(n, maxDecimals);
};
