import { Select as MuiSelect } from "@material-ui/core";
import Select from "../../../lbc-toolkit/molecules/Select";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import { TypedColumnFilter } from "components/templates/table-factory2/Table/useMakeServerSideTable";
import React from "react";
import { FilterProps, Row } from "react-table";
import translations from "translations";
import { getSelectBooleanOptions, useGetRandomId } from "../utils/utils";

export const DefaultColumnFilter = ({ column: { filterValue, setFilter, filter } }: FilterProps<object>) => {
  const [currentValue, setCurrentValue] = React.useState(filterValue);

  React.useEffect(() => {
    setCurrentValue(filterValue);
  }, [filterValue]);

  React.useEffect(() => {
    if (!currentValue) {
      return setFilter(currentValue);
    }
    const timer = setTimeout(() => {
      setFilter(currentValue);
    }, 500);
    return () => clearTimeout(timer);
  }, [currentValue]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCurrentValue(e.target.value || undefined);
  };

  return (
    <Input
      value={currentValue || ""}
      onChange={handleChange}
      style={{ width: "100%" }}
      inputProps={{ style: { color: "black", fontSize: "16px" } }}
      endAdornment={
        <InputAdornment style={{ pointerEvents: "none" }} position="end">
          <SearchIcon fontSize="large" />
        </InputAdornment>
      }
    />
  );
};

export const NumericFilter = ({
  column: { filterValue, setFilter },
}: {
  column: {
    filterValue: number | undefined | null;
    setFilter: (val: number | undefined | null) => void;
  };
}) => {
  const [currentValue, setCurrentValue] = React.useState(filterValue);

  React.useEffect(() => {
    setCurrentValue(filterValue);
  }, [filterValue]);

  React.useEffect(() => {
    if (!currentValue) {
      return setFilter(currentValue);
    }
    const timer = setTimeout(() => {
      setFilter(currentValue);
    }, 500);
    return () => clearTimeout(timer);
  }, [currentValue]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCurrentValue(+e.target.value || undefined);
  };

  return (
    <Input
      value={`${currentValue ?? ""}`}
      onChange={handleChange}
      style={{ width: "100%" }}
      placeholder={`Search ${""}...`}
      endAdornment={
        <InputAdornment style={{ pointerEvents: "none" }} position="end">
          <SearchIcon fontSize="large" />
        </InputAdornment>
      }
    />
  );
};

interface SelectItem {
  label: string;
  value: string;
}

function preFilteredRowsToSelectOptions(preFilteredRows: Array<Row<object>>, id: string): SelectItem[] {
  const resultSet = new Set<string>();
  preFilteredRows.forEach((row) => {
    const value = row.values[id];
    if (value !== undefined) resultSet.add(value);
  });
  return Array.from(resultSet)
    .sort((a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    })
    .map((el) => ({ label: el, value: el }));
  // return [{ label: "All", value: "" }, ...result];
}

export function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }: FilterProps<object>) {
  const options = React.useMemo(() => preFilteredRowsToSelectOptions(preFilteredRows, id), [id, preFilteredRows]);

  const componentId = useGetRandomId();

  return (
    <MuiSelect
      value={filterValue ?? ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      style={{ width: "100%" }}
      displayEmpty
    >
      <MenuItem value="" disabled={filterValue == null}>
        {filterValue == null ? <span style={{ color: "#959FC5" }}>Select ...</span> : <em>All</em>}
      </MenuItem>

      {options.map((option, i) => (
        <MenuItem key={`${componentId}-${i}`} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </MuiSelect>
  );
}

// see https://material-ui.com/components/autocomplete/#combo-box
const useComboBoxFilterStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: "100%",
      },
      input: {
        '&[class*="MuiOutlinedInput-root"]': {
          padding: "2px 1rem",
        },
      },
      popper: {
        color: "black",
      },
    }),
  { name: `ComboBoxFilter` }
);
export function ComboBoxFilter({ column: { filterValue, setFilter, preFilteredRows, id } }: FilterProps<object>) {
  const classes = useComboBoxFilterStyles();
  const options = React.useMemo(
    () => [{ label: "All", value: "" }, ...preFilteredRowsToSelectOptions(preFilteredRows, id)],
    [id, preFilteredRows]
  );

  const componentId = useGetRandomId();
  return (
    <Autocomplete
      id={componentId}
      classes={{
        inputRoot: classes.input,
        listbox: classes.popper,
      }}
      options={options}
      getOptionLabel={(option) => option.label}
      className={classes.root}
      value={filterValue}
      onChange={(event, value) => {
        setFilter(value?.value || undefined);
      }}
      renderInput={(params) => <TextField placeholder="Search ..." {...params} />}
    />
  );
}

export const NoFilter = () => <div style={{ height: 32 }} />;

export function SelectBooleanFilter({ column: { filterValue, setFilter } }: TypedColumnFilter<any, string>) {
  const [currentValue, setCurrentValue] = React.useState(filterValue ?? undefined);
  const componentId = useGetRandomId();
  const options = React.useMemo(() => getSelectBooleanOptions(), []);

  React.useEffect(() => {
    if (currentValue === translations.globals.terms.clear) setFilter(undefined);
    else setFilter(currentValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setCurrentValue((event?.target?.value as string) ?? undefined);
  };

  return (
    <div style={{ marginLeft: -10 }}>
      <Select
        id={componentId}
        name={`BooleanSelectFilter-${componentId}`}
        options={options}
        selected={currentValue}
        onChange={handleChange}
        height={25}
        paperMargin="2.6em"
      />
    </div>
  );
}
