import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "components/lbc-toolkit/atomics/Button/DefaultButton";
import Modal from "components/lbc-toolkit/molecules/Modal";
import { PruefungCreateForm, PruefungCreateProps } from "raft/PruefungForm";
import React, { useState } from "react";
import translations from "translations";
import { useGetMoodleModulsForPruefungCreateQuery } from "graphqlBase/MoodleModul/__generated__/getMoodleModulsForPruefungCreate";
import { onCreateUpdateMiddleware } from "./onUpdateDataMiddleWare";

type ModulPruefungCreatePropsInner = Pick<PruefungCreateProps, "moodleModulId">;

interface ModulPruefungAddButtonProps extends ModulPruefungCreatePropsInner {
  refetch?: () => void;
}

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    addButton: {
      fontSize: 14,
      color: theme.colors.tWhite,
      backgroundColor: theme.colors.sunYellow,
      height: 40,
      borderRadius: "unset",
      "&:hover": { color: theme.colors.tWhite, backgroundColor: theme.colors.sunYellow },
    },
  })
);

const ModulPruefungAddButton: React.FC<ModulPruefungAddButtonProps> = ({ moodleModulId, refetch }) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: moodleModulInfo } = useGetMoodleModulsForPruefungCreateQuery({ variables: { id: moodleModulId } });
  const { modulId, zeitraumId, modul } = moodleModulInfo?.moodleModul ?? {
    modulId: moodleModulId,
    zeitraumId: 1,
    modul: { dauer: 0 },
    skip: !moodleModulId,
  };
  function handleClick() {
    setIsModalOpen(true);
  }

  const closeModal = () => setIsModalOpen(false);

  const afterCreate = () => {
    if (refetch) refetch();
    setIsModalOpen(false);
  };
  if (!modulId) return null;
  // if (!zeitraumId || !modulId) return null;
  return (
    <>
      <Button
        label={`${"Prüfung"} ${translations.globals.button.add}`}
        buttonType="primary"
        onClick={handleClick}
        classes={{ colorPrimary: classes.addButton }}
      />
      <Modal
        // height="70vh"
        open={isModalOpen}
        hideCancel
        hideConfirm
        onClose={closeModal}
        onConfirm={closeModal}
        title={`${"Prüfung"} ${translations.globals.button.add}`}
      >
        <div style={{ height: "70vh" }}>
          <PruefungCreateForm
            moodleModulId={moodleModulId}
            modulId={modulId ?? 0}
            pruefungsdauer={modul?.dauer ?? 0}
            zeitraumId={zeitraumId ?? 0}
            onCancel={closeModal}
            afterCreate={afterCreate}
            onUpdateDataMiddleWare={onCreateUpdateMiddleware}
          />
        </div>
      </Modal>
    </>
  );
};
export default ModulPruefungAddButton;
