import Button from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import Props from "./index.d";
import translations from "translations";
import { filesAtom } from "../fileUpload/fileState";
import { useRecoilState } from "recoil";

const useStyles = makeStyles<Theme, Props>(
  (theme) =>
    createStyles({
      forward: {
        cursor: "pointer",
        pointerEvents: "all",
        height: "40px",
        alignItems: "center",
        appearance: "none",
        backgroundColor: (props) => (!props.disabled ? theme.colors.christineRed : theme.colors.lbcGrey),
        borderStyle: "none",
        borderRadius: "1px",
        color: (props) => (!props.disabled ? theme.colors.tWhite : theme.colors.grey400),
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "217px",
        "&:hover": {
          backgroundImage: "linear-gradient(rgb(255, 154, 30) 0%, rgb(239, 124, 0) 100%)",
        },
      },
      forwardDisabled: {
        cursor: "pointer",
        pointerEvents: "all",
        height: "40px",
        alignItems: "center",
        appearance: "none",
        backgroundColor: () => (theme.colors.lbcGrey),
        borderStyle: "none",
        borderRadius: "1px",
        color: (props) => (theme.colors.grey400),
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "217px",
        "&:hover": {
          backgroundImage: "linear-gradient(rgb(255, 154, 30) 0%, rgb(239, 124, 0) 100%)",
        },
      },
      backwards: {
        backgroundColor: (props) => (!props.disabled ? theme.colors.tWhite : theme.colors.koplingGrey),
        cursor: (props) => (!props.disabled ? "pointer" : "not-allowed"),
        height: "40px",
        alignItems: "center",
        appearance: "none",
        borderRadius: "1px",
        color: (props) => (!props.disabled ? theme.colors.tBlack : theme.colors.grey400),
        display: "flex",
        justifyContent: "center",
        width: "217px",
        "&:hover": {
          backgroundColor: (props) => (!props.disabled ? theme.colors.tWhite : theme.colors.koplingGrey),
        },
      },
      title: {
        boxSizing: "content-box",
        textTransform: "uppercase",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 15,
        lineHeight: "26px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        letterSpacing: "0.46px",
      },
      root: {},
    }),
  { name: "StepperButton" }
);

const StepperButton: React.FC<Props> = (props) => {
  const {
    data: { state, setAndSave },
    length,
    title,
    disabled,
    action,
    //@ts-ignore
    update,
  } = props;

  const classes = useStyles(props);
  const [fileList] = useRecoilState(filesAtom);

  const handleClick = () => {
    const modifier = action === "add" ? 1 : -1;

    setAndSave(state + modifier);
  };
  const submit = () => {
    update({ submit: true });
  };
  const fileLength = fileList.length;
  return (
    <div className={classes.root}>
      {action === "subract" && (
        <Button className={classes.backwards} disabled={+state === 0 || disabled} onClick={handleClick}>
          <span className={classes.title}>{translations.globals.terms.back}</span>
        </Button>
      )}
      {action === "add" &&
        ((state === 3 && fileLength === 0 && (
          <Button className={classes.forwardDisabled} disabled={true} onClick={handleClick}>
            <span className={classes.title}>{translations.globals.button.next}</span>
          </Button>
        )) ||
          (state < length && (
            <Button className={classes.forward} disabled={disabled} onClick={handleClick}>
              <span className={classes.title}>{translations.globals.terms.next}</span>
            </Button>
          )))}
      {action === "add" && state === length && (
        <Button className={classes.forward} disabled={disabled} onClick={submit}>
          <span className={classes.title}>{translations.globals.button.study}</span>
        </Button>
      )}
    </div>
  );
};
export default StepperButton;
