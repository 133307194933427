import React from "react";
import StudentList from "../StudentList";

const where = {
  ausbildungsArtId: { eq: 2 },
  matchStudentSemesters: { all: { studentSemesterStatusId: { in: [6] } } },
};
const type = "zertifikatsTeilnehmer";

const ZertifikatsTeilnehmerListe: React.FC<{}> = () => {
  return <StudentList formType={type} where={where} isZertifikat />;
};

export default ZertifikatsTeilnehmerListe;
