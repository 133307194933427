import { Wohnland } from "./../../../graphqlBase/types.d";
import validate from "validate.js";
import translations from "translations";
import { te } from "date-fns/locale";
const { validation: translation } = translations.pages.bewerbung;
//@ts-ignore
validate.options = { format: "flat", cleanAttributes: false };
interface ValidationResult<T extends {}> {
  [key: string]: string[];
}
validate.formatters.custom = (errors: Array<{ attribute: string; options: { message: string } }>) => {
  return errors.reduce<{ [key: string]: string[] }>((result, error) => {
    const existingErrors = result[error.attribute] ?? [];
    const errorMessages = existingErrors.includes(error.options.message)
      ? existingErrors
      : existingErrors.concat(error.options.message);
    return { ...result, [error.attribute]: errorMessages };
  }, {});
};

const constraints = {
  0: {
    studiengangSemesterId: { presence: { message: translation.underscore } },
    zahlungsmodellId: { presence: { message: translation.zahlungsmodellId } },
  },
  1: {
    geschlechtId: { presence: { message: translation.geschlechtId } },
    vorname: {
      presence: { message: translation.firstname },
      length: { minimum: 1, message: translation.firstname },
    },
    nachname: {
      presence: { message: translation.lastname },
      length: { minimum: 1, message: translation.lastname },
    },
    geburtsdatum: {
      presence: { message: translation.birthDate },
      length: { minimum: 1 },
    },
    geburtsort: {
      presence: { message: translation.birthPlace },
      length: { minimum: 1, message: translation.birthPlace },
    },
    geburtslandId: { presence: { message: translation.birthCountry } },
  },
  2: {
    strasse: {
      presence: { message: translation.street },
      length: { minimum: 1, message: translation.street },
    },
    wohnort: {
      presence: { message: translation.city },
      length: { minimum: 1, message: translation.city },
    },
    plz: {
      // numericality: {
      //   message: "Bitte geben Sie eine gültige Postleitzahl an!",
      // },
      presence: { message: translation.plz },
      length: { minimum: 1, message: translation.plz },
    },
    hausnummer: {
      // numericality: {
      //   message: "Bitte geben Sie eine gültige Hausnummer an!",
      // },
      presence: { message: translation.houseNr },

      length: { minimum: 1, message: translation.houseNr },
    },
    telefonnummer: {
      presence: { message: translation.phoneNumber },
      length: { minimum: 1, message: translation.phoneNumber },
    },
    email: {
      email: { message: translation.email },
      presence: { message: translation.email },
    },
    email2: {
      presence: { message: translation.email },
      equality: {
        attribute: "email",
        message: translation.emailMatch,
      },
    },
  },
  3: {
    fileUpload: {},
  },
  4: {
    privacyPolicyAccepted: {
      presence: { message: translation.privacyPolicy },
      exclusion: {
        within: [false],
        message: translation.privacyPolicy,
      },
    },
  },
};

function validateForm<T extends object>(data: T, step: keyof typeof constraints): ValidationResult<T> {
  return validate(data, constraints[step], { format: "custom" });
}

export default validateForm;
