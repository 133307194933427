import React from "react";
import MoodleModules from "..";

const now = new Date();
now.setUTCHours(0, 0, 0, 0);

const variables = {
  and: [
    { zeitraum: { zeitraumVon: { lte: now.toISOString() } } },
    { zeitraum: { zeitraumBis: { gte: now.toISOString() } } },
  ],
};

const CurrentModules: React.FC<{}> = () => {
  return (
    <div style={{ width: "100%", paddingLeft: "28px", paddingRight: "33px" }}>
      <MoodleModules where={variables} />
    </div>
  );
};

export default CurrentModules;
