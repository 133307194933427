import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetAllStudentIdCardsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllStudentIdCardsQuery = { allStudentIdCards?: Types.Maybe<{ __typename?: 'DownloadFile', downloadUri?: Types.Maybe<string>, fileName?: Types.Maybe<string> }> };


export const GetAllStudentIdCardsDocument = gql`
    query getAllStudentIdCards {
  allStudentIdCards {
    downloadUri
    fileName
  }
}
    `;

/**
 * __useGetAllStudentIdCardsQuery__
 *
 * To run a query within a React component, call `useGetAllStudentIdCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStudentIdCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStudentIdCardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllStudentIdCardsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllStudentIdCardsQuery, GetAllStudentIdCardsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllStudentIdCardsQuery, GetAllStudentIdCardsQueryVariables>(GetAllStudentIdCardsDocument, baseOptions);
      }
export function useGetAllStudentIdCardsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllStudentIdCardsQuery, GetAllStudentIdCardsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllStudentIdCardsQuery, GetAllStudentIdCardsQueryVariables>(GetAllStudentIdCardsDocument, baseOptions);
        }
export type GetAllStudentIdCardsQueryHookResult = ReturnType<typeof useGetAllStudentIdCardsQuery>;
export type GetAllStudentIdCardsLazyQueryHookResult = ReturnType<typeof useGetAllStudentIdCardsLazyQuery>;
export type GetAllStudentIdCardsQueryResult = ApolloReactCommon.QueryResult<GetAllStudentIdCardsQuery, GetAllStudentIdCardsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
