import React, { useEffect, useState } from "react";
import { useCreateAnmeldungMutation } from "../../../graphqlBase/Anmeldung/__generated__/createAnmeldung";
import useGetForeignKeyList from "../../formElements/fields/foreignKeySelectorInt/useGetForeignKeyList";
import DefaultInput from "../../atomics/DefaultInput";
import Select from "../../atomics/Select";
import Checkbox from "../../atomics/CheckboxBoolean";
import Button from "@material-ui/core/Button";
import { AnmeldungCreateType } from "../../../graphqlBase/types";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { VeranstaltungFragment } from "../../../graphqlBase/Anmeldung/__generated__/getVeranstaltungById";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import LoadingIndicator from "components/atomics/LoadingIndicator";
import translations from "translations";

interface FormState extends AnmeldungCreateType {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: { maxWidth: "100%", padding: "0 1em" },
      submit: {
        backgroundAttachment: "scroll",
        backgroundClip: "border-box",
        backgroundColor: "rgba(0, 0, 0, 0)",
        backgroundImage: "linear-gradient(rgb(255, 184, 48) 0%, rgb(245, 154, 18) 100%)",
        backgroundOrigin: "padding-box",
        backgroundPositionX: "0%",
        backgroundPositionY: "0%",
        backgroundSize: "auto",
        borderBottomColor: "rgb(233, 142, 6)",
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        borderBottomStyle: "none",
        borderBottomWidth: "0px",
        borderImageOutset: "0",
        borderImageRepeat: "stretch",
        borderImageSlice: "100%",
        borderImageSource: "none",
        borderImageWidth: "1",
        borderLeftColor: "rgb(233, 142, 6)",
        borderLeftStyle: "none",
        borderLeftWidth: "0px",
        borderRightColor: "rgb(233, 142, 6)",
        borderRightStyle: "none",
        borderRightWidth: "0px",
        borderTopColor: "rgb(233, 142, 6)",
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        borderTopStyle: "none",
        borderTopWidth: "0px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px 0px",
        boxSizing: "border-box",
        color: "rgb(255, 255, 255)",
        cursor: "pointer",
        display: "inline-block",
        fontFamily: "Roboto latin, Roboto, Arial",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "21.6px",
        overflowWrap: "break-word",
        paddingBottom: "25px",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "25px",
        textAlign: "center",
        textDecorationColor: "rgb(255, 255, 255)",
        textDecorationLine: "none",
        textDecorationStyle: "solid",
        textDecorationThickness: "auto",
        textShadow: "none",
        textSizeAdjust: "100%",
        textTransform: "uppercase",
        "&:hover": {
          backgroundImage: "linear-gradient(rgb(255, 154, 30) 0%, rgb(239, 124, 0) 100%)",
        },
      },
      disabledButton: {
        // backgroundColor: "lightGrey",
        // backgroundImage: "none",
      },
      header: { margin: "2em 0 1em 0" },
      section: {
        marginBottom: "16px",
      },
      flexContainer: { display: "flex" },
      bold: { fontWeight: "bold", marginBottom: "16px", marginTop: "3em" },
      gapRight: { marginRight: "0em" },
      gapLeft: { marginLeft: "2em" },
      flexGrow: {
        // flexGrow: 1,
        flexBasis: "50%",
      },
      selectContainer: {
        // flexGrow: 2,
        flexBasis: "50%",
        "& :first-child": {
          margin: 0,
        },
      },
      loadingIndicatorContainer: {
        display: "flex",
        maxHeight: "2em",
        alignItems: "center",
        marginLeft: "10px",
      },
      buttonContainer: {
        display: "flex",
        height: "100%",
        alignItems: "center",
      },
      afterCreate: {
        margin: "auto",
        maxWidth: "700px",
      },
    }),
  { name: "Veranstaltungen" }
);

interface VeranstaltungenFormProps {
  veranstaltung: VeranstaltungFragment;
}

const VeranstaltungenForm: React.FC<VeranstaltungenFormProps> = ({ veranstaltung }) => {
  const { anmeldungCreateForm: translation } = translations.pages.veranstaltungen;
  const classes = useStyles();
  const defaultWohnlandId = 1;
  const defaultAgreeDatenschutz = false;
  const [state, setstate] = useState<FormState>({
    veranstaltungId: veranstaltung.id,
    wohnlandId: defaultWohnlandId,
    datenschutz: defaultAgreeDatenschutz,
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [createAnmeldungMutation] = useCreateAnmeldungMutation({
    onCompleted: () => {
      setSuccessMessage(translation.short.successMessage);
    },
    onError: () => {
      setSuccessMessage(translation.short.errorMessage);
    },
  });

  const [currentCountry, setCurrentCountry] = useState(defaultWohnlandId);
  const [agreeDatenschutz, setAgreeDatenschutz] = useState(defaultAgreeDatenschutz);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const countryElems = useGetForeignKeyList({
    foreignKeyParams: { column: ["wohnlandName"], entityName: "wohnlands" },
  });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setstate({ ...state, [name]: value });
  };

  const onChangeCountry = (value: number | string) => {
    if (typeof value === "string") return;
    setstate({ ...state, wohnlandId: value });
    setCurrentCountry(value);
  };

  const onChangeCheckbox = (value: boolean) => {
    setAgreeDatenschutz(value);
    setstate({ ...state, datenschutz: value });
  };

  const handleOnSubmit = async () => {
    setIsLoading(true);
    try {
      await createAnmeldungMutation({ variables: { createAnmeldungs: [{ ...state }] } });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (successMessage) {
      setDisableSubmit(true);
    } else if (state.vorname && state.nachname && state.email && state.telefonnummer && state.datenschutz) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [state, successMessage]);

  return (
    <div className={classes.root}>
      <Typography variant="h2" component="h2">
        {translations.formatString(translation.short.headline, veranstaltung?.veranstaltungName)}
      </Typography>
      <Typography variant="subtitle1">{veranstaltung?.veranstaltungBeschreibung}</Typography>
      <div className={classes.section}>
        <Typography className={classes.bold}>{`${translation.long.name} *`}</Typography>
        <div className={classes.flexContainer}>
          <div className={clsx(classes.flexGrow, classes.gapRight)}>
            <DefaultInput
              fullWidth
              label={translation.short.firstname}
              name="vorname"
              value={state.vorname}
              onChange={handleOnChange}
            />
          </div>
          <div className={clsx(classes.flexGrow, classes.gapLeft)}>
            <DefaultInput
              fullWidth
              label={translation.short.lastname}
              name="nachname"
              value={state.nachname}
              onChange={handleOnChange}
            />
          </div>
        </div>
      </div>
      <div className={classes.section}>
        <Typography className={classes.bold}>{`${translation.short.email} *`}</Typography>
        <DefaultInput fullWidth name="email" value={state.email} onChange={handleOnChange} />
      </div>
      <div className={classes.section}>
        <Typography className={classes.bold}>{`${translation.short.phone} *`}</Typography>
        <DefaultInput fullWidth name="telefonnummer" value={state.telefonnummer} onChange={handleOnChange} />
      </div>
      <div className={classes.section}>
        <Typography className={classes.bold}>{translation.long.address}</Typography>
        <div className={clsx(classes.flexContainer, classes.section)}>
          <div className={clsx(classes.flexGrow, classes.gapRight)}>
            <DefaultInput
              fullWidth
              label={translation.long.street}
              name="strasse"
              value={state.strasse}
              onChange={handleOnChange}
            />
          </div>
          <div className={clsx(classes.flexGrow, classes.gapLeft)}>
            <DefaultInput
              fullWidth
              label={translation.long.houseNumber}
              name="hausnummer"
              value={state.hausnummer}
              onChange={handleOnChange}
            />
          </div>
        </div>
        <div className={classes.section}>
          <DefaultInput
            fullWidth
            label={translation.long.place}
            name="ort"
            value={state.ort}
            onChange={handleOnChange}
          />
        </div>
        <div className={classes.flexContainer}>
          <div className={clsx(classes.flexGrow, classes.gapRight)}>
            <DefaultInput
              fullWidth
              label={translation.long.postalCode}
              name="plz"
              value={state.plz}
              onChange={handleOnChange}
            />
          </div>
          <div className={clsx(classes.selectContainer, classes.gapLeft)}>
            <Select fullWidth state={currentCountry} error={false} setState={onChangeCountry} items={countryElems} />
          </div>
        </div>
      </div>
      <div>
        <div className={classes.section}>
          <Typography className={classes.bold}>{`${translation.short.privacyPolicy} *`}</Typography>
          <Checkbox
            disabled={false}
            setAndSave={onChangeCheckbox}
            checked={agreeDatenschutz}
            label={veranstaltung?.veranstaltungCheckboxFeldText ?? translation.short.privacyPolicyDefaultText}
          />
        </div>
        <div className={clsx(classes.section, classes.buttonContainer)}>
          <Button
            onClick={handleOnSubmit}
            className={classes.submit}
            classes={{ disabled: classes.disabledButton }}
            disabled={disableSubmit || isLoading}
          >
            {translation.short.signUp}
          </Button>
          <div className={classes.loadingIndicatorContainer}>{isLoading && <LoadingIndicator />}</div>
          {successMessage && <Typography variant="h4">{successMessage}</Typography>}
        </div>
      </div>
    </div>
  );
};

export default VeranstaltungenForm;
