import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { TableSortLabel } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { TableHeaderComponentProps } from "../tableTypes";

const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      headerLabel: {
        marginBottom: "0.5rem",
      },
    }),
  { name: `Table-Standard-Header` }
);
const TableHeadComponent: React.FC<TableHeaderComponentProps<object>> = ({
  config,
  tableInstance,
  tableProps,
  tableId,
}) => {
  const classes = useStyles();
  const { headerGroups } = tableInstance;
  return (
    <TableHead {...tableProps?.head}>
      {headerGroups.map((headerGroup, headerGroupIndex) => (
        <TableRow
          {...headerGroup.getHeaderGroupProps()}
          {...tableProps?.headRow}
          key={`table-${tableId}-hg-${headerGroupIndex}`}
        >
          {headerGroup.headers.map((column, cIndex) => {
            const getSortByToggleProps = column.canSort
              ? column.getSortByToggleProps({
                  ...column.getSortByToggleProps(),
                  title: undefined,
                })
              : undefined;
            const { width, minWidth, maxWidth } = column;
            let Wrapper: React.FC = Typography;
            if (config.sorting === true && column.canSort) {
              Wrapper = (props) => (
                <TableSortLabel
                  disabled={config.sorting !== true || !column.canSort}
                  className={classes.headerLabel}
                  active={column.isSorted && column.isSortedDesc !== undefined}
                  hideSortIcon={false}
                  direction={column.isSortedDesc ? "desc" : "asc"}
                  {...getSortByToggleProps}
                >
                  <Typography>{props.children}</Typography>
                </TableSortLabel>
              );
            }
            return (
              <TableCell
                {...column.getHeaderProps()}
                {...tableProps?.headCell}
                style={{
                  width,
                  minWidth,
                  maxWidth,
                  verticalAlign: "bottom",
                  ...tableProps?.headCell?.style,
                }}
                key={`table-${tableId}-hg-${headerGroupIndex}-c${cIndex}`}
              >
                <Wrapper>{column.render("Header")}</Wrapper>
                {column.canFilter && <div>{column.render("Filter")}</div>}
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </TableHead>
  );
};

export default TableHeadComponent;
