import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      padding: "1em",
      left: 0,
      right: 0,
      marginLeft: "auto",
      marginRight: "auto",
      borderRadius: 8,
      backgroundColor: "#fafafa",
    },
    modalHeader: {
      position: "relative",
      paddingBottom: 24,
      display: "flex",
      justifyContent: "space-between",
      "& button": {
        alignSelf: "flex-start",
      },
    },
    modalHeaderLabel: {
      margin: "0.5em 0 0 0.5em",
      fontSize: "1.7em",
      fontWeight: 700,
      fontFamily:
        "corporatecondensedbold, HelveticaNeueLTStd-BdCn, Helvetica Neue, HelveticaNeue, Helvetica, system-ui, sans-serif",
    },
    modalFooter: (props: { centerButtons?: boolean }) => ({
      padding: "1.5em 0",
      "& div": {
        display: "flex",
        justifyContent: props.centerButtons ? "center" : "flex-end",
      },
      "& .MuiButtonBase-root:nth-child(2)": {
        marginLeft: props.centerButtons ? "3em" : "1.5em",
      },
    }),
    modalContent: {
      padding: "0.7em",
      overflowY: "hidden",
    },
    closeButton: {
      backgroundColor: theme.colors.koplingGrey,
      color: theme.colors.mineShaftBlack,
      "& svg": {
        height: "1.5em",
        width: "1.5em",
      },
      "&:hover": { backgroundColor: theme.colors.koplingGrey },
      padding: "0.5em",
      right: 0,
      top: 0,
    },
    cancelButton: {
      backgroundColor: theme.colors.grey100,
      color: theme.colors.tBlack,
      height: 40,
      borderRadius: "unset",
      "&:hover": { backgroundColor: theme.colors.tWhite },
    },
    confirmButton: {
      backgroundColor: theme.colors.christineRed,
      color: theme.colors.tWhite,
      height: 40,
      borderRadius: "unset",
      "&:hover": { backgroundColor: theme.colors.christineRed },
    },
  })
);
