export default {
  name: "Regel",
  plural: "Regeln",
  columns: {
    deviceCapability: "Gerätefähigkeit",
    deviceCapabilityId: "Gerätefähigkeit-ID",
    id: "ID",
    isDeleted: "ist gelöscht",
    isEnabled: "aktiviert",
    ruleActionFailure: "Regel Aktion Fehler",
    ruleActionFailureId: "Regel Aktion Fehler ID",
    ruleActionSuccess: "Regel Aktion Erfolg",
    ruleActionSuccessId: "Regel Aktion Erfolg ID",
    ruleDescription: "Regel Beschreibung",
    ruleMessageFailure: "Regel Nachrichtenfehler",
    ruleMessageSuccess: "Regel Nachricht Erfolg",
    ruleName: "Regelname",
    ruleOperator: "Regel Operator",
    ruleReference: "Regel Referenz",
    ruleReferenceId: "Regel Referenz ID",
    ruleResult: "Regel Ergebnis",
    ruleResultType: "Regel Ergebnistyp",
    ruleResultTypeId: "Regel Ergebnistyp ID",
    tenantId: "",
    unitId: "Einheit ID",
  },
};
