import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Props from "./index.d";
import DefaultInput, { DefaultInputProps } from "components/atomics/DefaultInput";
import tableColors from "components/templates/table-factory/theme/tableColors";

const useStyles = makeStyles<Theme, DefaultInputProps>(
  (theme) =>
    createStyles({
      inputBox: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
      },
      percentEditorRoot: {
        borderRadius: "4px",
        background: tableColors.lbcgrey,
        color: theme.colors.grey500,
        fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
        fontSize: "16px",
        fontWeight: 400,
        height: "40px",
        lineHeight: 1.2,
        letterSpacing: 0,
        width: "180px",
        "& :focus": {
          background: theme.colors.tWhite,
          border: `1px solid ${theme.colors.grey500}`,
          borderRadius: "4px",
          height: "40px",
        },
        "&:hover": {
          backgroundColor: theme.colors.grey200,
        },
        "& :disabled": {
          color: theme.colors.grey500,
          cursor: "not-allowed",
          background: theme.colors.tWhite,
          border: "1px solid rgba(154, 151, 143, 0.5)",
          borderRadius: "4px",
          height: "40px",
          width: "180px",
          "&:hover": {
            backgroundColor: theme.colors.tWhite,
          },
        },
        "&:before": {
          borderBottomStyle: "none",
          borderBottom: 0,
          content: "none",
          transition: "none",
        },
        "&:after": {
          border: "none",
        },
      },
      root: {
        display: "flex",
        flexDirection: "column",
        color: theme.colors.grey500,
        fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: 1.2,
        letterSpacing: 0,
        "& .MuiInputBase-input": {
          padding: 0,
          paddingLeft: "8px",
        },
      },
      symbol: {
        paddingLeft: "5px",
      },
      titleBox: {
        margin: "0.2em 0.5em 0.5em 0.4em",
      },
    }),
  { name: "DefaultInput" }
);

const PercentEditorInner: React.FC<Props> = (props) => {
  const {
    data: { state, setstate, onBlur },
    title,
    ...rest
  } = props;

  const [initialStateLoaded, setinitialStateLoaded] = useState(false);
  const [displayValue, setDisplayValue] = useState<number | null>(null);

  useEffect(() => {
    if (!state || initialStateLoaded) return;
    setinitialStateLoaded(true);
    setDisplayValue(state * 100);
  }, [state]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const valueString = event.target.value
      .replace(",", ".")
      .split(".")
      .map((v) => {
        if (v === "" || v === "undefined") return "";
        const num = v !== "" ? v : "0";
        if (isNaN(+num)) return "0";
        return num;
      })
      .slice(0, 2)
      .join(".");

    const value = (valueString as unknown) as number;
    setDisplayValue(value);
    setstate(value / 100);
  };

  const classes = useStyles(props);

  return (
    <DefaultInput
      className={classes.percentEditorRoot}
      onBlur={onBlur}
      value={displayValue ?? ""}
      onChange={handleChange}
      {...rest}
    />
  );
};

const PercentEditor: React.FC<Props> = (props) => {
  const { title } = props;
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <div className={classes.titleBox}>
        <Typography align="left" variant="inherit">
          {title}:
        </Typography>
      </div>
      <div className={classes.inputBox}>
        <PercentEditorInner {...props} />
        <span className={classes.symbol}>%</span>
      </div>
    </div>
  );
};

export default PercentEditor;
