import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import Props from "./index.d";
import MoodleModulTermineList from "./MoodleModulTermineList";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        display: "flex",
      },
      listRoot: {
        width: "50%",

        height: "80vh",
        // overflowY: "auto",
      },
      listRootInner: {
        width: "100%",
        maxHeight: "80%",
        overflowY: "auto",
      },
      listItem: {
        paddingLeft: 0,
        paddingRight: 0,
        display: "flex",
        background: theme.colors.grey50,
        marginTop: 0,
      },
      listItemText: {
        flex: "1 1 0",
      },
      listBodyElem: {
        cursor: "pointer",
      },
      header: {
        fontWeight: "bold",
      },
      headerEnd: {
        // textAlign: "end",
      },
      largeCol: {
        flexGrow: 3,
      },
      notenDurchsSchnitt: {
        display: "flex",
        fontSize: "1.5em",
        fontWeight: "bold",
        height: 60,
        padding: "30px 0",
        "& div": {
          width: "30%",
          // padding: "0 50px 0 0",
        },
      },
    }),
  { name: "StudentModul" }
);

const MoodleModulTermine: React.FC<Props> = (props) => {
  const {
    data: { state: id },
  } = props;

  return (
    <div style={{ width: "100%" }}>
      <div>
        <MoodleModulTermineList {...props} />
      </div>
    </div>
  );
};

export default MoodleModulTermine;
