import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetBewerberOverviewQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.BewerberFilterInput>;
  take?: Types.Maybe<Types.Scalars['Int']>;
  skip?: Types.Maybe<Types.Scalars['Int']>;
  order?: Types.Maybe<Array<Types.BewerberSortInput> | Types.BewerberSortInput>;
}>;


export type GetBewerberOverviewQuery = { bewerbers: Array<{ __typename?: 'Bewerber', id: number, vorname: string, nachname: string, geburtsdatum: string, geburtsort: string, plz: string, wohnort: string, strasse: string, hausnummer: string, wohnlandId: number, telefonnummer: string, email: string, createdAt?: Types.Maybe<string>, newsletterAccepted: boolean, privacyPolicyAccepted: boolean, isImported?: Types.Maybe<boolean>, studiengangSemester?: Types.Maybe<{ __typename?: 'StudiengangSemester', id: number, semesterId?: Types.Maybe<number>, studiengangOnBewId?: Types.Maybe<number>, studiengangOnBew?: Types.Maybe<{ __typename?: 'StudiengangOnBew', id: number, studiengangName: string, studienmodell: { __typename?: 'Studienmodell', id: number, studienmodellName: string } }>, semester?: Types.Maybe<{ __typename?: 'Semester', id: number, semesterName?: Types.Maybe<string> }> }>, zahlungsmodell: { __typename?: 'Zahlungsmodell', id: number, zahlungsmodellName: string }, geschlecht: { __typename?: 'Geschlecht', id: number, geschlechtName: string }, geburtsland: { __typename?: 'Geburtsland', id: number, geburtlandName: string }, staatsangehoerigkeit: { __typename?: 'Staatsangehoerigkeit', staatsangehoerigkeitName: string }, wohnland: { __typename?: 'Wohnland', id: number, wohnlandName: string } }> };


export const GetBewerberOverviewDocument = gql`
    query getBewerberOverview($where: BewerberFilterInput, $take: Int, $skip: Int, $order: [BewerberSortInput!]) {
  bewerbers(where: $where, take: $take, skip: $skip, order: $order) {
    studiengangSemester {
      id
      semesterId
      studiengangOnBewId
      studiengangOnBew {
        id
        studiengangName
        studienmodell {
          id
          studienmodellName
        }
      }
      semester {
        id
        semesterName
      }
    }
    id
    zahlungsmodell {
      id
      zahlungsmodellName
    }
    geschlecht {
      id
      geschlechtName
    }
    vorname
    nachname
    geburtsdatum
    geburtsland {
      id
      geburtlandName
    }
    staatsangehoerigkeit {
      staatsangehoerigkeitName
    }
    geburtsort
    plz
    wohnort
    strasse
    hausnummer
    wohnlandId
    telefonnummer
    email
    wohnland {
      id
      wohnlandName
    }
    createdAt
    newsletterAccepted
    privacyPolicyAccepted
    zahlungsmodell {
      id
      zahlungsmodellName
    }
    email
    telefonnummer
    wohnort
    plz
    hausnummer
    strasse
    geburtsort
    geburtsdatum
    nachname
    vorname
    isImported
    createdAt
  }
}
    `;

/**
 * __useGetBewerberOverviewQuery__
 *
 * To run a query within a React component, call `useGetBewerberOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBewerberOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBewerberOverviewQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetBewerberOverviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBewerberOverviewQuery, GetBewerberOverviewQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBewerberOverviewQuery, GetBewerberOverviewQueryVariables>(GetBewerberOverviewDocument, baseOptions);
      }
export function useGetBewerberOverviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBewerberOverviewQuery, GetBewerberOverviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBewerberOverviewQuery, GetBewerberOverviewQueryVariables>(GetBewerberOverviewDocument, baseOptions);
        }
export type GetBewerberOverviewQueryHookResult = ReturnType<typeof useGetBewerberOverviewQuery>;
export type GetBewerberOverviewLazyQueryHookResult = ReturnType<typeof useGetBewerberOverviewLazyQuery>;
export type GetBewerberOverviewQueryResult = ApolloReactCommon.QueryResult<GetBewerberOverviewQuery, GetBewerberOverviewQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
