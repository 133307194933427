import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetZahlungsModellsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetZahlungsModellsQuery = { zahlungsmodells: Array<{ __typename?: 'Zahlungsmodell', id: number, zahlungsmodellName: string, studienmodellId?: Types.Maybe<number> }> };


export const GetZahlungsModellsDocument = gql`
    query getZahlungsModells {
  zahlungsmodells {
    id
    zahlungsmodellName
    studienmodellId
  }
}
    `;

/**
 * __useGetZahlungsModellsQuery__
 *
 * To run a query within a React component, call `useGetZahlungsModellsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetZahlungsModellsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetZahlungsModellsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetZahlungsModellsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetZahlungsModellsQuery, GetZahlungsModellsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetZahlungsModellsQuery, GetZahlungsModellsQueryVariables>(GetZahlungsModellsDocument, baseOptions);
      }
export function useGetZahlungsModellsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetZahlungsModellsQuery, GetZahlungsModellsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetZahlungsModellsQuery, GetZahlungsModellsQueryVariables>(GetZahlungsModellsDocument, baseOptions);
        }
export type GetZahlungsModellsQueryHookResult = ReturnType<typeof useGetZahlungsModellsQuery>;
export type GetZahlungsModellsLazyQueryHookResult = ReturnType<typeof useGetZahlungsModellsLazyQuery>;
export type GetZahlungsModellsQueryResult = ApolloReactCommon.QueryResult<GetZahlungsModellsQuery, GetZahlungsModellsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
