import { Localization, Updaters } from "../formGen.model";
import { QqlFormGenConfig } from "../types";
import fieldState from "./fieldState";
import FormGenerator from "./RenderChilds";

type ActionFunction = ({ key, obj }: { key: string; obj: any; root: any }) => void;

interface KeyActions {
  [key: string]: ActionFunction;
}

const language = (localization: Localization) => {};

// const addInjection (id:string,)

export default (params: any, config: QqlFormGenConfig, localization: Localization, self: FormGenerator) => {
  const keyActions: KeyActions = {
    multiLangStrings: ({ key, obj, root }) => {
      const currentLanuage = localization.getLanguage(),
        itemLanguages = Object.keys(obj);

      if (itemLanguages.includes(currentLanuage)) return obj[currentLanuage];
      if (itemLanguages.includes(config.languages[0])) return obj[config.languages[0]];
      return "";
    },
    _validateOnField: ({ key, obj, root }) => {
      return self.data ? self.data[obj[key]] : null;
    },
  };

  const actionsKeys = Object.keys(keyActions);

  const walkObj = ({ obj, root }: { obj: any; root: any }) => {
    Object.keys(obj).forEach((key) => {
      // console.log(key, obj[key]);
      if (obj[key] && typeof obj[key] === "string" && obj[key].includes("raftValuePick$%$")) {
        // console.log(obj[key]);
        const id = `${key}${obj[key]}` || "dd";
        const stateEffects = fieldState(null, id, self.saveData);
        const dataId = obj[key].replace("raftValuePick$%$", "").replace("$%$raftValuePick", "");

        const updatersIndex = self.updaters.findIndex((u) => u.id === id);
        if (updatersIndex > -1) {
          self.updaters[updatersIndex] = { dataId, stateEffects, id };
        } else {
          self.updaters.push({ dataId, stateEffects, id });
        }
        obj[key] = stateEffects;
      }
      if (obj[key] && typeof obj[key] !== "boolean" && typeof obj[key] !== "string" && typeof obj[key] !== "number") {
        obj[key] = walkObj({
          root,
          // @ts-ignore
          obj: Array.isArray(obj[key]) ? [...obj[key]] : { ...obj[key] },
        });
      }
      if (actionsKeys.includes(key)) {
        obj = keyActions[key]({ key, obj, root });
      }
    });
    return obj;
    //   if (obj.uiTypeDisplay && !obj.title) obj.title = obj.uiTypeDisplay;
  };
  walkObj({ obj: params, root: params });

  //  console.log(params);
  return { ...params, raftLanguage: localization.getLanguage() };
};
