import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { Theme } from "@material-ui/core";
import Props from "./index.d";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      margin: "1em",
      // backgroundColor: theme.palette.background.paper
    },
  })
);
interface GFCFieldSet_FixedFooter extends Props {
  children: React.ReactNode;
}

const FieldSetFixedFooter: React.FC<GFCFieldSet_FixedFooter> = (props) => {
  const { children } = props;
  const classes = useStyles();
  //  @ts-ignore
  if (props.hidden) return null;
  const childrenArray = React.Children.map(children, (child) => child);
  return (
    <div className={classes.root}>
      <div>{childrenArray?.[0]}</div>
      <div>{childrenArray?.[1]}</div>
    </div>
  );
};
export default FieldSetFixedFooter;
