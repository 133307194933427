export default {
  name: "Geräteattributwert",
  plural: "Geräteattributwerte",
  columns: {
    device: "Gerät",
    deviceAttribute: "Geräteattribut",
    deviceAttributeId: "Gerät Attribute-ID",
    deviceId: "Geräte-ID:",
    id: "ID",
    isDeleted: "ist gelöscht",
    tenantId: "Mieter ID",
    value: "Wert",
  },
};
