import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import ArrowDownIcon from "icons/components/ArrowDownIcon";
import getTestIDs from "lib/utils/getTestIDs";
import React from "react";
import translations from "translations";
import { useFormStyles } from "./utils";

export const testIDs = getTestIDs();

export interface SelectItem {
  label: string;
  value: string;
}
export interface SelectProps extends MuiSelectProps {
  disabled?: boolean;
  height?: string | number;
  icon?: React.ElementType<React.SVGProps<SVGSVGElement>> | undefined;
  iconSize?: string | number;
  // this prop is needed depending on which SVG icon is used
  id: string;
  label?: string;
  options?: SelectItem[];
  paperMargin?: string | number;
  placeholder?: string;
  selected: string | string[] | undefined;
  width?: string | number;
}

const Select: React.FC<SelectProps> = ({
  autoWidth,
  onChange,
  disabled,
  classes,
  defaultValue,
  variant,
  placeholder,
  options = [],
  label,
  id,
  name,
  width,
  height,
  icon,
  selected,
  iconSize,
  renderValue,
  children,
  value,
  paperMargin,
  ...other
}) => {
  const formClasses = useFormStyles({ iconSize });
  const labelId = `${id}-label`;

  const MenuProps = {
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        marginTop: paperMargin ?? "3.5em",
      },
    },
  };

  const content = options?.map((option, index) => {
    return (
      <MenuItem value={option.value} key={index}>
        {option.label}
      </MenuItem>
    );
  });

  return (
    <FormControl disabled={disabled} classes={formClasses}>
      {label && <InputLabel id={labelId}>{label}</InputLabel>}

      <MuiSelect
        id={id}
        IconComponent={icon ?? ArrowDownIcon}
        autoWidth={autoWidth}
        labelId={labelId}
        disabled={disabled}
        data-testid={testIDs.Select}
        value={selected ?? ""}
        onChange={onChange}
        style={{ width, minWidth: 260, height }}
        displayEmpty
        renderValue={(selected) =>
          options.reduce<string>(() => {
            if (!selected || selected === "") {
              return placeholder ?? translations.globals.placeholders.Dropdownfield;
            }
            return options.find((option) => option.value === selected)?.label ?? "";
          }, "")
        }
        input={<InputBase id={id} />}
        classes={classes}
        MenuProps={MenuProps}
        {...other}
      >
        {options && content}
        {children}
      </MuiSelect>
    </FormControl>
  );
};

export default Select;
