import { createStyles, makeStyles, Theme as AugmentedTheme, useTheme } from "@material-ui/core/styles";
import * as React from "react";

import LuxonUtils from "@date-io/luxon";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import Props from "./index.d";

interface GFCForm_Base extends Props {
  children: React.ElementType;
}

const useStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    form: {
      // flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    rightBar: {
      position: "absolute",
      right: 0,
      zIndex: -5,
      width: "22%",
      borderTop: "1px solid #D0D4DB",
    },
    rightBar2: {
      position: "absolute",
      right: 0,
      top: 0,
      zIndex: -6,
      width: "22%",
      backgroundColor: theme.palette.secondary.main,
      borderLeft: "1px solid #D0D4DB",
      height: "100%",
    },
  })
);

export default (props: GFCForm_Base) => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };
  const classes = useStyles();
  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      {props.children}
    </form>
  );
};
