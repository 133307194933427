import { useEffect } from "react";
const loadHubspotScript = (callback: () => void) => {
  const existingScript = document.getElementById("hs-script-loader");
  // console.log("loadHubspotScript");
  if (!existingScript) {
    // console.log("loadHubspotScript");
    const script = document.createElement("script");
    script.src = "https://js.hs-scripts.com/20233320.js";
    script.id = "hs-script-loader";
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

const LoadHubspot = () => {
  useEffect(() => {
    loadHubspotScript(() => {
      // console.log("loadHubspotScript2");
    });
  });
  return null;
};
export default LoadHubspot;
