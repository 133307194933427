import { prepareServerSideTable } from "components/templates/table-factory";
import actionsCellFactory from "components/templates/table-factory/Table/Cells/actionsCellFactory";
import {
  GetStudentOverviewQuery,
  GetStudentOverviewQueryVariables,
  ResultType,
  useGetStudentOverviewLazyQuery,
} from "graphqlBase/Students/__generated__/getStudentOverview";
import { Exact, SortEnumType, StudentFilterInput, StudentSortInput } from "graphqlBase/types";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import TableTopContainer from "components/atomics/TableTopContainer";
import { getCurrentStatus } from "lib/hooks/useGetStudentStatusFromSemesterStatus";
import { NoFilter } from "components/templates/table-factory/Table/Filters";
import StudentAddButton from "./TeilnehmerList/StudentAdd/StudentAddButton";
import useMediaQuery, { screenSizes } from "lib/hooks/useMediaQuery";
import DownloadAllTranscriptOfRecordsButton from "./TeilnehmerList/AllTranscriptOfRecords";
import DownloadAllStudentIdCardsButton from "./TeilnehmerList/AllStudentIdCards";
import DownloadAllAllCertificateOfStudyButton from "./TeilnehmerList/AllCertificateOfStudy";
import { QueryLazyOptions } from "@apollo/client";
import { ApiId } from "@azure/msal-browser";
type Student = ResultType<GetStudentOverviewQuery["students"]>;
const { useCreateServerSideColumns, useMakeServerSideTable, makeSeverSideFilter } = prepareServerSideTable<
  Student,
  StudentFilterInput,
  StudentSortInput,
  SortEnumType
>();

interface StudentListListProps {
  formType: "abgebrochen" | "teilnehmer" | "zertifikatsTeilnehmer" | "zertifikatsTeilnehmerAbgebrochen";
  isZertifikat?: boolean;
  where?: StudentFilterInput;
}
function getBaseOrder(sort: "ASC" | "DESC"): StudentSortInput[] {
  return [
    { studienstartSemesterId: sort as SortEnumType.Asc },
    { studienstartStudiengangKuerzel: sort as SortEnumType.Asc },
  ];
}

const nameSort: StudentSortInput = { nachname: "ASC" as SortEnumType.Asc };
const StudentList: React.FC<StudentListListProps> = ({ where, formType, isZertifikat }) => {
  const variables = {
    order: getBaseOrder("ASC").concat(nameSort),
    where: { ...where },
  };
  const history = useHistory();
  const [queryPre, { data, refetch }] = useGetStudentOverviewLazyQuery({
    fetchPolicy: "cache-and-network",
  });
  const [number, setNumber] = useState(0);

  const [kohortSort, setKohortSort] = useState<"ASC" | "DESC">("ASC");
  const query = useCallback(
    (options?: QueryLazyOptions<Exact<GetStudentOverviewQueryVariables>>) => {
      const order = options?.variables?.order ?? [];
      const orderArray = Array.isArray(order) ? order : ([order] as StudentSortInput[]);
      const includesStudienstartSemesterId = orderArray
        .flatMap((sort) => Object.keys(sort))
        .includes("studienstartSemesterId");

      const nextOrderArray = orderArray.reduce<StudentSortInput[]>((nextOrderArray, currentOrder) => {
        if (Object.keys(currentOrder).includes("studienstartSemesterId")) {
          const baseOrder: StudentSortInput[] = [
            { studienstartSemesterId: currentOrder.studienstartSemesterId as SortEnumType.Asc },
            { studienstartStudiengangKuerzel: currentOrder.studienstartSemesterId as SortEnumType.Asc },
          ];
          setKohortSort(currentOrder.studienstartSemesterId as SortEnumType);

          return baseOrder.concat(nextOrderArray);
        }
        return nextOrderArray.concat(currentOrder);
      }, []);
      const nextOrder = includesStudienstartSemesterId
        ? nextOrderArray
        : getBaseOrder(kohortSort).concat(nextOrderArray);
      queryPre({ variables: { ...variables, order: nextOrder } });
    },
    [queryPre, variables, kohortSort, setKohortSort]
  );

  const handleEdit = (value: number) => {
    if (formType === "teilnehmer") history.push(`/teilnehmer/teilnehmerEdit/${value}`);
    if (formType === "abgebrochen") history.push(`/teilnehmer/teilnehmerEdit/${value}`);
    if (formType === "zertifikatsTeilnehmer") history.push(`/teilnehmer/zertifikatsTeilnehmerEdit/${value}`);
  };

  const ActionCell = actionsCellFactory({
    onEdit: handleEdit,
    onDelete: {
      entity: "student",
      afterDelete: () => {
        if (refetch)
          refetch().catch(() => {
            //
          });
      },
    },
  });

  const matches = useMediaQuery(screenSizes.lg);
  const padding = useMediaQuery(screenSizes.lg);

  const { columns, serversideQueryConfig } = useCreateServerSideColumns(
    [
      {
        Header: "Nummer",
        accessor: (row, index) => index + 1,
        id: "stud-leng",
        remoteOrder: ({ order, sort }) => ({ studentId: sort }),
      },
      {
        Header: "Stud-ID",
        accessor: (row) => row.id,
        id: "stud-ID",
        remoteOrder: ({ order, sort }) => ({ studentId: sort }),
        remoteFilter: makeSeverSideFilter<number>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { studentId: { eq: filterValue && +filterValue } }),
        }),
      },
      {
        Header: "Vorname",
        accessor: "vorname",
        id: "vorname",
        remoteOrder: ({ order, sort }) => ({ vorname: sort }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) => merge(where, { vorname: { contains: filterValue } }),
        }),
      },
      {
        Header: "Nachname",
        accessor: "nachname",
        id: "nachname",
        remoteOrder: ({ order, sort }) => ({ nachname: sort }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) => merge(where, { nachname: { contains: filterValue } }),
        }),
      },
      {
        Header: "Email",
        accessor: "emailPrivat",
        id: "email",
        remoteOrder: ({ order, sort }) => ({ emailPrivat: sort }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) => merge(where, { emailPrivat: { contains: filterValue } }),
        }),
        hide: matches,
      },
      {
        Header: "Email Kolping",
        accessor: "emailKh",
        id: "emailKh",
        remoteOrder: ({ order, sort }) => ({ emailKh: sort }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) => merge(where, { emailKh: { contains: filterValue } }),
        }),
        hide: matches,
      },
      {
        Header: "Kohorte",
        accessor: (row) =>
          row.matchStudiengangStudents?.[0]?.zeitraum?.zeitraumNameKuerzel && row.studienstartStudiengangKuerzel
            ? `${row.matchStudiengangStudents?.[0]?.zeitraum?.zeitraumNameKuerzel ?? ""}-${
                row.studienstartStudiengangKuerzel ?? ""
              }`
            : "",
        id: "kohorte",
        remoteOrder: ({ order, sort }) => ({
          studienstartSemesterId: sort,
        }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, {
              or: [
                {
                  matchStudiengangStudents: { some: { zeitraum: { zeitraumNameKuerzel: { contains: filterValue } } } },
                },
                {
                  matchStudiengangStudents: {
                    some: { studiengang: { studiengangKuerzel: { contains: filterValue } } },
                  },
                },
              ],
            }),
        }),
      },
      {
        Header: "Studiengang",
        accessor: (row) => row.matchStudiengangStudents?.[0]?.studiengang?.studiengangName ?? "",
        id: "studiengang",
        // remoteOrder: ({ order, sort }) => ({
        //   matchStudiengangStudents: { some: { studiengangSemester: { studiengang: { studiengangName: sort } } } },
        // }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, {
              matchStudiengangStudents: {
                some: { studiengang: { studiengangName: { contains: filterValue } } },
              },
            }),
        }),
      },
      {
        Header: "Semester",
        accessor: (row) => row.matchStudiengangStudents?.[0]?.zeitraum?.zeitraumName ?? "",
        id: "semester",
        // remoteOrder: ({ order, sort }) => ({
        //   studiengangSemester: { semester: { semesterName: sort } },
        // }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, {
              matchStudiengangStudents: {
                some: { zeitraum: { zeitraumName: { contains: filterValue } } },
              },
            }),
        }),
      },
      {
        Header: "Studiumart",
        accessor: (row) => row.matchStudiengangStudents?.[0]?.studiumArt?.studiumArtName ?? "",
        id: "Studiumart",
        width: 60,
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, {
              matchStudiengangStudents: {
                some: { studiumArt: { studiumArtName: { contains: filterValue } } },
              },
            }),
        }),
      },
      {
        Header: "Status",
        accessor: (row) => getCurrentStatus(row),
        id: "Status",
        width: 60,
        remoteFilter: {
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, {
              matchStudiengangStudents: {
                some: { studiumArt: { studiumArtName: { contains: filterValue } } },
              },
            }),
          Filter: NoFilter,
        },
        // eslint-disable-next-line react/display-name
        // filter: () => <NoFilter />,
        // remoteFilter: makeSeverSideFilter<string>({
        //   filterPath: ({ filterValue, where, merge }) =>
        //     merge(where, {
        //       matchStudiengangStudents: {
        //         some: { studiumArt: { studiumArtName: { contains: filterValue } } },
        //       },
        //     }),
        // }),
      },
      {
        Header: "",
        accessor: "id",
        id: "id",
        width: 60,
        Cell: ActionCell,
      },
    ],
    [refetch, matches]
  );

  const { TableComponent: NewTableComponent } = useMakeServerSideTable(
    {
      data: data?.students ?? [],
      columns,
      initialState: {
        pageSize: 25,
        hiddenColumns: isZertifikat ? ["emailKh"] : [],
      },
    },
    {
      query,
      variables,
      serversideQueryConfig,
      tableId: `Bewerberlist-${formType}`,
    }
  );

  return (
    <div
      style={{
        width: "100%",
        marginTop: "2em",
        paddingLeft: padding ? "10px" : "15px",
        paddingRight: padding ? "10px" : "15px",
      }}
    >
      <TableTopContainer>
        <DownloadAllStudentIdCardsButton />
        <DownloadAllTranscriptOfRecordsButton />
        <DownloadAllAllCertificateOfStudyButton />
        {formType !== "abgebrochen" && formType !== "zertifikatsTeilnehmerAbgebrochen" && (
          <StudentAddButton key="student-add-button-1" formType={formType} />
        )}
      </TableTopContainer>
      <NewTableComponent tableProps={{ rowsPerPageOptions: [10, 25, 50, 100] }} />
    </div>
  );
};

export default StudentList;
