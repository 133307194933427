import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetStudentsForMatchMoodleModulStudentsQueryVariables = Types.Exact<{
  skip?: Types.Maybe<Types.Scalars['Int']>;
  take?: Types.Maybe<Types.Scalars['Int']>;
  where?: Types.Maybe<Types.StudentFilterInput>;
  order?: Types.Maybe<Array<Types.StudentSortInput> | Types.StudentSortInput>;
}>;


export type GetStudentsForMatchMoodleModulStudentsQuery = { students: Array<{ __typename?: 'Student', id: number, anrede?: Types.Maybe<string>, nachname?: Types.Maybe<string>, vorname?: Types.Maybe<string>, geburtsdatum?: Types.Maybe<string>, matchStudiengangStudents: Array<{ __typename?: 'MatchStudiengangStudent', id: number, studiengang?: Types.Maybe<{ __typename?: 'Studiengang', id: number, studiengangName?: Types.Maybe<string> }> }> }> };


export const GetStudentsForMatchMoodleModulStudentsDocument = gql`
    query getStudentsForMatchMoodleModulStudents($skip: Int, $take: Int, $where: StudentFilterInput, $order: [StudentSortInput!]) {
  students(where: $where, order: $order, skip: $skip, take: $take) {
    id
    anrede
    nachname
    vorname
    geburtsdatum
    matchStudiengangStudents {
      id
      studiengang {
        id
        studiengangName
      }
    }
  }
}
    `;

/**
 * __useGetStudentsForMatchMoodleModulStudentsQuery__
 *
 * To run a query within a React component, call `useGetStudentsForMatchMoodleModulStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsForMatchMoodleModulStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsForMatchMoodleModulStudentsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetStudentsForMatchMoodleModulStudentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStudentsForMatchMoodleModulStudentsQuery, GetStudentsForMatchMoodleModulStudentsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStudentsForMatchMoodleModulStudentsQuery, GetStudentsForMatchMoodleModulStudentsQueryVariables>(GetStudentsForMatchMoodleModulStudentsDocument, baseOptions);
      }
export function useGetStudentsForMatchMoodleModulStudentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStudentsForMatchMoodleModulStudentsQuery, GetStudentsForMatchMoodleModulStudentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStudentsForMatchMoodleModulStudentsQuery, GetStudentsForMatchMoodleModulStudentsQueryVariables>(GetStudentsForMatchMoodleModulStudentsDocument, baseOptions);
        }
export type GetStudentsForMatchMoodleModulStudentsQueryHookResult = ReturnType<typeof useGetStudentsForMatchMoodleModulStudentsQuery>;
export type GetStudentsForMatchMoodleModulStudentsLazyQueryHookResult = ReturnType<typeof useGetStudentsForMatchMoodleModulStudentsLazyQuery>;
export type GetStudentsForMatchMoodleModulStudentsQueryResult = ApolloReactCommon.QueryResult<GetStudentsForMatchMoodleModulStudentsQuery, GetStudentsForMatchMoodleModulStudentsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
