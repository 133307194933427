/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-key */
/* eslint-disable filenames/match-exported */
import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Props from "./index.d";
import DefaultInput, { DefaultInputProps } from "components/atomics/DefaultInput";
import { gql, useApolloClient } from "@apollo/client";
import { GetCheckIfMoodleIdExistsInOtherModulesDocument } from "graphqlBase/MoodleModul/__generated__/getCheckIfMoodleIdExistsInOtherModules";
import { useRecoilState } from "recoil";
import { disabledAtom } from "../sumbitButtonMoodle";

const useStyles = makeStyles<Theme, DefaultInputProps>(
  (theme) =>
    createStyles({
      decimalEditorRoot: {},
      root: {
        width: "100%",
      },
      titleBox: {},
      titleError: {
        color: theme.colors.red,
      },
      title: {
        color: theme.colors.green,
      },
    }),
  { name: "DecimalEditor" }
);

const DecimalEditorInner: React.FC<Props> = (props) => {
  const [disabledButton, setDisabledSubmitButton] = useRecoilState(disabledAtom);

  const {
    data: { state, setstate, setAndSave, onBlur, setNull },
    title,
    disabled,
    showMessage,
  } = props;

  const client = useApolloClient();

  const [error, setError] = useState(0);
  const [disabledInner, setdisabledInner] = useState(false);
  const query = gql`
    query checkIfMoodleModuleExists($moodleModuleId: Int!) {
      checkIfMoodleModuleExists(moodleModuleId: $moodleModuleId)
    }
  `;
  useEffect(() => {
    if (state === undefined) {
      setDisabledSubmitButton(false);
    }
  }, [state]);

  const callValidationApi = async (moodleModuleInternId: number) =>
    await client
      .query({
        query: GetCheckIfMoodleIdExistsInOtherModulesDocument,
        variables: { moodleModuleInternId: moodleModuleInternId },
        fetchPolicy: "no-cache",
      })

      .then((res) => !!res.data.checkIfMoodleIdExistsInOtherModules)
      .catch((e) => false);

  const validateMoodleInternId = () => {
    setdisabledInner(false);
    setDisabledSubmitButton(true);

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    callValidationApi(+state).then((isValid) => {
      if (isValid) {
        onBlur();
        setError(0);
        setdisabledInner(false);
      } else {
        setDisabledSubmitButton(false);

        setError(state);
        setAndSave(state);

        setdisabledInner(false);
      }
    });
  };
  const callValidationApiMoodleMoodleInternId = async (moodleModuleId: number) =>
    await client
      .query({
        query: query,
        variables: { moodleModuleId: moodleModuleId },
      })

      .then((res) => !!res.data.checkIfMoodleModuleExists)
      .catch((e) => false);

  const validateMoodleId = () => {
    setdisabledInner(false);
    setDisabledSubmitButton(false);

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    callValidationApiMoodleMoodleInternId(+state).then((isValid) => {
      if (isValid) {
        onBlur();
        setError(0);
        setdisabledInner(false);
      } else {
        setDisabledSubmitButton(true);

        setError(state);
        setAndSave(state);

        setdisabledInner(false);
      }
    });
  };
  const classes = useStyles(props);
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = event.target.value
      .replace(",", ".")
      .split(".")
      .map((v, i) => {
        if (v === "" || v === "undefined") return "";
        const num = v !== "" ? v.slice(0, i ? 5 : 15) : "0";
        if (isNaN(+num)) return "0";
        return num;
      })
      .slice(0, 1)
      .join(".");

    if (value.length) {
      setstate((+value as unknown) as number);
    } else {
      if (setNull) {
        setNull();
      } else {
        setstate((+value as unknown) as number);
      }
    }
  };

  const onBlurInner = () => {
    if (!state) {
      if (setNull) {
        setNull();
      } else {
        setAndSave(0);
      }
    } else {
      validateMoodleId();
      validateMoodleInternId();
    }
  };
  return (
    <>
      <DefaultInput
        fullWidth
        className={classes.decimalEditorRoot}
        onBlur={onBlurInner}
        value={state ?? ""}
        label={title}
        onChange={handleChange}
        disabled={disabled || disabledInner}
      />
      {showMessage && !!state ? (
        state !== error && disabledButton ? (
          <Typography className={classes.titleError} variant="h4">
            Es existiert bereits eine Verknüpfung zu diesem Moodle Modul!
          </Typography>
        ) : state === error && disabledButton ? (
          <Typography className={classes.titleError} variant="h4">
            Es existiert aktuell kein Moodle Modul mit der Id {error}!
          </Typography>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </>
  );
};

const DecimalEditor: React.FC<Props> = (props) => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <DecimalEditorInner {...props} />
      {(props.errors ?? []).map((e, index) => (
        <div className={classes.inputTitle}>
          <Typography key={index} align="left" variant="inherit">
            {e}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default DecimalEditor;
