/* eslint-disable @typescript-eslint/no-floating-promises */
// eslint-disable-next-line filenames/match-exported
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { useGetUploadFilesLazyQuery } from "graphqlBase/Beweber/__generated__/getUploadedFiles";
import { useDeleteUploadedDocumentMutation } from "graphqlBase/Beweber/__generated__/deleteUploadedDocument";
import TrashOutlinedIcon from "icons/components/TrashIcon";
import React, { useEffect, useState } from "react";
import theme from "themes/theme";
import Props from "./index.d";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListSubheader,
  Typography,
} from "@material-ui/core";
import Button from "components/lbc-toolkit/atomics/Button/DefaultButton";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        margin: "2em 0",
      },
      listRootHorizontal: {
        // height: "80vh",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },
      listRoot: {
        height: "80vh",
        width: "100%",
      },
      listRootInner: {
        width: "100%",
        maxHeight: "80%",
        overflowY: "auto",
      },
      listItem: {
        paddingLeft: 0,
        paddingRight: 0,
        display: "flex",
        background: theme.colors.grey50,
        marginTop: 0,
      },
      listItemText: {
        flex: "1 1 0",
      },
      header: {
        fontWeight: "bold",
      },
      headerEnd: {
        // textAlign: "end",
      },
      deleteButton: {
        "&:hover": {
          backgroundColor: theme.colors.tTransparent,
        },
        "& svg": {
          color: theme.colors.grey500,
          width: "1em",
          height: "1em",
        },
      },
      cancelButton: {
        alignItems: "center",
        appearance: "none",
        backgroundColor: theme.colors.tWhite,
        borderRadius: "0px",
        color: theme.colors.tBlack,
        display: "flex",
        flexDirection: "row",
        fontSize: "15px",
        fontWeight: 500,
        justifyContent: "center",
        lineHeight: "26px",
        letterSpacing: "0.46px",
        width: "217px",
        "&:hover": {
          alignItems: "center",
          appearance: "none",
          backgroundColor: theme.colors.tWhite,
          borderRadius: "0px",
          color: theme.colors.tBlack,
          display: "flex",
          flexDirection: "row",
          fontSize: "15px",
          fontWeight: 500,
          justifyContent: "center",
          lineHeight: "26px",
          letterSpacing: "0.46px",
          width: "217px",
        },
      },
      confirmButton: {
        alignItems: "center",
        appearance: "none",
        backgroundColor: theme.colors.kolpingOrange,
        borderRadius: "0px",
        color: theme.colors.tWhite,
        display: "flex",
        flexDirection: "row",
        fontSize: "15px",
        fontWeight: 500,
        justifyContent: "center",
        lineHeight: "26px",
        letterSpacing: "0.46px",
        width: "217px",
        "&:hover": {
          alignItems: "center",
          appearance: "none",
          backgroundColor: theme.colors.kolpingOrange,
          borderRadius: "0px",
          color: theme.colors.tWhite,
          display: "flex",
          flexDirection: "row",
          fontSize: "15px",
          fontWeight: 500,
          justifyContent: "center",
          lineHeight: "26px",
          letterSpacing: "0.46px",
          width: "217px",
        },
      },
      title: {
        fontWeight: "bold",
      },
      downloadeButton: {
        "&:hover": {
          backgroundColor: theme.colors.tTransparent,
        },
        "& svg": {
          color: theme.colors.grey500,
          marginTop: "0.3em",
          width: "1.5em",
          height: "1.5em",
        },
      },
    }),
  { name: "FileList-Table" }
);

const FileList: React.FC<Props> = (props) => {
  const {
    data: { state },
  } = props;
  const classes = useStyles();
  const [query, { data, refetch }] = useGetUploadFilesLazyQuery({
    fetchPolicy: "cache-and-network",
  });
  const [fileToDelete, setFileToDelete] = useState<number>();
  useEffect(() => {
    if (state)
      query({
        variables: { beweberId: state },
      });
  }, [state]);
  const onButtonClick = (id: number) => {
    (data?.uploadedFiles ?? []).map((entry) => {
      if (id === entry.id)
        fetch(entry.fileName).then((response) => {
          response.blob().then(() => {
            const fileURL = entry.downloadUri;
            const alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = entry.fileName;
            alink.click();
          });
        });
    });
  };

  const cancelDeleteFile = () => {
    setFileToDelete(undefined);
  };
  const [removeDocPre] = useDeleteUploadedDocumentMutation();
  const removeDoc = () => {
    removeDocPre({ variables: { uploadFileId: fileToDelete ?? 0 } })
      .catch(refetch)
      .catch(console.log);
    setFileToDelete(undefined);
  };
  return (
    <div className={classes.root}>
      <div className={classes.listRoot}>
        <div className={classes.listRootInner}>
          <List aria-label="Document-Tabelle" disablePadding>
            <ListSubheader className={classes.listItem}>
              <ListItemText className={clsx(classes.header)} disableTypography primary={"Name"} />
              <ListItemText className={clsx(classes.header, classes.headerEnd)} disableTypography primary={"Type"} />
            </ListSubheader>
            <Divider />
            {(data?.uploadedFiles ?? []).map((entry) => {
              if (entry.uploadFileType?.isDeleted) return null;
              return (
                <div key={entry.id}>
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      className={clsx(classes.headerEnd, classes.listItemText)}
                      disableTypography
                      primary={`${entry?.fileName}`}
                    />
                    <ListItemText
                      disableTypography
                      className={classes.listItemText}
                      primary={entry?.uploadFileType?.fileTypeName ?? ""}
                    />
                    {!!entry.id && (
                      <>
                        <div>
                          <IconButton className={classes.downloadeButton} onClick={() => onButtonClick(entry.id)}>
                            <GetAppIcon />
                          </IconButton>
                          <IconButton className={classes.deleteButton} onClick={() => setFileToDelete(entry.id)}>
                            <TrashOutlinedIcon />
                          </IconButton>
                        </div>
                        <div>
                          <Dialog
                            open={!!fileToDelete}
                            onClose={cancelDeleteFile}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle
                              disableTypography
                              id="alert-dialog-title"
                              style={{ backgroundColor: theme.colors.grey50 }}
                            >
                              <Typography className={classes.title} variant="h4">
                                Datei Löschen
                              </Typography>
                            </DialogTitle>
                            <DialogContent style={{ backgroundColor: theme.colors.grey50 }}>
                              <DialogContentText id="alert-dialog-description">
                                Sind Sie sicher, dass Sie die Datei löschen wollen?
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions style={{ backgroundColor: theme.colors.grey50 }}>
                              <Button
                                classes={{ colorPrimary: classes.cancelButton }}
                                disableRipple
                                onClick={cancelDeleteFile}
                                autoFocus
                                label="Abrechnen"
                                buttonType="primary"
                              />
                              <Button
                                classes={{ colorPrimary: classes.confirmButton }}
                                disableRipple
                                onClick={() => removeDoc()}
                                label="Löschen"
                                buttonType="primary"
                              />
                            </DialogActions>
                          </Dialog>
                        </div>
                      </>
                    )}
                  </ListItem>
                  <Divider />
                </div>
              );
            })}
          </List>
        </div>
      </div>
    </div>
  );
};
export default FileList;
