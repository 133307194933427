import type * as Types from "../../types";

import gql from "graphql-tag";
import type * as ApolloReactCommon from "@apollo/react-common";
import * as ApolloReactHooks from "@apollo/client";
export type GetMyStudentIdCardQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetMyStudentIdCardQuery = {
  myStudentIdCard?: Types.Maybe<{
    __typename?: "DownloadFile";
    fileName?: Types.Maybe<string>;
    fileContent?: Types.Maybe<Array<any>>;
    fileContentBase64?: Types.Maybe<string>;
    fileType?: Types.Maybe<string>;
    statusMessage?: Types.Maybe<string>;
    downloadUri?: Types.Maybe<string>;
  }>;
};

export const GetMyStudentIdCardDocument = gql`
  query getMyStudentIdCard {
    myStudentIdCard {
      fileName
      fileContent
      fileContentBase64
      fileType
      statusMessage
      downloadUri
    }
  }
`;

/**
 * __useGetMyStudentIdCardQuery__
 *
 * To run a query within a React component, call `useGetMyStudentIdCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyStudentIdCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyStudentIdCardQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyStudentIdCardQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyStudentIdCardQuery, GetMyStudentIdCardQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetMyStudentIdCardQuery, GetMyStudentIdCardQueryVariables>(
    GetMyStudentIdCardDocument,
    baseOptions
  );
}
export function useGetMyStudentIdCardLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyStudentIdCardQuery, GetMyStudentIdCardQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetMyStudentIdCardQuery, GetMyStudentIdCardQueryVariables>(
    GetMyStudentIdCardDocument,
    baseOptions
  );
}
export type GetMyStudentIdCardQueryHookResult = ReturnType<typeof useGetMyStudentIdCardQuery>;
export type GetMyStudentIdCardLazyQueryHookResult = ReturnType<typeof useGetMyStudentIdCardLazyQuery>;
export type GetMyStudentIdCardQueryResult = ApolloReactCommon.QueryResult<
  GetMyStudentIdCardQuery,
  GetMyStudentIdCardQueryVariables
>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
