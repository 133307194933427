import { makeStyles } from "@material-ui/core/styles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import {
  activeCommon,
  disabledCommon,
  hoverCommon,
  darkDisabledCommon,
} from "themes/Button/IconButton/commonButtonStyles";

export const useOtherStyles = makeStyles((theme) => ({
  label: {
    textTransform: "uppercase",
    boxSizing: "content-box",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 13,
    lineHeight: "26px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    letterSpacing: "0.46px",
  },
  danger: {
    backgroundColor: theme.colors.red400,
    color: theme.colors.tWhite,
    border: 0,
    ...disabledCommon,
    "& svg": {
      color: theme.colors.tWhite,
    },
    "&:hover": {
      backgroundColor: theme.colors.red500,
    },
    "&:active": {
      backgroundColor: theme.colors.red600,
    },
  },
  radial: {
    color: theme.colors.tBlack,
    backgroundColor: theme.colors.grey100,
    borderColor: theme.colors.grey100,
    borderRadius: 20,
    ...disabledCommon,
    ...hoverCommon,
    ...activeCommon,
  },
  darkPrimary: {
    ...darkDisabledCommon,
  },
  darkSecondary: {
    backgroundColor: theme.colors.tBlack,
    color: theme.colors.tWhite,
    border: `1px solid ${theme.colors.tWhite}`,
    "& svg": {
      color: theme.colors.tWhite,
    },
    "&:disabled": {
      backgroundColor: theme.colors.grey400,
      color: theme.colors.grey100,
      borderColor: theme.colors.grey400,
      "& div svg": {
        color: theme.colors.grey100,
      },
      "&:hover": {
        backgroundColor: theme.colors.grey400,
        color: theme.colors.grey100,
        borderColor: theme.colors.grey400,
      },
    },
    "&:hover": {
      backgroundColor: theme.colors.grey300,
      color: theme.colors.tBlack,
      borderColor: theme.colors.grey300,
      "& svg": {
        color: theme.colors.tBlack,
      },
    },
    "&:active": {
      backgroundColor: theme.colors.tWhite,
      color: theme.colors.tBlack,
    },
  },
  darkDanger: {
    backgroundColor: theme.colors.red500,
    color: theme.colors.tWhite,
    ...darkDisabledCommon,
    "&:hover": {
      backgroundColor: theme.colors.red400,
      color: theme.colors.tWhite,
    },
    "&:active": {
      backgroundColor: theme.colors.red500,
      color: theme.colors.tWhite,
    },
  },
  darkRadial: {
    backgroundColor: theme.colors.grey100,
    color: theme.colors.tBlack,
    borderColor: theme.colors.grey100,
    ...darkDisabledCommon,
    "&:hover": {
      backgroundColor: theme.colors.grey700,
      color: theme.colors.grey100,
    },
    "&:active": {
      backgroundColor: theme.colors.tBlack,
      color: theme.colors.grey100,
    },
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    "& svg": {
      fontSize: 18,
      margin: "auto",
    },
  },
  combinedWrapper: {
    display: "grid",
    gridTemplateRows: 40,
    gridTemplateColumns: "40px 0.5fr 3.5fr 0.5fr 40px",
    alignItems: "center",
    width: 217,
    "& svg": {
      fontSize: 18,
      gridRowStart: 1,
      gridRowEnd: 1,
      gridColumnStart: 3,
      gridColumnEnd: 2,
    },
    "& span": {
      fontSize: 21,
      margin: 0,
      marginLeft: 15,
      marginRight: 15,
      textTransform: "none",
      gridRowStart: 1,
      gridRowEnd: 2,
      gridColumnStart: 3,
      gridColumnEnd: "auto",
    },
  },
}));

export const getTheme = (theme: string, buttonType: string, otherClasses: ClassNameMap<string>) => {
  if (theme === "dark")
    if (buttonType === "primary") return otherClasses.darkPrimary;
    else if (buttonType === "secondary") return otherClasses.darkSecondary;
    else if (buttonType === "danger") return otherClasses.darkDanger;
    else if (buttonType === "radial") return otherClasses.darkRadial;
};
