import React from "react";
import StudentList from "../StudentList";

const where = {
  ausbildungsArtId: { eq: 2 },
  matchStudentSemesters: { some: { studentSemesterStatusId: { in: [5] } } },
};
const type = "zertifikatsTeilnehmerAbgebrochen";

const ZertifikatsTeilnehmerAbgebrochenListe: React.FC<{}> = () => {
  return <StudentList formType={type} where={where} isZertifikat />;
};

export default ZertifikatsTeilnehmerAbgebrochenListe;
