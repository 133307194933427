import * as React from "react";
import { makeStyles, createStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Props from "./index.d";
import tableColors from "components/templates/table-factory/theme/tableColors";

const useStyles = makeStyles(
  (theme: AugmentedTheme) =>
    createStyles({
      TitleRoot: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        height: 80,
        "& :disabled": {
          color: theme.colors.grey500,
          cursor: "not-allowed",
        },
      },
      title: {
        color: (props: { variant: string; disabled: boolean }) =>
          props.disabled ? tableColors.lbcgrey : theme.colors.tBlack,
        cursor: (props: { variant: string; disabled: boolean }) => (props.disabled ? "not-allowed" : "default"),
        letterSpacing: 0,
        fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
        fontSize: (props: { variant: string; disabled: boolean }) => {
          switch (props.variant) {
            case "h1": {
              return "56px";
            }
            case "h2": {
              return "32x";
            }
            case "h3": {
              return "24px";
            }
            case "h4": {
              return "21px";
            }
            case "h5": {
              return "16px";
            }
            case "h6": {
              return "14px";
            }
            case "p": {
              return "16px";
            }
            case "pLarge": {
              return "21px";
            }
            case "pSmall": {
              return "14px";
            }
            case "pSmallest": {
              return "11px";
            }
          }
        },
        fontWeight: (props: { variant: string; disabled: boolean }) => {
          switch (props.variant) {
            case "h1": {
              return 700;
            }
            case "h2": {
              return 700;
            }
            case "h3": {
              return 700;
            }
            case "h4": {
              return 700;
            }
            case "h5": {
              return 700;
            }
            case "h6": {
              return 700;
            }
            case "p": {
              return 400;
            }
            case "pLarge": {
              return 400;
            }
            case "pSmall": {
              return 400;
            }
            case "pSmallest": {
              return 400;
            }
          }
        },
        lineHeight: (props: { variant: string; disabled: boolean }) => {
          switch (props.variant) {
            case "p": {
              return 1.5;
            }
            case "pLarge": {
              return 1.5;
            }
            case "pSmall": {
              return 1.5;
            }
            case "pSmallest": {
              return 1.5;
            }
          }
        },
      },
    }),
  { name: "Title" }
);

const TitleComponent: React.FC<Props> = (props) => {
  const { title, disabled, variant } = props;
  const classes = useStyles({ variant, disabled });
  return (
    <div className={classes.TitleRoot}>
      <Typography align="right" className={classes.title}>
        {title}
      </Typography>
    </div>
  );
};

export default TitleComponent;
