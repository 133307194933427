import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { prepareServerSideTable } from "components/templates/table-factory";
import { GetModulsQuery, ResultType, useGetModulsLazyQuery } from "graphqlBase/Modul/__generated__/getModuls";
import { ModulFilterInput, ModulSortInput, SortEnumType } from "graphqlBase/types";
import React from "react";
import { useHistory } from "react-router";
import actionsCellFactory from "../../../../templates/table-factory/Table/Cells/actionsCellFactory";
import ModuleAddButton from "../ModuleAdd/ModuleAddButton";
import TableTopContainer from "components/atomics/TableTopContainer";
import { QueryVariables } from "components/templates/table-factory2/Table/useMakeServerSideTable";

const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      root: { width: "100%", marginTop: "2em", paddingLeft: "28px", paddingRight: "33px" },
    }),
  { name: `modue-ModuleList` }
);

type Modul = ResultType<GetModulsQuery["moduls"]>;

interface ModuleBaseListProps {
  variables: QueryVariables<ModulFilterInput, ModulSortInput[]>;
  isZertifikat?: boolean;
}

const baseVariables = {
  order: [{ modulName: "ASC" as SortEnumType.Asc }],
};

const { useCreateServerSideColumns, useMakeServerSideTable, makeSeverSideFilter } = prepareServerSideTable<
  Modul,
  ModulFilterInput,
  ModulSortInput,
  SortEnumType
>();

const ModuleBaseList: React.FC<ModuleBaseListProps> = ({ variables, isZertifikat }) => {
  const history = useHistory();
  const classes = useStyles();
  const [query, { data, refetch }] = useGetModulsLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const handleEdit = (value: number) => {
    history.push(`${isZertifikat ? "zertifikatsModuleEdit" : "moduleEdit"}/${value}`);
  };

  const ActionCell = actionsCellFactory({
    onEdit: handleEdit,
    onDelete: {
      entity: "modul",
      afterDelete: () => {
        if (refetch) refetch().catch(() => {});
      },
    },
  });

  const { columns, serversideQueryConfig } = useCreateServerSideColumns(
    [
      {
        Header: "Modulname",
        accessor: (row) => row.modulName,
        id: "modulname",
        // remoteOrder: ({ order, sort }) => ({ vorname: sort }),
        remoteOrder: ({ order, sort }) => ({ modulName: sort }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) => merge(where, { modulName: { contains: filterValue } }),
        }),
      },
      {
        Header: "Studiengangtyp",
        accessor: (row) => row.studiengangTyp?.studiengangTypKuerzel,
        id: "Studiengangtyp",
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { studiengangTyp: { studiengangTypKuerzel: { contains: filterValue } } }),
        }),
      },
      {
        Header: "ECTS Punkte",
        accessor: (row) => row.ectspunkte,
        id: "ectspunkte",
        remoteFilter: makeSeverSideFilter<number>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { ectspunkte: { eq: filterValue && +filterValue } }),
        }),
      },
      {
        Header: "Workload",
        accessor: (row) => row.workload,
        id: "workload",
        remoteFilter: makeSeverSideFilter<number>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { workload: { eq: filterValue && +filterValue } }),
        }),
      },
      {
        Header: "Digitale Präsenzstunden",
        accessor: (row) => row.digitalePraesenzstunden,
        id: "digitalePraesenzstunden",
        remoteFilter: makeSeverSideFilter<number>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { digitalePraesenzstunden: { eq: filterValue && +filterValue } }),
        }),
      },
      {
        Header: "Lokale Präsenzstunden",
        accessor: (row) => row.lokalePraesenzstunden,
        id: "lokalePraesenzstunden",
        remoteFilter: makeSeverSideFilter<number>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { lokalePraesenzstunden: { eq: filterValue && +filterValue } }),
        }),
      },
      {
        Header: "Case Days",
        accessor: (row) => row.caseDays,
        id: "caseDays",
        remoteFilter: makeSeverSideFilter<number>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { caseDays: { eq: filterValue && +filterValue } }),
        }),
      },
      {
        Header: "Digital gestützte Lehre",
        accessor: (row) => row.digitalGestuetzeLehre,
        id: "digitalGestuetzteLehre",
        remoteFilter: makeSeverSideFilter<number>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { digitalGestuetzeLehre: { eq: filterValue && +filterValue } }),
        }),
      },
      {
        Header: "Selbststudienzeit",
        accessor: (row) => row.selbststudienzeit,
        id: "selbststudienzeit",
        remoteFilter: makeSeverSideFilter<number>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { selbststudienzeit: { eq: filterValue && +filterValue } }),
        }),
      },
      {
        Header: "",
        accessor: "id",
        id: "id",
        Cell: ActionCell,
        width: 60,
      },
    ],
    [refetch]
  );

  const { TableComponent } = useMakeServerSideTable(
    {
      data: data?.moduls ?? [],
      columns,
      initialState: { pageSize: 25, hiddenColumns: isZertifikat ? ["fachpruefungsordnung"] : [] },
    },
    {
      query,
      variables: { ...baseVariables, ...variables },
      serversideQueryConfig,
      tableId: "ModulList",
    }
  );

  return (
    <div className={classes.root}>
      <TableTopContainer>
        <ModuleAddButton key="modul-add-button-1" isZertifikat={isZertifikat} />
      </TableTopContainer>
      <TableComponent tableProps={{ rowsPerPageOptions: [10, 25, 50, 100] }} />
    </div>
  );
};

export default ModuleBaseList;
