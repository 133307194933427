import { ApolloClient, HttpLink, gql, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import cache from "./cache";
import resolvers from "./resolvers";
import initialCacheData from "./cache/initialCacheData";
import typeDefs from "./typeDefs";
import { logout, getTokenAsync } from "authorization";

const authLink = setContext(
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  (_, { headers }) =>
    new Promise((resolve, reject) => {
      getTokenAsync()
        .then((token) => {
          // console.log(token);
          resolve({
            headers: {
              ...headers,
              authorization: token ? `Bearer ${token}` : "",
            },
          });
        })
        .catch((e) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          logout().finally(() => reject(e));
        });
    })
);

const httpLink = new HttpLink({
  // uri: "/graphql/",
  uri: process.env.REACT_APP_GRAPHQL_URI,
});

const link = process.env.REACT_APP_USE_API === "CampusManagement" ? ApolloLink.from([authLink, httpLink]) : httpLink;

const client = new ApolloClient({
  cache: cache,
  link,
  resolvers,
  typeDefs,
  connectToDevTools: true,
});

client.writeQuery({
  query: gql`
    query Query {
      ${Object.keys(initialCacheData).join("\n")}
    }
  `,
  data: {
    initialCacheData,
  },
});

export default client;
