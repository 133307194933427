import { DocumentNode } from "graphql";
import { QueryArray } from "../formGen.model";

const regex = /(?:\(.*\)) *{/gm;
const subst = ` {`;

function findClosingBracket(str: string, pos: any) {
  const rExp = /\{|\}/gm;
  rExp.lastIndex = pos + 1;
  var deep = 1;
  while ((pos = rExp.exec(str))) {
    if (!(deep += str[pos.index] === "{" ? 1 : -1)) {
      return pos.index;
    }
  }
}

export default ({
  updateAfterCreateQueries,
  entity,
}: {
  updateAfterCreateQueries?: QueryArray;
  entity: string;
}): { fields: string[]; fragments: string[] } => {
  if (!updateAfterCreateQueries) return { fields: [], fragments: [] };

  const { fields, fragments } = updateAfterCreateQueries.reduce(
    ({ fields, fragments }: { fields: string[]; fragments: string[] }, { query }) => {
      const [currentFields, currentFragments] = getBodyParts({ query, entity });

      return {
        fields: fields.concat(currentFields),
        fragments: fragments.concat(currentFragments),
      };
    },
    { fields: [], fragments: [] }
  );
  return { fields: fields, fragments: fragments };
};

const getBodyParts = ({ query, entity }: { query: DocumentNode; entity: string }) => {
  let fragment = "";
  const search = `${entity}s {`;
  const body = (query.loc?.source.body ?? "").replace(regex, subst);

  const begin = body.indexOf(search) + search.length;
  const nextBody = body.slice(begin, findClosingBracket(body, begin));

  const fragmentBegin = body.indexOf("fragment");

  if (fragmentBegin) {
    fragment = body.slice(fragmentBegin, body.length);
  }
  return [nextBody, fragment];
};
