import * as React from "react";
import Props from "./index.d";
import GridBase from "../gridBase";

interface GFCFieldSet_Horizontal extends Props {
  children: React.ReactNode;
}

const GridItem: React.FC<GFCFieldSet_Horizontal> = (props) => {
  return (
    <GridBase item {...props}>
      {props.children}
    </GridBase>
  );
};
export default GridItem;
