type ContentType =
  | "application/pdf"
  | "application/zip"
  | "text/comma-separated-values"
  | "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export default function downloadBase64File(contentType: ContentType, base64String: string, fileName: string) {
  const linkSource = `data:${contentType};base64,${base64String}`;
  const downloadLink = document.createElement("a");
  document.body.appendChild(downloadLink);
  downloadLink.href = linkSource;
  downloadLink.target = "_self";
  downloadLink.download = fileName;
  downloadLink.click();
}

export function openBase64FileInNewTab(data: string) {
  const w = window.open("about:blank");
  if (w !== null) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetch(data)
      .then(async (res) => await res.blob())
      .then((blob) => {
        const objectUrl = URL.createObjectURL(blob);
        const iFrame = w.document.createElement("iframe");
        iFrame.src = objectUrl;
        iFrame.style.width = "100vw";
        iFrame.style.height = "100vh";
        iFrame.style.border = "0";
        w.document.body.style.margin = "0";
        w.document.body.style.padding = "0";
        w.document.body.appendChild(iFrame);
      });
  }
}
