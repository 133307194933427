import { atom, useRecoilCallback } from "recoil";
import { MatchStudiengangModul, MatchModulPruefungTyp } from "../../../../graphqlBase/types";

type StudiengangModul = Pick<MatchStudiengangModul, "id" | "studiengangId" | "planSemester" | "modulId">;

export interface ModulZuordnungen extends StudiengangModul {}

export const moduleDropdownIds = atom<number[]>({ key: "moduleDropdownIds", default: [] });

export const modulZuordnungen = atom<ModulZuordnungen[]>({
  key: "modulZuordnungen",
  default: [],
});

type ModulPruefungTyp = Pick<
  MatchModulPruefungTyp,
  "id" | "pruefungTypId" | "dauer" | "modulId" | "anteil" | "isDeleted"
>;

export interface PruefungTypZuordnungen extends ModulPruefungTyp {}

export const pruefungTypDropdownIds = atom<number[]>({ key: "pruefungTypDropdownIds", default: [] });

export const pruefungTypZuordnungen = atom<PruefungTypZuordnungen[]>({
  key: "pruefungTypZuordnungen",
  default: [],
});

export const useNotificationCallback = () => {
  return useRecoilCallback(({ snapshot }) => async () => {
    const [modulZuordnungenList] = await Promise.all([snapshot.getPromise(modulZuordnungen)]);
    const [pruefungTypZuordnungenList] = await Promise.all([snapshot.getPromise(pruefungTypZuordnungen)]);
    return { modulZuordnungenList, pruefungTypZuordnungenList };
  });
};
