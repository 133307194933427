import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { formatISO, parseISO } from "date-fns";
import React, { useState } from "react";
import Props from "./index.d";

export type DatePickerProps = Props;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    DatePickerRoot: {
      // height: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
      margin: 10,
      // minHeight: 40,
      // padding: 20,
    },
    title: {
      // minWidth: 160,
      marginRight: 10,
      align: "right",
    },
  })
);

const dateTimeToISO = (date: MaterialUiPickersDate | Date) => {
  let isoString = "";
  if (date) {
    try {
      isoString = formatISO((date as unknown) as Date);
    } catch (e) {
      console.log(e, date);
    }
  }
  return isoString;
};

const DatePicker2: React.FC<Props> = (props) => {
  const {
    title,
    data: { state, setAndSave, setstate },
    ...rest
  } = props;
  const [stateInner, setstateInner] = useState(state ? parseISO(state) : null);
  const classes = useStyles();

  React.useEffect(() => {
    setstateInner(state ? parseISO(state) : null);
    return () => {};
  }, [state]);

  const setAndSaveInner = (date: MaterialUiPickersDate | Date) => {
    setAndSave(dateTimeToISO(date));
  };
  const handleChange = (date: MaterialUiPickersDate) => {
    if (date) setstateInner((date as unknown) as Date);
  };
  const handleAccept = (date: MaterialUiPickersDate) => {
    setAndSaveInner(date);
  };

  return (
    <div className={classes.DatePickerRoot}>
      <Typography align="right" className={classes.title} variant="body1">
        {title}:
      </Typography>
      <KeyboardDatePicker
        // {...translation.date}
        style={{ width: "10em" }}
        KeyboardButtonProps={{ style: { padding: 0 } }}
        placeholder="  .  .   "
        openTo="year"
        value={stateInner}
        disabled={props.disabled}
        onBlur={() => (stateInner ? setAndSaveInner(stateInner) : null)}
        onAccept={handleAccept}
        onChange={handleChange}
        format="dd.MM.yyyy"
        views={["year", "month", "date"]}
      />
    </div>
  );
};

export default DatePicker2;
