const tableColors = {
  black: "#000000",
  white: "#ffffff",
  whiteSmoke: "#F4F4F4",
  blue: "#223c8c",
  lightblue: "#B3D4FC",
  green: "#1D9A73",
  orange: "#F8A064",
  red: "#B63032",
  darkgray: "#737373",
  brown: "#351e10",
  brownLight: "#8b5b4c",
  brownVeryLight: "#c49357",
  verydarkgrey: "#616161",
  veryverydarkgrey: "#555555",
  lightgrey: "rgba(255, 255, 255, 0.7)",
  midgrey: "#d9d9d9",
  lbcgrey: "#e6e5e3",
  lbcyellow: "rgba(253, 196, 0, 0.6)",
  grey300: "#E0E0E0",
  grey700: "#44423e",
};

export default tableColors;
export const themeColors = tableColors;
export type Themetablecolors = keyof typeof themeColors;
