import { createStyles, makeStyles } from "@material-ui/core/styles";
import DefaultInput from "components/atomics/DefaultInput";
import FormHeader from "components/atomics/FormHeader";
import Select from "components/atomics/Select";
import { useGetPruefungsTypenForPruefungQuery } from "graphqlBase/PruefungTyp/__generated__/getPruefungsTypenForPruefung";
import React, { useEffect, useState } from "react";
import Props from "./index.d";
const useStyles = makeStyles(
  () =>
    createStyles({
      foreignKeySelectorIntBox: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },
      input: {
        marginTop: "16px",
        width: "100%",
      },
    }),
  { name: "foreignKeySelector" }
);
const PruefungsTypOnPruefung: React.FC<Props> = (props) => {
  const {
    data: { state, setAndSave },
    disabled,
    title,
    pickDefaultFirst,
    label,
    noHeader,
    errors,
    noFormControlMarginTop = false,
    raftVariables,
    //@ts-ignore
    update,
  } = props;

  interface Item {
    label: string;
    value: number;
  }

  const [items, setItems] = useState<Item[]>([]);
  const { data } = useGetPruefungsTypenForPruefungQuery({
    variables: { modulId: raftVariables.modulId ?? 0 },
    fetchPolicy: "network-only",
  });
  const [anteil, setAnteil] = useState("");

  useEffect(() => {
    const selectedTyps = data?.pruefungTyps.find(({ id }) => id === state);
    if (selectedTyps) {
      const newAnteil = selectedTyps.matchModulPruefungTyps[0].anteil;
      const dauer = selectedTyps.matchModulPruefungTyps[0].dauer;
      if (newAnteil) setAnteil(`${newAnteil}%`);
      update({ pruefungsdauer: dauer ?? 90 });
    }
  }, [state, data?.pruefungTyps]);

  useEffect(() => {
    if (data?.pruefungTyps.length && !items.length) {
      const filteredItems = data.pruefungTyps.reduce<Item[]>((filteredItemsAgg, elem) => {
        return filteredItemsAgg.concat({
          label: `${elem.pruefungTypName}`,
          value: elem.id,
        });
      }, []);
      setItems(filteredItems);
    }
  }, [data?.pruefungTyps]);

  useEffect(() => {
    if (pickDefaultFirst && !state && items.length) setAndSave(items[0].value);
  }, [items, state, pickDefaultFirst, setAndSave]);

  const classes = useStyles(props);
  return (
    <div className={classes.foreignKeySelectorIntBox}>
      {!noHeader && <FormHeader title={title} />}
      <Select
        label={label}
        setState={(value) => setAndSave(+value)}
        state={state}
        items={items}
        disabled={disabled}
        error={!!errors?.length}
        helperText={errors?.join(" ") ?? undefined}
        noFormControlMarginTop={noFormControlMarginTop}
      />
      <DefaultInput className={classes.input} value={anteil} disabled={true} label={"Anteil"} variant="outlined" />
    </div>
  );
};

export default PruefungsTypOnPruefung;
