// eslint-disable-next-line filenames/match-exported
import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import translations from "translations";
import Props from "./index.d";
import Modal from "components/lbc-toolkit/molecules/Modal";
import { Typography } from "@material-ui/core";
import { atom, useRecoilValue } from "recoil";

export const disabledAtom = atom<boolean>({
  key: "disabledAtom",
  default: false,
});
const useStylesDisabledButton = makeStyles<Theme>(
  (theme) =>
    createStyles({
      SubmitButton: {
        cursor: "pointer",
        height: "40px",
        alignItems: "center",
        appearance: "none",
        backgroundColor: theme.colors.christineRed,
        borderStyle: "none",
        // borderRadius: "4px",
        color: theme.colors.tWhite,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "217px",
        "&:disabled": {
          backgroundColor: theme.colors.lbcGrey,
          color: theme.colors.grey400,
        },
      },
      title: {
        textTransform: "uppercase",
        boxSizing: "content-box",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 15,
        lineHeight: "26px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        letterSpacing: "0.46px",
      },
    }),
  { name: "Button" }
);
const SubmitButtonMoodle: React.FC<Props> = (props) => {
  const {
    confirm,
    title,
    disabled,
    buttonLabel,
    data: { setAndSave },
  } = props;
  const classes = useStylesDisabledButton();
  const [submit, setSubmit] = useState(false);
  const disabledSubmitButton = useRecoilValue(disabledAtom);
  const setAndSaveInner = () => {
    if (!confirm) {
      setAndSave(true);
    } else {
      setSubmit(true);
    }
  };
  const submitAfterConfirm = () => {
    setSubmit(false);
    setAndSave(true);
  };

  return (
    <>
      <Modal
        open={!!submit}
        onClose={() => setSubmit(false)}
        onCancel={() => setSubmit(false)}
        onConfirm={submitAfterConfirm}
        primaryCancel
        title={title}
      >
        <Typography variant="h4">{translations.globals.popupMessages.saveData}</Typography>
      </Modal>
      <div className={classes.root}>
        <button className={classes.SubmitButton} disabled={disabledSubmitButton || disabled} onClick={setAndSaveInner}>
          <span className={classes.title}>{buttonLabel ?? translations.globals.terms.submit}</span>
        </button>
      </div>
    </>
  );
};
export default SubmitButtonMoodle;
