export default {
  name: "Gerät",
  plural: "Geräte",
  columns: {
    assetDevice: "Asset-Gerät",
    description: "Beschreibung",
    deviceAttributeValue: "Geräteattributwert",
    deviceCapability: "Gerätefähigkeit",
    deviceCapabilityId: "Gerätefähigkeit-ID",
    deviceModel: "Gerätemodell",
    deviceModelId: "Gerätemodell-ID",
    deviceModelVersion: "Gerätemodellversion",
    deviceModelVersionId: "Gerätemodellversion-ID",
    deviceName: "Gerätename",
    gatewayDeviceConnection: "Gateway-Geräteverbindung",
    id: "ID",
    inverseParentDevice: "inverses übergeordnetes Gerät",
    isDeleted: "ist gelöscht",
    parentDevice: "übergeordnetes Gerät",
    parentDeviceId: "übergeordnetes Gerät-ID",
    sensorId: "Sensor-ID",
    serialNo: "Seriennummer",
    tenantId: "Mieter ID",
  },
};
