import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TableTopContainer from "components/atomics/TableTopContainer";
import Modal from "components/lbc-toolkit/molecules/Modal";
import { prepareServerSideTable } from "components/templates/table-factory";
import actionsCellFactory from "components/templates/table-factory/Table/Cells/actionsCellFactory";
import {
  GetPruefungsergebniseQuery,
  GetPruefungsergebniseQueryVariables,
  ResultType,
  useGetPruefungsergebniseLazyQuery,
} from "graphqlBase/Pruefungsergebnis/__generated__/getPruefungsergebnise";
import { PruefungsergebniFilterInput, PruefungsergebniSortInput, SortEnumType } from "graphqlBase/types";
import mergePre from "lodash/merge";
import { PruefungsergebniUpdateForm } from "raft/PruefungsergebniForm";
import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { formatDateTime } from "translations/formatter";
import PruefungsVerlaufAddButton from "./PruefungsVerlaufAddButton";
import { modulIdForPruefungsCreate } from "./recoilState";
const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      root: { width: "100%", marginTop: "2em", paddingLeft: "30px", paddingRight: "30px", height: "50%" },
    }),
  { name: `StudentModul-StudienVerlauf` }
);

type Pruefungsergebnise = ResultType<GetPruefungsergebniseQuery["pruefungsergebnis"]>;

interface PruefungsVerlaufProps {
  modulId: number;
  studentId: number;
  refetchModulList?: () => void;
}

const { useCreateServerSideColumns, useMakeServerSideTable, makeSeverSideFilter } = prepareServerSideTable<
  Pruefungsergebnise,
  PruefungsergebniFilterInput,
  PruefungsergebniSortInput,
  SortEnumType
>();

const PruefungsVerlauf: React.FC<PruefungsVerlaufProps> = ({ modulId, studentId, refetchModulList }) => {
  const variables: { where: GetPruefungsergebniseQueryVariables["where"] } = {
    where: { and: [{ pruefung: { modulId: { eq: modulId } } }, { studentId: { eq: studentId } }] },
  };
  const classes = useStyles();
  const [queryPre, { data, refetch }] = useGetPruefungsergebniseLazyQuery({
    fetchPolicy: "cache-and-network",
    variables,
  });
  const setModulIdForPruefungsCreate = useSetRecoilState(modulIdForPruefungsCreate);
  const query = (vars: any) => {
    queryPre(mergePre(variables, vars.variables));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ergebnisId, setErgebnisId] = useState<number | null>(null);
  const handleEdit = (value: number) => {
    setErgebnisId(value);
    setIsModalOpen(true);
  };

  useEffect(() => {
    setModulIdForPruefungsCreate(modulId);
  }, [modulId, setModulIdForPruefungsCreate]);

  const ActionCell = actionsCellFactory({
    onEdit: handleEdit,
    // onDelete: {
    //   entity: "bewerber",
    //   afterDelete: () => {
    //     if (refetch)
    //       refetch().catch(() => {
    //         //
    //       });
    //   },
    // },
  });
  const closeModal = () => {
    if (refetchModulList) refetchModulList();
    setIsModalOpen(false);
  };
  const { columns, serversideQueryConfig } = useCreateServerSideColumns(
    [
      {
        Header: "Datum",
        accessor: (row) =>
          row.pruefung?.datumUhrzeitVon ? formatDateTime(new Date(row.pruefung.datumUhrzeitVon)) : "",
        id: "createdAt",
      },
      {
        Header: "Note",
        accessor: (row) => (row?.note ? row?.note : null),
        id: "note",
      },
      {
        Header: "Punkte",
        accessor: (row) => (row?.punkte ? row?.punkte : null),
        id: "punkte",
      },
      {
        Header: "Bemerkung",
        accessor: "bemerkung",
        id: "email",
      },
      {
        Header: "Pruefungstyp",
        accessor: (row) => row.pruefung?.pruefungTyp?.pruefungTypName ?? "",
        id: "pruefungTypName",
      },
      {
        Header: "",
        accessor: "id",
        id: "id",
        // eslint-disable-next-line react/display-name
        Cell: ActionCell,
        width: 60,
      },
    ],
    [refetch]
  );

  const { TableComponent: NewTableComponent } = useMakeServerSideTable(
    {
      data: data?.pruefungsergebnis ?? [],
      columns,
      initialState: { pageSize: 25 },
    },
    {
      query,
      variables,
      serversideQueryConfig,
      tableId: "Bewerberlist",
    }
  );

  const refetchFull = () => {
    if (refetch) refetch();
    if (refetchModulList) refetchModulList();
  };
  return (
    <div className={classes.root}>
      <Modal
        open={isModalOpen}
        hideCancel
        hideConfirm
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => setIsModalOpen(false)}
        title="Prüfung aktualisieren"
      >
        <>
          {ergebnisId && <PruefungsergebniUpdateForm id={ergebnisId} afterUpdate={closeModal} onCancel={closeModal} />}
        </>
      </Modal>
      <TableTopContainer>
        <PruefungsVerlaufAddButton
          studentId={studentId}
          key="pruefungsverlauf-add-button-1"
          refetch={refetchFull}
          disabled={!!data?.pruefungsergebnis.length}
        />
      </TableTopContainer>
      <NewTableComponent tableProps={{ rowsPerPageOptions: [10, 25, 50, 100] }} />
    </div>
  );
};

export default PruefungsVerlauf;
