import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetSummaryDetailsQueryVariables = Types.Exact<{
  studiengangSemesterId?: Types.Maybe<Types.Scalars['Int']>;
  zahlungsmodellId?: Types.Maybe<Types.Scalars['Int']>;
  geschlechtId?: Types.Maybe<Types.Scalars['Int']>;
  staatsangehoerigkeitId?: Types.Maybe<Types.Scalars['Int']>;
  wohnlandId?: Types.Maybe<Types.Scalars['Int']>;
  geburtslandId?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type GetSummaryDetailsQuery = { studiengangSemesters: Array<{ __typename: 'StudiengangSemester', id: number, semesterId?: Types.Maybe<number>, studiengangOnBewId?: Types.Maybe<number>, studiengangOnBew?: Types.Maybe<{ __typename: 'StudiengangOnBew', id: number, studienmodellId: number, studiengangName: string, studienmodell: { __typename: 'Studienmodell', id: number, studienmodellName: string } }>, semester?: Types.Maybe<{ __typename: 'Semester', id: number, semesterName?: Types.Maybe<string>, studiengangSemesters: Array<{ __typename: 'StudiengangSemester', id: number, studiengangOnBewId?: Types.Maybe<number> }> }> }>, zahlungsmodells: Array<{ __typename: 'Zahlungsmodell', value: number, label: string }>, geschlechts: Array<{ __typename: 'Geschlecht', value: number, label: string }>, geburtslands: Array<{ __typename: 'Geburtsland', value: number, label: string }>, staatsangehoerigkeits: Array<{ __typename: 'Staatsangehoerigkeit', value: number, label: string }>, wohnlands: Array<{ __typename: 'Wohnland', value: number, label: string }> };


export const GetSummaryDetailsDocument = gql`
    query getSummaryDetails($studiengangSemesterId: Int = 0, $zahlungsmodellId: Int = 0, $geschlechtId: Int = 0, $staatsangehoerigkeitId: Int = 0, $wohnlandId: Int = 0, $geburtslandId: Int = 0) {
  studiengangSemesters(where: {studiengangSemesterId: {eq: $studiengangSemesterId}}) {
    id
    semesterId
    studiengangOnBewId
    studiengangOnBew {
      id
      studienmodellId
      studiengangName
      studienmodell {
        id
        studienmodellName
        __typename
      }
      __typename
    }
    semester {
      id
      semesterName
      studiengangSemesters {
        id
        studiengangOnBewId
        __typename
      }
      __typename
    }
    __typename
  }
  zahlungsmodells(where: {zahlungsmodellId: {eq: $zahlungsmodellId}}) {
    value: id
    label: zahlungsmodellName
    __typename
  }
  geschlechts(where: {geschlechtId: {eq: $geschlechtId}}) {
    value: id
    label: geschlechtName
    __typename
  }
  geburtslands(where: {geburtslandId: {eq: $geburtslandId}}) {
    value: id
    label: geburtlandName
    __typename
  }
  staatsangehoerigkeits(where: {staatsangehoerigkeitId: {eq: $staatsangehoerigkeitId}}) {
    value: id
    label: staatsangehoerigkeitName
    __typename
  }
  wohnlands(where: {wohnlandId: {eq: $wohnlandId}}) {
    value: id
    label: wohnlandName
    __typename
  }
}
    `;

/**
 * __useGetSummaryDetailsQuery__
 *
 * To run a query within a React component, call `useGetSummaryDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSummaryDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSummaryDetailsQuery({
 *   variables: {
 *      studiengangSemesterId: // value for 'studiengangSemesterId'
 *      zahlungsmodellId: // value for 'zahlungsmodellId'
 *      geschlechtId: // value for 'geschlechtId'
 *      staatsangehoerigkeitId: // value for 'staatsangehoerigkeitId'
 *      wohnlandId: // value for 'wohnlandId'
 *      geburtslandId: // value for 'geburtslandId'
 *   },
 * });
 */
export function useGetSummaryDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSummaryDetailsQuery, GetSummaryDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSummaryDetailsQuery, GetSummaryDetailsQueryVariables>(GetSummaryDetailsDocument, baseOptions);
      }
export function useGetSummaryDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSummaryDetailsQuery, GetSummaryDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSummaryDetailsQuery, GetSummaryDetailsQueryVariables>(GetSummaryDetailsDocument, baseOptions);
        }
export type GetSummaryDetailsQueryHookResult = ReturnType<typeof useGetSummaryDetailsQuery>;
export type GetSummaryDetailsLazyQueryHookResult = ReturnType<typeof useGetSummaryDetailsLazyQuery>;
export type GetSummaryDetailsQueryResult = ApolloReactCommon.QueryResult<GetSummaryDetailsQuery, GetSummaryDetailsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
