import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";
import Props from "./index.d";

import { useGetPruefungsdatenQuery } from "graphqlBase/Pruefung/__generated__/getPruefungsdaten";
import Select, { SelectProps } from "components/atomics/Select";
import FormHeader from "components/atomics/FormHeader";
import { useRecoilValue } from "recoil";
import { modulIdForPruefungsCreate } from "../StudentModul/recoilState";
const useStyles = makeStyles(
  (theme: AugmentedTheme) =>
    createStyles({
      foreignKeySelectorIntBox: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },
    }),
  { name: "foreignKeySelector" }
);

interface Elem {
  value: number;
  label: string;
}
const ForeignKeySelectorInt: React.FC<Props> = (props) => {
  const {
    data: { state, setAndSave },
    disabled,
    title,
    pickDefaultFirst,
    label,
    noHeader,
    errors,
    noFormControlMarginTop = false,
  } = props;

  const modulId = useRecoilValue(modulIdForPruefungsCreate);

  const [elems, setElems] = useState<Elem[]>([]);

  const { data } = useGetPruefungsdatenQuery({ variables: { where: { modulId: { eq: modulId } } } });

  useEffect(() => {
    setElems(
      (data?.pruefungs ?? []).map((entry) => {
        const label = `${entry.moodleModul?.modul?.modulName ?? ""} ${
          entry.moodleModul?.zeitraum?.zeitraumName ?? ""
        } ${entry.pruefungTyp?.pruefungTypName ?? ""}`;
        return {
          value: entry.id,
          label,
        };
      })
    );
  }, [data, setElems]);

  useEffect(() => {
    if (pickDefaultFirst && !state && !!elems && elems.length) setAndSave(elems[0].value);
  }, [elems, state, pickDefaultFirst]);

  const classes = useStyles(props);
  return (
    <div className={classes.foreignKeySelectorIntBox}>
      {!noHeader && <FormHeader title={title} />}

      <Select
        label={label}
        setState={(value) => setAndSave(+value)}
        state={state}
        items={elems}
        disabled={disabled}
        error={!!errors?.length}
        helperText={errors?.join(" ") ?? undefined}
        noFormControlMarginTop={noFormControlMarginTop}
      />
    </div>
  );
};

export default ForeignKeySelectorInt;
