export default {
  label: "Produkte",
  studium: {
    label: "Studiengang",
    labelPlural: "Studiengänge",
  },
  zertifikat: {
    label: "Zertifikat",
    labelPlural: "Zertifikate",
  },
  module: {
    label: "Modul",
    labelPlural: "Module",
    moduleUpdateForm: {
      semester: "Semester",
    },
  },
  zertifikatsModule: {
    label: "Zertifikatsmodul",
    labelPlural: "Zertifikatsmodule",
    moduleUpdateForm: {
      semester: "Semester",
    },
  },
};
