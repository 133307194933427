export default {
  name: "Zuordnungsattribut Gerätemodellattributsatz",
  plural: "Zuordnungsattribute Gerätemodellattributsätze",
  columns: {
    deviceAttribute: "Geräteattribut",
    deviceAttributeId: "Geräteattribut-ID",
    deviceModelAttributeSet: "Gerätemodellattributsatz",
    deviceModelAttributeSetId: "Gerätemodellattributsatz-ID",
    id: "ID",
    isDeleted: "ist gelöscht",
    tenantId: "Mieter-ID",
  },
};
