export default {
  name: "Ausgabetyp der Abfragestatistik",
  plural: "Ausgabetypen der Abfragestatistik",
  columns: {
    filtered_item_count: "gefilterte Artikelanzahl",
    returned_item_count: "Anzahl der zurückgegebenen Artikel",
    skip: "überspringen",
    take: "nehmen",
    total_item_count: "Gesamtzahl der Artikel",
  },
};
