export default {
  name: "Regelergebnistyp",
  plural: "Regelergebnistypen",
  columns: {
    id: "ID",
    isDeleted: "ist gelöscht",
    rule: "Regel",
    ruleResultTypeDescription: "Regel Ergebnistyp Beschreibung",
    ruleResultTypeName: "Regel Ergebnis Typ Name",
  },
};
