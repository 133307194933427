import React from "react";
import StudiengangList from "./StudiengangList";

const Studium: React.FC<{}> = () => {
  return (
    <div style={{ width: "100%" }}>
      <StudiengangList />
    </div>
  );
};

export default Studium;
