import type * as Types from "../../types";

import gql from "graphql-tag";
import type * as ApolloReactCommon from "@apollo/react-common";
import * as ApolloReactHooks from "@apollo/client";
export type GetMyTranscriptOfRecordsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetMyTranscriptOfRecordsQuery = {
  myTranscriptOfRecords?: Types.Maybe<{
    __typename?: "DownloadFile";
    fileName?: Types.Maybe<string>;
    fileContent?: Types.Maybe<Array<any>>;
    fileContentBase64?: Types.Maybe<string>;
    fileType?: Types.Maybe<string>;
    statusMessage?: Types.Maybe<string>;
    downloadUri?: Types.Maybe<string>;
  }>;
};

export const GetMyTranscriptOfRecordsDocument = gql`
  query getMyTranscriptOfRecords {
    myTranscriptOfRecords {
      fileName
      fileContent
      fileContentBase64
      fileType
      statusMessage
      downloadUri
    }
  }
`;

/**
 * __useGetMyTranscriptOfRecordsQuery__
 *
 * To run a query within a React component, call `useGetMyTranscriptOfRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyTranscriptOfRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyTranscriptOfRecordsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyTranscriptOfRecordsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyTranscriptOfRecordsQuery, GetMyTranscriptOfRecordsQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetMyTranscriptOfRecordsQuery, GetMyTranscriptOfRecordsQueryVariables>(
    GetMyTranscriptOfRecordsDocument,
    baseOptions
  );
}
export function useGetMyTranscriptOfRecordsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMyTranscriptOfRecordsQuery,
    GetMyTranscriptOfRecordsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetMyTranscriptOfRecordsQuery, GetMyTranscriptOfRecordsQueryVariables>(
    GetMyTranscriptOfRecordsDocument,
    baseOptions
  );
}
export type GetMyTranscriptOfRecordsQueryHookResult = ReturnType<typeof useGetMyTranscriptOfRecordsQuery>;
export type GetMyTranscriptOfRecordsLazyQueryHookResult = ReturnType<typeof useGetMyTranscriptOfRecordsLazyQuery>;
export type GetMyTranscriptOfRecordsQueryResult = ApolloReactCommon.QueryResult<
  GetMyTranscriptOfRecordsQuery,
  GetMyTranscriptOfRecordsQueryVariables
>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
