import { Resolvers } from "@apollo/client/core/types";
import Query from "./query";
import Mutation from "./mutation";

const resolvers: Resolvers = {
  Query,
  Mutation,
};

export default resolvers;
