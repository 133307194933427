import {
  makeNewFormMemoized,
  genericDeleteFunction as genericDeleteFunctionPre,
} from "./"
import {
  Operation,
  QueryArray,
  DeleteChildProps,
  BeforeRemoteMuatation,
  BeforeRemoteMuatationProps,
  OnUpdateDataMiddleWare,
  OnUpdateDataMiddleWareProps,
  ValidateForm,
  ValidateFormProps,
} from "@ml-pa/react-apollo-form-tool/formGen.model"
import React from "react"
import * as SchemaTypes from "./internal/schemaTypes"
import { FormTypes, ModifyProps } from "./internal/typeLinks"

export type MyStudentDataUpdateOnUpdateDataMiddleWare =
  OnUpdateDataMiddleWare<SchemaTypes.MyStudentDataInputUpdate>
export type MyStudentDataUpdateOnUpdateDataMiddleWareProps =
  OnUpdateDataMiddleWareProps<SchemaTypes.MyStudentDataInputUpdate>
export type MyStudentDataUpdateBeforeRemoteMuatation = BeforeRemoteMuatation<
  SchemaTypes.MyStudentDataInputUpdate,
  SchemaTypes.MyStudentDataInputUpdateProps["injectedValues"]
>
export type MyStudentDataUpdateBeforeRemoteMuatationProps =
  BeforeRemoteMuatationProps<
    SchemaTypes.MyStudentDataInputUpdate,
    SchemaTypes.MyStudentDataInputUpdateProps["injectedValues"]
  >
export type MyStudentDataUpdateValidateForm = ValidateForm<
  SchemaTypes.MyStudentDataInputUpdate,
  SchemaTypes.MyStudentDataInputUpdateProps["injectedValues"]
>
export type MyStudentDataUpdateValidateFormProps = ValidateFormProps<
  SchemaTypes.MyStudentDataInputUpdate,
  SchemaTypes.MyStudentDataInputUpdateProps["injectedValues"]
>

export interface MyStudentDataUpdateProps
  extends SchemaTypes.MyStudentDataInputUpdateProps {
  multiInstance?: boolean
  formVersion?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<SchemaTypes.MyStudentDataInputUpdate>
  afterUpdate?: (data: SchemaTypes.MyStudentDataInputUpdate) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<SchemaTypes.MyStudentDataInputUpdate>
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.MyStudentDataInputUpdate,
    SchemaTypes.MyStudentDataInputUpdateProps["injectedValues"]
  >
  validateForm?: ValidateForm<
    SchemaTypes.MyStudentDataInputUpdate,
    SchemaTypes.MyStudentDataInputUpdateProps["injectedValues"]
  >
  fetchAllFieldsOnUpdate?: boolean
}

export const MyStudentDataUpdateForm: React.FC<MyStudentDataUpdateProps> = (
  props
) => {
  const {
      afterUpdate,
      injectedValues,
      multiInstance = false,
      formVersion,
      onCancel,
      fetchAllFieldsOnUpdate,
      beforeRemoteMuatation,
      onUpdateDataMiddleWare,
      validateForm,
      modifyProps,
      ...params
    } = props,
    { Form: MyStudentDataUpdate } = makeNewFormMemoized({
      entity: "myStudentData",

      operation: "update",
      formVersion,
      params: multiInstance ? params : undefined,
    })
  return (
    <MyStudentDataUpdate
      params={params}
      onCancel={onCancel}
      injectedValues={injectedValues}
      afterUpdate={afterUpdate}
      fetchAllFieldsOnUpdate={fetchAllFieldsOnUpdate}
      beforeRemoteMuatation={beforeRemoteMuatation}
      onUpdateDataMiddleWare={onUpdateDataMiddleWare}
      validateForm={validateForm}
      modifyProps={modifyProps}
    />
  )
}
