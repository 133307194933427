import React from "react";
import Typography from "@material-ui/core/Typography";

export interface PageTitleProps {
  /**
   * set page title
   */
  title: string;
}

const PageTitle: React.FC<PageTitleProps> = (props) => {
  return <Typography color="inherit">{props.title}</Typography>;
};

export default PageTitle;
