import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type UpdatePruefungsErgebnisseMutationVariables = Types.Exact<{
  pruefungsergebnis?: Types.Maybe<Array<Types.Maybe<Types.PruefungsergebniUpdateType>> | Types.Maybe<Types.PruefungsergebniUpdateType>>;
}>;


export type UpdatePruefungsErgebnisseMutation = { updatePruefungsergebnis?: Types.Maybe<Array<Types.Maybe<{ __typename?: 'Pruefungsergebni', id: number, note?: Types.Maybe<number>, punkte?: Types.Maybe<number>, pruefungStatusId?: Types.Maybe<number>, bemerkung?: Types.Maybe<string>, pruefungStatus?: Types.Maybe<{ __typename?: 'PruefungStatus', id: number, pruefungStatusName?: Types.Maybe<string> }> }>>> };


export const UpdatePruefungsErgebnisseDocument = gql`
    mutation updatePruefungsErgebnisse($pruefungsergebnis: [PruefungsergebniUpdateType]) {
  updatePruefungsergebnis(pruefungsergebnis: $pruefungsergebnis) {
    id
    note
    punkte
    pruefungStatusId
    pruefungStatus {
      id
      pruefungStatusName
    }
    bemerkung
  }
}
    `;
export type UpdatePruefungsErgebnisseMutationFn = ApolloReactCommon.MutationFunction<UpdatePruefungsErgebnisseMutation, UpdatePruefungsErgebnisseMutationVariables>;

/**
 * __useUpdatePruefungsErgebnisseMutation__
 *
 * To run a mutation, you first call `useUpdatePruefungsErgebnisseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePruefungsErgebnisseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePruefungsErgebnisseMutation, { data, loading, error }] = useUpdatePruefungsErgebnisseMutation({
 *   variables: {
 *      pruefungsergebnis: // value for 'pruefungsergebnis'
 *   },
 * });
 */
export function useUpdatePruefungsErgebnisseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePruefungsErgebnisseMutation, UpdatePruefungsErgebnisseMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePruefungsErgebnisseMutation, UpdatePruefungsErgebnisseMutationVariables>(UpdatePruefungsErgebnisseDocument, baseOptions);
      }
export type UpdatePruefungsErgebnisseMutationHookResult = ReturnType<typeof useUpdatePruefungsErgebnisseMutation>;
export type UpdatePruefungsErgebnisseMutationResult = ApolloReactCommon.MutationResult<UpdatePruefungsErgebnisseMutation>;
export type UpdatePruefungsErgebnisseMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePruefungsErgebnisseMutation, UpdatePruefungsErgebnisseMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
