import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";
import Props from "./index.d";
import {
  useGetModulsQuery,
  GetModulsQuery,
  ResultType,
  useGetModulsLazyQuery,
} from "graphqlBase/Modul/__generated__/getModuls";
import TextField from "@material-ui/core/TextField";

type Modul = ResultType<GetModulsQuery["moduls"]>;

const useStyles = makeStyles((theme: AugmentedTheme) => createStyles({}), { name: "MoodleModulDisplayTextFieldModul" });
const MoodleModulDisplayTextFieldModul: React.FC<Props> = (props) => {
  const {
    data: { state, setAndSave },
    disabled,
    modulProperty,
  } = props;
  const classes = useStyles(props);
  const [currData, setCurrData] = useState<Modul | null>(null);
  const [displayValue, setDisplayValue] = useState<string | number | undefined>(undefined);
  const [displayLabel, setDisplayLabel] = useState<string | undefined>(undefined);
  const { data, loading, error } = useGetModulsQuery({
    variables: {
      where: { modulId: { eq: state } },
    },
    skip: typeof state === "string",
  });

  useEffect(() => {
    if (!data) return;
    setCurrData(data.moduls[0] ?? null);
    if (modulProperty === "studiengangTypeKuerzel") {
      setDisplayValue(data?.moduls[0]?.studiengangTyp?.studiengangTypKuerzel ?? undefined);
      setDisplayLabel("Studiengangtyp");
    }

    if (modulProperty === "ectspoints") {
      setDisplayValue(data?.moduls[0]?.ectspunkte ?? undefined);
      setDisplayLabel("ECTS Modul");
    }

    if (modulProperty === "id") {
      setDisplayValue(data.moduls[0]?.id ?? undefined);
      setDisplayLabel("Master Modul ID");
    }
  }, [data]);

  return (
    <TextField
      InputLabelProps={{ shrink: true }}
      label={displayLabel}
      autoFocus
      fullWidth
      variant="outlined"
      disabled
      value={displayValue}
    />
  );
};

export default MoodleModulDisplayTextFieldModul;
