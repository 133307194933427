import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Theme } from "@material-ui/core";
import Props from "./gridBase.d";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexGrow: 1,
      flexDirection: "row",
      // backgroundColor: theme.palette.background.paper
    },
    child: {
      display: "inherit",
      marginLeft: 30,
    },
  })
);
interface GFCFieldSet_Horizontal extends Props {
  children: React.ReactNode;
  container?: boolean;
  item?: boolean;
}

const FieldSetHorizontal: React.FC<GFCFieldSet_Horizontal> = (props) => {
  // @ts-ignore
  const { raftQuery, raftVariables, raftId, xs, flatTree, ...rest } = props;
  const classes = useStyles({});
  // @ts-ignore
  if (props.hidden) return null;
  return (
    <Grid xs={xs ?? true} {...rest}>
      {props.children}
    </Grid>
  );
};
export default FieldSetHorizontal;
