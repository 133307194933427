// eslint-disable-next-line filenames/match-exported
import React, { useCallback, useState } from "react";
import Dropzone from "components/molecules/Dropzone";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import FileList from "components/molecules/Dropzone/FileList";
import Props from "./index.d";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useRecoilState } from "recoil";
import { FileWithContent, filesAtom } from "./fileState";

const useStyles = makeStyles((theme) =>
  createStyles({
    fileUpload: {
      width: "600px",
      // marginLeft: "20px",
      "@media screen and (max-width: 700px)": {
        width: "100%",
      },
    },
    loading: {
      paddingTop: 10,
      paddingBottom: 0,
      color: "#ff6d00",
      width: "38rem",
      borderRadius: 10,
      marginTop: 10,
    },
  })
);

const toBase64 = async (files: File[]) => {
  const fileReads = files.map<Promise<FileWithContent>>(async (file) => {
    return await new Promise<FileWithContent>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({
          ...file,
          name: file.name,
          fileContent: (reader.result as string)
            .replace("data:application/pdf;base64,", "")
            .replace("data:image/png;base64,", "")
            .replace("data:image/jpeg;base64,", ""),
        });
      reader.onerror = (error) => reject(error);
    });
  });
  return Promise.all(fileReads);
};
const FileUpload: React.FC<Props> = ({ label, title, loading, uploadFileType, showNote }) => {
  const classes = useStyles();
  const [fileList, setFileList] = useRecoilState(filesAtom);

  const handleDeleteFile = (fileToDelete: File) => {
    setFileList((previousFiles) =>
      previousFiles.filter((file) => fileToDelete.name !== file.name || file.uploadFileType !== uploadFileType)
    );
  };
  const addFiles = useCallback(
    async (files: File[]) => {
      const base64Results = await toBase64(files);
      setFileList((previousFiles) =>
        previousFiles
          .filter((file) => file.uploadFileType !== uploadFileType)
          .concat(base64Results.map((file) => ({ ...file, uploadFileType })))
      );
    },
    [fileList, setFileList]
  );

  return (
    <div className={classes.fileUpload}>
      <Typography style={{ marginTop: 20 }} variant="body1">
        {title}
      </Typography>
      <div>
        <Dropzone
          accept={["image/jpeg", "image/png", ".pdf", ".doc", ".docx", ".pages"]}
          maxFileSize={1024 * 1024 * 1} // 50 MB
          label={label}
          maxSize={1024 * 1024 * 1}
          onAddFiles={addFiles}
          maxFiles={3}
        />
        {loading && <LinearProgress className={classes.loading} />}
        <FileList
          files={fileList.filter((file) => file.uploadFileType === uploadFileType)}
          onClickDelete={handleDeleteFile}
          loading={loading}
        />
        {showNote && (
          <Typography style={{ marginTop: 20 }} variant="body1">
            * Auch mit einer{" "}
            <span style={{ fontWeight: "bold" }}>
              Berufsausbildung und Berufserfahrung in einem fachfremden Bereich{" "}
            </span>{" "}
            kannst du nach einer Feststellungsprüfung die Hochschulzugangsberechtigung erlangen. Sprich uns einfach an!
          </Typography>
        )}
      </div>
    </div>
  );
};
export default FileUpload;
