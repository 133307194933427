import React, { useEffect } from "react";
import { makeStyles, createStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";
import Props from "./index.d";
import MenuItem from "@material-ui/core/MenuItem";
import useGetForeignKeyList from "./useGetForeignKeyList";
import Select, { SelectProps } from "components/atomics/Select";
import FormHeader from "components/atomics/FormHeader";
const useStyles = makeStyles(
  (theme: AugmentedTheme) =>
    createStyles({
      foreignKeySelectorIntBox: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },
    }),
  { name: "foreignKeySelector" }
);
const ForeignKeySelectorInt: React.FC<Props> = (props) => {
  const {
    data: { state, setAndSave },
    disabled,
    title,
    pickDefaultFirst,
    foreignKeyParams,
    label,
    noHeader,
    errors,
    sortOnColumns,
    noFormControlMarginTop = false,
  } = props;
  const elems = useGetForeignKeyList({ foreignKeyParams, sortOnColumns });
  useEffect(() => {
    if (pickDefaultFirst && !state && !!elems && elems.length) setAndSave(elems[0].value);
  }, [elems, state, pickDefaultFirst]);

  const classes = useStyles(props);
  return (
    <div className={classes.foreignKeySelectorIntBox}>
      {!noHeader && <FormHeader title={title} />}

      <Select
        label={label}
        setState={(value) => setAndSave(+value)}
        state={state}
        items={elems}
        disabled={disabled}
        error={!!errors?.length}
        helperText={errors?.join(" ") ?? undefined}
        noFormControlMarginTop={noFormControlMarginTop}
        readonly={props.readonly}
      />
    </div>
  );
};

export default ForeignKeySelectorInt;
