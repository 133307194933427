export default {
  name: "Asset-Attributwert",
  plural: "Asset-Attributwerte",
  columns: {
    asset: "Asset",
    assetAttribute: "Asset-Attribut",
    assetAttributeId: "Asset-Attribut ID",
    assetId: "Asset ID",
    id: "ID",
    isDeleted: "ist gelöscht",
    tenantId: "Mieter ID",
    value: "Wert",
  },
};
