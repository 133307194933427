import { useEffect, useState } from "react";
import { StateEffects } from "../formGen.model";

export default (valueIn: any, idPre: string, saveData: (data: any) => void, required?: boolean) => {
  const id = idPre,
    value = valueIn !== null ? valueIn : undefined;

  const [state, setstateInner] = useState(value),
    [isBlured, setBlured] = useState(false);

  const setstate = (val: any) => {
    // console.log(id, required);
    // console.log(id, val, valueIn, state, typeof val);

    setstateInner(val === null ? undefined : val);
  };

  useEffect(() => {
    if (isBlured) {
      saveData({ [id]: state });
      setBlured(false);
    }
  }, [state, isBlured]);

  const onBlur = () => {
    setBlured(true);
  };

  const updateValue = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // console.log(event.target.value);
    setstate(event.target.value);
  };
  const setAndSave = (val: any) => {
    setstateInner(val);
    saveData({ [id]: val });
  };
  const setNull = required
    ? undefined
    : () => {
        setstateInner(null);
        saveData({ [id]: null });
      };

  // useEffect(() => {
  //   console.log(id, value);
  //   setstateInner(value);
  //   return () => {};
  // }, [value]);

  const stateEffects: StateEffects = {
    state,
    setstate,
    updateValue,
    onBlur,
    setAndSave,
    setNull,
  };
  return stateEffects;
};
