import { createMuiTheme, Theme } from "@material-ui/core/styles";
import tableColors from "./tableColors";

declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    tableColors: typeof tableColors;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    tableColors?: typeof tableColors;
  }
}

const tableTheme: Theme = createMuiTheme({
  palette: {
    primary: {
      // light: tableColors.lightblue,
      // main: tableColors.blue,
      main: tableColors.lbcgrey,
    },
    secondary: { main: tableColors.lbcyellow },
    success: {
      main: tableColors.green,
    },
    warning: { main: tableColors.orange },
    error: {
      main: tableColors.red,
    },
    text: {
      // primary: tableColors.whiteSmoke,
      primary: tableColors.black,
    },
  },
  tableColors,
  typography: {
    htmlFontSize: 16,
    fontFamily: "Roboto latin, Roboto, Arial",
    // fontFamily: "'Open Sans',Arial,sans-serif",
    // fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
    // h5: {
    //   fontWeight: 500,
    //   fontSize: 48,
    //   letterSpacing: -0.2,
    // },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 80,
    },
  },
});

export default tableTheme;
