export default {
  cancel: "Abbrechen",
  wear: "Abnutzung",
  submit: "Absenden",
  create: "Anlegen",
  reset: "Zurücksetzen",
  asset: "Asset",
  confirmationOfOrder: "Auftragsbestätigung",
  feature: "Ausstattungsmerkmal",
  equipment: "Ausrüstung",
  select: "Auswählen",
  administrator: "Administrator",
  digger: "Bagger",
  yearOfConstruction: "Baujahr",
  confirm: "Bestätigen",
  edit: "Bearbeiten",
  event: "Ereignis",
  continue: "Fortsetzen",
  machine: "Gerät",
  deliveryNote: "Lieferschein",
  rentalAgreement: "Mietvertrag",
  tenant: "Mandant",
  topSlewingCranes: "Obendreher",
  OK: "OK",
  scheduler: "Planer",
  productArea: "Produktbereich",
  productDivision: "Produktsparte",
  productGroup: "Produktgruppe",
  productLine: "Produktlinie",
  productFamily: "Produktfamilie",
  productModel: "Produktmodell",
  productCategory: "Produktkategorie",
  wheelLoader: "Radlader",
  invoice: "Rechnung",
  crawler: "Raupenbagger",
  quickChanger: "Schnellwechsler",
  weldingBadge: "Schweißplakette",
  save: "Speichern",
  listOfParts: "Stückliste",
  towerPiece: "Turmstück",
  next: "Weiter",
  yes: "Ja",
  no: "Nein",
  back: "Zurück",
  assign: "Zuordnen",
  field: "Feld",
  key: "Schlüssel",
  value: "Wert",
  true: "Wahr",
  false: "Falsch",
  clear: "Löschen",
  sync: "synchronisieren",
};
