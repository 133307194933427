import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetPrufungsFormOnModulQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type GetPrufungsFormOnModulQuery = { modul?: Types.Maybe<{ __typename?: 'Modul', id: number, pruefungsform?: Types.Maybe<{ __typename?: 'Pruefungsform', pruefungsformenName: string }> }> };


export const GetPrufungsFormOnModulDocument = gql`
    query getPrufungsFormOnModul($id: Int!) {
  modul(id: $id) {
    id
    pruefungsform {
      pruefungsformenName
    }
  }
}
    `;

/**
 * __useGetPrufungsFormOnModulQuery__
 *
 * To run a query within a React component, call `useGetPrufungsFormOnModulQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrufungsFormOnModulQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrufungsFormOnModulQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPrufungsFormOnModulQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPrufungsFormOnModulQuery, GetPrufungsFormOnModulQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPrufungsFormOnModulQuery, GetPrufungsFormOnModulQueryVariables>(GetPrufungsFormOnModulDocument, baseOptions);
      }
export function useGetPrufungsFormOnModulLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPrufungsFormOnModulQuery, GetPrufungsFormOnModulQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPrufungsFormOnModulQuery, GetPrufungsFormOnModulQueryVariables>(GetPrufungsFormOnModulDocument, baseOptions);
        }
export type GetPrufungsFormOnModulQueryHookResult = ReturnType<typeof useGetPrufungsFormOnModulQuery>;
export type GetPrufungsFormOnModulLazyQueryHookResult = ReturnType<typeof useGetPrufungsFormOnModulLazyQuery>;
export type GetPrufungsFormOnModulQueryResult = ApolloReactCommon.QueryResult<GetPrufungsFormOnModulQuery, GetPrufungsFormOnModulQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
