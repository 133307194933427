export default {
  name: "Regelreferenzeinheit",
  plural: "Regelreferenzeinheiten",
  columns: {
    id: "ID",
    isDeleted: "ist gelöscht",
    ruleReference: "Regel Referenz",
    ruleReferenceUnitDescription: "Regel Referenz Einheit Beschreibung",
    ruleReferenceUnitName: "Regel Referenz Einheitenname",
  },
};
