import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  useGetMatchMoodleModulStudentsLazyQuery,
  GetMatchMoodleModulStudentsQuery,
} from "graphqlBase/MatchMoodleModulStudent/__generated__/getMatchMoodleModulStudents";
import { useRemoveStudentFromMoodleModulStutentMappingsMutation } from "graphqlBase/MatchMoodleModulStudent/__generated__/removeStudentFromMoodleModulStutentMappings";
import TrashOutlinedIcon from "icons/components/TrashIcon";
import React, { useEffect, useMemo, useState } from "react";
import Props from "./index.d";
import { ListSubheader } from "@material-ui/core";
import AddStudentsToMoodleModule from "./AddStudentsToMoodleModule";
import MoodleSync from "../helpers/MoodleSync";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        margin: "2em 0",
      },
      listRootHorizontal: {
        // height: "80vh",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },
      listRoot: {
        // height: "80vh",
        width: "100%",
      },
      listRootInner: {
        width: "100%",
        maxHeight: "80%",
        overflowY: "auto",
      },
      listItem: {
        paddingLeft: 0,
        paddingRight: 0,
        display: "flex",
        background: theme.colors.grey50,
        marginTop: 0,
      },
      listItemText: {
        flex: "1 1 0",
      },
      header: {
        fontWeight: "bold",
      },
      headerEnd: {
        // textAlign: "end",
      },
      deleteButton: {
        "&:hover": {
          backgroundColor: theme.colors.tTransparent,
        },
        "& svg": {
          color: theme.colors.grey500,
          width: "1em",
          height: "1em",
        },
      },
    }),
  { name: "Modulzuordnung-Table" }
);

const MoodleModulStudents: React.FC<Props> = (props) => {
  const {
    data: { state },
    disabled,
  } = props;
  const classes = useStyles();

  const [query, { data, refetch }] = useGetMatchMoodleModulStudentsLazyQuery({});

  useEffect(() => {
    if (state)
      query({
        variables: {
          filterInput: { moodleModulId: { eq: state } },
        },
      });
  }, [state]);

  const [removeStudentPre] = useRemoveStudentFromMoodleModulStutentMappingsMutation();

  const removeStudent = (id: number) => {
    removeStudentPre({ variables: { studentsToRemove: [{ id, isUserDeleted: true }] } }).catch(console.log);
  };

  const studentsInModule = useMemo(() => {
    return (data?.matchMoodleModulStudents ?? []).reduce<number[]>((studentsInModule, entry) => {
      if (!entry.isUserDeleted && entry.studentId) return studentsInModule.concat(entry.studentId);
      return studentsInModule;
    }, []);
  }, [data?.matchMoodleModulStudents]);

  return (
    <div className={classes.root}>
      <div className={classes.listRootHorizontal}>
        {!!state && <MoodleSync {...props} buttonLabel="Studenten" syncOperation="syncStudents" />}
        {!!state && (
          <AddStudentsToMoodleModule
            studentsInModule={studentsInModule}
            refetchMatchList={refetch}
            moodleModulId={state}
          />
        )}
      </div>
      <div className={classes.listRoot}>
        <div className={classes.listRootInner}>
          <List aria-label="Modul-Studiengang-Tabelle" disablePadding>
            <ListSubheader className={classes.listItem}>
              <ListItemText
                className={clsx(classes.header, classes.headerEnd, classes.listItemText)}
                disableTypography
                primary={"Stud-ID"}
              />
              <ListItemText className={clsx(classes.header, classes.listItemText)} disableTypography primary={"Name"} />
              <ListItemText
                className={clsx(classes.header, classes.listItemText)}
                disableTypography
                primary={"Studiengang"}
              />
              <ListItemText
                className={clsx(classes.header, classes.listItemText)}
                disableTypography
                primary={"Studiengangtyp"}
              />
            </ListSubheader>
            <Divider />
            {(data?.matchMoodleModulStudents ?? []).map((entry) => {
              if (entry.isUserDeleted) return null;
              return (
                <div key={entry.id}>
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      className={clsx(classes.headerEnd, classes.listItemText)}
                      disableTypography
                      primary={entry?.studentId ?? ""}
                    />
                    <ListItemText
                      className={classes.listItemText}
                      disableTypography
                      primary={`${entry?.student?.vorname ?? ""} ${entry?.student?.nachname ?? ""}`}
                    />
                    <ListItemText
                      disableTypography
                      className={classes.listItemText}
                      primary={entry.student?.matchStudiengangStudents
                        .filter((mapping) => mapping.studentId === entry.studentId)
                        .map((s) => s.studiengang?.studiengangName)
                        .join(" ")}
                    />
                    <ListItemText
                      className={classes.listItemText}
                      disableTypography
                      primary={entry.student?.matchStudiengangStudents
                        .filter((mapping) => mapping.studentId === entry.studentId)
                        .map((s) => s.studiengang?.studiengangTyp?.studiengangTypKuerzel)
                        .join(" ")}
                    />
                    {!!entry.studentId && (
                      <IconButton className={classes.deleteButton} onClick={() => removeStudent(entry.id)}>
                        <TrashOutlinedIcon />
                      </IconButton>
                    )}
                  </ListItem>
                  <Divider />
                </div>
              );
            })}
          </List>
        </div>
      </div>
    </div>
  );
};
export default MoodleModulStudents;
