import * as React from "react";
import {
  createStyles,
  makeStyles,
  Theme as AugmentedTheme,
  useTheme,
} from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    form: {
      // flexGrow: 1,
      // backgroundColor: theme.palette.secondary.light,

      display: "flex",
      flexDirection: "column",
      width: "90%",
      height: "90%",
      margin: "5%",
    },
  })
);

export default () => {
  const { form } = useStyles({});
  return <Skeleton animation={false} className={form} />;
};
