const colors = {
  /* Table colors */
  greyStripedRow: "rgb(243, 243, 241)",

  /* Kolping colors */
  mineShaftBlack: "#232323",
  smaltBlue: "#5A8791",
  gumboTeal: "#77A9A8",
  christineRed: "#F59A12",

  /* Status colors */
  greenStandard: "rgb(69, 191, 75)", // GreenStandard
  brownStandard: "rgb(191, 147, 64)", // StandardBrown
  yellowLight: "rgb(254, 232, 173)", // LightYellow
  sunYellow: "#F59A12",
  kolpingOrange: "#EF7C00",
  orageRed: "#EA5906",
  koplingGrey: "#D0D0D0",
  /* Badge colors */
  blueLight: "rgb(0, 43, 255)",

  /* Theming PoC */
  brandYellow: "#fdc400", // standardYellow
  tBlack: "#000000",
  tWhite: "#ffffff", // lightgrey
  tTransparent: "#00000000",
  lbcGrey: "#e6e5e3",
  grey20: "#333333",
  grey50: "#f1f1f1",
  grey95: "#f2f2f2",
  grey100: "#e6e5e4", // LightestGrey
  grey200: "#dbdad6", // Grey
  grey300: "#c7c6c5", // LightGrey
  grey400: "#9a978f", // gray
  grey500: "#787673",
  grey600: "#605e5b", // DarkGrey
  grey700: "#44423e", // DarkestGrey
  grey800: "#363430",
  grey900: "#282724",
  greyA900: "#1b1a18",
  red400: "#fe655c",
  red500: "#ff1b0f",
  red600: "#e60b00",
  blue200: "#e2e4ef",
  blue300: "#c8ceea",
  blue400: "#919cd4",
  blue500: "#475ab8",
  blue600: "#394791",

  /* Other */
  red: "#E60000",
  lightSlateBlue: "#8080FF",
  redCarmine: "#B30000",
  crimson: "#DC143C",
  brown: "#4D1A00",
  blueViolet: "#8A2BE2",
  indigo: "#4B0082",
  sealBrown: "#330000",
  magenta: "#FF00FF",
  hollywoodCerise: "#FF00AC",
  limeGreen: "#00FF00",
  gold: "#E2B007",
  darkOrange: "#FF8C00",
  deepPink: "#FF1493",
  darkOrchid: "#9932CC",
  darkBlue: "#000080",
  steelBlue: "#4682B4",
  yellow1: "#FFFF00",

  whiteSmoke: "#ECEDE8", // yellow
  blue: "#003c64",
  lightBlue: "#B3D4FC",
  green: "#1D9A73",
  orange: "#F07D00",
  darkGrey: "#737373", // darkGray
  lightBrown: "#8b5b4c", // brownLight
  veryLightBrown: "#c49357", // brownVeryLight
  veryDarkGrey: "#616161",
  midGrey: "#d9d9d9",
};

export default colors;
export const themeColors = colors;
export type Themecolors = keyof typeof themeColors;
