export default {
  name: "Regelaktion",
  plural: "Regelaktionen",
  columns: {
    id: "ID",
    isDeleted: "ist gelöscht",
    ruleActionDescription: "Regel Aktion Beschreibung",
    ruleActionName: "Regel Aktionsname",
    ruleRuleActionFailure: "Regel Aktion Fehler",
    ruleRuleActionSuccess: "Regel Aktion Erfolg",
  },
};
