const punkteToNote = [
  [96, 1.0],
  [91, 1.3],
  [86, 1.7],
  [81, 2.0],
  [76, 2.3],
  [71, 2.7],
  [66, 3.0],
  [61, 3.3],
  [56, 3.7],
  [50, 4.0],
  [0, 5.0],
];

export default ({ punkte, maxPunkte }: { punkte: number; maxPunkte: number }): number => {
  const relativePunkte = Math.round((100 / maxPunkte) * punkte);
  return (punkteToNote.find(([punkteRel]) => punkteRel <= relativePunkte) ?? [0, 6.0])[1];
};
