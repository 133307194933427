import * as React from "react";
import { makeStyles, createStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import Props from "./index.d";

const useStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    SubmitButton: {
      // height: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
    },
    title: {
      color: theme.colors.red,
    },
  })
);

const ErrorDisplay: React.FC<Props> = (props) => {
  const {
    title,

    data: { state },
  } = props;
  const errorsExist = Object.values(state ?? {}).some((errors) => !!errors.length);
  const classes = useStyles({});
  return (
    <>
      {/* {errorsExist && (
        <Typography className={classes.title} variant="h4">
          Bitte füllen Sie alle mit * markierten Felder aus!
        </Typography>
      )} */}
      {/* {Object.values(state ?? {}).map((err) => (
        <Typography className={classes.title} variant="h4">
          {err}
        </Typography>
      ))} */}
    </>
  );
};

export default ErrorDisplay;
