import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";

import Props from "./index.d";
import { useGetMatchStudiengangModulsQuery } from "graphqlBase/matchStudiengangModuls/__generated__/getMatchStudiengangModuls";
import LoadingIndicator from "components/atomics/LoadingIndicator";
import translation from "translations";
import { ListSubheader } from "@material-ui/core";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
      },
      listRoot: {
        height: "40vh",
      },
      listRootInner: {
        width: "100%",
        maxHeight: "80%",
        overflowY: "auto",
      },
      listItem: {
        paddingLeft: 0,
        paddingRight: 0,
        display: "flex",
        background: theme.colors.grey50,
        marginTop: 0,
      },
      header: {
        fontWeight: "bold",
      },
      headerEnd: {
        textAlign: "end",
      },
    }),
  { name: "Modulzuordnung-Table" }
);

const ModulzuordnungTable: React.FC<Props> = (props) => {
  const {
    data: { state },
  } = props;
  const classes = useStyles();

  const { data, loading } = useGetMatchStudiengangModulsQuery({
    variables: {
      filterInput: { modulId: { eq: state } },
    },
    fetchPolicy: "network-only",
    skip: typeof state === "string",
  });

  if (loading) return <LoadingIndicator />;

  return (
    <div className={classes.root}>
      <div className={classes.listRoot}>
        <div className={classes.listRootInner}>
          <List aria-label="Modul-Studiengang-Tabelle" disablePadding>
            <ListSubheader className={classes.listItem}>
              <ListItemText
                className={classes.header}
                disableTypography
                primary={translation.pages.produkte.studium.label}
              />
              <ListItemText
                className={clsx(classes.header, classes.headerEnd)}
                disableTypography
                primary={translation.pages.produkte.module.moduleUpdateForm.semester}
              />
            </ListSubheader>
            <Divider />
            {(data?.matchStudiengangModuls ?? []).map((entry) => {
              return (
                <div key={entry.id}>
                  <ListItem className={classes.listItem}>
                    <ListItemText disableTypography primary={entry.studiengang.studiengangName ?? ""} />
                    <ListItemText
                      className={clsx(classes.header, classes.headerEnd)}
                      disableTypography
                      primary={entry.planSemester ?? ""}
                    />
                  </ListItem>
                  <Divider />
                </div>
              );
            })}
          </List>
        </div>
      </div>
    </div>
  );
};

export default ModulzuordnungTable;
