import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { GetStudentOverviewDocument } from "graphqlBase/Students/__generated__/getStudentOverview";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "components/atomics/Loader";
import { StudentCreateForm, StudentCreateOnUpdateDataMiddleWare } from "raft/StudentForm";
import { useCreateMatchStudiengangStudentsMutation } from "graphqlBase/matchStudiengangStudents/__generated__/createMatchStudiengangStudents";

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    formWrapper: {
      display: "flex",
      width: "100%",
    },
    addButton: {
      fontSize: 14,
      color: theme.colors.tWhite,
      backgroundColor: theme.colors.sunYellow,
      height: 40,
      borderRadius: "unset",
      "&:hover": { color: theme.colors.tWhite, backgroundColor: theme.colors.sunYellow },
    },
  })
);

const StudentAdd: React.FC<{}> = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [createMatchStudiengangStudents] = useCreateMatchStudiengangStudentsMutation();
  const [state, setstate] = React.useState(false);

  const close = () => {
    history.goBack();
  };

  const handleAfterCreate = async ({ id }: { id: number }) => {
    const matchStudiengangStudent = await createMatchStudiengangStudents({
      variables: {
        matchStudiengangStudents: [{ studentId: id, studiumStatusId: 5 }],
      },
    });

    if (
      matchStudiengangStudent.data?.createMatchStudiengangStudents &&
      matchStudiengangStudent.data?.createMatchStudiengangStudents.length > 0
    ) {
      history.push(
        `${location.pathname.includes("addTeilnehmer") ? "zertifikatsStudiengang" : "studiengang"}/${
          matchStudiengangStudent.data.createMatchStudiengangStudents[0]?.id
        }/${id}`
      );
    }
  };

  return (
    <div className={classes.formWrapper}>
      <Loader style={{ justifyContent: "center" }} loading={state}>
        <StudentCreateForm
          onCancel={close}
          ausbildungsArtId={location.pathname.includes("addTeilnehmer") ? 2 : 1}
          afterCreate={handleAfterCreate}
          beforeRemoteMuatation={(p) => {
            setstate(true);
            return {
              ...p.data,
            };
          }}
          updateAfterCreateQueries={[
            {
              query: GetStudentOverviewDocument,
              variables: {},
            },
          ]}
        />
      </Loader>
    </div>
  );
};

export default StudentAdd;
