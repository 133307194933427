import * as React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";
import Props from "./index.d";
import { useState } from "react";
import tableColors from "components/templates/table-factory/theme/tableColors";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
      },
      checkbox: {
        appearance: "none",
        borderRadius: "4px",
        border: "0.1em solid black",
        fontSize: "16px",
        height: "18px",
        margin: "0.3em 0.3em 0.3em 0.3em",
        WebkitAppearance: "none",
        width: "18px",
        userSelect: "none",
        "&:hover": {
          backgroundColor: (props: { disabled: boolean }) => (props.disabled ? "transparent" : theme.colors.grey200),
        },
        "&:focus": {
          outline: "none",
        },
        "&:disabled": {
          border: "0.1em solid",
          borderColor: tableColors.lbcgrey,
          cursor: "not-allowed",
        },
        "&:checked": {
          appearance: "none",
          backgroundColor: theme.colors.brandYellow,
          backgroundImage: `url('data:image/svg+xml;utf8,<svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>')`,
          border: "none",
          height: "18px",
          WebkitAppearance: "none",
          width: "18px",
          "&:hover": {
            backgroundColor: theme.colors.brandYellow,
          },
          "&:disabled": {
            border: tableColors.lbcgrey,
            backgroundColor: tableColors.lbcgrey,
            opacity: 0.5,
          },
        },
      },
      label: {
        color: theme.colors.grey500,
        fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
        fontSize: "16px",
        margin: "0.2em 0.4em 0.2em 0.3em",
      },
      title: {
        marginRight: 10,
      },
    }),
  { name: "Checkbox" }
);

const CheckBoxes: React.FC<Props> = (props) => {
  const {
    data: { state, setAndSave },
    items,
    title,
    disabled,
  } = props;
  const classes = useStyles({ disabled });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAndSave(parseInt(event.target.value));
  };

  const handleCheck = (value: number, label: string) => {};
  return (
    <div className={classes.root}>
      {!!title.trim().length && (
        <Typography align="right" className={classes.title} variant="body2">
          {title}
          {title.trim().length ? ":" : ""}
        </Typography>
      )}
      <RadioGroup aria-label="position" name="position" value={state || 0} onChange={handleChange} row>
        {items.map((item, index) => {
          return (
            <div key={item.value + index}>
              <input
                className={classes.checkbox}
                type="checkbox"
                key={item.value}
                value={item.value}
                disabled={props.disabled}
                onChange={() => handleCheck(item.value, item.label)}
              />
              <label key={item.label} className={classes.label}>
                {item.label}
              </label>
            </div>
          );
        })}
      </RadioGroup>
    </div>
  );
};
export default CheckBoxes;
