import { useHistory, useLocation } from "react-router-dom";
import React from "react";
import Button from "components/lbc-toolkit/atomics/Button/DefaultButton";
import translations from "translations";
import { useStyles } from "../ModuleAdd";

interface ModuleAddButtonProps {
  isZertifikat?: boolean;
}

const ModuleAddButton: React.FC<ModuleAddButtonProps> = ({ isZertifikat }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  function handleClick() {
    history.push(`${location.pathname}/addModule`);
  }

  return (
    <Button
      // currently the button style breaks if the label is to long fo falling back to module.label instead of zertifikatsModule.label
      label={`${isZertifikat ? translations.pages.produkte.module.label : translations.pages.produkte.module.label} ${
        translations.globals.button.add
      }`}
      buttonType="primary"
      onClick={handleClick}
      classes={{ colorPrimary: classes.addButton }}
    />
  );
};
export default ModuleAddButton;
