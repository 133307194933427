export default {
  name: "Gerätefähigkeit",
  columns: {
    capability: "Fähigkeit",
    capabilityId: "Fähigkeit ID",
    device: "Gerät",
    id: "id",
    isDeleted: "ist gelöscht",
    technicalMax: "technische Max",
    technicalMin: "technische Min",
    tenantId: "Mieter ID",
    unit: "Einheit",
    unitId: "Einheit ID",
  },
};
