import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { StudentUpdateForm } from "raft/StudentForm";
import React from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import onUpdateUpdateMiddlewareStudentAndBewerber from "../onUpdateMiddleWareStudentAndBewerber";

const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      BewerberUpdateFormBox: {
        // margin: "0 auto",
        // padding: "0 1em",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
      afterCreate: {
        margin: "auto",
        maxWidth: "700px",
      },
      afterCreateBody: {
        marginTop: "1em",
      },
      afterCreateHeadline: { marginBottom: "10px" },
    }),

  { name: `BewerberUpdate` }
);

const BewerberUpdate: React.FC<{}> = () => {
  const classes = useStyles({});
  const history = useHistory();
  const location = useLocation();
  const { bewerberId } = useParams<{ bewerberId?: string }>();
  const id = +(bewerberId ?? "0");

  const onLeave = () =>
    history.push(
      location.pathname.includes("zertifikatsTeilnehmerEdit")
        ? "/teilnehmer/zertifikatsTeilnehmer"
        : "/teilnehmer/teilnehmerList"
    );

  if (!id) return null;
  return (
    <div className={classes.BewerberUpdateFormBox}>
      <StudentUpdateForm
        ausbildungsArtId={location.pathname.includes("zertifikatsTeilnehmerEdit") ? 2 : 1}
        formVersion="bewerber"
        onCancel={onLeave}
        afterUpdate={onLeave}
        id={id}
        onUpdateDataMiddleWare={onUpdateUpdateMiddlewareStudentAndBewerber}
      />
    </div>
  );
};

export default BewerberUpdate;
