import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import useSnackbarAlert from "./useSnackbarAlert";
import PublishIcon from "@material-ui/icons/Publish";

const useStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    paper: {
      cursor: "pointer",
      width: "95%",
      borderRadius: 10,
      boxShadow: "none",
      "@media screen and (max-width: 700px)": {
        maxWidth: "320%",
      },
    },
    paperDropActive: {
      backgroundColor: theme.palette.primary.dark,
    },
    textField: {
      backgroundColor: "white",
      border: `1px solid #9a978f`,
      borderRadius: 10,
      display: "flexbox",
      alignItems: "center",
      width: "100%",
      height: "3em",
      "&:hover": {
        border: `1px solid #ff6d00`,
        color: "#ff6d00",
      },
    },
    displayText: {
      color: "#000",
      fontSize: "16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "15%",
      width: "380%",
      height: "2.5em",
      "@media screen and (max-width: 700px)": {
        maxWidth: "320%",
        fontSize: "11px",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    typography: {
      color: "#787673",
      fontSize: "16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      width: "100%",
      height: "2.5em",
      "&:hover": {
        color: `#ff6d00`,
      },
      "@media screen and (max-width: 700px)": {
        justifyContent: "center",
        alignItems: "center",
        height: "2.1em",
        display: "flex",
      },
    },
    publishIcon: {
      color: "#000",
      fontSize: "150%",
      marginLeft: "15%",
      marginRight: "15%",
      "&:hover": {
        color: `#000`,
      },
      "@media screen and (max-width: 700px)": {
        fontSize: "120%",
      },
    },
    hover: {
      "&:hover": {
        color: "#ff6d00",
      },
    },
  })
);

export interface DropzoneProps {
  accept?: string[];
  label?: string;
  loading?: boolean;
  maxFileSize?: number;
  maxFiles?: number;
  maxSize?: number;
  onAddFiles: (files: File[]) => void;
}

const Dropzone: React.FC<DropzoneProps> = (props) => {
  const classes = useStyles();
  const { alertComponent, addAlert } = useSnackbarAlert({});
  const [filesError, setFilesError] = useState<string | string[]>("");

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (props?.loading) return;
      const files: File[] = [];
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          const blob = new Blob([new Uint8Array(reader.result as ArrayBuffer)], { type: file.type });
          const updateFile = new File([blob], file.name, { type: file.type, lastModified: Date.now() });
          files.push({
            ...updateFile,
          });
        };
        reader.readAsArrayBuffer(file);
      });
      if (acceptedFiles.length === 0) {
        setFilesError("too many files");
        return;
      }
      props.onAddFiles(acceptedFiles);
    },
    [props]
  );

  const handleDropRejected = (rejectedFiles: FileRejection[], event: DropEvent) => {
    if (rejectedFiles.length > 0 && rejectedFiles[0].errors[0].code === "too-many-files") {
      addAlert("Es dürfen maximal 3 Dateien pro Bereich ausgewählt werden!");
    } else {
      addAlert("Die Datei darf nicht größer als 1MB sein!");
    }
  };

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    onDropRejected: handleDropRejected,
    accept: props.accept,
    maxSize: props.maxSize,
    maxFiles: props.maxFiles,
    noDrag: true,
  });
  useEffect(() => {
    if (filesError) {
      setTimeout(() => {
        setFilesError("");
      }, 5000);
    }
  }, [filesError]);
  return (
    <>
      <Paper className={clsx(classes.paper, isDragActive ? classes.paperDropActive : undefined)} {...getRootProps()}>
        <input {...getInputProps()} />
        <div className={classes.textField}>
          <Typography className={classes.typography}>
            <div className={classes.displayText}>{props.label ? props.label : ""}</div>
            <div className={classes.typography}>
              <PublishIcon className={classes.publishIcon} />
            </div>
          </Typography>
        </div>
      </Paper>
      {alertComponent}
    </>
  );
};
export default Dropzone;
