export default {
  header: "Warnung",
  dataloss_1:
    "Sie sind im Begriff diese Seite zu verlassen. Ihre Eingaben oder Änderungen während dieser Sitzung werden nicht gespeichert. Möchten Sie diese Seite wirklich verlassen? Nein (zurück zur Eingabe) / Ja (Seite verlassen)",
  changedata:
    "Bitte bestätigen Sie, dass Sie berechtigt sind die Daten manuell zu ändern und dass die neuen Daten korrekt sind.",
  saveData: "Wollen Sie die Änderungen speichern?",
  changeobject:
    "Diese(r) <name of object> wurde zuletzt vom Benutzer <mail-address of user having last updated the object>  bearbeitet (<timestamp>). Bitte bestätigen Sie, dass Sie berechtigt sind die Reservierung zu ändern",
  blockobject:
    "Diese(r) <name of object> wird gerade vom Benutzer <mail-address of user blocking the object> bearbeitet und kann derzeit nicht geändert werden. Bitte versuchen Sie es in 15 Minuten erneut oder bitten Sie den anderen Benutzer die Bearbeitung abzubrechen",
  dataloss_2: "Möchten Sie wirklich löschen?",
  sycFailed:
    "Die Moodule Modul ID is nicht eindeutig. Bitte korrigieren Sie die Daten im CMS Portal und versuchen Sie es erneut",
  examnModalMessage: "Es sind ungespeicherte Änderungen vorhanden. Möchten Sie diese Speichern?",
  AddStudentMessage: "Möchten Sie Studenten zur Prüfung hinzufügen?",
};
