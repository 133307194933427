import React from "react";
import StudentList from "../StudentList";

//was changed from Bewerberlist (1) to AbbrecherList (4)
const where = {
  ausbildungsArtId: { eq: 1 },
  matchStudentSemesters: {
    some: {
      studentSemesterStatusId: {
        in: [5],
      },
      zeitraum: {
        zeitraumVon: { lte: new Date().toISOString() },
      },
    },
  },
};
const type = "abgebrochen";

const AbbrecherList: React.FC<{}> = () => {
  return <StudentList formType={type} where={where} />;
};

export default AbbrecherList;
