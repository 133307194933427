import React, { useEffect } from "react";
import TreeView from "@material-ui/lab/TreeView";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";

import Props from "./index.d";
import { useGetMatchStudiengangModulsQuery } from "graphqlBase/matchStudiengangModuls/__generated__/getMatchStudiengangModuls";
import LoadingIndicator from "components/atomics/LoadingIndicator";

interface Tree {
  planSemester: number | null | undefined;
  moduls: Modul[];
}

interface Modul {
  modulId: number;
  modulName: string;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: { display: "flex", alignItems: "center", flexDirection: "column" },
      container: {
        width: "50%",
        [theme.breakpoints.down("md")]: {
          width: "75%",
        },
      },
    }),
  { name: "SemesterModulZuordnung" }
);

const SemesterModulZuordnung: React.FC<Props> = (props) => {
  const {
    data: { state },
  } = props;
  const classes = useStyles();

  const { data, loading } = useGetMatchStudiengangModulsQuery({
    variables: {
      filterInput: { studiengangId: { eq: state } },
    },
    fetchPolicy: "network-only",
    skip: typeof state === "string",
  });

  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [treeData, setTreeData] = React.useState<Tree[]>([]);

  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setSelected(nodeIds);
  };

  useEffect(() => {
    if (!data) return;

    const uniquePlanSemester = Array.from(
      new Set((data.matchStudiengangModuls ?? []).map((entry) => entry.planSemester))
    );

    const currTreeData = uniquePlanSemester.reduce((acc: Tree[], curr) => {
      const matches = (data.matchStudiengangModuls ?? []).filter((entry) => entry.planSemester === curr);
      const modulMatches = matches.map((entry) => {
        return { modulName: entry.modul.modulName, modulId: entry.modulId };
      });
      return acc.concat({ planSemester: curr, moduls: modulMatches });
    }, []);

    if (currTreeData && currTreeData.length > 0) {
      let entry: Tree[] = [];
      const idx = currTreeData.findIndex((data) => data.planSemester === null);
      if (idx !== -1) {
        entry = currTreeData.splice(idx, 1);
      }
    }
    const sorted = currTreeData.sort((a, b) => {
      if (a.planSemester == undefined || b.planSemester == undefined) return 1;
      return a.planSemester - b.planSemester;
    });
    setTreeData(sorted);
    setExpanded(sorted.map((entry) => `FS-${entry.planSemester ?? "notAssigned"}`));
  }, [data]);

  if (loading) return <LoadingIndicator />;

  return (
    <div className={classes.root}>
      <TreeView
        className={classes.container}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
      >
        {treeData.map((tree) => {
          return (
            <div>
              <TreeItem
                nodeId={`FS-${tree.planSemester ?? "notAssigned"}`}
                label={tree.planSemester ? `${tree.planSemester}. Fachsemester` : "Nicht zugewiesen"}
              >
                {tree.moduls.map((modul) => {
                  return <TreeItem nodeId={`${modul.modulId}`} label={modul.modulName} />;
                })}
              </TreeItem>
            </div>
          );
        })}
      </TreeView>
    </div>
  );
};

export default SemesterModulZuordnung;
