import { FormHelperText } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import SelectPre from "@material-ui/core/Select";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { CSSProperties } from "react";

interface StyleProps {
  fontSize: CSSProperties["fontSize"];
  noFormControlMarginTop: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    formControl: {
      width: "100%",
      marginTop: ({ noFormControlMarginTop }) => (noFormControlMarginTop ? 0 : theme.spacing(2)),
    },
    selectEmpty: {
      marginTop: ({ noFormControlMarginTop }) => (noFormControlMarginTop ? 0 : theme.spacing(2)),
    },
    inputLabel: {
      // fontSize: "1.2em",
      // lineHeight: "1.2em",
    },
    select: {
      fontSize: ({ fontSize }) => fontSize,
      display: ({ fontSize }) => (fontSize ? "flex" : "unset"),
      alignItems: ({ fontSize }) => (fontSize ? "center" : "unset"),
    },
    menuItem: {
      fontSize: ({ fontSize }) => fontSize,
    },
  })
);

export interface SelectProps {
  disabled?: boolean;
  error: boolean;
  fontSize?: CSSProperties["fontSize"];
  fullWidth?: boolean;
  helperText?: React.ReactNode;
  items: Array<{ label: string; value: string | number }>;
  label?: string;
  noFormControlMarginTop?: boolean;
  readonly?: boolean;
  setState: (val: string | number) => void;
  state: string | number;
}

const Select: React.FC<SelectProps> = ({
  state,
  setState,
  label,
  items,
  disabled,
  error,
  helperText,
  fullWidth = false,
  readonly,
  noFormControlMarginTop = false,
  fontSize,
}) => {
  const classes = useStyles({ noFormControlMarginTop, fontSize });

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setState(event.target.value as string);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "30vh",
      },
    },
  };

  return (
    <FormControl error={error} variant="outlined" className={classes.formControl}>
      {!!label && (
        <InputLabel className={classes.inputLabel} id="demo-simple-select-outlined-label">
          {label}
        </InputLabel>
      )}

      <SelectPre
        MenuProps={MenuProps}
        fullWidth={fullWidth}
        error={error}
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={!state ? "" : state}
        onChange={handleChange}
        label={label}
        disabled={disabled}
        classes={{ select: classes.select }}
        readOnly={readonly}
      >
        {items.map(({ label, value }) => (
          <MenuItem className={classes.menuItem} key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </SelectPre>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
export default Select;
