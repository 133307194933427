import type * as Types from "../../types";

import gql from "graphql-tag";
import type * as ApolloReactCommon from "@apollo/react-common";
import * as ApolloReactHooks from "@apollo/client";
export type GetMyCertificateOfStudyQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetMyCertificateOfStudyQuery = {
  myCertificateOfStudy?: Types.Maybe<{
    __typename?: "DownloadFile";
    fileName?: Types.Maybe<string>;
    fileContent?: Types.Maybe<Array<any>>;
    fileContentBase64?: Types.Maybe<string>;
    fileType?: Types.Maybe<string>;
    statusMessage?: Types.Maybe<string>;
    downloadUri?: Types.Maybe<string>;
  }>;
};

export const GetMyCertificateOfStudyDocument = gql`
  query getMyCertificateOfStudy {
    myCertificateOfStudy {
      fileName
      fileContent
      fileContentBase64
      fileType
      statusMessage
      downloadUri
    }
  }
`;

/**
 * __useGetMyCertificateOfStudyQuery__
 *
 * To run a query within a React component, call `useGetMyCertificateOfStudyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCertificateOfStudyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCertificateOfStudyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyCertificateOfStudyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyCertificateOfStudyQuery, GetMyCertificateOfStudyQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetMyCertificateOfStudyQuery, GetMyCertificateOfStudyQueryVariables>(
    GetMyCertificateOfStudyDocument,
    baseOptions
  );
}
export function useGetMyCertificateOfStudyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMyCertificateOfStudyQuery,
    GetMyCertificateOfStudyQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetMyCertificateOfStudyQuery, GetMyCertificateOfStudyQueryVariables>(
    GetMyCertificateOfStudyDocument,
    baseOptions
  );
}
export type GetMyCertificateOfStudyQueryHookResult = ReturnType<typeof useGetMyCertificateOfStudyQuery>;
export type GetMyCertificateOfStudyLazyQueryHookResult = ReturnType<typeof useGetMyCertificateOfStudyLazyQuery>;
export type GetMyCertificateOfStudyQueryResult = ApolloReactCommon.QueryResult<
  GetMyCertificateOfStudyQuery,
  GetMyCertificateOfStudyQueryVariables
>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
