import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, useLocation, useRouteMatch } from "react-router-dom";
import routes, { Route as RouteInterface } from "./routes";
import { gql, useApolloClient } from "@apollo/client";
import MainLayout from "../components/templates/MainLayout";
import Breadcrumb from "components/molecules/Breadcrumb";
import Development from "components/pages/Development";
import { matchPath } from "react-router";
const renderRoute = (route: RouteInterface) => {
  return (
    <Route key={route.path} path={route.path} exact={route.exact}>
      {route.addBreadcrumb && <Breadcrumb routes={routes} />}
      {route.component !== null ? <route.component /> : <Development label={route.label} />}
    </Route>
  );
};

const flattenRoutes = (routes: RouteInterface[]) => {
  let result: Array<Omit<RouteInterface, "subRoutes">> = [];
  routes.forEach((el) => {
    const { subRoutes, ...route } = el;
    result.push(route);
    if (subRoutes !== undefined) {
      result = result.concat(flattenRoutes(subRoutes.map((el) => ({ ...el, path: route.path + el.path }))));
    }
  });
  return result;
};

const withSubRoutes = flattenRoutes(routes);

const RouterSwitch = () => {
  const location = useLocation();
  const client = useApolloClient();
  const [currentPageTitle, setCurrentPageTitle] = useState<string | null>(null);

  useEffect(() => {
    const currentRoute = withSubRoutes.find(({ path, exact }) => matchPath(location.pathname ?? "", { path, exact }));
    if (currentRoute?.mainLayout !== undefined && currentRoute.mainLayout) {
      setCurrentPageTitle(currentRoute.label);
    } else {
      setCurrentPageTitle(null);
    }
  }, [location.pathname]);

  useEffect(() => {
    client.writeQuery({
      query: gql`
        query Query {
          currentPageTitle
        }
      `,
      data: {
        currentPageTitle,
      },
    });
  }, [currentPageTitle, client]);

  return (
    <Switch>
      {currentPageTitle !== null && (
        <MainLayout pageTitle={currentPageTitle}>
          {withSubRoutes.filter((route) => route.mainLayout).map(renderRoute)}
        </MainLayout>
      )}
      {withSubRoutes.map(renderRoute)}
    </Switch>
  );
};

const Router = () => {
  return (
    <BrowserRouter>
      <RouterSwitch />
    </BrowserRouter>
  );
};

export default Router;
