import { createMuiTheme, Theme } from "@material-ui/core/styles";
import colors from "./colors";

declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    colors: typeof colors;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    colors?: typeof colors;
  }
}

const theme: Theme = createMuiTheme({
  palette: {
    primary: {
      // light: colors.lightblue,
      main: colors.sunYellow,
    },
    secondary: { main: colors.steelBlue },
    success: {
      main: colors.green,
    },
    warning: { main: colors.orange },
    error: {
      main: colors.red,
    },
    text: {
      primary: colors.tBlack,
    },
  },
  colors,
  typography: {
    htmlFontSize: 10,
    fontFamily: "Roboto latin, Roboto",
    h2: {
      fontFamily: "Roboto latin bold, Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "3.2rem",
      lineHeight: "1.5",
    },
    h5: {
      fontFamily: "Roboto latin bold, Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "1.6em",
      lineHeight: 2.4,
    },
    h4: {
      fontFamily: "Roboto latin bold, Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "1.8em",
      lineHeight: 2.1,
    },
    h3: {
      fontFamily: "Roboto latin, Roboto",
      fontSize: "2.1em",
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
    MuiButton: {
      disableRipple: true,
      disableElevation: true,
    },
    MuiIconButton: {
      disableRipple: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 80,
    },
  },
});

export default theme;
