import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import {
  CellProps,
  Column,
  PluginHook,
  Row,
  TableInstance,
  TableOptions,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useColumnOrder,
} from "react-table";
import { IndeterminateCheckbox, IndeterminateCheckboxRow } from "./Cells/TableCells";
// import createTableComponent, { TableComponentProps } from "./CreateTableComponents/createTableComponent";
import { DefaultColumnFilter } from "./Filters";
import { MakeTableConfig, TableComponentProps } from "./tableTypes";
import createTableComponent from "./CreateTableComponents/createTableComponent";
// import { TableHeaderComponentProps } from "../Table/CreateTableComponents/TableHeadComponent";

export interface MakeTableConfigBase {
  dense?: boolean;
  filtering?: boolean;
  pagination?: boolean;
  rowSelection?: boolean;
  rowSelectionAllDisbled?: boolean;
  sorting?: boolean;
  stickyHeader?: boolean;
  striped?: boolean;
  subComponent?: boolean;
}

// export interface ObjectType<T extends Object> {}

// export type CreateTableComponent<T extends Object = {}> = (
//   params: CreateTableComponentParams<T>
// ) => React.FC<TableComponentProps<T>>;

// export interface MakeTableConfig<T> extends MakeTableConfigBase {
//   createCustomComponent?: CreateTableComponent<T>;
//   customTableComponents?: {
//     // Header?: CreateTableComponent<T>;
//     Header?: React.FC<TableHeaderComponentProps<object>>;
//   };
// }

// export interface CreateTableComponentParams<T extends object> {
//   config: MakeTableConfig<T>;
//   tableInstance: TableInstance<T>;
// }

// export interface CreateTableComponentRowParams<T extends CreateTableComponentParams<T>> {
//   row: number;
// }

// // export type CreateTableComponent<T extends Object = {}> = (
// //   params: CreateTableComponentParams<T>
// // ) => React.FC<TableComponentProps<T>>;

// // export interface CreateTableComponentParams<T extends object> {
// //   config: MakeTableConfigBase;
// //   tableInstance: TableInstance<T>;
// // }
// // export interface MakeTableConfig<T> extends MakeTableConfigBase {
// //   createCustomComponent?: CreateTableComponent<T>;
// // }

export function useCreateColumns<D extends Object>(params: Array<Column<D>>): Array<Column<D>> {
  return React.useMemo(() => params, []);
}

function useMakeTable<D extends { id: number | string }>(
  tableOptions: TableOptions<D>,
  config: MakeTableConfig<D> = {}
) {
  const defaultTableOptions: Partial<TableOptions<D>> = {
    autoResetSortBy: false,
    autoResetFilters: false,
    autoResetSelectedRows: false,
    autoResetExpanded: false,
    autoResetGlobalFilter: false,
    autoResetGroupBy: false,
    autoResetPage: false,
    autoResetRowState: false,
    getRowId: (row) => `${row.id}`,
  };

  const _config: MakeTableConfig<D> = {
    pagination: true, // pagination by default
    stickyHeader: true,
    columnOrder: true,
    ...config,
  };

  const plugins: Array<PluginHook<D>> = [];

  if (_config.filtering === true) {
    plugins.push(useFilters);
    plugins.push(useGlobalFilter);
    defaultTableOptions.defaultColumn = {
      Filter: DefaultColumnFilter,
    };
  }
  if (_config.sorting === true) {
    plugins.push(useSortBy);
  }
  if (_config.columnOrder) {
    plugins.push(useColumnOrder);
  }
  if (_config.subComponent === true) {
    plugins.push(useExpanded);
    if (tableOptions.columns.findIndex((c) => c.id === "expander") === -1) {
      plugins.push((hooks) => {
        hooks.visibleColumns.push((columns) => [
          {
            id: "expander",
            width: 30,
            // eslint-disable-next-line react/display-name
            Cell: (props: CellProps<D>) => (
              <span {...props.row.getToggleRowExpandedProps()}>
                {props.row.isExpanded ? <ExpandMoreIcon color="secondary" /> : <ChevronRightIcon color="secondary" />}
              </span>
            ),
          },
          ...columns,
        ]);
      });
    }
  }
  if (_config.pagination === true) {
    plugins.push(usePagination);
  }
  if (_config.rowSelection === true) {
    plugins.push(useRowSelect);
    if (tableOptions.columns.findIndex((c) => c.id === "selector") === -1) {
      plugins.push((hooks) => {
        const isSortAble = !!(tableOptions.initialState?.sortBy ?? []).find((sort) => sort.id === "selector");
        const width = isSortAble ? 100 : 50;
        hooks.visibleColumns.push((columns) => [
          // Column for selection
          {
            id: "selector",
            width: width,
            minWidth: width,
            maxWidth: width,
            canSort: isSortAble,
            sortType: (
              rowA: Row<D>,
              rowB: Row<D>
              // columnId: string,
              // desc: boolean
            ) => {
              // console.log(rowA);

              return (
                // @ts-ignore
                rowB.original.selectedRealId - rowA.original.selectedRealId
              );
            },
            Header: _config.rowSelectionAllDisbled
              ? ""
              : (props) => (
                  <>
                    <IndeterminateCheckbox {...props.getToggleAllRowsSelectedProps()} />
                    {isSortAble ? "" : ""}
                  </>
                ),
            // eslint-disable-next-line react/display-name
            Cell: (props: CellProps<D>) => <IndeterminateCheckboxRow {...props.row.getToggleRowSelectedProps()} />,
          },
          ...columns,
        ]);
      });
    }
  }
  if (config.columnOrder) {
    plugins.push(useColumnOrder);
  }

  const options = {
    ...defaultTableOptions,
    ...tableOptions,
  };

  const createTableComponentInner = _config.createCustomComponent ?? createTableComponent;
  const tableInstance = useTable<D>(options, ...plugins);

  const TableComponent = React.useMemo(() => createTableComponentInner({ tableInstance, config: _config }), []);

  return { tableInstance, TableComponent };
}

export default useMakeTable;
