import * as React from "react";
import { makeStyles, createStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { BewerberInputCreate } from "raft/internal/schemaTypes";
import Props from "./index.d";
import { formatDate } from "translations/formatter";
import { useGetSummaryDetailsQuery } from "graphqlBase/misc/__generated__/getSummaryDetails";
import translations from "translations";
import clsx from "clsx";

const useStyles = makeStyles(
  (theme: AugmentedTheme) =>
    createStyles({
      headline: { fontSize: "2em" },
      subtitle: { fontSize: "1.2em", marginTop: "1em" },
      summaryBox: { display: "flex", flexDirection: "column" },
      summaryBoxRow: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
        },
      },
      summaryBoxInner: {
        display: "flex",
        flexDirection: "column",
        width: "30vw",
        marginBottom: "2em",
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
      },
      summaryBoxStudiengang: { display: "flex", flexDirection: "column", marginBottom: "2em", marginTop: "2em" },
      spaceTop: { marginTop: "1em" },
      title: {},
      infoContainer: { display: "flex" },
      infoLabel: {
        flexBasis: "45%",
      },
      infoValue: {
        flexBasis: "55%",
      },
      noMargin: { margin: 0 },
    }),
  { name: "Summary" }
);

const getData = (state: string) => {
  try {
    return JSON.parse(window.window.localStorage.getItem("applicationKo") ?? "");
  } catch (e) {
    return undefined;
  }
};

const SummaryComponent: React.FC<Props> = (props) => {
  const {
    title,
    disabled,
    data: { state },
  } = props;
  const data = getData(state) as BewerberInputCreate | undefined;
  if (!data) return null;
  return <Inner data={data} />;
};

const Inner: React.FC<{ data: BewerberInputCreate }> = ({ data }) => {
  const {
    studiengangSemesterId = 0,
    zahlungsmodellId = 0,
    geschlechtId = 0,
    staatsangehoerigkeitId = 0,
    wohnlandId = 0,
    geburtslandId = 0,
  } = data;
  const { form: translation } = translations.pages.bewerbung;
  const { data: summaryData } = useGetSummaryDetailsQuery({
    variables: {
      studiengangSemesterId,
      zahlungsmodellId,
      geschlechtId,
      staatsangehoerigkeitId,
      wohnlandId,
      geburtslandId,
    },
  });
  const classes = useStyles({});
  try {
    return (
      <div className={classes.summaryBox}>
        <Typography variant={"h3"} className={classes.headline}>
          {translation.checkPersonalData}
        </Typography>
        <Typography variant={"subtitle2"} className={classes.subtitle}>
          <p className={classes.noMargin}>{translation.confirmData}</p>
        </Typography>
        <div className={classes.summaryBoxStudiengang}>
          <Typography variant="h5">{translation.course}</Typography>
          <Typography className={classes.spaceTop}>
            {summaryData?.studiengangSemesters[0].studiengangOnBew?.studienmodell.studienmodellName}
          </Typography>
          <Typography className={classes.spaceTop}>
            {summaryData?.studiengangSemesters[0].studiengangOnBew?.studiengangName}
          </Typography>
          <Typography>{summaryData?.studiengangSemesters[0].semester?.semesterName}</Typography>
          <Typography className={classes.spaceTop}>{summaryData?.zahlungsmodells[0].label}</Typography>
        </div>
        {/* <Typography>{summaryData?.studiengangSemesters[0].se}</Typography> */}
        <div className={classes.summaryBoxRow}>
          <div className={classes.summaryBoxInner}>
            <Typography variant="h5">{translation.personalInformation}</Typography>
            <div className={clsx(classes.spaceTop, classes.infoContainer)}>
              <Typography className={classes.infoLabel}>{`${translation.gender}:`}</Typography>
              <Typography> {summaryData?.geschlechts[0].label}</Typography>
            </div>
            <div className={classes.infoContainer}>
              <Typography className={classes.infoLabel}>{`${translation.name}:`}</Typography>
              <Typography className={classes.infoValue}>
                {data.vorname} {data.nachname}
              </Typography>
            </div>
            <div className={classes.infoContainer}>
              <Typography className={classes.infoLabel}>{`${translation.birthDate}:`}</Typography>
              <Typography className={classes.infoValue}>{formatDate(new Date(data.geburtsdatum ?? ""))}</Typography>
            </div>
            <div className={classes.infoContainer}>
              <Typography className={classes.infoLabel}>{`${translation.birthPlace}:`}</Typography>
              <Typography className={classes.infoValue}>{data.geburtsort}</Typography>
            </div>
            {/* <div className={classes.infoContainer}>
              <Typography className={classes.infoLabel}>Wohnort:</Typography>
              <Typography>{data.wohnort}</Typography>
            </div> */}
            <div className={classes.infoContainer}>
              <Typography className={classes.infoLabel}>{`${translation.nationality}:`}</Typography>
              <Typography className={classes.infoValue}>{summaryData?.staatsangehoerigkeits[0].label}</Typography>
            </div>
            <div className={classes.infoContainer}>
              <Typography className={classes.infoLabel}>{`${translation.birthCountry}:`}</Typography>
              <Typography className={classes.infoValue}>{summaryData?.geburtslands[0].label}</Typography>
            </div>
          </div>
          <div className={classes.summaryBoxInner}>
            <Typography variant="h5">{translation.contactDetails}</Typography>
            {data.strasse && data.hausnummer && (
              <div className={clsx(classes.spaceTop, classes.infoContainer)}>
                <Typography className={classes.infoLabel}>{`${translation.street}:`}</Typography>
                <Typography className={classes.infoValue}>
                  {data.strasse} {data.hausnummer}
                </Typography>
              </div>
            )}
            {data.plz && (
              <div className={classes.infoContainer}>
                <Typography className={classes.infoLabel}>{`${translation.plz}:`}</Typography>
                <Typography className={classes.infoValue}>{data.plz}</Typography>
              </div>
            )}
            {data.wohnort && (
              <div className={classes.infoContainer}>
                <Typography className={classes.infoLabel}>{`${translation.placeOfResidence}:`}:</Typography>
                <Typography className={classes.infoValue}>{data.wohnort}</Typography>
              </div>
            )}
            <div className={classes.infoContainer}>
              <Typography className={classes.infoLabel}>{`${translation.countryOfResidence}:`}</Typography>
              <Typography className={classes.infoValue}>{summaryData?.wohnlands[0].label}</Typography>
            </div>
            {data.telefonnummer && (
              <div className={classes.infoContainer}>
                <Typography className={classes.infoLabel}>{`${translation.phoneNumber}:`}</Typography>
                <Typography className={classes.infoValue}>{data.telefonnummer}</Typography>
              </div>
            )}
            <div className={classes.infoContainer}>
              <Typography className={classes.infoLabel}>{`${translation.email}:`}</Typography>
              <Typography className={classes.infoValue}>{data.email}</Typography>
            </div>
          </div>
        </div>
      </div>
    );
  } catch (e) {
    return null;
  }
};

export default SummaryComponent;
