import { useHistory } from "react-router-dom";
import React from "react";
import Button from "components/lbc-toolkit/atomics/Button/DefaultButton";
import translations from "translations";
import { useStyles } from "../StudentAdd";

interface StudentAddButtonProps {
  formType: "abgebrochen" | "teilnehmer" | "zertifikatsTeilnehmer" | "zertifikatsTeilnehmerAbgebrochen";
}

const StudentAddButton: React.FC<StudentAddButtonProps> = ({ formType }) => {
  const classes = useStyles();
  const history = useHistory();

  function handleClick() {
    if (formType === "zertifikatsTeilnehmer") {
      history.push(`addTeilnehmer`);
      return;
    }
    history.push(`addStudent`);
  }

  return (
    <Button
      label={`${formType === "zertifikatsTeilnehmer" ? "Teilnehmer" : "Studenten"} ${"hinzufügen"}`}
      buttonType="primary"
      onClick={handleClick}
      classes={{ colorPrimary: classes.addButton }}
    />
  );
};
export default StudentAddButton;
