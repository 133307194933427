import { responsiveFontSizes, Theme } from "@material-ui/core/styles";
import theme from "./theme";
import overrides from "./overrides";

const main: Theme = {
  ...theme,
  overrides,
};

export default responsiveFontSizes(main);
