import { createStyles, makeStyles, Theme as AugmentedTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Select, { SelectProps } from "components/atomics/Select";
import { useGetListsForstudiengangSemestersSelectQuery } from "graphqlBase/misc/__generated__/getListsForstudiengangSemestersSelect";
import { useGetZahlungsModellsQuery } from "graphqlBase/ZahlungsModell/__generated__/getZahlungsModells";
import React, { useState, useEffect, useCallback } from "react";
import FormHeader from "components/atomics/FormHeader";
// import DefaultButton from "../../../atomics/DefaultButton";
import Props from "./index.d";
import translations from "translations";

type ItemArray = SelectProps["items"];
type ItemPre = ItemArray[number];
interface Item extends ItemPre {
  value: number;
}

interface StudienModelItem extends Item {
  studiengangSemesterId: number;
}
interface StudienGangItem extends Item {
  studiengangSemesterId: number;
  studienmodellId: number;
}
interface SemesterItem extends Item {
  studiengangSemesterId: number;
  studiengangIds: (number | null | undefined)[];
}

const useStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    StudienGangSemesterSelect: {
      // height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    title: {
      minWidth: 160,
      marginRight: 10,
      align: "right",
    },
  })
);

const zahlungsModellSort = [2, 3, 1, 5, 4];

const StudienGangSemesterSelect: React.FC<Props> = (props) => {
  const {
    title,
    errors,
    disabled,
    noTitles,
    noAsterisks = false,
    data: { state, setAndSave },
    //@ts-ignore
    update,
  } = props;

  const { form: translation } = translations.pages.bewerbung;
  const { data } = useGetListsForstudiengangSemestersSelectQuery();
  const { data: zahlungsModellData } = useGetZahlungsModellsQuery();
  const [studienmodell, setStudienmodell] = useState<number>(0);
  const [studiengang, setStudiengang] = useState<number>(0);
  const [semester, setSemester] = useState<number>(0);
  const [zahlungsModell, setZahlungsModell] = useState<number>(0);

  const [possibleStudienGaenge, setPossibleStudienGaenge] = useState<StudienGangItem[]>([]);
  const [possibleSemesters, setPossibleSemesters] = useState<SemesterItem[]>([]);
  const [possibleStudienmodell, setPossibleStudienmodell] = useState<StudienModelItem[]>([]);
  const [possibleZahlungsmodell, setPossibleZahlungsmodell] = useState<Item[]>([]);

  useEffect(() => {
    if (!studiengang || !semester) return;
    const studiengangSemesterid =
      (data?.studiengangSemesters ?? []).find((studiengangSemester) => {
        return studiengangSemester.studiengangOnBewId === studiengang && studiengangSemester.semesterId === semester;
      })?.id ?? null;

    //@ts-ignore
    setAndSave(studiengangSemesterid);
  }, [studienmodell, studiengang, semester, data]);

  useEffect(() => {
    const studiengangSemesters = (data?.studiengangSemesters ?? []).find(
      (studiengangSemesters) => studiengangSemesters.id === state
    );
    if (!studiengangSemesters) return;
    setStudienmodell(studiengangSemesters?.studiengangOnBew?.studienmodell.id ?? 0);
    setStudiengang(studiengangSemesters?.studiengangOnBewId ?? 0);
    setSemester(studiengangSemesters.semesterId ?? 0);
  }, [state, data]);

  useEffect(() => {
    const items = (data?.studiengangSemesters ?? []).reduce<StudienModelItem[]>(
      (studienModels, studiengangSemester) => {
        const elem = {
          studiengangSemesterId: studiengangSemester.id,
          label: studiengangSemester.studiengangOnBew?.studienmodell.studienmodellName ?? "",
          value: studiengangSemester.studiengangOnBew?.studienmodell.id ?? 0,
        };
        if (~studienModels.findIndex((item) => item.value === elem.value)) {
          return studienModels;
        }

        return studienModels.concat(elem);
      },
      []
    );

    setPossibleStudienmodell(items);
    if (items.length === 1) {
      setStudienmodell(items[0].value);
    }
  }, [data, state]);

  useEffect(() => {
    const items = (data?.studiengangSemesters ?? []).reduce<StudienGangItem[]>((studienGaenge, studiengangSemester) => {
      const elem = {
        studiengangSemesterId: studiengangSemester.id,
        label: studiengangSemester.studiengangOnBew?.studiengangName ?? "",
        value: studiengangSemester.studiengangOnBew?.id ?? 0,
        studienmodellId: studiengangSemester.studiengangOnBew?.studienmodellId ?? 0,
      };

      if (
        (studienmodell && elem.studienmodellId !== studienmodell) ||
        ~studienGaenge.findIndex((studiengang) => studiengang.value === elem.value)
      )
        return studienGaenge;
      return studienGaenge.concat(elem);
    }, []);

    const zahlungsmodells = (zahlungsModellData?.zahlungsmodells ?? [])
      .filter((zahlungsModell) => zahlungsModell.studienmodellId === studienmodell)
      .map((zahlungsModell) => ({
        value: zahlungsModell.id,
        label: zahlungsModell.zahlungsmodellName,
      }))
      .sort(
        (a, b) =>
          zahlungsModellSort.findIndex((key) => key === a.value) -
          zahlungsModellSort.findIndex((key) => key === b.value)
      );
    setPossibleStudienGaenge(items);
    setPossibleZahlungsmodell(zahlungsmodells);
    if (items.length === 1) {
      setStudiengang(items[0].value);
    }
    if (zahlungsmodells.length) {
      setZahlungsModell(zahlungsmodells[0].value);
    }
  }, [data, zahlungsModellData, state, studienmodell]);

  useEffect(() => {
    const items = (data?.studiengangSemesters ?? []).reduce<SemesterItem[]>((semesters, studiengangSemester) => {
      const elem = {
        studiengangSemesterId: studiengangSemester.id,
        label: studiengangSemester.semester?.semesterName ?? "",
        value: studiengangSemester.semester?.id ?? 0,
        studiengangIds:
          studiengangSemester.semester?.studiengangSemesters.flatMap(
            (studiengangSemesterInner) => studiengangSemesterInner.studiengangOnBewId
          ) ?? [],
      };
      if (
        ~semesters.findIndex((semester) => semester.value === elem.value) ||
        (studiengang && !elem.studiengangIds.includes(studiengang))
      )
        return semesters;

      return semesters.concat(elem);
    }, []);

    setPossibleSemesters(items);
    if (items.length === 1) {
      setSemester(items[0].value);
    }
  }, [data, state, studiengang]);

  useEffect(() => {
    if (!!zahlungsModellData) update({ zahlungsmodellId: zahlungsModell });
  }, [zahlungsModell, zahlungsModellData]);

  const classes = useStyles({});
  return (
    <div className={classes.StudienGangSemesterSelect}>
      {!noTitles && <FormHeader title={`${translation.modelText} ${noAsterisks ? "" : "*"}`} />}
      <Select
        label={`${translation.model} ${noAsterisks ? "" : "*"}`}
        setState={(value) => setStudienmodell(+value)}
        state={studienmodell}
        items={possibleStudienmodell}
        disabled={disabled}
        error={!!errors?.length && !studienmodell}
      />
      {!noTitles && <FormHeader title={`${translation.courseText} ${noAsterisks ? "" : "*"}`} />}
      <Select
        label={`${translation.course} ${noAsterisks ? "" : "*"}`}
        setState={(value) => setStudiengang(+value)}
        state={studiengang}
        items={possibleStudienGaenge}
        disabled={disabled}
        error={!!errors?.length && !studiengang}
      />
      <Select
        label={`${translation.start} ${noAsterisks ? "" : "*"}`}
        setState={(value) => setSemester(+value)}
        state={semester}
        items={possibleSemesters}
        disabled={disabled}
        error={!!errors?.length && !semester}
      />
      {!noTitles && <FormHeader title={`${translation.paymentText} ${noAsterisks ? "" : "*"}`} />}
      <Select
        label={`${translation.payment} ${noAsterisks ? "" : "*"}`}
        setState={(value) => {
          setZahlungsModell(+value);
        }}
        state={zahlungsModell}
        items={possibleZahlungsmodell}
        disabled={disabled}
        error={!!errors?.length && !zahlungsModell}
      />
    </div>
  );
};

export default StudienGangSemesterSelect;
