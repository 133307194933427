import React from "react";
import MoodleModules from "..";

const now = new Date();
now.setUTCHours(0, 0, 0, 0);

const variables = { zeitraum: { zeitraumBis: { lt: now.toISOString() } } };

const PastModules: React.FC<{}> = () => {
  return (
    <div style={{ width: "100%" }}>
      <MoodleModules where={variables} hideAddButton hideImportButton />
    </div>
  );
};

export default PastModules;
