import React from "react";
import MoodleModules from "..";

const variables = {};

const AllModules: React.FC<{}> = () => {
  return (
    <div style={{ width: "100%", overflowY: "hidden" }}>
      <MoodleModules where={variables} hideImportButton />
    </div>
  );
};

export default AllModules;
