import type * as Types from "../../types";

import gql from "graphql-tag";
import type * as ApolloReactCommon from "@apollo/react-common";
import * as ApolloReactHooks from "@apollo/client";
export type GetAllTranscriptsOfRecordQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetAllTranscriptsOfRecordQuery = {
  allTranscriptsOfRecord?: Types.Maybe<{
    __typename?: "DownloadFile";
    fileName?: Types.Maybe<string>;
    fileContentBase64?: Types.Maybe<string>;
    fileType?: Types.Maybe<string>;
    downloadUri?: Types.Maybe<string>;
  }>;
};

export const GetAllTranscriptsOfRecordDocument = gql`
  query getAllTranscriptsOfRecord {
    allTranscriptsOfRecord {
      fileName
      downloadUri
    }
  }
`;

/**
 * __useGetAllTranscriptsOfRecordQuery__
 *
 * To run a query within a React component, call `useGetAllTranscriptsOfRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTranscriptsOfRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTranscriptsOfRecordQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllTranscriptsOfRecordQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAllTranscriptsOfRecordQuery,
    GetAllTranscriptsOfRecordQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetAllTranscriptsOfRecordQuery, GetAllTranscriptsOfRecordQueryVariables>(
    GetAllTranscriptsOfRecordDocument,
    baseOptions
  );
}
export function useGetAllTranscriptsOfRecordLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllTranscriptsOfRecordQuery,
    GetAllTranscriptsOfRecordQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetAllTranscriptsOfRecordQuery, GetAllTranscriptsOfRecordQueryVariables>(
    GetAllTranscriptsOfRecordDocument,
    baseOptions
  );
}
export type GetAllTranscriptsOfRecordQueryHookResult = ReturnType<typeof useGetAllTranscriptsOfRecordQuery>;
export type GetAllTranscriptsOfRecordLazyQueryHookResult = ReturnType<typeof useGetAllTranscriptsOfRecordLazyQuery>;
export type GetAllTranscriptsOfRecordQueryResult = ApolloReactCommon.QueryResult<
  GetAllTranscriptsOfRecordQuery,
  GetAllTranscriptsOfRecordQueryVariables
>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
