import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      marginTop: "45%",
      marginLeft: "45%",
    },
  })
);

export default () => {
  const classes = useStyles();
  return (
    <div>
      <CircularProgress className={classes.progress} size={"5%"} />
    </div>
  );
};
