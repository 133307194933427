import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetStudentGradeOverviewQueryVariables = Types.Exact<{
  studentId: Types.Scalars['Int'];
}>;


export type GetStudentGradeOverviewQuery = { studentGradeOverview?: Types.Maybe<{ __typename?: 'StudentGradeOverview', grade?: Types.Maybe<number>, eCTS?: Types.Maybe<number>, currentSemester?: Types.Maybe<string>, student?: Types.Maybe<{ __typename?: 'Student', id: number, vorname?: Types.Maybe<string>, nachname?: Types.Maybe<string> }>, modules?: Types.Maybe<Array<Types.Maybe<{ __typename?: 'ModuleGrade', id: number, modulId: number, modulbezeichnung?: Types.Maybe<string>, semester?: Types.Maybe<number>, pruefungsId?: Types.Maybe<number>, pruefungsform?: Types.Maybe<string>, points?: Types.Maybe<number>, grade?: Types.Maybe<number>, eCTS?: Types.Maybe<number>, color?: Types.Maybe<string>, examStatus?: Types.Maybe<string>, eCTSString?: Types.Maybe<string>, statusText?: Types.Maybe<string>, note?: Types.Maybe<number> }>>> }> };


export const GetStudentGradeOverviewDocument = gql`
    query getStudentGradeOverview($studentId: Int!) {
  studentGradeOverview(studentId: $studentId) {
    grade
    eCTS
    student {
      id
      vorname
      nachname
    }
    currentSemester
    modules {
      id
      modulId
      modulbezeichnung
      semester
      pruefungsId
      pruefungsform
      points
      grade
      eCTS
      color
      examStatus
      eCTSString
      statusText
      note
    }
  }
}
    `;

/**
 * __useGetStudentGradeOverviewQuery__
 *
 * To run a query within a React component, call `useGetStudentGradeOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentGradeOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentGradeOverviewQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetStudentGradeOverviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStudentGradeOverviewQuery, GetStudentGradeOverviewQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStudentGradeOverviewQuery, GetStudentGradeOverviewQueryVariables>(GetStudentGradeOverviewDocument, baseOptions);
      }
export function useGetStudentGradeOverviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStudentGradeOverviewQuery, GetStudentGradeOverviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStudentGradeOverviewQuery, GetStudentGradeOverviewQueryVariables>(GetStudentGradeOverviewDocument, baseOptions);
        }
export type GetStudentGradeOverviewQueryHookResult = ReturnType<typeof useGetStudentGradeOverviewQuery>;
export type GetStudentGradeOverviewLazyQueryHookResult = ReturnType<typeof useGetStudentGradeOverviewLazyQuery>;
export type GetStudentGradeOverviewQueryResult = ApolloReactCommon.QueryResult<GetStudentGradeOverviewQuery, GetStudentGradeOverviewQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
