import { Pruefungsergebni } from "graphqlBase/types";

import { atomFamily, selectorFamily, useRecoilCallback, atom, DefaultValue } from "recoil";
export interface PruefungsergebniExtend extends Pruefungsergebni {
  edit?: boolean;
}
const pruefungsErgebnisList = atomFamily<PruefungsergebniExtend, number>({
  key: "pruefungsErgebnisList",
  default: { id: 0 },
});

const pruefungIds = atom<number[]>({
  key: "pruefungIds",
  default: [],
});

export const pruefungsErgebnisse = selectorFamily<PruefungsergebniExtend, Pruefungsergebni>({
  key: "pruefungsErgnisse",
  get:
    ({ id }) =>
    ({ get }) => {
      const atom = get(pruefungsErgebnisList(id));
      return atom;
    },
  set:
    ({ id }) =>
    ({ set, get }, pruefungsErgebnis) => {
      if (pruefungsErgebnis instanceof DefaultValue || !pruefungsErgebnis.id) return;
      set(pruefungsErgebnisList(id), { ...pruefungsErgebnis, edit: true });
      const fieldIds = get(pruefungIds);
      if (!fieldIds.includes(id) && pruefungsErgebnis.edit === true) {
        set(pruefungIds, (prev) => [...prev, id]);
      }
    },
});

export type PruefungsergebniForRow = PruefungsergebniExtend;

export const usePruefungsErgebnisListCallback = () => {
  return useRecoilCallback(({ snapshot, reset }) => async () => {
    const resetPruefungsIds = () => reset(pruefungIds)
    const ids = await snapshot.getPromise(pruefungIds);
    const pruefungsergebnis = await Promise.all(
      ids.map(async (id) => await snapshot.getPromise(pruefungsErgebnisList(id)))
    );
    return { pruefungsergebnis, ids, resetPruefungsIds };
  });
};
