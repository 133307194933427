import getTestIDs from "lib/utils/getTestIDs";
import React from "react";

export const testIDs = getTestIDs();

const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.70715 8.00004L12.3536 4.35359L11.6465 3.64648L8.00004 7.29293L4.35359 3.64648L3.64648 4.35359L7.29293 8.00004L3.64648 11.6465L4.35359 12.3536L8.00004 8.70715L11.6465 12.3536L12.3536 11.6465L8.70715 8.00004Z"
      fill="currentColor"
    />
  </svg>
);
export default CloseIcon;
