import { useQuery, gql } from "@apollo/client";
import { useMemo } from "react";
import props from "./index.d";

interface UseGetForeignKeyListParams {
  foreignKeyParams: props["foreignKeyParams"];
}
function useGetForeignKeyList({ foreignKeyParams }: UseGetForeignKeyListParams): { value: string; label: string }[] {
  const query = useMemo(() => {
    return gql`query{
          elems:${foreignKeyParams.entityName}{
            value:id
            label: ${foreignKeyParams.column}
          }
        }`;
  }, [foreignKeyParams]);
  const { data } = useQuery(query);
  return data?.elems ?? [];
}

export default useGetForeignKeyList;
