export default {
  name: "Hersteller",
  plural: "Hersteller",
  columns: {
    id: "ID",
    isDeleted: "ist gelöscht",
    manufacturerName: "Herstellername",
    manufacturerShorthandName: "Hersteller Kurzschrift Name",
    tenantId: "Mieter-ID",
  },
};
