import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      root: {
        minHeight: "60px",
        justifyContent: "flex-end",
        display: "flex",
        width: "100%",
        alignItems: "center",
      },
    }),
  { name: `TableTopContainer` }
);

const TableTopContainer: React.FC<{}> = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

export default TableTopContainer;
