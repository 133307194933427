import { useHistory, useLocation } from "react-router-dom";
import React from "react";
import Button from "components/lbc-toolkit/atomics/Button/DefaultButton";
import translations from "translations";
import { useStyles } from "../StudiumAdd";

interface ModuleAddButtonProps {
  isZertifikat?: boolean;
}

const StudiumAddButton: React.FC<ModuleAddButtonProps> = ({ isZertifikat }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  function handleClick() {
    history.push(`${location.pathname}/addstudium`);
  }

  return (
    <Button
      label={`${isZertifikat ? translations.pages.produkte.studium.label : translations.pages.produkte.studium.label} ${
        translations.globals.button.add
      }`}
      buttonType="primary"
      onClick={handleClick}
      classes={{ colorPrimary: classes.addButton }}
    />
  );
};
export default StudiumAddButton;
