import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useGtMyStudentDataQuery } from "graphqlBase/MyStudentData/__generated__/getMyStudentData";
import { MyStudentDataUpdateForm } from "raft/MyStudentDataForm";
const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      MyStudentFormBox: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
    }),

  { name: `Pages-Home` }
);

const MyStudent: React.FC<{}> = () => {
  const classes = useStyles({});

  const { data, refetch } = useGtMyStudentDataQuery();

  const id = data?.myStudentData?.studentId;

  useEffect(() => {
    refetch().catch(() => {
      return id;
    });
  }, [id, refetch]);

  const afterUpdate = () => {
    refetch().catch(() => {
      return id;
    });
  };

  return (
    <div className={classes.MyStudentFormBox}>
      <MyStudentDataUpdateForm
        onUpdateDataMiddleWare={(data) => {
          return data.data;
        }}
        afterUpdate={afterUpdate}
      />
    </div>
  );
};

export default MyStudent;
