import { Configuration, PopupRequest, PublicClientApplication } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_ADAL_TENANT ?? ""}`,
    clientId:
      process.env.REACT_APP_GRAPHQL_URI === "https://app-kolping-prod-gateway.azurewebsites.net/graphql"
        ? process.env.REACT_APP_ADAL_CLIENT_ID_PROD ?? ""
        : process.env.REACT_APP_ADAL_CLIENT_ID ?? "",
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: [
    `${
      process.env.REACT_APP_GRAPHQL_URI === "https://app-kolping-prod-gateway.azurewebsites.net/graphql"
        ? process.env.REACT_APP_ADAL_AAD_SCOPE_PROD
        : process.env.REACT_APP_ADAL_AAD_SCOPE
    }`,
    "profile",
  ],
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const getUserName = () => msalInstance.getAllAccounts()[0]?.name ?? "";

export const getTokenAsync = async () => {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    return await msalInstance.loginRedirect();
  }
  const request = {
    ...loginRequest,
    account: activeAccount ?? accounts[0],
  };

  const authResult = await msalInstance.acquireTokenSilent(request);
  return authResult.accessToken;
};

export const getDecodedJWT = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  return msalInstance.getActiveAccount();
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export const logout = async () => await msalInstance.logoutRedirect();
