export default {
  anmeldungCreateForm: {
    long: {
      address: "Anschrift",
      name: "Name",
      houseNumber: "Hausnummer",
      place: "Ort",
      postalCode: "PLZ",
      street: "Straße",
    },
    short: {
      email: "Email",
      errorMessage: "Etwas ist bei der Anmeldung schiefgelaufen.",
      date: "Termin",
      firstname: "Vorname",
      lastname: "Nachname",
      headline: "Anmeldung zur Veranstaltung {0}",
      phone: "Telefon",
      privacyPolicy: "Datenschutzhinweis",
      privacyPolicyDefaultText:
        "Ich erlaube der Kolping Hochschule, meine Kontaktdaten im Zusammenhang mit der Infoveranstaltung zu verwenden.",
      signUp: "Anmelden",
      successMessage: "Schön, dass du dich angemeldet hast!",
    },
  },
};
