import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
const useStyles = makeStyles<Theme, LoaderProps>((theme: Theme) =>
  createStyles({
    loaderBox: {
      display: "flex",
      height: "100%",
      width: "100%",
    },
    progress: {
      top: "50%",
      left: "50%",
      width: "5%",
      color: theme.colors.brandYellow,
      position: "absolute",
    },
    fadeOpacity: (props) => ({
      opacity: props.loading ? (props.hideChildrenOnLoad ? 0 : 0.4) : 1,
      width: "100%",
    }),
  })
);

interface LoaderProps {
  hideChildrenOnLoad?: boolean;
  loading: boolean;
  style?: object;
}
const Loader: React.FC<LoaderProps> = (props) => {
  const classes = useStyles(props);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  return (
    <>
      {props.loading && <CircularProgress className={classes.progress} size="70" />}
      <div style={props?.style} className={classes.loaderBox}>
        <div className={classes.fadeOpacity}>{!(props.hideChildrenOnLoad && props.loading) && props.children}</div>
      </div>
    </>
  );
};

export default Loader;
