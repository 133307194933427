import { useEffect, useState } from "react";

export const screenSizes = { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920 };

const useMediaQuery = (minWidth: number) => {
  const [isMinWidth, setMinWidth] = useState({
    windowWidth: window.innerWidth,
    isDesiredWidth: false,
  });

  useEffect(() => {
    const resizeHandler = () => {
      const currentWindowWidth = window.innerWidth;
      const isDesiredWidth = currentWindowWidth < minWidth;
      setMinWidth({ windowWidth: currentWindowWidth, isDesiredWidth });
    };
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
  }, [minWidth, isMinWidth.windowWidth]);

  return isMinWidth.isDesiredWidth;
};

export default useMediaQuery;
