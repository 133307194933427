import React from "react";
import { useParams } from "react-router-dom";
import { useGetVeranstaltungbyIdQuery } from "../../../graphqlBase/Anmeldung/__generated__/getVeranstaltungById";
import { Typography } from "@material-ui/core";
import VeranstaltungenForm from "./VeranstaltungenForm";

const Veranstaltung: React.FC<{}> = () => {
  const { veranstaltungId } = useParams<{ veranstaltungId?: string }>();

  const veranstaltung = useGetVeranstaltungbyIdQuery({
    variables: { id: parseInt(veranstaltungId ?? "0") },
  });

  if (veranstaltung.loading) return <Typography>Loading ...</Typography>;
  if (veranstaltung.error) return <Typography>ERROR! {veranstaltung.error.message}</Typography>;
  if (veranstaltung.data?.veranstaltung == null) return <Typography>Veranstaltung existiert nicht.</Typography>;

  return <VeranstaltungenForm veranstaltung={veranstaltung.data.veranstaltung} />;
};

export default Veranstaltung;
