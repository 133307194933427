import React from "react";
import { useHistory } from "react-router";

import {
  useGetMoodleModulsLazyQuery,
  GetMoodleModulsQuery,
  ResultType,
} from "graphqlBase/MoodleModul/__generated__/getMoodleModuls";
import { prepareServerSideTable } from "components/templates/table-factory";
import { SortEnumType, MoodleModulFilterInput, MoodleModulSortInput } from "graphqlBase/types";
import { formatDate } from "translations/formatter";
import actionsCellFactory from "components/templates/table-factory/Table/Cells/actionsCellFactory";
import ModuleAddButton from "./MoodleModuleAdd/MoodleModuleAddButton";
import TableTopContainer from "components/atomics/TableTopContainer";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import useMediaQuery, { screenSizes } from "lib/hooks/useMediaQuery";
import MoodleModulImportButton from "../../../formElements/fields/MoodleModulTermine/ModulTerminImportButton";

const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      root: { width: "100%", marginTop: "2em", paddingLeft: "28px", paddingRight: "33px" },
    }),
  { name: `MoodleModules` }
);

type MoodleModul = ResultType<GetMoodleModulsQuery["moodleModuls"]>;

const { useCreateServerSideColumns, useMakeServerSideTable, makeSeverSideFilter } = prepareServerSideTable<
  MoodleModul,
  MoodleModulFilterInput,
  MoodleModulSortInput,
  SortEnumType
>();

interface MoodleModulesProps {
  hideAddButton?: boolean;
  hideImportButton?: boolean;
  where: MoodleModulFilterInput;
}
const MoodleModules: React.FC<MoodleModulesProps> = ({ where, hideAddButton, hideImportButton }) => {
  const classes = useStyles();
  const history = useHistory();
  const [query, { data, refetch }] = useGetMoodleModulsLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const handleEdit = (value: number) => {
    history.push(`moodleModulEdit/${value}`);
  };

  const ActionCell = actionsCellFactory({
    onEdit: handleEdit,
    onDelete: {
      entity: "moodleModul",
      afterDelete: () => {
        if (refetch)
          refetch().catch(() => {
            //
          });
      },
    },
  });

  const { columns, serversideQueryConfig } = useCreateServerSideColumns(
    [
      {
        Header: "Zeitraum",
        accessor: (row) => row.zeitraum?.zeitraumName,
        id: "zeitraum",
        remoteOrder: ({ order, sort }) => ({ zeitraum: { zeitraumName: sort } }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { zeitraum: { zeitraumName: { contains: filterValue } } }),
        }),
      },
      {
        Header: "Modulname",
        accessor: (row) => row.modul?.modulName,
        id: "modulName",
        remoteOrder: ({ order, sort }) => ({ modul: { modulName: sort } }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { modul: { modulName: { contains: filterValue } } }),
        }),
      },
      {
        Header: "Studiengangtyp",
        accessor: (row) => row.modul?.studiengangTyp?.studiengangTypKuerzel,
        id: "studiengangtyp",
        remoteOrder: ({ order, sort }) => ({ modul: { studiengangTyp: { studiengangTypKuerzel: sort } } }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { modul: { studiengangTyp: { studiengangTypKuerzel: { contains: filterValue } } } }),
        }),
      },
      {
        Header: "Modulverantwortlicher",
        accessor: (row) =>
          row.modulVerantwortlicher?.id
            ? `${row.modulVerantwortlicher.vorname} ${row.modulVerantwortlicher.nachname}`
            : "",
        id: "modulverantwortlicher",
        remoteOrder: ({ order, sort }) => ({ modulVerantwortlicher: { vorname: sort } }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, {
              or: [
                { modulVerantwortlicher: { vorname: { contains: filterValue } } },
                { modulVerantwortlicher: { nachname: { contains: filterValue } } },
              ],
            }),
        }),
      },
      {
        Header: "Lehrender",
        accessor: (row) => (row.lehrender?.id ? `${row.lehrender.vorname} ${row.lehrender.nachname}` : ""),
        id: "Lehrender",
        remoteOrder: ({ order, sort }) => ({ lehrender: { vorname: sort } }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, {
              or: [
                { lehrender: { vorname: { contains: filterValue } } },
                { lehrender: { nachname: { contains: filterValue } } },
              ],
            }),
        }),
      },
      {
        Header: "ECTS Punkte",
        accessor: (row) => row.modul?.ectspunkte,
        id: "ectspoints",
        remoteOrder: ({ order, sort }) => ({ modul: { ectspunkte: sort } }),
        remoteFilter: makeSeverSideFilter<number>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { modul: { ectspunkte: { eq: filterValue && +filterValue } } }),
        }),
      },
      {
        Header: "Von",
        accessor: (row) => (row.zeitraum?.zeitraumVon ? formatDate(new Date(row.zeitraum.zeitraumVon)) : ""),
        id: "zeitraumVon",
        remoteOrder: ({ order, sort }) => ({ zeitraum: { zeitraumVon: sort } }),
        remoteFilter: makeSeverSideFilter<number>({
          filterPath: ({ filterValue, where, merge }) => merge(where, { modul: { ectspunkte: { eq: filterValue } } }),
          Filter: (f) => <div style={{ height: 32 }} />,
        }),
      },
      {
        Header: "Bis",
        accessor: (row) => (row.zeitraum?.zeitraumBis ? formatDate(new Date(row.zeitraum.zeitraumBis)) : ""),
        id: "zeitraumBis",
        remoteOrder: ({ order, sort }) => ({ zeitraum: { zeitraumBis: sort } }),
        remoteFilter: makeSeverSideFilter<number>({
          filterPath: ({ filterValue, where, merge }) => merge(where, { modul: { ectspunkte: { eq: filterValue } } }),
          Filter: (f) => <div style={{ height: 32 }} />,
        }),
      },
      {
        Header: "Moodle Id",
        accessor: (row) => row.id,
        id: "moodleId",
        // remoteOrder: ({ order, sort }) => ({ moodleModulInternId: sort }),
        remoteFilter: makeSeverSideFilter<number>({
          filterPath: ({ filterValue, where, merge }) => merge(where, { moodleModulInternId: { eq: filterValue } }),
          Filter: (f) => <div style={{ height: 32 }} />,
        }),
        hide: true,
      },
      {
        Header: "",
        accessor: "id",
        id: "id",
        Cell: ActionCell,
        width: 60,
      },
    ],
    [refetch]
  );

  const { TableComponent } = useMakeServerSideTable(
    {
      data: data?.moodleModuls ?? [],
      columns,
      initialState: { pageSize: 25 },
    },
    {
      query,
      variables: { where, order: [{ modul: { modulName: "ASC" as SortEnumType.Asc } }] },
      serversideQueryConfig,
      tableId: "pastMoodleModuls",
    }
  );

  const padding = useMediaQuery(screenSizes.md);

  return (
    <div
      className={classes.root}
      style={{
        paddingLeft: padding ? "10px" : "48px",
        paddingRight: padding ? "10px" : "63px",
      }}
    >
      <TableTopContainer>
        {!hideImportButton && <MoodleModulImportButton where={where} />}
        {!hideAddButton && <ModuleAddButton key="moodlemodul-add-button-1" />}
      </TableTopContainer>
      <TableComponent />
    </div>
  );
};

export default MoodleModules;
