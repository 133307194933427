export default {
  label: "Interessenten",
  anmeldung: "Anmeldung",
  onlineAnmeldungen: {
    label: "Online Anmeldungen",
  },
  editBewerber: {
    label: "Online Bewerber Bearbeiten",
  },
  veranstaltungen: {
    label: "Veranstaltungen",
  },
  interessenten: {
    label: "Interessenten",
  },
  beratungsanfragen: {
    label: "Beratungsanfragen",
  },
};
