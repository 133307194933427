import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetStudiengangsQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.StudiengangFilterInput>;
  take?: Types.Maybe<Types.Scalars['Int']>;
  skip?: Types.Maybe<Types.Scalars['Int']>;
  order?: Types.Maybe<Array<Types.StudiengangSortInput> | Types.StudiengangSortInput>;
}>;


export type GetStudiengangsQuery = { studiengangs: Array<{ __typename?: 'Studiengang', id: number, ausbildungsArtId?: Types.Maybe<number>, studiengangName?: Types.Maybe<string>, studiengangKuerzel?: Types.Maybe<string>, fachpruefungsordnung: string, versionsNummer?: Types.Maybe<number>, activeFrom?: Types.Maybe<string>, activeTo?: Types.Maybe<string>, regelstudienzeit?: Types.Maybe<number>, umfangModule?: Types.Maybe<number>, ectsmodul?: Types.Maybe<number>, ectsgesamt?: Types.Maybe<number>, ectszulassungsvoraussetzungBapruefung?: Types.Maybe<number>, ectsbathesis?: Types.Maybe<number>, ectskolloquium?: Types.Maybe<number>, studiengangTyp: { __typename?: 'StudiengangTyp', id: number, studiengangTypName: string }, verantwortlichMitarbeiterStudiengangs: Array<{ __typename?: 'VerantwortlichMitarbeiterStudiengang', id: number, studiengangId: number, stammdatenMaid: number, stammdatenMa: { __typename?: 'StammdatenMa', id: number, vorname: string, nachname: string, benutzername: string } }> }> };


export const GetStudiengangsDocument = gql`
    query getStudiengangs($where: StudiengangFilterInput, $take: Int, $skip: Int, $order: [StudiengangSortInput!]) {
  studiengangs(where: $where, take: $take, skip: $skip, order: $order) {
    id
    ausbildungsArtId
    studiengangName
    studiengangKuerzel
    fachpruefungsordnung
    versionsNummer
    studiengangTyp {
      id
      studiengangTypName
    }
    verantwortlichMitarbeiterStudiengangs {
      id
      studiengangId
      stammdatenMaid
      stammdatenMa {
        id
        vorname
        nachname
        benutzername
      }
    }
    activeFrom
    activeTo
    regelstudienzeit
    umfangModule
    ectsmodul
    ectsgesamt
    ectszulassungsvoraussetzungBapruefung
    ectsbathesis
    ectskolloquium
  }
}
    `;

/**
 * __useGetStudiengangsQuery__
 *
 * To run a query within a React component, call `useGetStudiengangsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudiengangsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudiengangsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetStudiengangsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStudiengangsQuery, GetStudiengangsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStudiengangsQuery, GetStudiengangsQueryVariables>(GetStudiengangsDocument, baseOptions);
      }
export function useGetStudiengangsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStudiengangsQuery, GetStudiengangsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStudiengangsQuery, GetStudiengangsQueryVariables>(GetStudiengangsDocument, baseOptions);
        }
export type GetStudiengangsQueryHookResult = ReturnType<typeof useGetStudiengangsQuery>;
export type GetStudiengangsLazyQueryHookResult = ReturnType<typeof useGetStudiengangsLazyQuery>;
export type GetStudiengangsQueryResult = ApolloReactCommon.QueryResult<GetStudiengangsQuery, GetStudiengangsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
