import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetStudentIdCardQueryVariables = Types.Exact<{
  studentId: Types.Scalars['Int'];
}>;


export type GetStudentIdCardQuery = { studentIdCard?: Types.Maybe<{ __typename?: 'DownloadFile', fileName?: Types.Maybe<string>, fileContentBase64?: Types.Maybe<string> }> };


export const GetStudentIdCardDocument = gql`
    query getStudentIdCard($studentId: Int!) {
  studentIdCard(studentId: $studentId) {
    fileName
    fileContentBase64
  }
}
    `;

/**
 * __useGetStudentIdCardQuery__
 *
 * To run a query within a React component, call `useGetStudentIdCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentIdCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentIdCardQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetStudentIdCardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStudentIdCardQuery, GetStudentIdCardQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStudentIdCardQuery, GetStudentIdCardQueryVariables>(GetStudentIdCardDocument, baseOptions);
      }
export function useGetStudentIdCardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStudentIdCardQuery, GetStudentIdCardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStudentIdCardQuery, GetStudentIdCardQueryVariables>(GetStudentIdCardDocument, baseOptions);
        }
export type GetStudentIdCardQueryHookResult = ReturnType<typeof useGetStudentIdCardQuery>;
export type GetStudentIdCardLazyQueryHookResult = ReturnType<typeof useGetStudentIdCardLazyQuery>;
export type GetStudentIdCardQueryResult = ApolloReactCommon.QueryResult<GetStudentIdCardQuery, GetStudentIdCardQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
