import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { StudentUpdateForm } from "raft/StudentForm";
import React from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  useSemesterStatusCallback,
  studentSemesterStatusIds,
} from "components/formElements/fields/StudienVerlauf/recoilState";
import { useUpdateMatchStudentSemestersMutation } from "graphqlBase/MatchStudentSemesters/__generated__/updateMatchStudentSemesters";
import onUpdateUpdateMiddlewareStudentAndBewerber from "../onUpdateMiddleWareStudentAndBewerber";
import { useSetRecoilState, useRecoilCallback } from "recoil";
import { closeOnUpdate } from "./recoilState";
const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      StudentUpdateFormBox: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
      afterCreate: {
        margin: "auto",
        maxWidth: "700px",
      },
      afterCreateBody: {
        marginTop: "1em",
      },
      afterCreateHeadline: { marginBottom: "10px" },
    }),

  { name: `Pages-Home` }
);

const useSaveSemesterStatus = () => {
  return useSemesterStatusCallback();
};

const StudentUpdate: React.FC<{}> = () => {
  const classes = useStyles({});
  const history = useHistory();
  const { teilnehmerId } = useParams<{ teilnehmerId?: string }>();
  const id = +(teilnehmerId ?? "0");
  const location = useLocation();
  const semesterStatus = useSaveSemesterStatus();
  const [updateMatchStudentSemester] = useUpdateMatchStudentSemestersMutation();
  const setSemesterStatusIds = useSetRecoilState(studentSemesterStatusIds);
  const setShouldClose = useSetRecoilState(closeOnUpdate);

  const shouldClose = useRecoilCallback(
    ({ snapshot }) => async () => {
      return await snapshot.getPromise(closeOnUpdate);
    },
    []
  );
  const onLeave = () => {
    history.push(
      location.pathname.includes("zertifikatsTeilnehmerEdit")
        ? "/teilnehmer/zertifikatsTeilnehmer"
        : "/teilnehmer/teilnehmerList"
    );
  };
  const onAfterUpdate = async () => {
    const currSemesterStatus = await semesterStatus();
    if (
      currSemesterStatus.studentSemesterStatus[id] != null &&
      Object.keys(currSemesterStatus.studentSemesterStatus[id]).length !== 0 &&
      currSemesterStatus.studentSemesterStatus[id].constructor === Object
    ) {
      const matchStudentSemestersVariables = [];
      for (const [key, value] of Object.entries(currSemesterStatus.studentSemesterStatus[id])) {
        matchStudentSemestersVariables.push({ id: parseInt(key), studentSemesterStatusId: value });
      }
      try {
        await updateMatchStudentSemester({
          variables: { matchStudentSemesters: matchStudentSemestersVariables },
        });
      } catch (error) {}
    }
    const shouldCloseAfterSave = await shouldClose();
    if (shouldCloseAfterSave) {
      setShouldClose(false);
      onLeave();
    }
  };

  React.useEffect(() => {
    return () => {
      setSemesterStatusIds({});
    };
  }, [setSemesterStatusIds]);

  if (!id) return null;
  return (
    <div className={classes.StudentUpdateFormBox}>
      <StudentUpdateForm
        ausbildungsArtId={location.pathname.includes("zertifikatsTeilnehmerEdit") ? 2 : 1}
        // ausbildungsArtId={2}
        onCancel={onLeave}
        afterUpdate={onAfterUpdate}
        onUpdateDataMiddleWare={onUpdateUpdateMiddlewareStudentAndBewerber}
        id={id}
      />
    </div>
  );
};

export default StudentUpdate;
