import colors from "themes/colors";

export const disabledCommon = {
  "&:disabled": {
    backgroundColor: colors.grey100,
    color: colors.grey600,
    border: `1px solid ${colors.grey300}`,
    "& svg": {
      color: colors.grey600,
    },
    "&:hover": {
      backgroundColor: colors.grey100,
      color: colors.grey600,
      border: `1px solid ${colors.grey300}`,
      "& svg": {
        color: colors.grey600,
      },
    },
    "&:active": {
      backgroundColor: colors.grey100,
      color: colors.grey600,
      border: `1px solid ${colors.grey300}`,
      "& svg": {
        color: colors.grey600,
      },
    },
  },
};

export const darkDisabledCommon = {
  "&:disabled": {
    backgroundColor: "transparent",
    color: colors.grey300,
    border: `1px solid ${colors.grey600}`,
    "& svg": {
      color: colors.grey300,
    },
    "&:hover": {
      backgroundColor: "transparent",
      color: colors.grey300,
      border: `1px solid ${colors.grey600}`,
      "& svg": {
        color: colors.grey300,
      },
    },
  },
};

export const hoverCommon = {
  "&:hover": {
    color: colors.grey100,
    backgroundColor: colors.grey700,
    borderColor: colors.grey700,
    "& svg": {
      color: colors.grey100,
    },
  },
};

export const activeCommon = {
  "&:active": {
    "&:hover": {
      color: colors.grey100,
      backgroundColor: colors.tBlack,
      borderColor: colors.tBlack,
      "& svg": {
        color: colors.grey100,
      },
    },
  },
};
