import React from "react";
import { useHistory } from "react-router";

import { prepareServerSideTable } from "components/templates/table-factory";
import { SortEnumType, StudiengangFilterInput, StudiengangSortInput } from "graphqlBase/types";
import {
  GetStudiengangsQuery,
  ResultType,
  useGetStudiengangsLazyQuery,
} from "graphqlBase/Studiengang/__generated__/getStudiengangs";
import actionsCellFactory from "../../../../templates/table-factory/Table/Cells/actionsCellFactory";
import TableTopContainer from "components/atomics/TableTopContainer";
import { QueryVariables } from "components/templates/table-factory2/Table/useMakeServerSideTable";
import StudiumAddButton from "../StudiumAdd/StudiumAddButton";
type Studiengang = ResultType<GetStudiengangsQuery["studiengangs"]>;

interface StudiengangListBaseProps {
  variables?: QueryVariables<StudiengangFilterInput, StudiengangSortInput[]>;
  isZertifikat?: boolean;
}

const baseVariables = {
  order: [{ studiengangId: "DESC" as SortEnumType.Desc }],
};

const { useCreateServerSideColumns, useMakeServerSideTable, makeSeverSideFilter } = prepareServerSideTable<
  Studiengang,
  StudiengangFilterInput,
  StudiengangSortInput,
  SortEnumType
>();

const StudiengangListBase: React.FC<StudiengangListBaseProps> = ({ variables, isZertifikat }) => {
  const history = useHistory();
  const [query, { data, refetch }] = useGetStudiengangsLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const handleEdit = (value: number) => {
    history.push(`${isZertifikat ? "zertifikatEdit" : "studiengangEdit"}/${value}`);
  };

  const ActionCell = actionsCellFactory({
    onEdit: handleEdit,
    // onDelete: {
    //   entity: "studiengang",
    //   afterDelete: () => {
    //     if (refetch) refetch().catch(() => {});
    //   },
    // },
  });

  const { columns, serversideQueryConfig } = useCreateServerSideColumns(
    [
      {
        Header: "Studiengang",
        accessor: (row) => row.studiengangName,
        id: "studiengangName",
        remoteOrder: ({ order, sort }) => ({ studiengangName: sort }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) => merge(where, { studiengangName: { contains: filterValue } }),
        }),
      },
      {
        Header: "Studiengangkürzel",
        accessor: (row) => row.studiengangKuerzel,
        id: "studiengangKuerzel",
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { studiengangKuerzel: { contains: filterValue } }),
        }),
      },
      {
        Header: "Fachprüfungsordnung",
        accessor: (row) => row.fachpruefungsordnung,
        id: "fachpruefungsordnung",
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { fachpruefungsordnung: { contains: filterValue } }),
        }),
      },
      {
        Header: "Versionsnummer",
        accessor: (row) => row.versionsNummer,
        id: "versionsNummer",
        remoteFilter: makeSeverSideFilter<number>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { versionsNummer: { eq: filterValue && +filterValue } }),
        }),
      },
      {
        Header: "Studiengangstyp",
        accessor: (row) => row.studiengangTyp?.studiengangTypName,
        id: "studiengangTypName",
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { studiengangTyp: { studiengangTypName: { contains: filterValue } } }),
        }),
      },
      {
        Header: "Regelstudienzeit",
        accessor: (row) => row.regelstudienzeit,
        id: "regelstudienzeit",
        remoteFilter: makeSeverSideFilter<number>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { regelstudienzeit: { eq: filterValue && +filterValue } }),
        }),
      },
      {
        Header: "Umfang Module",
        accessor: (row) => row.umfangModule,
        id: "umfangModule",
        remoteFilter: makeSeverSideFilter<number>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { umfangModule: { eq: filterValue && +filterValue } }),
        }),
        hide: true,
      },
      {
        Header: "ECTS Gesamt",
        accessor: (row) => row.ectsgesamt,
        id: "ectsgesamt",
        remoteFilter: makeSeverSideFilter<number>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { ectsgesamt: { eq: filterValue && +filterValue } }),
        }),
      },
      {
        Header: "",
        accessor: "id",
        id: "id",
        Cell: ActionCell,
        width: 60,
      },
    ],
    [refetch]
  );

  const { TableComponent, tableInstance } = useMakeServerSideTable(
    {
      data: data?.studiengangs ?? [],
      columns,
      initialState: { pageSize: 25, hiddenColumns: isZertifikat ? ["fachpruefungsordnung"] : [] },
    },
    {
      query,
      variables: { ...baseVariables, ...variables },
      serversideQueryConfig,
      tableId: "StudiengangTable",
    }
  );

  return (
    <div style={{ width: "100%", marginTop: "2em", paddingLeft: "28px", paddingRight: "33px" }}>
      <TableTopContainer>
        <StudiumAddButton key="studium-add-button-1" />
      </TableTopContainer>
      <TableComponent />
    </div>
  );
};
export default StudiengangListBase;
