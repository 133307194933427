import {
  StudentSemesterStatusesFragment,
  useGetCurrentStudentStatusQuery,
} from "../../graphqlBase/MatchStudentSemesters/__generated__/getCurrentStudentStatus";
const useGetStudentStatusFromSemesterStatus = (studentId: number) => {
  const { data } = useGetCurrentStudentStatusQuery({ variables: { studentId } });
  if (!data?.student) return "";
  return getCurrentStatus(data.student);
};

export const getCurrentStatus = ({ matchStudentSemesters }: StudentSemesterStatusesFragment) => {
  const now = new Date();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const nextStatus = (matchStudentSemesters ?? []).find(
    (matchStudentSemester) => new Date(matchStudentSemester.zeitraum?.zeitraumBis ?? "01.01.1970") > now
  );
  const statusEntry = nextStatus ?? matchStudentSemesters[matchStudentSemesters.length - 1];
  return statusEntry?.studentSemesterStatus?.studentSemesterStatusName ?? "";
};

export default useGetStudentStatusFromSemesterStatus;
