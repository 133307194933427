import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetUploadFilesQueryVariables = Types.Exact<{
  beweberId: Types.Scalars['Int'];
}>;


export type GetUploadFilesQuery = { uploadedFiles: Array<{ __typename?: 'UploadedFile', bewerberId: number, id: number, downloadUri: string, fileTypeId: number, fileNameBlob: string, fileName: string, uploadFileType?: Types.Maybe<{ __typename?: 'UploadFileType', fileTypeName: string, isDeleted: boolean }> }> };


export const GetUploadFilesDocument = gql`
    query getUploadFiles($beweberId: Int!) {
  uploadedFiles(where: {bewerberId: {eq: $beweberId}}) {
    bewerberId
    id
    downloadUri
    fileTypeId
    fileNameBlob
    fileName
    uploadFileType {
      fileTypeName
      isDeleted
    }
  }
}
    `;

/**
 * __useGetUploadFilesQuery__
 *
 * To run a query within a React component, call `useGetUploadFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadFilesQuery({
 *   variables: {
 *      beweberId: // value for 'beweberId'
 *   },
 * });
 */
export function useGetUploadFilesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUploadFilesQuery, GetUploadFilesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUploadFilesQuery, GetUploadFilesQueryVariables>(GetUploadFilesDocument, baseOptions);
      }
export function useGetUploadFilesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUploadFilesQuery, GetUploadFilesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUploadFilesQuery, GetUploadFilesQueryVariables>(GetUploadFilesDocument, baseOptions);
        }
export type GetUploadFilesQueryHookResult = ReturnType<typeof useGetUploadFilesQuery>;
export type GetUploadFilesLazyQueryHookResult = ReturnType<typeof useGetUploadFilesLazyQuery>;
export type GetUploadFilesQueryResult = ApolloReactCommon.QueryResult<GetUploadFilesQuery, GetUploadFilesQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
