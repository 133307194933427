import React from "react";
import { createStyles, makeStyles, Theme, ThemeProvider } from "@material-ui/core/styles";
import MuiTable from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import { makeId } from "../../utils/utils";
import TablePaginationActions from "../TablePaginationActions";
import { main } from "../../theme";
import { CreateTableComponent, TableComponentProps } from "../tableTypes";
import TableHeaderComponent from "../CreateTableComponents/TableHeadComponent";
import TableBodyRowComponent from "../CreateTableComponents/TableBodyRowComponent";
import TableSubcomponent from "../CreateTableComponents/TableSubcomponent";
import PaginationComponent from "../CreateTableComponents/TablePagination";

import { useElementSize } from "usehooks-ts";

interface TableComponentPropsExtended extends TableComponentProps {
  measuredHeight: number;
}

const createTableComponent: CreateTableComponent<any> = ({ tableInstance, config }) => {
  const tableId = makeId(4);
  const useStyles = makeStyles<Theme, TableComponentPropsExtended>(
    (theme) =>
      createStyles({
        root: {
          marginBottom: config.autoSize ? "50vh" : "2em",
          height: "100%",
          maxHeight: config.autoSize ? "max-content" : undefined,
        },
        container: ({ measuredHeight }) => {
          if (config.stickyHeader) {
            return {
              height: config.autoSize ? measuredHeight - 50 : undefined,
              maxHeight: "73vh",
            };
          }
          return {};
        },
      }),

    { name: `Table-${tableId}` }
  );

  const TableComponent: React.FC<TableComponentProps<object>> = (props) => {
    const [squareRef, { height }] = useElementSize();
    const classes = useStyles({ ...props, measuredHeight: height });
    const { getTableProps, getTableBodyProps, rows, page, prepareRow } = tableInstance;
    const bodyRows = config.pagination === true ? page : rows;

    const Header = config.customTableComponents?.Header ? config.customTableComponents.Header : TableHeaderComponent;
    const BodyRow = config.customTableComponents?.BodyRow
      ? config.customTableComponents.BodyRow
      : TableBodyRowComponent;
    const Subcomponent = config.customTableComponents?.Subcomponent
      ? config.customTableComponents.Subcomponent
      : TableSubcomponent;
    const Pagination = config.customTableComponents?.Pagination
      ? config.customTableComponents?.Pagination
      : PaginationComponent;
    const theme = config.customTableComponents?.Theme ? config.customTableComponents.Theme : main;

    return (
      <ThemeProvider theme={theme}>
        <div className={classes.root} {...props.rootProps} ref={squareRef}>
          <TableContainer {...props.tableProps?.container} className={classes.container}>
            <MuiTable {...getTableProps()} {...props.tableProps?.table} stickyHeader={config.stickyHeader}>
              <Header tableId={tableId} config={config} tableInstance={tableInstance} tableProps={props.tableProps} />
              <TableBody {...getTableBodyProps()} {...props.tableProps?.body}>
                {bodyRows.map((row, rowIndex) => {
                  prepareRow(row);
                  return (
                    <React.Fragment key={`table-${tableId}-br-${row.id}`}>
                      <BodyRow
                        tableId={tableId}
                        row={row}
                        rowIndex={rowIndex}
                        config={config}
                        tableInstance={tableInstance}
                        tableProps={props.tableProps}
                      />
                      {config.subComponent === true && row.isExpanded && props.renderRowSubComponent !== undefined && (
                        <Subcomponent
                          tableId={tableId}
                          row={row}
                          config={config}
                          tableInstance={tableInstance}
                          tableProps={props.tableProps}
                          renderRowSubComponent={props.renderRowSubComponent}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </MuiTable>
          </TableContainer>
          <Pagination
            TablePaginationActions={TablePaginationActions}
            tableProps={props.tableProps}
            config={config}
            tableInstance={tableInstance}
          />
        </div>
      </ThemeProvider>
    );
  };
  return TableComponent;
};

export default createTableComponent;
