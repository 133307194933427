import { atom, useRecoilCallback } from "recoil";

export interface MatchStudentSemesterStatusIdStudentSpecific {
  [matchStudentSemesterId: number]: number;
}

export interface MatchStudentSemesterStatusIds {
  [studentId: number]: MatchStudentSemesterStatusIdStudentSpecific;
}

export const studentSemesterStatusIds = atom<MatchStudentSemesterStatusIds>({
  key: "studentSemesterStatusIds",
  default: {},
});

export const useSemesterStatusCallback = () => {
  return useRecoilCallback(({ snapshot }) => async () => {
    const [studentSemesterStatus] = await Promise.all([snapshot.getPromise(studentSemesterStatusIds)]);
    return { studentSemesterStatus };
  });
};
