import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type UploadDocumentsMutationVariables = Types.Exact<{
  documents?: Types.Maybe<Array<Types.Maybe<Types.AttachementInput>> | Types.Maybe<Types.AttachementInput>>;
}>;


export type UploadDocumentsMutation = { uploadDocuments?: Types.Maybe<Array<Types.Maybe<{ __typename?: 'UploadedFile', id: number, downloadUri: string, fileNameBlob: string, fileTypeId: number, bewerberId: number, uploadDate: string, isDeleted: boolean, uploadFileType?: Types.Maybe<{ __typename?: 'UploadFileType', id: number }> }>>> };


export const UploadDocumentsDocument = gql`
    mutation uploadDocuments($documents: [AttachementInput]) {
  uploadDocuments(documents: $documents) {
    id
    downloadUri
    fileNameBlob
    fileTypeId
    bewerberId
    uploadFileType {
      id
    }
    uploadDate
    isDeleted
  }
}
    `;
export type UploadDocumentsMutationFn = ApolloReactCommon.MutationFunction<UploadDocumentsMutation, UploadDocumentsMutationVariables>;

/**
 * __useUploadDocumentsMutation__
 *
 * To run a mutation, you first call `useUploadDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDocumentsMutation, { data, loading, error }] = useUploadDocumentsMutation({
 *   variables: {
 *      documents: // value for 'documents'
 *   },
 * });
 */
export function useUploadDocumentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadDocumentsMutation, UploadDocumentsMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadDocumentsMutation, UploadDocumentsMutationVariables>(UploadDocumentsDocument, baseOptions);
      }
export type UploadDocumentsMutationHookResult = ReturnType<typeof useUploadDocumentsMutation>;
export type UploadDocumentsMutationResult = ApolloReactCommon.MutationResult<UploadDocumentsMutation>;
export type UploadDocumentsMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadDocumentsMutation, UploadDocumentsMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
