import gql from "graphql-tag";
import { ApolloClient } from "@apollo/client";
import { QueryArray, DeleteChildProps } from "../formGen.model";
import afterCreateOrDelete from "./afterCreateOrDelete";
export interface DeleteParamsPre {
  entity: string;
  client: ApolloClient<any>;
  afterDelete?: (data: any) => void;
  updateAfterDeleteQueries?: QueryArray;
}

export type IdType = string[] | string;
export type DeleteItemsFucType = (idsIn: string[] | number) => Promise<IdType>;

export default ({ entity, client, afterDelete, updateAfterDeleteQueries }: DeleteParamsPre) => async (
  idsIn: number[] | number
) => {
  const entityUpperCase = entity.charAt(0).toUpperCase() + entity.slice(1);
  const ids = typeof idsIn === "string" ? [idsIn] : idsIn;

  const mutation = gql`mutation RaftDelete${entity}($ids:[Int!]!){
        result: delete${entityUpperCase}s(ids:$ids){
           id
           __typename
         }
       }`;
  const result = await client.mutate({
    mutation,
    variables: { ids },
  });
  const deletedIds: { id: string; __typename: string }[] = result.data?.result.map((row: { id: string }) => row.id);
  if (updateAfterDeleteQueries) {
    await afterCreateOrDelete({
      data: deletedIds,
      client,
      queries: updateAfterDeleteQueries,
      type: "delete",
      entity,
    });
  }
  if (afterDelete) afterDelete(deletedIds);

  return deletedIds;
};
