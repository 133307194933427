import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { InputProps } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Props from "./index.d";
import DefaultInput, { DefaultInputProps } from "components/atomics/DefaultInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { setState } from "react-jsonschema-form/lib/utils";
import { gql, useApolloClient } from "@apollo/client";

interface DecimalEditorPropsInner {
  inputProps?: InputProps;
}

// interface DefaultInputProps extends InputProps {}

const useStyles = makeStyles<Theme, DefaultInputProps>(
  (theme) =>
    createStyles({
      decimalEditorRoot: {},
      root: {
        width: "100%",
      },
      titleBox: {},
      title: {
        color: theme.colors.red,
      },
    }),
  { name: "DecimalEditor" }
);

const query = gql`
  query checkIfMoodleModuleExists($moodleModuleId: Int!) {
    checkIfMoodleModuleExists(moodleModuleId: $moodleModuleId)
  }
`;

const DecimalEditorInner: React.FC<Props> = (props) => {
  const {
    data: { state, setstate, setAndSave, onBlur, setNull },
    title,
    disabled,
    raftId,

    ...rest
  } = props;

  const client = useApolloClient();

  const [error, setError] = useState(0);
  const [disabledInner, setdisabledInner] = useState(false);

  const callValidationApi = (moodleModuleId: number) =>
    client
      .query({ query, variables: { moodleModuleId } })
      .then((res) => !!res.data.checkIfMoodleModuleExists)
      .catch((e) => false);

  const validateMoodleId = () => {
    setdisabledInner(true);

    callValidationApi(+state).then((isValid) => {
      if (isValid) {
        onBlur();
        setError(0);
        setdisabledInner(false);
      } else {
        setError(state);
        setdisabledInner(false);
      }
    });
  };
  const classes = useStyles(props);
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = event.target.value
      .replace(",", ".")
      .split(".")
      .map((v, i) => {
        if (v === "" || v === "undefined") return "";
        const num = v !== "" ? v.slice(0, i ? 5 : 15) : "0";
        if (isNaN(+num)) return "0";
        return num;
      })
      .slice(0, 1)
      .join(".");

    if (value.length) {
      setstate((+value as unknown) as number);
    } else {
      if (setNull) {
        setNull();
      } else {
        setstate((+value as unknown) as number);
      }
    }
  };

  const onBlurInner = () => {
    if (!state) {
      if (setNull) {
        setNull();
      } else {
        setAndSave(0);
      }
    } else {
      validateMoodleId();
    }
  };
  return (
    <>
      <DefaultInput
        fullWidth
        className={classes.decimalEditorRoot}
        onBlur={onBlurInner}
        value={state ?? ""}
        label={title}
        onChange={handleChange}
        disabled={disabled || disabledInner}
        // {...rest}
      />
      {!!error && (
        <Typography className={classes.title} variant="h4">
          {`Es existiert aktuell kein Moodle Modul mit der Id ${error}!`}
        </Typography>
      )}
    </>
  );
};

const DecimalEditor: React.FC<Props> = (props) => {
  const { title } = props;
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      {/* <div className={classes.titleBox}>
        <Typography align="left" variant="inherit">
          {title}:
        </Typography>
      </div> */}
      <DecimalEditorInner {...props} />
      {(props.errors ?? []).map((e, index) => (
        <div className={classes.inputTitle}>
          <Typography key={index} align="left" variant="inherit">
            {e}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default DecimalEditor;
