import React from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { Route } from "routes/routes";
import { useLocation } from "react-router-dom";

const Breadcrumb: React.FC<{ routes: Route[] }> = (props) => {
  const { routes } = props;
  const location = useLocation();
  const route: Route | undefined = routes.find((route: Route) =>
    route.subRoutes?.map((subRoute) => location.pathname.includes(subRoute.path))
  );
  const subRoute: Route | undefined = route?.subRoutes?.find((subRoute) => location.pathname.includes(subRoute.path));
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" href={route?.path}>
        {route?.label}
      </Link>
      <Typography color="inherit">{subRoute?.label}</Typography>
    </Breadcrumbs>
  );
};

export default Breadcrumb;
