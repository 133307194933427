import { useHistory, useLocation } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useState } from "react";
import Button from "components/lbc-toolkit/atomics/Button/DefaultButton";
import translations from "translations";
import Modal from "components/lbc-toolkit/molecules/Modal";
import {
  ModulterminCreateForm,
  ModulterminCreateProps,
  ModulterminCreateOnUpdateDataMiddleWare,
} from "raft/ModulterminForm";
import { add } from "date-fns";

type ModulterminCreatePropsInner = Pick<ModulterminCreateProps, "moodleModulId">;

interface ModulTerminAddButtonProps extends ModulterminCreatePropsInner {
  refetch?: () => void;
}

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    addButton: {
      fontSize: 14,
      color: theme.colors.tWhite,
      backgroundColor: theme.colors.sunYellow,
      height: 40,
      borderRadius: "unset",
      "&:hover": { color: theme.colors.tWhite, backgroundColor: theme.colors.sunYellow },
    },
  })
);

const ModulTerminAddButton: React.FC<ModulTerminAddButtonProps> = ({ moodleModulId, refetch }) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleClick() {
    // history.push(`${location.pathname}/addAnmeldung`);
    setIsModalOpen(true);
  }

  const closeModal = () => setIsModalOpen(false);

  const afterCreate = () => {
    if (refetch) refetch();
    setIsModalOpen(false);
  };

  const onUpdateUpdateMiddleware: ModulterminCreateOnUpdateDataMiddleWare = ({ data, origin, changedFields }) => {
    if (origin === "user" && (changedFields.includes("datumVon") || changedFields.includes("dauer")) && data.datumVon) {
      return {
        ...data,
        dauer: data.dauer ?? 90,
        datumBis: add(new Date(data.datumVon as string), { minutes: data.dauer ?? 90 }).toISOString(),
      };
    } else {
      return { ...data };
    }
  };

  return (
    <>
      <Button
        label={`${translations.pages.moodleModul.date} ${translations.globals.button.add}`}
        buttonType="primary"
        onClick={handleClick}
        classes={{ colorPrimary: classes.addButton }}
      />
      <Modal
        open={isModalOpen}
        hideCancel
        hideConfirm
        onClose={closeModal}
        onConfirm={closeModal}
        title={`${translations.pages.moodleModul.module} ${translations.pages.moodleModul.date} ${translations.globals.button.add}`}
      >
        <ModulterminCreateForm
          moodleModulId={moodleModulId}
          onCancel={closeModal}
          afterCreate={afterCreate}
          onUpdateDataMiddleWare={onUpdateUpdateMiddleware}
        />
      </Modal>
    </>
  );
};
export default ModulTerminAddButton;
